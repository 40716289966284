import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Button } from '@ui/components';
import { cn } from '@ui/lib/utils';

import { getBetaCodesQueryOptions } from '@/api/clubs/useGetBetaCodes';
import CreateBetaCodeDialog from '@/components/clubs/CreateBetaCodeDialog';
import DeleteBetaCodeDialog from '@/components/clubs/DeleteBetaCodeDialog';
import PageError from '@/components/errors/PageError';
import { LONG_DATE_FORMAT } from '@/const';
import { format } from 'date-fns';
import { Trash2 } from 'lucide-react';

export const Route = createFileRoute('/_auth/beta-codes')({
  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(getBetaCodesQueryOptions);
  },
  component: BetaCodesPage,
  errorComponent: PageError,
  onError: (err) => console.log(err),
});

function BetaCodesPage() {
  const betaCodesQuery = useSuspenseQuery(getBetaCodesQueryOptions);
  const betaCodes = betaCodesQuery.data;

  return (
    <section className="container">
      <header className="mb-4 flex items-center justify-between">
        <h1 className="font-heading text-xl font-semibold">Beta codes</h1>
        <CreateBetaCodeDialog trigger={<Button>Beta code erstellen</Button>} />
      </header>

      {betaCodes.length === 0 && (
        <div className="mt-12 flex items-center justify-center">
          <h2 className="font-heading text-3xl font-bold">Keine Beta Codes</h2>
        </div>
      )}

      {betaCodes.length > 0 && (
        <ul className="space-y-2">
          {betaCodes.map(
            ({
              id,
              code,
              firstName,
              lastName,
              email,
              deletedAt,
              createdAt,
            }) => (
              <li
                key={id}
                className={cn('flex justify-between rounded border p-4', {
                  'border-green-100 bg-green-100': !!deletedAt,
                  'border-red-300 bg-red-100': !deletedAt,
                })}
              >
                <div>
                  <span className="spacing mb-2 block text-xl font-bold tracking-wider">
                    {code}
                  </span>
                  <span className="mb-1 block text-sm">
                    {firstName} {lastName} - {email}
                  </span>
                  <span className="block text-xs text-muted-foreground">
                    Erstellt am {format(createdAt, LONG_DATE_FORMAT)}
                    {deletedAt && (
                      <>
                        {' '}
                        &bull; Eingelöst am{' '}
                        {format(deletedAt, LONG_DATE_FORMAT)}
                      </>
                    )}
                  </span>
                </div>
                <DeleteBetaCodeDialog
                  id={id}
                  trigger={
                    <Button variant="ghost" size="icon-sm">
                      <Trash2 size={16} />
                    </Button>
                  }
                />
              </li>
            ),
          )}
        </ul>
      )}
    </section>
  );
}
