import { queryOptions, useQuery } from '@tanstack/react-query';

import { clubKeys } from '@/api/clubs/club.keys';
import { authClient } from '@/lib/axios';

export const getClubsQueryOptions = queryOptions({
  queryKey: clubKeys.base,
  queryFn: () => authClient.get('/console/clubs'),
});

export const useGetClubs = () => useQuery(getClubsQueryOptions);
