'use client';

import { createContext, useContext } from 'react';

export type StepState = {
  /**
   * The position of the step
   */
  index: number;
  isLast: boolean;
  onNext: () => void;
};

export const StepContext = createContext<StepState | undefined>(undefined);

export function useStep(): StepState {
  const context = useContext(StepContext);

  if (!context) {
    throw new Error('useStep must be used with StepperProvider');
  }

  return context;
}
