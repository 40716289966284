import {
  ConsoleUser,
  Pageable,
  Pagination,
  UserFilters,
} from '@clubsoul/api-contracts';
import { defaultPagination } from '@clubsoul/const';
import { objectPropsToString } from '@clubsoul/utils';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { userKeys } from '@/api/users/user.keys';
import { authClient } from '@/lib/axios';

export const getUsersQueryOptions = (
  filters: UserFilters = {},
  pagination: Pageable = defaultPagination,
) =>
  queryOptions({
    queryKey: userKeys.get(filters, pagination),
    queryFn: () =>
      authClient.post<Pagination<ConsoleUser>>(
        `/console/users?${new URLSearchParams(objectPropsToString(pagination)).toString()}`,
        filters,
      ),
  });

export const useGetUsers = (filters?: UserFilters, pagination?: Pageable) =>
  useQuery(getUsersQueryOptions(filters, pagination));

export const getUserByIdQueryOptions = (id: string) =>
  queryOptions({
    queryKey: userKeys.getOne(id),
    queryFn: () => authClient.get<ConsoleUser>(`/console/users/${id}`),
  });

export const useGetUser = (id: string) => useQuery(getUserByIdQueryOptions(id));
