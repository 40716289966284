import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ConsoleEditClubRequest,
  ConsoleEditClubSchema,
} from '@clubsoul/api-contracts';
import { ClubStatus, ClubVisibility } from '@clubsoul/const';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormSelect,
  FormWithMethods,
  SelectItem,
  SubmitButton,
  Switch,
} from '@ui/components';
import { getApiError } from '@ui/utils/apiError';
import { mapToDirtyFields } from '@ui/utils/validation';

import { useUpdateClub } from '@/api/clubs/useUpdateClub';
import { LONG_DATE_FORMAT } from '@/const';
import { format } from 'date-fns';

type ClubEditDialogProps = {
  trigger: ReactNode;
  id: string;
  status: ClubStatus;
  visibility: ClubVisibility;
  verifiedAt?: Date;
};

export default function ClubEditDialog({
  trigger,
  id,
  status,
  verifiedAt,
  visibility,
}: ClubEditDialogProps) {
  const [open, setOpen] = useState(false);
  const { mutateAsync: update, isPending, error } = useUpdateClub(id);

  const form = useForm({
    defaultValues: {
      status,
      visibility,
      verified: !!verifiedAt,
    },
    resolver: zodResolver(ConsoleEditClubSchema),
    shouldUnregister: true,
  });

  const handleSubmit = async (data: ConsoleEditClubRequest) => {
    try {
      await update(mapToDirtyFields(data, form.formState.dirtyFields));
      setOpen(false);
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Club bearbeiten</DialogTitle>
        </DialogHeader>

        <FormWithMethods {...form} onSubmit={handleSubmit}>
          <FormSelect name="visibility" label="Sichtbarkeit">
            <SelectItem value={ClubVisibility.PRIVATE}>Privat</SelectItem>
            <SelectItem value={ClubVisibility.PUBLIC}>Öffentlich</SelectItem>
          </FormSelect>
          <FormSelect name="status" label="Status">
            <SelectItem value={ClubStatus.FOUNDING}>In Gründung</SelectItem>
            <SelectItem value={ClubStatus.ACTIVE}>Aktiv</SelectItem>
          </FormSelect>

          <FormField
            name="verified"
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormLabel>Verifiziert</FormLabel>
                <FormControl>
                  <Switch
                    className="block"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                </FormControl>
                {verifiedAt && (
                  <FormDescription>
                    Dieser Club wurde schon am{' '}
                    {format(verifiedAt, LONG_DATE_FORMAT)} verifiziert.
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
          <div>
            <SubmitButton
              isLoading={isPending}
              disabled={Object.keys(form.formState.dirtyFields).length === 0}
            >
              Speichern
            </SubmitButton>
            {error && (
              <span className="mt-2 block text-sm text-red-600">
                {getApiError(error).message ?? 'Fehler aufgetreten'}
              </span>
            )}
          </div>
        </FormWithMethods>
      </DialogContent>
    </Dialog>
  );
}
