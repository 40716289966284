import { SetUserFlagsRequest } from '@clubsoul/api-contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { featureKeys } from '@/api/features/feature.keys';
import { authClient } from '@/lib/axios';

export function useSetUserFeature() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: SetUserFlagsRequest) =>
      authClient.post('/console/users/features', payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: featureKeys.base });
    },
  });
}

export function useSetUserFeatureById() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      payload,
    }: {
      id: string;
      payload: SetUserFlagsRequest;
    }) => authClient.post(`/console/users/features/${id}`, payload),
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: featureKeys.get(id) });
    },
  });
}
