// src/club.ts
var ClubVisibility = /* @__PURE__ */ ((ClubVisibility2) => {
  ClubVisibility2["PRIVATE"] = "PRIVATE";
  ClubVisibility2["PUBLIC"] = "PUBLIC";
  return ClubVisibility2;
})(ClubVisibility || {});
var ClubStatus = /* @__PURE__ */ ((ClubStatus2) => {
  ClubStatus2["FOUNDING"] = "FOUNDING";
  ClubStatus2["ACTIVE"] = "ACTIVE";
  return ClubStatus2;
})(ClubStatus || {});
var MAX_CLUB_BOARD_MEMBERS_SIZE = 15;

// src/const.ts
var KB = 1024;
var MB = KB * KB;

// src/features.ts
var FEATURES_FILE_NAME = "features.json";
var MEMBERSHIPS_ENABLED = {
  enabled: false
};
var PURCHASES_ENABLED = {
  enabled: false
};
var SETTINGS_ENABLED = {
  enabled: false
};

// src/http-status.ts
var HttpStatusCode = /* @__PURE__ */ ((HttpStatusCode2) => {
  HttpStatusCode2[HttpStatusCode2["Continue"] = 100] = "Continue";
  HttpStatusCode2[HttpStatusCode2["SwitchingProtocols"] = 101] = "SwitchingProtocols";
  HttpStatusCode2[HttpStatusCode2["Processing"] = 102] = "Processing";
  HttpStatusCode2[HttpStatusCode2["EarlyHints"] = 103] = "EarlyHints";
  HttpStatusCode2[HttpStatusCode2["Ok"] = 200] = "Ok";
  HttpStatusCode2[HttpStatusCode2["Created"] = 201] = "Created";
  HttpStatusCode2[HttpStatusCode2["Accepted"] = 202] = "Accepted";
  HttpStatusCode2[HttpStatusCode2["NonAuthoritativeInformation"] = 203] = "NonAuthoritativeInformation";
  HttpStatusCode2[HttpStatusCode2["NoContent"] = 204] = "NoContent";
  HttpStatusCode2[HttpStatusCode2["ResetContent"] = 205] = "ResetContent";
  HttpStatusCode2[HttpStatusCode2["PartialContent"] = 206] = "PartialContent";
  HttpStatusCode2[HttpStatusCode2["MultiStatus"] = 207] = "MultiStatus";
  HttpStatusCode2[HttpStatusCode2["AlreadyReported"] = 208] = "AlreadyReported";
  HttpStatusCode2[HttpStatusCode2["ImUsed"] = 226] = "ImUsed";
  HttpStatusCode2[HttpStatusCode2["MultipleChoices"] = 300] = "MultipleChoices";
  HttpStatusCode2[HttpStatusCode2["MovedPermanently"] = 301] = "MovedPermanently";
  HttpStatusCode2[HttpStatusCode2["Found"] = 302] = "Found";
  HttpStatusCode2[HttpStatusCode2["SeeOther"] = 303] = "SeeOther";
  HttpStatusCode2[HttpStatusCode2["NotModified"] = 304] = "NotModified";
  HttpStatusCode2[HttpStatusCode2["UseProxy"] = 305] = "UseProxy";
  HttpStatusCode2[HttpStatusCode2["Unused"] = 306] = "Unused";
  HttpStatusCode2[HttpStatusCode2["TemporaryRedirect"] = 307] = "TemporaryRedirect";
  HttpStatusCode2[HttpStatusCode2["PermanentRedirect"] = 308] = "PermanentRedirect";
  HttpStatusCode2[HttpStatusCode2["BadRequest"] = 400] = "BadRequest";
  HttpStatusCode2[HttpStatusCode2["Unauthorized"] = 401] = "Unauthorized";
  HttpStatusCode2[HttpStatusCode2["PaymentRequired"] = 402] = "PaymentRequired";
  HttpStatusCode2[HttpStatusCode2["Forbidden"] = 403] = "Forbidden";
  HttpStatusCode2[HttpStatusCode2["NotFound"] = 404] = "NotFound";
  HttpStatusCode2[HttpStatusCode2["MethodNotAllowed"] = 405] = "MethodNotAllowed";
  HttpStatusCode2[HttpStatusCode2["NotAcceptable"] = 406] = "NotAcceptable";
  HttpStatusCode2[HttpStatusCode2["ProxyAuthenticationRequired"] = 407] = "ProxyAuthenticationRequired";
  HttpStatusCode2[HttpStatusCode2["RequestTimeout"] = 408] = "RequestTimeout";
  HttpStatusCode2[HttpStatusCode2["Conflict"] = 409] = "Conflict";
  HttpStatusCode2[HttpStatusCode2["Gone"] = 410] = "Gone";
  HttpStatusCode2[HttpStatusCode2["LengthRequired"] = 411] = "LengthRequired";
  HttpStatusCode2[HttpStatusCode2["PreconditionFailed"] = 412] = "PreconditionFailed";
  HttpStatusCode2[HttpStatusCode2["PayloadTooLarge"] = 413] = "PayloadTooLarge";
  HttpStatusCode2[HttpStatusCode2["UriTooLong"] = 414] = "UriTooLong";
  HttpStatusCode2[HttpStatusCode2["UnsupportedMediaType"] = 415] = "UnsupportedMediaType";
  HttpStatusCode2[HttpStatusCode2["RangeNotSatisfiable"] = 416] = "RangeNotSatisfiable";
  HttpStatusCode2[HttpStatusCode2["ExpectationFailed"] = 417] = "ExpectationFailed";
  HttpStatusCode2[HttpStatusCode2["ImATeapot"] = 418] = "ImATeapot";
  HttpStatusCode2[HttpStatusCode2["MisdirectedRequest"] = 421] = "MisdirectedRequest";
  HttpStatusCode2[HttpStatusCode2["UnprocessableEntity"] = 422] = "UnprocessableEntity";
  HttpStatusCode2[HttpStatusCode2["Locked"] = 423] = "Locked";
  HttpStatusCode2[HttpStatusCode2["FailedDependency"] = 424] = "FailedDependency";
  HttpStatusCode2[HttpStatusCode2["TooEarly"] = 425] = "TooEarly";
  HttpStatusCode2[HttpStatusCode2["UpgradeRequired"] = 426] = "UpgradeRequired";
  HttpStatusCode2[HttpStatusCode2["PreconditionRequired"] = 428] = "PreconditionRequired";
  HttpStatusCode2[HttpStatusCode2["TooManyRequests"] = 429] = "TooManyRequests";
  HttpStatusCode2[HttpStatusCode2["RequestHeaderFieldsTooLarge"] = 431] = "RequestHeaderFieldsTooLarge";
  HttpStatusCode2[HttpStatusCode2["UnavailableForLegalReasons"] = 451] = "UnavailableForLegalReasons";
  HttpStatusCode2[HttpStatusCode2["InternalServerError"] = 500] = "InternalServerError";
  HttpStatusCode2[HttpStatusCode2["NotImplemented"] = 501] = "NotImplemented";
  HttpStatusCode2[HttpStatusCode2["BadGateway"] = 502] = "BadGateway";
  HttpStatusCode2[HttpStatusCode2["ServiceUnavailable"] = 503] = "ServiceUnavailable";
  HttpStatusCode2[HttpStatusCode2["GatewayTimeout"] = 504] = "GatewayTimeout";
  HttpStatusCode2[HttpStatusCode2["HttpVersionNotSupported"] = 505] = "HttpVersionNotSupported";
  HttpStatusCode2[HttpStatusCode2["VariantAlsoNegotiates"] = 506] = "VariantAlsoNegotiates";
  HttpStatusCode2[HttpStatusCode2["InsufficientStorage"] = 507] = "InsufficientStorage";
  HttpStatusCode2[HttpStatusCode2["LoopDetected"] = 508] = "LoopDetected";
  HttpStatusCode2[HttpStatusCode2["NotExtended"] = 510] = "NotExtended";
  HttpStatusCode2[HttpStatusCode2["NetworkAuthenticationRequired"] = 511] = "NetworkAuthenticationRequired";
  return HttpStatusCode2;
})(HttpStatusCode || {});

// src/invoice.ts
var InvoiceType = /* @__PURE__ */ ((InvoiceType2) => {
  InvoiceType2["MEMBERSHIP"] = "MEMBERSHIP";
  return InvoiceType2;
})(InvoiceType || {});
var InvoiceStatus = /* @__PURE__ */ ((InvoiceStatus2) => {
  InvoiceStatus2["PENDING"] = "PENDING";
  InvoiceStatus2["CANCELLED"] = "CANCELLED";
  return InvoiceStatus2;
})(InvoiceStatus || {});
var InvoiceItemStatus = /* @__PURE__ */ ((InvoiceItemStatus2) => {
  InvoiceItemStatus2["ACTIVE"] = "ACTIVE";
  InvoiceItemStatus2["CANCELLED"] = "CANCELLED";
  InvoiceItemStatus2["REVISED"] = "REVISED";
  return InvoiceItemStatus2;
})(InvoiceItemStatus || {});

// src/member.ts
var MemberType = /* @__PURE__ */ ((MemberType2) => {
  MemberType2["MEMBER"] = "MEMBER";
  MemberType2["WORKER"] = "WORKER";
  return MemberType2;
})(MemberType || {});
var MemberStatus = /* @__PURE__ */ ((MemberStatus2) => {
  MemberStatus2["JOINING"] = "JOINING";
  MemberStatus2["ACTIVE"] = "ACTIVE";
  MemberStatus2["BLOCKED"] = "BLOCKED";
  MemberStatus2["EXITING"] = "EXITING";
  MemberStatus2["EXITED"] = "EXITED";
  return MemberStatus2;
})(MemberStatus || {});
var InvitationStatus = /* @__PURE__ */ ((InvitationStatus2) => {
  InvitationStatus2["PENDING"] = "PENDING";
  InvitationStatus2["ACCEPTED"] = "ACCEPTED";
  return InvitationStatus2;
})(InvitationStatus || {});
var MemberIdDateType = /* @__PURE__ */ ((MemberIdDateType2) => {
  MemberIdDateType2["YEAR"] = "YEAR";
  return MemberIdDateType2;
})(MemberIdDateType || {});
var MemberWaitlistStatus = /* @__PURE__ */ ((MemberWaitlistStatus2) => {
  MemberWaitlistStatus2["NEW"] = "NEW";
  MemberWaitlistStatus2["INVITED"] = "INVITED";
  MemberWaitlistStatus2["ACCEPTED"] = "ACCEPTED";
  MemberWaitlistStatus2["DECLINED"] = "DECLINED";
  return MemberWaitlistStatus2;
})(MemberWaitlistStatus || {});

// src/membership.ts
var MembershipInterval = /* @__PURE__ */ ((MembershipInterval2) => {
  MembershipInterval2["ONCE"] = "ONCE";
  MembershipInterval2["MONTHLY"] = "MONTHLY";
  MembershipInterval2["QUARTERLY"] = "QUARTERLY";
  MembershipInterval2["SEMIYEARLY"] = "SEMIYEARLY";
  MembershipInterval2["YEARLY"] = "YEARLY";
  return MembershipInterval2;
})(MembershipInterval || {});

// src/mime.ts
var MimeType = /* @__PURE__ */ ((MimeType2) => {
  MimeType2["PLAIN"] = "text/plain";
  MimeType2["CSV"] = "text/csv";
  MimeType2["PNG"] = "image/png";
  MimeType2["JPEG"] = "image/jpeg";
  MimeType2["WEBP"] = "image/webp";
  MimeType2["JSON"] = "application/json";
  MimeType2["PDF"] = "application/pdf";
  MimeType2["EXCEL"] = "application/vnd.ms-excel";
  MimeType2["EXCEL_OPEN_XML"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  return MimeType2;
})(MimeType || {});
var MimeTypeExtension = {
  ["text/plain" /* PLAIN */]: ".txt",
  ["text/csv" /* CSV */]: ".csv",
  ["image/png" /* PNG */]: ".png",
  ["image/jpeg" /* JPEG */]: ".jpeg",
  ["image/webp" /* WEBP */]: ".webp",
  ["application/json" /* JSON */]: ".json",
  ["application/pdf" /* PDF */]: ".pdf",
  ["application/vnd.ms-excel" /* EXCEL */]: ".xls",
  ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /* EXCEL_OPEN_XML */]: ".xlsx"
};

// src/pagination.ts
var PAGE_PARAM_NAME = "page";
var SIZE_PARAM_NAME = "size";
var DEFAULT_PAGE = 1;
var DEFAULT_SIZE = 15;
var defaultPagination = { size: DEFAULT_SIZE, page: DEFAULT_PAGE };

// src/permission.ts
var PermissionName = /* @__PURE__ */ ((PermissionName2) => {
  PermissionName2["DASHBOARD_ACCESS"] = "DASHBOARD_ACCESS";
  PermissionName2["CLUB_READ"] = "CLUB_READ";
  PermissionName2["CLUB_UPDATE"] = "CLUB_UPDATE";
  PermissionName2["CLUB_DELETE"] = "CLUB_DELETE";
  PermissionName2["CLUB_ROLE_CREATE"] = "CLUB_ROLE_CREATE";
  PermissionName2["CLUB_ROLE_UPDATE"] = "CLUB_ROLE_UPDATE";
  PermissionName2["CLUB_ROLE_DELETE"] = "CLUB_ROLE_DELETE";
  PermissionName2["MEMBER_READ"] = "MEMBER_READ";
  PermissionName2["MEMBER_CREATE"] = "MEMBER_CREATE";
  PermissionName2["MEMBER_UPDATE"] = "MEMBER_UPDATE";
  PermissionName2["MEMBER_DELETE"] = "MEMBER_DELETE";
  PermissionName2["MEMBERSHIP_READ"] = "MEMBERSHIP_READ";
  PermissionName2["MEMBERSHIP_CREATE"] = "MEMBERSHIP_CREATE";
  PermissionName2["MEMBERSHIP_UPDATE"] = "MEMBERSHIP_UPDATE";
  PermissionName2["MEMBERSHIP_DELETE"] = "MEMBERSHIP_DELETE";
  return PermissionName2;
})(PermissionName || {});

// src/person.ts
var Gender = /* @__PURE__ */ ((Gender2) => {
  Gender2["MALE"] = "MALE";
  Gender2["FEMALE"] = "FEMALE";
  Gender2["NON_BINARY"] = "NON_BINARY";
  return Gender2;
})(Gender || {});
var Salutation = /* @__PURE__ */ ((Salutation2) => {
  Salutation2["MR"] = "MR";
  Salutation2["MRS"] = "MRS";
  return Salutation2;
})(Salutation || {});
var Title = /* @__PURE__ */ ((Title2) => {
  Title2["DR"] = "DR";
  Title2["PROF"] = "PROF";
  return Title2;
})(Title || {});

// src/regex.ts
var ISO_DATE_REGEX = /^([1-3]{1}\d{3})-(([0]{1}[1-9]{1})|([1]{1}[0-2]{1}))-(([0]{1}[1-9]{1})|([1-2]{1}[0-9]{1})|([3]{1}[0-1]{1}))$/;

// src/validation.ts
var ValidationMessage = {
  required: "Pflichtfeld",
  min: (fieldName, min) => `${fieldName} muss aus mindestens ${min} Buchstaben bestehen`,
  minNumber: (fieldName, min) => `${fieldName} muss aus mindestens ${min} Zahlen bestehen`,
  max: (fieldName, max) => `${fieldName} darf maximal aus ${max} Buchstaben bestehen`,
  maxNumber: (fieldName, max) => `${fieldName} darf maximal aus ${max} Zahlen bestehen`,
  invalidEmail: "Die E-Mail Addresse ist ung\xFCltig",
  invalidPhone: "Die Telefonnummer ist ung\xFCltig",
  invalidUrl: "Ung\xFCltige URL. Beispiel: https://beispiel.de"
};
export {
  ClubStatus,
  ClubVisibility,
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  FEATURES_FILE_NAME,
  Gender,
  HttpStatusCode,
  ISO_DATE_REGEX,
  InvitationStatus,
  InvoiceItemStatus,
  InvoiceStatus,
  InvoiceType,
  KB,
  MAX_CLUB_BOARD_MEMBERS_SIZE,
  MB,
  MEMBERSHIPS_ENABLED,
  MemberIdDateType,
  MemberStatus,
  MemberType,
  MemberWaitlistStatus,
  MembershipInterval,
  MimeType,
  MimeTypeExtension,
  PAGE_PARAM_NAME,
  PURCHASES_ENABLED,
  PermissionName,
  SETTINGS_ENABLED,
  SIZE_PARAM_NAME,
  Salutation,
  Title,
  ValidationMessage,
  defaultPagination
};
