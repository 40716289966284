import { CreateBetaCodeRequest } from '@clubsoul/api-contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { clubKeys } from '@/api/clubs/club.keys';
import { authClient } from '@/lib/axios';

export const useCreateBetaCode = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fields: CreateBetaCodeRequest) =>
      authClient.post(`console/clubs/beta-codes`, fields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clubKeys.getBetaCodes() });
    },
  });
};
