import { Badge } from '@ui/components';

type ClubVerifiedBadgeProps = {
  verifiedAt?: Date;
};

export default function ClubVerifiedBadge({
  verifiedAt,
}: ClubVerifiedBadgeProps) {
  if (verifiedAt) {
    return (
      <Badge size="sm" variant="active">
        Verifiziert
      </Badge>
    );
  }

  return (
    <Badge size="sm" variant="inactive">
      Nicht verifiziert
    </Badge>
  );
}
