import { useSuspenseQuery } from '@tanstack/react-query';
import { Link, createFileRoute } from '@tanstack/react-router';
import { Loader } from '@ui/components';

import { getClubsQueryOptions } from '@/api/clubs/useGetClubs';
import ClubStatusBadge from '@/components/clubs/ClubStatusBadge';
import ClubVerifiedBadge from '@/components/clubs/ClubVerifiedBadge';
import ClubVisibilityBadge from '@/components/clubs/ClubVisibilityBadge';
import PageError from '@/components/errors/PageError';

export const Route = createFileRoute('/_auth/clubs/')({
  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(getClubsQueryOptions);
  },
  component: ClubsPage,
  errorComponent: PageError,
  pendingComponent: () => (
    <div className="container">
      <Loader />
    </div>
  ),
  onError: (err) => console.log(err),
});

function ClubsPage() {
  const clubsQuery = useSuspenseQuery(getClubsQueryOptions);
  const clubs = clubsQuery.data;

  return (
    <section className="container">
      <h1 className="mb-4 font-heading text-xl font-semibold">
        {clubs.length} Clubs
      </h1>
      <ul className="flex flex-col gap-2">
        {clubs.map(({ id, name, cid, visibility, status, verifiedAt }: any) => (
          <li
            key={id}
            className="rounded-md border transition ease-linear hover:bg-border"
          >
            <Link to={id} className="inline-block p-4">
              <h2 className="mb-2">
                {name} - <strong>{cid}</strong>
              </h2>
              <div className="flex gap-2 text-sm">
                <ClubVisibilityBadge visibility={visibility} />
                <ClubStatusBadge status={status} />
                <ClubVerifiedBadge verifiedAt={verifiedAt} />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
}
