import { HttpStatusCode } from 'axios';
import { ZodIssue } from 'zod';

export const enum ApiError {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export interface BaseApiError {
  statusCode: HttpStatusCode;
  path: string;
  message: string;
}

export interface ApiErrorResponse<ErrorCode = any> {
  statusCode: number;
  error: ApiError & ErrorCode;
  path: string;
  message: string;
}

export interface ValidationErrorResponse
  extends Pick<ApiErrorResponse, 'statusCode' | 'path'> {
  error: ApiError.VALIDATION_ERROR;
  issues: ZodIssue[];
}
