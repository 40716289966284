import { ReactNode } from 'react';

import { Badge } from '@ui/components';

import Property from '@/components/common/Property';

type ConfigPropertyProps = {
  label: string;
  value: ReactNode | boolean;
};

export default function ConfigProperty({ label, value }: ConfigPropertyProps) {
  if (typeof value === 'boolean') {
    return (
      <div>
        <span className="block font-semibold">{label}</span>
        {value ? (
          <Badge variant="active" size="sm">
            Ja
          </Badge>
        ) : (
          <Badge variant="destructive" size="sm">
            Nein
          </Badge>
        )}
      </div>
    );
  }
  return <Property label={label} value={label} />;
}
