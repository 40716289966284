import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';
import {
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { z } from 'zod';

import { FormBase } from '../../components/ui/form-base';

import { cn } from '../../lib/utils';

import { Button, ButtonProps } from '../../components/ui/button';
import { Separator } from '../../components/ui/separator';

export type FormProps<
  TFieldValues extends FieldValues = FieldValues,
  Context = any,
  Response = unknown,
> = Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> &
  Omit<UseFormProps<TFieldValues, Context>, 'resolver'> & {
    schema: z.ZodType<TFieldValues>;
    onSubmit: (values: TFieldValues) => Response;
  };

/**
 * Wrapper for react-hook-form
 */
export function Form<
  TFieldValues extends FieldValues = FieldValues,
  Context = any,
>(props: FormProps<TFieldValues, Context>) {
  const {
    mode = 'onSubmit',
    reValidateMode = 'onChange',
    defaultValues,
    values,
    resetOptions,
    context,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
    criteriaMode,
    delayError,
    children,
    onSubmit,
    className,
    schema,
    ...otherProps
  } = props;

  const formMethods = useForm<TFieldValues>({
    mode,
    reValidateMode,
    defaultValues,
    values,
    resetOptions,
    resolver: zodResolver(schema),
    context,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
    criteriaMode,
    delayError,
  });

  const handleSubmit = formMethods.handleSubmit((formValues) =>
    onSubmit(formValues),
  );

  return (
    <FormBase<TFieldValues> {...formMethods}>
      <form
        className={cn('flex flex-col gap-4', className)}
        onSubmit={handleSubmit}
        {...otherProps}
      >
        {children}
      </form>
    </FormBase>
  );
}

export type FormWithMethodsProps<
  TFieldValues extends FieldValues = FieldValues,
  Context = any,
  Response = unknown,
> = UseFormReturn<TFieldValues, Context> & {
  className?: string;
  onSubmit: (values: TFieldValues) => Response;
  children: ReactNode;
};

export function FormWithMethods<
  TFieldValues extends FieldValues = FieldValues,
  Context = any,
>(props: FormWithMethodsProps<TFieldValues, Context>) {
  const { className, onSubmit, children, ...formMethods } = props;

  return (
    <FormBase<TFieldValues> {...formMethods}>
      <form
        className={cn('space-y-8', className)}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </FormBase>
  );
}

export type FormTitleProps = {
  /**
   * @default h2
   */
  tag?: ElementType;
  children?: ReactNode;
  className?: string;
};

function FormTitle(props: FormTitleProps) {
  const { tag: Tag = 'h2', children, className, ...otherProps } = props;

  return (
    <Tag
      className={cn('mb-4 text-lg font-semibold', className)}
      {...otherProps}
    >
      {children}
    </Tag>
  );
}

export type FormDividerProps = {
  className?: string;
};

function FormDivider(props: FormDividerProps) {
  const { className, ...otherProps } = props;

  return <Separator className={cn(className)} {...otherProps} />;
}

export type FormSectionProps = {
  className?: string;
  children: ReactNode;
};

function FormSection(props: FormSectionProps) {
  const { children, className, ...otherProps } = props;

  return (
    <div className={cn('space-y-4', className)} {...otherProps}>
      {children}
    </div>
  );
}

export type FormRowProps = {
  className?: string;
  children: ReactNode;
};

function FormRow(props: FormRowProps) {
  const { children, className, ...otherProps } = props;

  return (
    <div
      className={cn(
        'flex flex-col gap-4 md:flex-row [&>div]:grow md:[&>div]:basis-1/2',
        className,
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export type FormCancelButtonProps = ButtonProps;

export function FormCancelButton(props: FormCancelButtonProps) {
  const { children = 'Abbrechen', ...otherProps } = props;

  return (
    <Button type="button" variant={'outline'} {...otherProps}>
      {children}
    </Button>
  );
}

Form.Title = FormTitle;
Form.Divider = FormDivider;
Form.Section = FormSection;
Form.Row = FormRow;
Form.CancelButton = FormCancelButton;
