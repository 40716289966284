import { ReactElement } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form-base';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';

type FormSelectProps<T extends FieldValues> = {
  name: Path<T>;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  children: ReactElement<typeof SelectItem> | ReactElement<typeof SelectItem>[];
};

const RESET_KEY = '__reset';

export function FormSelect<T extends FieldValues>(props: FormSelectProps<T>) {
  const { name, placeholder, label, disabled, className, children, required } =
    props;
  const { control } = useFormContext<T>();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>
            {label} {required && '*'}
          </FormLabel>
          <FormControl>
            <Select
              value={field.value}
              disabled={disabled}
              onValueChange={(value) => {
                if (value === RESET_KEY) {
                  field.onChange('');
                  return;
                }
                field.onChange(value);
              }}
              required={required}
            >
              <SelectTrigger ref={field.ref}>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
              <SelectContent>
                {(field.value !== '' || field.value !== undefined) && (
                  <SelectItem value={RESET_KEY}>
                    <span className="text-red-500">Auswahl löschen</span>
                  </SelectItem>
                )}
                {children}
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
