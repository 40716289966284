'use client';

import { useState } from 'react';

import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  TableOptions,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Loader2,
} from 'lucide-react';

import { Button } from './button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select';

import { cn } from '../../lib/utils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './table';

// Should be aligned with the one in @clubsoul/api-contracts
// Duplicating so this package has no dependencies
interface Pagination<T> {
  content: T[];
  meta: {
    page: number;
    size: number;
    total: number;
    totalPages: number;
    currentSize: number;
    hasPrev: boolean;
    hasNext: boolean;
  };
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  meta?: Pagination<TData>['meta'];
  onPaginationChange?: OnChangeFn<PaginationState>;
  pagination?: PaginationState;
  onRowClick?: (row: TData) => void;
  emptyLabel?: string;
  isLoading?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  meta,
  pagination: externalPagination,
  onPaginationChange,
  onRowClick,
  emptyLabel = 'Keine Daten',
  isLoading = false,
}: DataTableProps<TData, TValue>) {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  let tableProps: TableOptions<TData> = {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  };

  if (externalPagination && meta) {
    tableProps = {
      ...tableProps,
      pageCount: meta.totalPages ?? -1,
      onPaginationChange,
      manualPagination: true,
      state: {
        pagination: externalPagination,
      },
    };
  } else {
    tableProps = {
      ...tableProps,
      getPaginationRowModel: getPaginationRowModel(),
      onPaginationChange: setPagination,
      state: {
        pagination,
      },
    };
  }

  const table = useReactTable(tableProps);

  return (
    <div>
      <Table className="whitespace-nowrap">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                <div className="flex h-full w-full flex-col items-center justify-center">
                  <Loader2 className="mb-2 animate-spin" />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    role={onRowClick ? 'button' : 'row'}
                    tabIndex={onRowClick ? 0 : -1}
                    data-state={row.getIsSelected() && 'selected'}
                    onClick={() => onRowClick?.(row.original)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onRowClick?.(row.original);
                      }
                    }}
                    className={cn({
                      'cursor-pointer': !!onRowClick,
                    })}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {emptyLabel}
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>

      <div className="flex items-center justify-end space-x-1 py-4">
        <Button
          variant="outline"
          size="xs"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronsLeft className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          size="xs"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <span className="flex select-none items-center gap-1 text-sm">
          {table.getState().pagination.pageIndex + 1} von{' '}
          {table.getPageCount() || 1}
        </span>
        <Button
          variant="outline"
          size="xs"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          size="xs"
          onClick={() => table.setPageIndex(table.getPageCount())}
          disabled={!table.getCanNextPage()}
        >
          <ChevronsRight className="h-4 w-4" />
        </Button>
        {/* <span className="block text-sm">Zeilen</span> */}
        <Select
          value={table.getState().pagination.pageSize.toString()}
          onValueChange={(size) => table.setPageSize(parseInt(size))}
        >
          <SelectTrigger className="h-[26px] w-[max-content] select-none gap-2 px-2 py-1">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="10">10</SelectItem>
            <SelectItem value="15">15</SelectItem>
            <SelectItem value="30">30</SelectItem>
            <SelectItem value="50">50</SelectItem>
            <SelectItem value="100">100</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
