import { PropsWithChildren, useEffect, useState } from 'react';

import { auth } from '@/lib/firebase';

import { AuthContext, ConsoleUser } from './useAuth';

export function AuthProvider({ children }: PropsWithChildren) {
  const [user, setUser] = useState<ConsoleUser | null>(null);
  const isAuthenticated = !!auth.currentUser;

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setUser(null);
        return;
      }

      const result = await user.getIdTokenResult();
      setUser(result.claims.clubsoul as ConsoleUser);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}
