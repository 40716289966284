import { ClubStatus } from '@clubsoul/const';
import { Badge } from '@ui/components';

type ClubStatusBadgeProps = {
  status: ClubStatus;
};

const statusToText: Record<ClubStatus, string> = {
  [ClubStatus.FOUNDING]: 'In Gründung',
  [ClubStatus.ACTIVE]: 'Aktiv',
};

export default function ClubStatusBadge({ status }: ClubStatusBadgeProps) {
  if (status === ClubStatus.FOUNDING) {
    return (
      <Badge
        size="sm"
        className="border-transparent bg-orange-400 text-primary-foreground hover:bg-orange-400"
      >
        {statusToText[status]}
      </Badge>
    );
  }

  return (
    <Badge size="sm" variant={'active'}>
      {statusToText[status]}
    </Badge>
  );
}
