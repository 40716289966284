// src/data/countries.ts
var countries = [
  {
    id: 1,
    name: "Afghanistan",
    iso3: "AFG",
    iso2: "AF",
    numeric_code: "004",
    phone_code: "93",
    capital: "Kabul",
    currency: "AFN",
    currency_name: "Afghan afghani",
    currency_symbol: "\u060B",
    tld: ".af",
    native: "\u0627\u0641\u063A\u0627\u0646\u0633\u062A\u0627\u0646",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Afghan",
    timezones: [
      {
        zoneName: "Asia/Kabul",
        gmtOffset: 16200,
        gmtOffsetName: "UTC+04:30",
        abbreviation: "AFT",
        tzName: "Afghanistan Time"
      }
    ],
    translations: {
      kr: "\uC544\uD504\uAC00\uB2C8\uC2A4\uD0C4",
      "pt-BR": "Afeganist\xE3o",
      pt: "Afeganist\xE3o",
      nl: "Afghanistan",
      hr: "Afganistan",
      fa: "\u0627\u0641\u063A\u0627\u0646\u0633\u062A\u0627\u0646",
      de: "Afghanistan",
      es: "Afganist\xE1n",
      fr: "Afghanistan",
      ja: "\u30A2\u30D5\u30AC\u30CB\u30B9\u30BF\u30F3",
      it: "Afghanistan",
      cn: "\u963F\u5BCC\u6C57",
      tr: "Afganistan"
    },
    latitude: "33.00000000",
    longitude: "65.00000000",
    emoji: "\u{1F1E6}\u{1F1EB}",
    emojiU: "U+1F1E6 U+1F1EB"
  },
  {
    id: 2,
    name: "Aland Islands",
    iso3: "ALA",
    iso2: "AX",
    numeric_code: "248",
    phone_code: "358",
    capital: "Mariehamn",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".ax",
    native: "\xC5land",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Aland Island",
    timezones: [
      {
        zoneName: "Europe/Mariehamn",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uC62C\uB780\uB4DC \uC81C\uB3C4",
      "pt-BR": "Ilhas de Aland",
      pt: "Ilhas de Aland",
      nl: "\xC5landeilanden",
      hr: "\xC5landski otoci",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u0627\u0644\u0646\u062F",
      de: "\xC5land",
      es: "Alandia",
      fr: "\xC5land",
      ja: "\u30AA\u30FC\u30E9\u30F3\u30C9\u8AF8\u5CF6",
      it: "Isole Aland",
      cn: "\u5965\u5170\u7FA4\u5C9B",
      tr: "\xC5land Adalari"
    },
    latitude: "60.11666700",
    longitude: "19.90000000",
    emoji: "\u{1F1E6}\u{1F1FD}",
    emojiU: "U+1F1E6 U+1F1FD"
  },
  {
    id: 3,
    name: "Albania",
    iso3: "ALB",
    iso2: "AL",
    numeric_code: "008",
    phone_code: "355",
    capital: "Tirana",
    currency: "ALL",
    currency_name: "Albanian lek",
    currency_symbol: "Lek",
    tld: ".al",
    native: "Shqip\xEBria",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Albanian",
    timezones: [
      {
        zoneName: "Europe/Tirane",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC54C\uBC14\uB2C8\uC544",
      "pt-BR": "Alb\xE2nia",
      pt: "Alb\xE2nia",
      nl: "Albani\xEB",
      hr: "Albanija",
      fa: "\u0622\u0644\u0628\u0627\u0646\u06CC",
      de: "Albanien",
      es: "Albania",
      fr: "Albanie",
      ja: "\u30A2\u30EB\u30D0\u30CB\u30A2",
      it: "Albania",
      cn: "\u963F\u5C14\u5DF4\u5C3C\u4E9A",
      tr: "Arnavutluk"
    },
    latitude: "41.00000000",
    longitude: "20.00000000",
    emoji: "\u{1F1E6}\u{1F1F1}",
    emojiU: "U+1F1E6 U+1F1F1"
  },
  {
    id: 4,
    name: "Algeria",
    iso3: "DZA",
    iso2: "DZ",
    numeric_code: "012",
    phone_code: "213",
    capital: "Algiers",
    currency: "DZD",
    currency_name: "Algerian dinar",
    currency_symbol: "\u062F\u062C",
    tld: ".dz",
    native: "\u0627\u0644\u062C\u0632\u0627\u0626\u0631",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Algerian",
    timezones: [
      {
        zoneName: "Africa/Algiers",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC54C\uC81C\uB9AC",
      "pt-BR": "Arg\xE9lia",
      pt: "Arg\xE9lia",
      nl: "Algerije",
      hr: "Al\u017Eir",
      fa: "\u0627\u0644\u062C\u0632\u0627\u06CC\u0631",
      de: "Algerien",
      es: "Argelia",
      fr: "Alg\xE9rie",
      ja: "\u30A2\u30EB\u30B8\u30A7\u30EA\u30A2",
      it: "Algeria",
      cn: "\u963F\u5C14\u53CA\u5229\u4E9A",
      tr: "Cezayir"
    },
    latitude: "28.00000000",
    longitude: "3.00000000",
    emoji: "\u{1F1E9}\u{1F1FF}",
    emojiU: "U+1F1E9 U+1F1FF"
  },
  {
    id: 5,
    name: "American Samoa",
    iso3: "ASM",
    iso2: "AS",
    numeric_code: "016",
    phone_code: "1",
    capital: "Pago Pago",
    currency: "USD",
    currency_name: "US Dollar",
    currency_symbol: "$",
    tld: ".as",
    native: "American Samoa",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "American Samoan",
    timezones: [
      {
        zoneName: "Pacific/Pago_Pago",
        gmtOffset: -39600,
        gmtOffsetName: "UTC-11:00",
        abbreviation: "SST",
        tzName: "Samoa Standard Time"
      }
    ],
    translations: {
      kr: "\uC544\uBA54\uB9AC\uCE78\uC0AC\uBAA8\uC544",
      "pt-BR": "Samoa Americana",
      pt: "Samoa Americana",
      nl: "Amerikaans Samoa",
      hr: "Ameri\u010Dka Samoa",
      fa: "\u0633\u0627\u0645\u0648\u0622\u06CC \u0622\u0645\u0631\u06CC\u06A9\u0627",
      de: "Amerikanisch-Samoa",
      es: "Samoa Americana",
      fr: "Samoa am\xE9ricaines",
      ja: "\u30A2\u30E1\u30EA\u30AB\u9818\u30B5\u30E2\u30A2",
      it: "Samoa Americane",
      cn: "\u7F8E\u5C5E\u8428\u6469\u4E9A",
      tr: "Amerikan Samoasi"
    },
    latitude: "-14.33333333",
    longitude: "-170.00000000",
    emoji: "\u{1F1E6}\u{1F1F8}",
    emojiU: "U+1F1E6 U+1F1F8"
  },
  {
    id: 6,
    name: "Andorra",
    iso3: "AND",
    iso2: "AD",
    numeric_code: "020",
    phone_code: "376",
    capital: "Andorra la Vella",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".ad",
    native: "Andorra",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Andorran",
    timezones: [
      {
        zoneName: "Europe/Andorra",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC548\uB3C4\uB77C",
      "pt-BR": "Andorra",
      pt: "Andorra",
      nl: "Andorra",
      hr: "Andora",
      fa: "\u0622\u0646\u062F\u0648\u0631\u0627",
      de: "Andorra",
      es: "Andorra",
      fr: "Andorre",
      ja: "\u30A2\u30F3\u30C9\u30E9",
      it: "Andorra",
      cn: "\u5B89\u9053\u5C14",
      tr: "Andorra"
    },
    latitude: "42.50000000",
    longitude: "1.50000000",
    emoji: "\u{1F1E6}\u{1F1E9}",
    emojiU: "U+1F1E6 U+1F1E9"
  },
  {
    id: 7,
    name: "Angola",
    iso3: "AGO",
    iso2: "AO",
    numeric_code: "024",
    phone_code: "244",
    capital: "Luanda",
    currency: "AOA",
    currency_name: "Angolan kwanza",
    currency_symbol: "Kz",
    tld: ".ao",
    native: "Angola",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Angolan",
    timezones: [
      {
        zoneName: "Africa/Luanda",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uC559\uACE8\uB77C",
      "pt-BR": "Angola",
      pt: "Angola",
      nl: "Angola",
      hr: "Angola",
      fa: "\u0622\u0646\u06AF\u0648\u0644\u0627",
      de: "Angola",
      es: "Angola",
      fr: "Angola",
      ja: "\u30A2\u30F3\u30B4\u30E9",
      it: "Angola",
      cn: "\u5B89\u54E5\u62C9",
      tr: "Angola"
    },
    latitude: "-12.50000000",
    longitude: "18.50000000",
    emoji: "\u{1F1E6}\u{1F1F4}",
    emojiU: "U+1F1E6 U+1F1F4"
  },
  {
    id: 8,
    name: "Anguilla",
    iso3: "AIA",
    iso2: "AI",
    numeric_code: "660",
    phone_code: "1",
    capital: "The Valley",
    currency: "XCD",
    currency_name: "East Caribbean dollar",
    currency_symbol: "$",
    tld: ".ai",
    native: "Anguilla",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Anguillan",
    timezones: [
      {
        zoneName: "America/Anguilla",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC575\uADC8\uB77C",
      "pt-BR": "Anguila",
      pt: "Anguila",
      nl: "Anguilla",
      hr: "Angvila",
      fa: "\u0622\u0646\u06AF\u0648\u06CC\u0644\u0627",
      de: "Anguilla",
      es: "Anguilla",
      fr: "Anguilla",
      ja: "\u30A2\u30F3\u30AE\u30E9",
      it: "Anguilla",
      cn: "\u5B89\u572D\u62C9",
      tr: "Anguilla"
    },
    latitude: "18.25000000",
    longitude: "-63.16666666",
    emoji: "\u{1F1E6}\u{1F1EE}",
    emojiU: "U+1F1E6 U+1F1EE"
  },
  {
    id: 9,
    name: "Antarctica",
    iso3: "ATA",
    iso2: "AQ",
    numeric_code: "010",
    phone_code: "672",
    capital: "",
    currency: "AAD",
    currency_name: "Antarctican dollar",
    currency_symbol: "$",
    tld: ".aq",
    native: "Antarctica",
    region: "Polar",
    region_id: "6",
    subregion: "",
    subregion_id: null,
    nationality: "Antarctic",
    timezones: [
      {
        zoneName: "Antarctica/Casey",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AWST",
        tzName: "Australian Western Standard Time"
      },
      {
        zoneName: "Antarctica/Davis",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "DAVT",
        tzName: "Davis Time"
      },
      {
        zoneName: "Antarctica/DumontDUrville",
        gmtOffset: 36e3,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "DDUT",
        tzName: "Dumont d'Urville Time"
      },
      {
        zoneName: "Antarctica/Mawson",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "MAWT",
        tzName: "Mawson Station Time"
      },
      {
        zoneName: "Antarctica/McMurdo",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "NZDT",
        tzName: "New Zealand Daylight Time"
      },
      {
        zoneName: "Antarctica/Palmer",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "CLST",
        tzName: "Chile Summer Time"
      },
      {
        zoneName: "Antarctica/Rothera",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ROTT",
        tzName: "Rothera Research Station Time"
      },
      {
        zoneName: "Antarctica/Syowa",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "SYOT",
        tzName: "Showa Station Time"
      },
      {
        zoneName: "Antarctica/Troll",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      },
      {
        zoneName: "Antarctica/Vostok",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "VOST",
        tzName: "Vostok Station Time"
      }
    ],
    translations: {
      kr: "\uB0A8\uADF9",
      "pt-BR": "Ant\xE1rtida",
      pt: "Ant\xE1rctida",
      nl: "Antarctica",
      hr: "Antarktika",
      fa: "\u062C\u0646\u0648\u0628\u06AF\u0627\u0646",
      de: "Antarktika",
      es: "Ant\xE1rtida",
      fr: "Antarctique",
      ja: "\u5357\u6975\u5927\u9678",
      it: "Antartide",
      cn: "\u5357\u6781\u6D32",
      tr: "Antartika"
    },
    latitude: "-74.65000000",
    longitude: "4.48000000",
    emoji: "\u{1F1E6}\u{1F1F6}",
    emojiU: "U+1F1E6 U+1F1F6"
  },
  {
    id: 10,
    name: "Antigua And Barbuda",
    iso3: "ATG",
    iso2: "AG",
    numeric_code: "028",
    phone_code: "1",
    capital: "St. John's",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".ag",
    native: "Antigua and Barbuda",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Antiguan or Barbudan",
    timezones: [
      {
        zoneName: "America/Antigua",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC564\uD2F0\uAC00 \uBC14\uBD80\uB2E4",
      "pt-BR": "Ant\xEDgua e Barbuda",
      pt: "Ant\xEDgua e Barbuda",
      nl: "Antigua en Barbuda",
      hr: "Antigva i Barbuda",
      fa: "\u0622\u0646\u062A\u06CC\u06AF\u0648\u0627 \u0648 \u0628\u0627\u0631\u0628\u0648\u062F\u0627",
      de: "Antigua und Barbuda",
      es: "Antigua y Barbuda",
      fr: "Antigua-et-Barbuda",
      ja: "\u30A2\u30F3\u30C6\u30A3\u30B0\u30A2\u30FB\u30D0\u30FC\u30D6\u30FC\u30C0",
      it: "Antigua e Barbuda",
      cn: "\u5B89\u63D0\u74DC\u548C\u5DF4\u5E03\u8FBE",
      tr: "Antigua Ve Barbuda"
    },
    latitude: "17.05000000",
    longitude: "-61.80000000",
    emoji: "\u{1F1E6}\u{1F1EC}",
    emojiU: "U+1F1E6 U+1F1EC"
  },
  {
    id: 11,
    name: "Argentina",
    iso3: "ARG",
    iso2: "AR",
    numeric_code: "032",
    phone_code: "54",
    capital: "Buenos Aires",
    currency: "ARS",
    currency_name: "Argentine peso",
    currency_symbol: "$",
    tld: ".ar",
    native: "Argentina",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Argentine",
    timezones: [
      {
        zoneName: "America/Argentina/Buenos_Aires",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/Catamarca",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/Cordoba",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/Jujuy",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/La_Rioja",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/Mendoza",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/Rio_Gallegos",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/Salta",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/San_Juan",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/San_Luis",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/Tucuman",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      },
      {
        zoneName: "America/Argentina/Ushuaia",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time"
      }
    ],
    translations: {
      kr: "\uC544\uB974\uD5E8\uD2F0\uB098",
      "pt-BR": "Argentina",
      pt: "Argentina",
      nl: "Argentini\xEB",
      hr: "Argentina",
      fa: "\u0622\u0631\u0698\u0627\u0646\u062A\u06CC\u0646",
      de: "Argentinien",
      es: "Argentina",
      fr: "Argentine",
      ja: "\u30A2\u30EB\u30BC\u30F3\u30C1\u30F3",
      it: "Argentina",
      cn: "\u963F\u6839\u5EF7",
      tr: "Arjantin"
    },
    latitude: "-34.00000000",
    longitude: "-64.00000000",
    emoji: "\u{1F1E6}\u{1F1F7}",
    emojiU: "U+1F1E6 U+1F1F7"
  },
  {
    id: 12,
    name: "Armenia",
    iso3: "ARM",
    iso2: "AM",
    numeric_code: "051",
    phone_code: "374",
    capital: "Yerevan",
    currency: "AMD",
    currency_name: "Armenian dram",
    currency_symbol: "\u058F",
    tld: ".am",
    native: "\u0540\u0561\u0575\u0561\u057D\u057F\u0561\u0576",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Armenian",
    timezones: [
      {
        zoneName: "Asia/Yerevan",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "AMT",
        tzName: "Armenia Time"
      }
    ],
    translations: {
      kr: "\uC544\uB974\uBA54\uB2C8\uC544",
      "pt-BR": "Arm\xEAnia",
      pt: "Arm\xE9nia",
      nl: "Armeni\xEB",
      hr: "Armenija",
      fa: "\u0627\u0631\u0645\u0646\u0633\u062A\u0627\u0646",
      de: "Armenien",
      es: "Armenia",
      fr: "Arm\xE9nie",
      ja: "\u30A2\u30EB\u30E1\u30CB\u30A2",
      it: "Armenia",
      cn: "\u4E9A\u7F8E\u5C3C\u4E9A",
      tr: "Ermenistan"
    },
    latitude: "40.00000000",
    longitude: "45.00000000",
    emoji: "\u{1F1E6}\u{1F1F2}",
    emojiU: "U+1F1E6 U+1F1F2"
  },
  {
    id: 13,
    name: "Aruba",
    iso3: "ABW",
    iso2: "AW",
    numeric_code: "533",
    phone_code: "297",
    capital: "Oranjestad",
    currency: "AWG",
    currency_name: "Aruban florin",
    currency_symbol: "\u0192",
    tld: ".aw",
    native: "Aruba",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Aruban",
    timezones: [
      {
        zoneName: "America/Aruba",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC544\uB8E8\uBC14",
      "pt-BR": "Aruba",
      pt: "Aruba",
      nl: "Aruba",
      hr: "Aruba",
      fa: "\u0622\u0631\u0648\u0628\u0627",
      de: "Aruba",
      es: "Aruba",
      fr: "Aruba",
      ja: "\u30A2\u30EB\u30D0",
      it: "Aruba",
      cn: "\u963F\u9C81\u5DF4",
      tr: "Aruba"
    },
    latitude: "12.50000000",
    longitude: "-69.96666666",
    emoji: "\u{1F1E6}\u{1F1FC}",
    emojiU: "U+1F1E6 U+1F1FC"
  },
  {
    id: 14,
    name: "Australia",
    iso3: "AUS",
    iso2: "AU",
    numeric_code: "036",
    phone_code: "61",
    capital: "Canberra",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".au",
    native: "Australia",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "Australian",
    timezones: [
      {
        zoneName: "Antarctica/Macquarie",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "MIST",
        tzName: "Macquarie Island Station Time"
      },
      {
        zoneName: "Australia/Adelaide",
        gmtOffset: 37800,
        gmtOffsetName: "UTC+10:30",
        abbreviation: "ACDT",
        tzName: "Australian Central Daylight Saving Time"
      },
      {
        zoneName: "Australia/Brisbane",
        gmtOffset: 36e3,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "AEST",
        tzName: "Australian Eastern Standard Time"
      },
      {
        zoneName: "Australia/Broken_Hill",
        gmtOffset: 37800,
        gmtOffsetName: "UTC+10:30",
        abbreviation: "ACDT",
        tzName: "Australian Central Daylight Saving Time"
      },
      {
        zoneName: "Australia/Currie",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time"
      },
      {
        zoneName: "Australia/Darwin",
        gmtOffset: 34200,
        gmtOffsetName: "UTC+09:30",
        abbreviation: "ACST",
        tzName: "Australian Central Standard Time"
      },
      {
        zoneName: "Australia/Eucla",
        gmtOffset: 31500,
        gmtOffsetName: "UTC+08:45",
        abbreviation: "ACWST",
        tzName: "Australian Central Western Standard Time (Unofficial)"
      },
      {
        zoneName: "Australia/Hobart",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time"
      },
      {
        zoneName: "Australia/Lindeman",
        gmtOffset: 36e3,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "AEST",
        tzName: "Australian Eastern Standard Time"
      },
      {
        zoneName: "Australia/Lord_Howe",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "LHST",
        tzName: "Lord Howe Summer Time"
      },
      {
        zoneName: "Australia/Melbourne",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time"
      },
      {
        zoneName: "Australia/Perth",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "AWST",
        tzName: "Australian Western Standard Time"
      },
      {
        zoneName: "Australia/Sydney",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time"
      }
    ],
    translations: {
      kr: "\uD638\uC8FC",
      "pt-BR": "Austr\xE1lia",
      pt: "Austr\xE1lia",
      nl: "Australi\xEB",
      hr: "Australija",
      fa: "\u0627\u0633\u062A\u0631\u0627\u0644\u06CC\u0627",
      de: "Australien",
      es: "Australia",
      fr: "Australie",
      ja: "\u30AA\u30FC\u30B9\u30C8\u30E9\u30EA\u30A2",
      it: "Australia",
      cn: "\u6FB3\u5927\u5229\u4E9A",
      tr: "Avustralya"
    },
    latitude: "-27.00000000",
    longitude: "133.00000000",
    emoji: "\u{1F1E6}\u{1F1FA}",
    emojiU: "U+1F1E6 U+1F1FA"
  },
  {
    id: 15,
    name: "Austria",
    iso3: "AUT",
    iso2: "AT",
    numeric_code: "040",
    phone_code: "43",
    capital: "Vienna",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".at",
    native: "\xD6sterreich",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Austrian",
    timezones: [
      {
        zoneName: "Europe/Vienna",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC624\uC2A4\uD2B8\uB9AC\uC544",
      "pt-BR": "\xE1ustria",
      pt: "\xE1ustria",
      nl: "Oostenrijk",
      hr: "Austrija",
      fa: "\u0627\u062A\u0631\u06CC\u0634",
      de: "\xD6sterreich",
      es: "Austria",
      fr: "Autriche",
      ja: "\u30AA\u30FC\u30B9\u30C8\u30EA\u30A2",
      it: "Austria",
      cn: "\u5965\u5730\u5229",
      tr: "Avusturya"
    },
    latitude: "47.33333333",
    longitude: "13.33333333",
    emoji: "\u{1F1E6}\u{1F1F9}",
    emojiU: "U+1F1E6 U+1F1F9"
  },
  {
    id: 16,
    name: "Azerbaijan",
    iso3: "AZE",
    iso2: "AZ",
    numeric_code: "031",
    phone_code: "994",
    capital: "Baku",
    currency: "AZN",
    currency_name: "Azerbaijani manat",
    currency_symbol: "m",
    tld: ".az",
    native: "Az\u0259rbaycan",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Azerbaijani, Azeri",
    timezones: [
      {
        zoneName: "Asia/Baku",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "AZT",
        tzName: "Azerbaijan Time"
      }
    ],
    translations: {
      kr: "\uC544\uC81C\uB974\uBC14\uC774\uC794",
      "pt-BR": "Azerbaij\xE3o",
      pt: "Azerbaij\xE3o",
      nl: "Azerbeidzjan",
      hr: "Azerbajd\u017Ean",
      fa: "\u0622\u0630\u0631\u0628\u0627\u06CC\u062C\u0627\u0646",
      de: "Aserbaidschan",
      es: "Azerbaiy\xE1n",
      fr: "Azerba\xEFdjan",
      ja: "\u30A2\u30BC\u30EB\u30D0\u30A4\u30B8\u30E3\u30F3",
      it: "Azerbaijan",
      cn: "\u963F\u585E\u62DC\u7586",
      tr: "Azerbaycan"
    },
    latitude: "40.50000000",
    longitude: "47.50000000",
    emoji: "\u{1F1E6}\u{1F1FF}",
    emojiU: "U+1F1E6 U+1F1FF"
  },
  {
    id: 18,
    name: "Bahrain",
    iso3: "BHR",
    iso2: "BH",
    numeric_code: "048",
    phone_code: "973",
    capital: "Manama",
    currency: "BHD",
    currency_name: "Bahraini dinar",
    currency_symbol: ".\u062F.\u0628",
    tld: ".bh",
    native: "\u200F\u0627\u0644\u0628\u062D\u0631\u064A\u0646",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Bahraini",
    timezones: [
      {
        zoneName: "Asia/Bahrain",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time"
      }
    ],
    translations: {
      kr: "\uBC14\uB808\uC778",
      "pt-BR": "Bahrein",
      pt: "Bar\xE9m",
      nl: "Bahrein",
      hr: "Bahrein",
      fa: "\u0628\u062D\u0631\u06CC\u0646",
      de: "Bahrain",
      es: "Bahrein",
      fr: "Bahre\xEFn",
      ja: "\u30D0\u30FC\u30EC\u30FC\u30F3",
      it: "Bahrein",
      cn: "\u5DF4\u6797",
      tr: "Bahreyn"
    },
    latitude: "26.00000000",
    longitude: "50.55000000",
    emoji: "\u{1F1E7}\u{1F1ED}",
    emojiU: "U+1F1E7 U+1F1ED"
  },
  {
    id: 19,
    name: "Bangladesh",
    iso3: "BGD",
    iso2: "BD",
    numeric_code: "050",
    phone_code: "880",
    capital: "Dhaka",
    currency: "BDT",
    currency_name: "Bangladeshi taka",
    currency_symbol: "\u09F3",
    tld: ".bd",
    native: "Bangladesh",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Bangladeshi",
    timezones: [
      {
        zoneName: "Asia/Dhaka",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "BDT",
        tzName: "Bangladesh Standard Time"
      }
    ],
    translations: {
      kr: "\uBC29\uAE00\uB77C\uB370\uC2DC",
      "pt-BR": "Bangladesh",
      pt: "Bangladeche",
      nl: "Bangladesh",
      hr: "Banglade\u0161",
      fa: "\u0628\u0646\u06AF\u0644\u0627\u062F\u0634",
      de: "Bangladesch",
      es: "Bangladesh",
      fr: "Bangladesh",
      ja: "\u30D0\u30F3\u30B0\u30E9\u30C7\u30B7\u30E5",
      it: "Bangladesh",
      cn: "\u5B5F\u52A0\u62C9",
      tr: "Banglade\u015F"
    },
    latitude: "24.00000000",
    longitude: "90.00000000",
    emoji: "\u{1F1E7}\u{1F1E9}",
    emojiU: "U+1F1E7 U+1F1E9"
  },
  {
    id: 20,
    name: "Barbados",
    iso3: "BRB",
    iso2: "BB",
    numeric_code: "052",
    phone_code: "1",
    capital: "Bridgetown",
    currency: "BBD",
    currency_name: "Barbadian dollar",
    currency_symbol: "Bds$",
    tld: ".bb",
    native: "Barbados",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Barbadian",
    timezones: [
      {
        zoneName: "America/Barbados",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uBC14\uBCA0\uC774\uB3C4\uC2A4",
      "pt-BR": "Barbados",
      pt: "Barbados",
      nl: "Barbados",
      hr: "Barbados",
      fa: "\u0628\u0627\u0631\u0628\u0627\u062F\u0648\u0633",
      de: "Barbados",
      es: "Barbados",
      fr: "Barbade",
      ja: "\u30D0\u30EB\u30D0\u30C9\u30B9",
      it: "Barbados",
      cn: "\u5DF4\u5DF4\u591A\u65AF",
      tr: "Barbados"
    },
    latitude: "13.16666666",
    longitude: "-59.53333333",
    emoji: "\u{1F1E7}\u{1F1E7}",
    emojiU: "U+1F1E7 U+1F1E7"
  },
  {
    id: 21,
    name: "Belarus",
    iso3: "BLR",
    iso2: "BY",
    numeric_code: "112",
    phone_code: "375",
    capital: "Minsk",
    currency: "BYN",
    currency_name: "Belarusian ruble",
    currency_symbol: "Br",
    tld: ".by",
    native: "\u0411\u0435\u043B\u0430\u0440\u0443\u0301\u0441\u044C",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Belarusian",
    timezones: [
      {
        zoneName: "Europe/Minsk",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time"
      }
    ],
    translations: {
      kr: "\uBCA8\uB77C\uB8E8\uC2A4",
      "pt-BR": "Bielorr\xFAssia",
      pt: "Bielorr\xFAssia",
      nl: "Wit-Rusland",
      hr: "Bjelorusija",
      fa: "\u0628\u0644\u0627\u0631\u0648\u0633",
      de: "Wei\xDFrussland",
      es: "Bielorrusia",
      fr: "Bi\xE9lorussie",
      ja: "\u30D9\u30E9\u30EB\u30FC\u30B7",
      it: "Bielorussia",
      cn: "\u767D\u4FC4\u7F57\u65AF",
      tr: "Belarus"
    },
    latitude: "53.00000000",
    longitude: "28.00000000",
    emoji: "\u{1F1E7}\u{1F1FE}",
    emojiU: "U+1F1E7 U+1F1FE"
  },
  {
    id: 22,
    name: "Belgium",
    iso3: "BEL",
    iso2: "BE",
    numeric_code: "056",
    phone_code: "32",
    capital: "Brussels",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".be",
    native: "Belgi\xEB",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Belgian",
    timezones: [
      {
        zoneName: "Europe/Brussels",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uBCA8\uAE30\uC5D0",
      "pt-BR": "B\xE9lgica",
      pt: "B\xE9lgica",
      nl: "Belgi\xEB",
      hr: "Belgija",
      fa: "\u0628\u0644\u0698\u06CC\u06A9",
      de: "Belgien",
      es: "B\xE9lgica",
      fr: "Belgique",
      ja: "\u30D9\u30EB\u30AE\u30FC",
      it: "Belgio",
      cn: "\u6BD4\u5229\u65F6",
      tr: "Bel\xE7ika"
    },
    latitude: "50.83333333",
    longitude: "4.00000000",
    emoji: "\u{1F1E7}\u{1F1EA}",
    emojiU: "U+1F1E7 U+1F1EA"
  },
  {
    id: 23,
    name: "Belize",
    iso3: "BLZ",
    iso2: "BZ",
    numeric_code: "084",
    phone_code: "501",
    capital: "Belmopan",
    currency: "BZD",
    currency_name: "Belize dollar",
    currency_symbol: "$",
    tld: ".bz",
    native: "Belize",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Belizean",
    timezones: [
      {
        zoneName: "America/Belize",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America)"
      }
    ],
    translations: {
      kr: "\uBCA8\uB9AC\uC988",
      "pt-BR": "Belize",
      pt: "Belize",
      nl: "Belize",
      hr: "Belize",
      fa: "\u0628\u0644\u06CC\u0632",
      de: "Belize",
      es: "Belice",
      fr: "Belize",
      ja: "\u30D9\u30EA\u30FC\u30BA",
      it: "Belize",
      cn: "\u4F2F\u5229\u5179",
      tr: "Belize"
    },
    latitude: "17.25000000",
    longitude: "-88.75000000",
    emoji: "\u{1F1E7}\u{1F1FF}",
    emojiU: "U+1F1E7 U+1F1FF"
  },
  {
    id: 24,
    name: "Benin",
    iso3: "BEN",
    iso2: "BJ",
    numeric_code: "204",
    phone_code: "229",
    capital: "Porto-Novo",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".bj",
    native: "B\xE9nin",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Beninese, Beninois",
    timezones: [
      {
        zoneName: "Africa/Porto-Novo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uBCA0\uB0C9",
      "pt-BR": "Benin",
      pt: "Benim",
      nl: "Benin",
      hr: "Benin",
      fa: "\u0628\u0646\u06CC\u0646",
      de: "Benin",
      es: "Ben\xEDn",
      fr: "B\xE9nin",
      ja: "\u30D9\u30CA\u30F3",
      it: "Benin",
      cn: "\u8D1D\u5B81",
      tr: "Benin"
    },
    latitude: "9.50000000",
    longitude: "2.25000000",
    emoji: "\u{1F1E7}\u{1F1EF}",
    emojiU: "U+1F1E7 U+1F1EF"
  },
  {
    id: 25,
    name: "Bermuda",
    iso3: "BMU",
    iso2: "BM",
    numeric_code: "060",
    phone_code: "1",
    capital: "Hamilton",
    currency: "BMD",
    currency_name: "Bermudian dollar",
    currency_symbol: "$",
    tld: ".bm",
    native: "Bermuda",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "Bermudian, Bermudan",
    timezones: [
      {
        zoneName: "Atlantic/Bermuda",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uBC84\uBBA4\uB2E4",
      "pt-BR": "Bermudas",
      pt: "Bermudas",
      nl: "Bermuda",
      hr: "Bermudi",
      fa: "\u0628\u0631\u0645\u0648\u062F\u0627",
      de: "Bermuda",
      es: "Bermudas",
      fr: "Bermudes",
      ja: "\u30D0\u30DF\u30E5\u30FC\u30C0",
      it: "Bermuda",
      cn: "\u767E\u6155\u5927",
      tr: "Bermuda"
    },
    latitude: "32.33333333",
    longitude: "-64.75000000",
    emoji: "\u{1F1E7}\u{1F1F2}",
    emojiU: "U+1F1E7 U+1F1F2"
  },
  {
    id: 26,
    name: "Bhutan",
    iso3: "BTN",
    iso2: "BT",
    numeric_code: "064",
    phone_code: "975",
    capital: "Thimphu",
    currency: "BTN",
    currency_name: "Bhutanese ngultrum",
    currency_symbol: "Nu.",
    tld: ".bt",
    native: "\u02BCbrug-yul",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Bhutanese",
    timezones: [
      {
        zoneName: "Asia/Thimphu",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "BTT",
        tzName: "Bhutan Time"
      }
    ],
    translations: {
      kr: "\uBD80\uD0C4",
      "pt-BR": "But\xE3o",
      pt: "But\xE3o",
      nl: "Bhutan",
      hr: "Butan",
      fa: "\u0628\u0648\u062A\u0627\u0646",
      de: "Bhutan",
      es: "But\xE1n",
      fr: "Bhoutan",
      ja: "\u30D6\u30FC\u30BF\u30F3",
      it: "Bhutan",
      cn: "\u4E0D\u4E39",
      tr: "Butan"
    },
    latitude: "27.50000000",
    longitude: "90.50000000",
    emoji: "\u{1F1E7}\u{1F1F9}",
    emojiU: "U+1F1E7 U+1F1F9"
  },
  {
    id: 27,
    name: "Bolivia",
    iso3: "BOL",
    iso2: "BO",
    numeric_code: "068",
    phone_code: "591",
    capital: "Sucre",
    currency: "BOB",
    currency_name: "Bolivian boliviano",
    currency_symbol: "Bs.",
    tld: ".bo",
    native: "Bolivia",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Bolivian",
    timezones: [
      {
        zoneName: "America/La_Paz",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "BOT",
        tzName: "Bolivia Time"
      }
    ],
    translations: {
      kr: "\uBCFC\uB9AC\uBE44\uC544",
      "pt-BR": "Bol\xEDvia",
      pt: "Bol\xEDvia",
      nl: "Bolivia",
      hr: "Bolivija",
      fa: "\u0628\u0648\u0644\u06CC\u0648\u06CC",
      de: "Bolivien",
      es: "Bolivia",
      fr: "Bolivie",
      ja: "\u30DC\u30EA\u30D3\u30A2\u591A\u6C11\u65CF\u56FD",
      it: "Bolivia",
      cn: "\u73BB\u5229\u7EF4\u4E9A",
      tr: "Bolivya"
    },
    latitude: "-17.00000000",
    longitude: "-65.00000000",
    emoji: "\u{1F1E7}\u{1F1F4}",
    emojiU: "U+1F1E7 U+1F1F4"
  },
  {
    id: 155,
    name: "Bonaire, Sint Eustatius and Saba",
    iso3: "BES",
    iso2: "BQ",
    numeric_code: "535",
    phone_code: "599",
    capital: "Kralendijk",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".an",
    native: "Caribisch Nederland",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Bonaire",
    timezones: [
      {
        zoneName: "America/Anguilla",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uBCF4\uB124\uB974 \uC12C",
      "pt-BR": "Bonaire",
      pt: "Bonaire",
      fa: "\u0628\u0648\u0646\u06CC\u0631",
      de: "Bonaire, Sint Eustatius und Saba",
      fr: "Bonaire, Saint-Eustache et Saba",
      it: "Bonaire, Saint-Eustache e Saba",
      cn: "\u535A\u5185\u5C14\u5C9B\u3001\u5723\u5C24\u65AF\u7279\u6B47\u65AF\u548C\u8428\u5DF4\u5C9B",
      tr: "Karayip Hollandasi"
    },
    latitude: "12.15000000",
    longitude: "-68.26666700",
    emoji: "\u{1F1E7}\u{1F1F6}",
    emojiU: "U+1F1E7 U+1F1F6"
  },
  {
    id: 28,
    name: "Bosnia and Herzegovina",
    iso3: "BIH",
    iso2: "BA",
    numeric_code: "070",
    phone_code: "387",
    capital: "Sarajevo",
    currency: "BAM",
    currency_name: "Bosnia and Herzegovina convertible mark",
    currency_symbol: "KM",
    tld: ".ba",
    native: "Bosna i Hercegovina",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Bosnian or Herzegovinian",
    timezones: [
      {
        zoneName: "Europe/Sarajevo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uBCF4\uC2A4\uB2C8\uC544 \uD5E4\uB974\uCCB4\uACE0\uBE44\uB098",
      "pt-BR": "B\xF3snia e Herzegovina",
      pt: "B\xF3snia e Herzegovina",
      nl: "Bosni\xEB en Herzegovina",
      hr: "Bosna i Hercegovina",
      fa: "\u0628\u0648\u0633\u0646\u06CC \u0648 \u0647\u0631\u0632\u06AF\u0648\u06CC\u0646",
      de: "Bosnien und Herzegowina",
      es: "Bosnia y Herzegovina",
      fr: "Bosnie-Herz\xE9govine",
      ja: "\u30DC\u30B9\u30CB\u30A2\u30FB\u30D8\u30EB\u30C4\u30A7\u30B4\u30D3\u30CA",
      it: "Bosnia ed Erzegovina",
      cn: "\u6CE2\u65AF\u5C3C\u4E9A\u548C\u9ED1\u585E\u54E5\u7EF4\u90A3",
      tr: "Bosna Hersek"
    },
    latitude: "44.00000000",
    longitude: "18.00000000",
    emoji: "\u{1F1E7}\u{1F1E6}",
    emojiU: "U+1F1E7 U+1F1E6"
  },
  {
    id: 29,
    name: "Botswana",
    iso3: "BWA",
    iso2: "BW",
    numeric_code: "072",
    phone_code: "267",
    capital: "Gaborone",
    currency: "BWP",
    currency_name: "Botswana pula",
    currency_symbol: "P",
    tld: ".bw",
    native: "Botswana",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "Motswana, Botswanan",
    timezones: [
      {
        zoneName: "Africa/Gaborone",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time"
      }
    ],
    translations: {
      kr: "\uBCF4\uCE20\uC640\uB098",
      "pt-BR": "Botsuana",
      pt: "Botsuana",
      nl: "Botswana",
      hr: "Bocvana",
      fa: "\u0628\u0648\u062A\u0633\u0648\u0627\u0646\u0627",
      de: "Botswana",
      es: "Botswana",
      fr: "Botswana",
      ja: "\u30DC\u30C4\u30EF\u30CA",
      it: "Botswana",
      cn: "\u535A\u8328\u74E6\u7EB3",
      tr: "Botsvana"
    },
    latitude: "-22.00000000",
    longitude: "24.00000000",
    emoji: "\u{1F1E7}\u{1F1FC}",
    emojiU: "U+1F1E7 U+1F1FC"
  },
  {
    id: 30,
    name: "Bouvet Island",
    iso3: "BVT",
    iso2: "BV",
    numeric_code: "074",
    phone_code: "0055",
    capital: "",
    currency: "NOK",
    currency_name: "Norwegian Krone",
    currency_symbol: "kr",
    tld: ".bv",
    native: "Bouvet\xF8ya",
    region: "",
    region_id: null,
    subregion: "",
    subregion_id: null,
    nationality: "Bouvet Island",
    timezones: [
      {
        zoneName: "Europe/Oslo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uBD80\uBCB3 \uC12C",
      "pt-BR": "Ilha Bouvet",
      pt: "Ilha Bouvet",
      nl: "Bouveteiland",
      hr: "Otok Bouvet",
      fa: "\u062C\u0632\u06CC\u0631\u0647 \u0628\u0648\u0648\u0647",
      de: "Bouvetinsel",
      es: "Isla Bouvet",
      fr: "\xCEle Bouvet",
      ja: "\u30D6\u30FC\u30D9\u5CF6",
      it: "Isola Bouvet",
      cn: "\u5E03\u7EF4\u5C9B",
      tr: "Bouvet Adasi"
    },
    latitude: "-54.43333333",
    longitude: "3.40000000",
    emoji: "\u{1F1E7}\u{1F1FB}",
    emojiU: "U+1F1E7 U+1F1FB"
  },
  {
    id: 31,
    name: "Brazil",
    iso3: "BRA",
    iso2: "BR",
    numeric_code: "076",
    phone_code: "55",
    capital: "Brasilia",
    currency: "BRL",
    currency_name: "Brazilian real",
    currency_symbol: "R$",
    tld: ".br",
    native: "Brasil",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Brazilian",
    timezones: [
      {
        zoneName: "America/Araguaina",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Bras\xEDlia Time"
      },
      {
        zoneName: "America/Bahia",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Bras\xEDlia Time"
      },
      {
        zoneName: "America/Belem",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Bras\xEDlia Time"
      },
      {
        zoneName: "America/Boa_Vista",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)[3"
      },
      {
        zoneName: "America/Campo_Grande",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)[3"
      },
      {
        zoneName: "America/Cuiaba",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "BRT",
        tzName: "Brasilia Time"
      },
      {
        zoneName: "America/Eirunepe",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "ACT",
        tzName: "Acre Time"
      },
      {
        zoneName: "America/Fortaleza",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Bras\xEDlia Time"
      },
      {
        zoneName: "America/Maceio",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Bras\xEDlia Time"
      },
      {
        zoneName: "America/Manaus",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)"
      },
      {
        zoneName: "America/Noronha",
        gmtOffset: -7200,
        gmtOffsetName: "UTC-02:00",
        abbreviation: "FNT",
        tzName: "Fernando de Noronha Time"
      },
      {
        zoneName: "America/Porto_Velho",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)[3"
      },
      {
        zoneName: "America/Recife",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Bras\xEDlia Time"
      },
      {
        zoneName: "America/Rio_Branco",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "ACT",
        tzName: "Acre Time"
      },
      {
        zoneName: "America/Santarem",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Bras\xEDlia Time"
      },
      {
        zoneName: "America/Sao_Paulo",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Bras\xEDlia Time"
      }
    ],
    translations: {
      kr: "\uBE0C\uB77C\uC9C8",
      "pt-BR": "Brasil",
      pt: "Brasil",
      nl: "Brazili\xEB",
      hr: "Brazil",
      fa: "\u0628\u0631\u0632\u06CC\u0644",
      de: "Brasilien",
      es: "Brasil",
      fr: "Br\xE9sil",
      ja: "\u30D6\u30E9\u30B8\u30EB",
      it: "Brasile",
      cn: "\u5DF4\u897F",
      tr: "Brezilya"
    },
    latitude: "-10.00000000",
    longitude: "-55.00000000",
    emoji: "\u{1F1E7}\u{1F1F7}",
    emojiU: "U+1F1E7 U+1F1F7"
  },
  {
    id: 32,
    name: "British Indian Ocean Territory",
    iso3: "IOT",
    iso2: "IO",
    numeric_code: "086",
    phone_code: "246",
    capital: "Diego Garcia",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".io",
    native: "British Indian Ocean Territory",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "BIOT",
    timezones: [
      {
        zoneName: "Indian/Chagos",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "IOT",
        tzName: "Indian Ocean Time"
      }
    ],
    translations: {
      kr: "\uC601\uAD6D\uB839 \uC778\uB3C4\uC591 \uC9C0\uC5ED",
      "pt-BR": "Territ\xF3rio Brit\xE2nico do Oceano \xED\xCDdico",
      pt: "Territ\xF3rio Brit\xE2nico do Oceano \xCDndico",
      nl: "Britse Gebieden in de Indische Oceaan",
      hr: "Britanski Indijskooceanski teritorij",
      fa: "\u0642\u0644\u0645\u0631\u0648 \u0628\u0631\u06CC\u062A\u0627\u0646\u06CC\u0627 \u062F\u0631 \u0627\u0642\u06CC\u0627\u0646\u0648\u0633 \u0647\u0646\u062F",
      de: "Britisches Territorium im Indischen Ozean",
      es: "Territorio Brit\xE1nico del Oc\xE9ano \xCDndico",
      fr: "Territoire britannique de l'oc\xE9an Indien",
      ja: "\u30A4\u30AE\u30EA\u30B9\u9818\u30A4\u30F3\u30C9\u6D0B\u5730\u57DF",
      it: "Territorio britannico dell'oceano indiano",
      cn: "\u82F1\u5C5E\u5370\u5EA6\u6D0B\u9886\u5730",
      tr: "Britanya Hint Okyanusu Topraklari"
    },
    latitude: "-6.00000000",
    longitude: "71.50000000",
    emoji: "\u{1F1EE}\u{1F1F4}",
    emojiU: "U+1F1EE U+1F1F4"
  },
  {
    id: 33,
    name: "Brunei",
    iso3: "BRN",
    iso2: "BN",
    numeric_code: "096",
    phone_code: "673",
    capital: "Bandar Seri Begawan",
    currency: "BND",
    currency_name: "Brunei dollar",
    currency_symbol: "B$",
    tld: ".bn",
    native: "Negara Brunei Darussalam",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Bruneian",
    timezones: [
      {
        zoneName: "Asia/Brunei",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "BNT",
        tzName: "Brunei Darussalam Time"
      }
    ],
    translations: {
      kr: "\uBE0C\uB8E8\uB098\uC774",
      "pt-BR": "Brunei",
      pt: "Brunei",
      nl: "Brunei",
      hr: "Brunej",
      fa: "\u0628\u0631\u0648\u0646\u0626\u06CC",
      de: "Brunei",
      es: "Brunei",
      fr: "Brunei",
      ja: "\u30D6\u30EB\u30CD\u30A4\u30FB\u30C0\u30EB\u30B5\u30E9\u30FC\u30E0",
      it: "Brunei",
      cn: "\u6587\u83B1",
      tr: "Brunei"
    },
    latitude: "4.50000000",
    longitude: "114.66666666",
    emoji: "\u{1F1E7}\u{1F1F3}",
    emojiU: "U+1F1E7 U+1F1F3"
  },
  {
    id: 34,
    name: "Bulgaria",
    iso3: "BGR",
    iso2: "BG",
    numeric_code: "100",
    phone_code: "359",
    capital: "Sofia",
    currency: "BGN",
    currency_name: "Bulgarian lev",
    currency_symbol: "\u041B\u0432.",
    tld: ".bg",
    native: "\u0411\u044A\u043B\u0433\u0430\u0440\u0438\u044F",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Bulgarian",
    timezones: [
      {
        zoneName: "Europe/Sofia",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uBD88\uAC00\uB9AC\uC544",
      "pt-BR": "Bulg\xE1ria",
      pt: "Bulg\xE1ria",
      nl: "Bulgarije",
      hr: "Bugarska",
      fa: "\u0628\u0644\u063A\u0627\u0631\u0633\u062A\u0627\u0646",
      de: "Bulgarien",
      es: "Bulgaria",
      fr: "Bulgarie",
      ja: "\u30D6\u30EB\u30AC\u30EA\u30A2",
      it: "Bulgaria",
      cn: "\u4FDD\u52A0\u5229\u4E9A",
      tr: "Bulgaristan"
    },
    latitude: "43.00000000",
    longitude: "25.00000000",
    emoji: "\u{1F1E7}\u{1F1EC}",
    emojiU: "U+1F1E7 U+1F1EC"
  },
  {
    id: 35,
    name: "Burkina Faso",
    iso3: "BFA",
    iso2: "BF",
    numeric_code: "854",
    phone_code: "226",
    capital: "Ouagadougou",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".bf",
    native: "Burkina Faso",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Burkinabe",
    timezones: [
      {
        zoneName: "Africa/Ouagadougou",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uBD80\uB974\uD0A4\uB098 \uD30C\uC18C",
      "pt-BR": "Burkina Faso",
      pt: "Burquina Faso",
      nl: "Burkina Faso",
      hr: "Burkina Faso",
      fa: "\u0628\u0648\u0631\u06A9\u06CC\u0646\u0627\u0641\u0627\u0633\u0648",
      de: "Burkina Faso",
      es: "Burkina Faso",
      fr: "Burkina Faso",
      ja: "\u30D6\u30EB\u30AD\u30CA\u30D5\u30A1\u30BD",
      it: "Burkina Faso",
      cn: "\u5E03\u57FA\u7EB3\u6CD5\u7D22",
      tr: "Burkina Faso"
    },
    latitude: "13.00000000",
    longitude: "-2.00000000",
    emoji: "\u{1F1E7}\u{1F1EB}",
    emojiU: "U+1F1E7 U+1F1EB"
  },
  {
    id: 36,
    name: "Burundi",
    iso3: "BDI",
    iso2: "BI",
    numeric_code: "108",
    phone_code: "257",
    capital: "Bujumbura",
    currency: "BIF",
    currency_name: "Burundian franc",
    currency_symbol: "FBu",
    tld: ".bi",
    native: "Burundi",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Burundian",
    timezones: [
      {
        zoneName: "Africa/Bujumbura",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time"
      }
    ],
    translations: {
      kr: "\uBD80\uB8EC\uB514",
      "pt-BR": "Burundi",
      pt: "Bur\xFAndi",
      nl: "Burundi",
      hr: "Burundi",
      fa: "\u0628\u0648\u0631\u0648\u0646\u062F\u06CC",
      de: "Burundi",
      es: "Burundi",
      fr: "Burundi",
      ja: "\u30D6\u30EB\u30F3\u30B8",
      it: "Burundi",
      cn: "\u5E03\u9686\u8FEA",
      tr: "Burundi"
    },
    latitude: "-3.50000000",
    longitude: "30.00000000",
    emoji: "\u{1F1E7}\u{1F1EE}",
    emojiU: "U+1F1E7 U+1F1EE"
  },
  {
    id: 37,
    name: "Cambodia",
    iso3: "KHM",
    iso2: "KH",
    numeric_code: "116",
    phone_code: "855",
    capital: "Phnom Penh",
    currency: "KHR",
    currency_name: "Cambodian riel",
    currency_symbol: "KHR",
    tld: ".kh",
    native: "K\xE2mp\u016Dch\xE9a",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Cambodian",
    timezones: [
      {
        zoneName: "Asia/Phnom_Penh",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time"
      }
    ],
    translations: {
      kr: "\uCE84\uBCF4\uB514\uC544",
      "pt-BR": "Camboja",
      pt: "Camboja",
      nl: "Cambodja",
      hr: "Kambod\u017Ea",
      fa: "\u06A9\u0627\u0645\u0628\u0648\u062C",
      de: "Kambodscha",
      es: "Camboya",
      fr: "Cambodge",
      ja: "\u30AB\u30F3\u30DC\u30B8\u30A2",
      it: "Cambogia",
      cn: "\u67EC\u57D4\u5BE8",
      tr: "Kambo\xE7ya"
    },
    latitude: "13.00000000",
    longitude: "105.00000000",
    emoji: "\u{1F1F0}\u{1F1ED}",
    emojiU: "U+1F1F0 U+1F1ED"
  },
  {
    id: 38,
    name: "Cameroon",
    iso3: "CMR",
    iso2: "CM",
    numeric_code: "120",
    phone_code: "237",
    capital: "Yaounde",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".cm",
    native: "Cameroon",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Cameroonian",
    timezones: [
      {
        zoneName: "Africa/Douala",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uCE74\uBA54\uB8EC",
      "pt-BR": "Camar\xF5es",
      pt: "Camar\xF5es",
      nl: "Kameroen",
      hr: "Kamerun",
      fa: "\u06A9\u0627\u0645\u0631\u0648\u0646",
      de: "Kamerun",
      es: "Camer\xFAn",
      fr: "Cameroun",
      ja: "\u30AB\u30E1\u30EB\u30FC\u30F3",
      it: "Camerun",
      cn: "\u5580\u9EA6\u9686",
      tr: "Kamerun"
    },
    latitude: "6.00000000",
    longitude: "12.00000000",
    emoji: "\u{1F1E8}\u{1F1F2}",
    emojiU: "U+1F1E8 U+1F1F2"
  },
  {
    id: 39,
    name: "Canada",
    iso3: "CAN",
    iso2: "CA",
    numeric_code: "124",
    phone_code: "1",
    capital: "Ottawa",
    currency: "CAD",
    currency_name: "Canadian dollar",
    currency_symbol: "$",
    tld: ".ca",
    native: "Canada",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "Canadian",
    timezones: [
      {
        zoneName: "America/Atikokan",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America)"
      },
      {
        zoneName: "America/Blanc-Sablon",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      },
      {
        zoneName: "America/Cambridge_Bay",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)"
      },
      {
        zoneName: "America/Creston",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)"
      },
      {
        zoneName: "America/Dawson",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)"
      },
      {
        zoneName: "America/Dawson_Creek",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)"
      },
      {
        zoneName: "America/Edmonton",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)"
      },
      {
        zoneName: "America/Fort_Nelson",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)"
      },
      {
        zoneName: "America/Glace_Bay",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      },
      {
        zoneName: "America/Goose_Bay",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      },
      {
        zoneName: "America/Halifax",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      },
      {
        zoneName: "America/Inuvik",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      },
      {
        zoneName: "America/Iqaluit",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Moncton",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      },
      {
        zoneName: "America/Nipigon",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Pangnirtung",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Rainy_River",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Rankin_Inlet",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Regina",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Resolute",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/St_Johns",
        gmtOffset: -12600,
        gmtOffsetName: "UTC-03:30",
        abbreviation: "NST",
        tzName: "Newfoundland Standard Time"
      },
      {
        zoneName: "America/Swift_Current",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Thunder_Bay",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Toronto",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Vancouver",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America"
      },
      {
        zoneName: "America/Whitehorse",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      },
      {
        zoneName: "America/Winnipeg",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Yellowknife",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uCE90\uB098\uB2E4",
      "pt-BR": "Canad\xE1",
      pt: "Canad\xE1",
      nl: "Canada",
      hr: "Kanada",
      fa: "\u06A9\u0627\u0646\u0627\u062F\u0627",
      de: "Kanada",
      es: "Canad\xE1",
      fr: "Canada",
      ja: "\u30AB\u30CA\u30C0",
      it: "Canada",
      cn: "\u52A0\u62FF\u5927",
      tr: "Kanada"
    },
    latitude: "60.00000000",
    longitude: "-95.00000000",
    emoji: "\u{1F1E8}\u{1F1E6}",
    emojiU: "U+1F1E8 U+1F1E6"
  },
  {
    id: 40,
    name: "Cape Verde",
    iso3: "CPV",
    iso2: "CV",
    numeric_code: "132",
    phone_code: "238",
    capital: "Praia",
    currency: "CVE",
    currency_name: "Cape Verdean escudo",
    currency_symbol: "$",
    tld: ".cv",
    native: "Cabo Verde",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Verdean",
    timezones: [
      {
        zoneName: "Atlantic/Cape_Verde",
        gmtOffset: -3600,
        gmtOffsetName: "UTC-01:00",
        abbreviation: "CVT",
        tzName: "Cape Verde Time"
      }
    ],
    translations: {
      kr: "\uCE74\uBCF4\uBCA0\uB974\uB370",
      "pt-BR": "Cabo Verde",
      pt: "Cabo Verde",
      nl: "Kaapverdi\xEB",
      hr: "Zelenortska Republika",
      fa: "\u06A9\u06CC\u067E \u0648\u0631\u062F",
      de: "Kap Verde",
      es: "Cabo Verde",
      fr: "Cap Vert",
      ja: "\u30AB\u30FC\u30DC\u30D9\u30EB\u30C7",
      it: "Capo Verde",
      cn: "\u4F5B\u5F97\u89D2",
      tr: "Cabo Verde"
    },
    latitude: "16.00000000",
    longitude: "-24.00000000",
    emoji: "\u{1F1E8}\u{1F1FB}",
    emojiU: "U+1F1E8 U+1F1FB"
  },
  {
    id: 41,
    name: "Cayman Islands",
    iso3: "CYM",
    iso2: "KY",
    numeric_code: "136",
    phone_code: "1",
    capital: "George Town",
    currency: "KYD",
    currency_name: "Cayman Islands dollar",
    currency_symbol: "$",
    tld: ".ky",
    native: "Cayman Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Caymanian",
    timezones: [
      {
        zoneName: "America/Cayman",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uCF00\uC774\uBA3C \uC81C\uB3C4",
      "pt-BR": "Ilhas Cayman",
      pt: "Ilhas Caim\xE3o",
      nl: "Caymaneilanden",
      hr: "Kajmanski otoci",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u06A9\u06CC\u0645\u0646",
      de: "Kaimaninseln",
      es: "Islas Caim\xE1n",
      fr: "\xCEles Ca\xEFmans",
      ja: "\u30B1\u30A4\u30DE\u30F3\u8AF8\u5CF6",
      it: "Isole Cayman",
      cn: "\u5F00\u66FC\u7FA4\u5C9B",
      tr: "Cayman Adalari"
    },
    latitude: "19.50000000",
    longitude: "-80.50000000",
    emoji: "\u{1F1F0}\u{1F1FE}",
    emojiU: "U+1F1F0 U+1F1FE"
  },
  {
    id: 42,
    name: "Central African Republic",
    iso3: "CAF",
    iso2: "CF",
    numeric_code: "140",
    phone_code: "236",
    capital: "Bangui",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".cf",
    native: "K\xF6d\xF6r\xF6s\xEAse t\xEE B\xEAafr\xEEka",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Central African",
    timezones: [
      {
        zoneName: "Africa/Bangui",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uC911\uC559\uC544\uD504\uB9AC\uCE74 \uACF5\uD654\uAD6D",
      "pt-BR": "Rep\xFAblica Centro-Africana",
      pt: "Rep\xFAblica Centro-Africana",
      nl: "Centraal-Afrikaanse Republiek",
      hr: "Srednjoafri\u010Dka Republika",
      fa: "\u062C\u0645\u0647\u0648\u0631\u06CC \u0622\u0641\u0631\u06CC\u0642\u0627\u06CC \u0645\u0631\u06A9\u0632\u06CC",
      de: "Zentralafrikanische Republik",
      es: "Rep\xFAblica Centroafricana",
      fr: "R\xE9publique centrafricaine",
      ja: "\u4E2D\u592E\u30A2\u30D5\u30EA\u30AB\u5171\u548C\u56FD",
      it: "Repubblica Centrafricana",
      cn: "\u4E2D\u975E",
      tr: "Orta Afrika Cumhuriyeti"
    },
    latitude: "7.00000000",
    longitude: "21.00000000",
    emoji: "\u{1F1E8}\u{1F1EB}",
    emojiU: "U+1F1E8 U+1F1EB"
  },
  {
    id: 43,
    name: "Chad",
    iso3: "TCD",
    iso2: "TD",
    numeric_code: "148",
    phone_code: "235",
    capital: "N'Djamena",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".td",
    native: "Tchad",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Chadian",
    timezones: [
      {
        zoneName: "Africa/Ndjamena",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uCC28\uB4DC",
      "pt-BR": "Chade",
      pt: "Chade",
      nl: "Tsjaad",
      hr: "\u010Cad",
      fa: "\u0686\u0627\u062F",
      de: "Tschad",
      es: "Chad",
      fr: "Tchad",
      ja: "\u30C1\u30E3\u30C9",
      it: "Ciad",
      cn: "\u4E4D\u5F97",
      tr: "\xC7ad"
    },
    latitude: "15.00000000",
    longitude: "19.00000000",
    emoji: "\u{1F1F9}\u{1F1E9}",
    emojiU: "U+1F1F9 U+1F1E9"
  },
  {
    id: 44,
    name: "Chile",
    iso3: "CHL",
    iso2: "CL",
    numeric_code: "152",
    phone_code: "56",
    capital: "Santiago",
    currency: "CLP",
    currency_name: "Chilean peso",
    currency_symbol: "$",
    tld: ".cl",
    native: "Chile",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Chilean",
    timezones: [
      {
        zoneName: "America/Punta_Arenas",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "CLST",
        tzName: "Chile Summer Time"
      },
      {
        zoneName: "America/Santiago",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "CLST",
        tzName: "Chile Summer Time"
      },
      {
        zoneName: "Pacific/Easter",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EASST",
        tzName: "Easter Island Summer Time"
      }
    ],
    translations: {
      kr: "\uCE60\uB9AC",
      "pt-BR": "Chile",
      pt: "Chile",
      nl: "Chili",
      hr: "\u010Cile",
      fa: "\u0634\u06CC\u0644\u06CC",
      de: "Chile",
      es: "Chile",
      fr: "Chili",
      ja: "\u30C1\u30EA",
      it: "Cile",
      cn: "\u667A\u5229",
      tr: "\u015Eili"
    },
    latitude: "-30.00000000",
    longitude: "-71.00000000",
    emoji: "\u{1F1E8}\u{1F1F1}",
    emojiU: "U+1F1E8 U+1F1F1"
  },
  {
    id: 45,
    name: "China",
    iso3: "CHN",
    iso2: "CN",
    numeric_code: "156",
    phone_code: "86",
    capital: "Beijing",
    currency: "CNY",
    currency_name: "Chinese yuan",
    currency_symbol: "\xA5",
    tld: ".cn",
    native: "\u4E2D\u56FD",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Chinese",
    timezones: [
      {
        zoneName: "Asia/Shanghai",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CST",
        tzName: "China Standard Time"
      },
      {
        zoneName: "Asia/Urumqi",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "XJT",
        tzName: "China Standard Time"
      }
    ],
    translations: {
      kr: "\uC911\uAD6D",
      "pt-BR": "China",
      pt: "China",
      nl: "China",
      hr: "Kina",
      fa: "\u0686\u06CC\u0646",
      de: "China",
      es: "China",
      fr: "Chine",
      ja: "\u4E2D\u56FD",
      it: "Cina",
      cn: "\u4E2D\u56FD",
      tr: "\xC7in"
    },
    latitude: "35.00000000",
    longitude: "105.00000000",
    emoji: "\u{1F1E8}\u{1F1F3}",
    emojiU: "U+1F1E8 U+1F1F3"
  },
  {
    id: 46,
    name: "Christmas Island",
    iso3: "CXR",
    iso2: "CX",
    numeric_code: "162",
    phone_code: "61",
    capital: "Flying Fish Cove",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".cx",
    native: "Christmas Island",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "Christmas Island",
    timezones: [
      {
        zoneName: "Indian/Christmas",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "CXT",
        tzName: "Christmas Island Time"
      }
    ],
    translations: {
      kr: "\uD06C\uB9AC\uC2A4\uB9C8\uC2A4 \uC12C",
      "pt-BR": "Ilha Christmas",
      pt: "Ilha do Natal",
      nl: "Christmaseiland",
      hr: "Bo\u017Ei\u0107ni otok",
      fa: "\u062C\u0632\u06CC\u0631\u0647 \u06A9\u0631\u06CC\u0633\u0645\u0633",
      de: "Weihnachtsinsel",
      es: "Isla de Navidad",
      fr: "\xCEle Christmas",
      ja: "\u30AF\u30EA\u30B9\u30DE\u30B9\u5CF6",
      it: "Isola di Natale",
      cn: "\u5723\u8BDE\u5C9B",
      tr: "Christmas Adasi"
    },
    latitude: "-10.50000000",
    longitude: "105.66666666",
    emoji: "\u{1F1E8}\u{1F1FD}",
    emojiU: "U+1F1E8 U+1F1FD"
  },
  {
    id: 47,
    name: "Cocos (Keeling) Islands",
    iso3: "CCK",
    iso2: "CC",
    numeric_code: "166",
    phone_code: "61",
    capital: "West Island",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".cc",
    native: "Cocos (Keeling) Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "Cocos Island",
    timezones: [
      {
        zoneName: "Indian/Cocos",
        gmtOffset: 23400,
        gmtOffsetName: "UTC+06:30",
        abbreviation: "CCT",
        tzName: "Cocos Islands Time"
      }
    ],
    translations: {
      kr: "\uCF54\uCF54\uC2A4 \uC81C\uB3C4",
      "pt-BR": "Ilhas Cocos",
      pt: "Ilhas dos Cocos",
      nl: "Cocoseilanden",
      hr: "Kokosovi Otoci",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u06A9\u0648\u06A9\u0648\u0633",
      de: "Kokosinseln",
      es: "Islas Cocos o Islas Keeling",
      fr: "\xCEles Cocos",
      ja: "\u30B3\u30B3\u30B9\uFF08\u30AD\u30FC\u30EA\u30F3\u30B0\uFF09\u8AF8\u5CF6",
      it: "Isole Cocos e Keeling",
      cn: "\u79D1\u79D1\u65AF\uFF08\u57FA\u6797\uFF09\u7FA4\u5C9B",
      tr: "Cocos Adalari"
    },
    latitude: "-12.50000000",
    longitude: "96.83333333",
    emoji: "\u{1F1E8}\u{1F1E8}",
    emojiU: "U+1F1E8 U+1F1E8"
  },
  {
    id: 48,
    name: "Colombia",
    iso3: "COL",
    iso2: "CO",
    numeric_code: "170",
    phone_code: "57",
    capital: "Bogot\xE1",
    currency: "COP",
    currency_name: "Colombian peso",
    currency_symbol: "$",
    tld: ".co",
    native: "Colombia",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Colombian",
    timezones: [
      {
        zoneName: "America/Bogota",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "COT",
        tzName: "Colombia Time"
      }
    ],
    translations: {
      kr: "\uCF5C\uB86C\uBE44\uC544",
      "pt-BR": "Col\xF4mbia",
      pt: "Col\xF4mbia",
      nl: "Colombia",
      hr: "Kolumbija",
      fa: "\u06A9\u0644\u0645\u0628\u06CC\u0627",
      de: "Kolumbien",
      es: "Colombia",
      fr: "Colombie",
      ja: "\u30B3\u30ED\u30F3\u30D3\u30A2",
      it: "Colombia",
      cn: "\u54E5\u4F26\u6BD4\u4E9A",
      tr: "Kolombiya"
    },
    latitude: "4.00000000",
    longitude: "-72.00000000",
    emoji: "\u{1F1E8}\u{1F1F4}",
    emojiU: "U+1F1E8 U+1F1F4"
  },
  {
    id: 49,
    name: "Comoros",
    iso3: "COM",
    iso2: "KM",
    numeric_code: "174",
    phone_code: "269",
    capital: "Moroni",
    currency: "KMF",
    currency_name: "Comorian franc",
    currency_symbol: "CF",
    tld: ".km",
    native: "Komori",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Comoran, Comorian",
    timezones: [
      {
        zoneName: "Indian/Comoro",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uCF54\uBAA8\uB85C",
      "pt-BR": "Comores",
      pt: "Comores",
      nl: "Comoren",
      hr: "Komori",
      fa: "\u06A9\u0648\u0645\u0648\u0631",
      de: "Union der Komoren",
      es: "Comoras",
      fr: "Comores",
      ja: "\u30B3\u30E2\u30ED",
      it: "Comore",
      cn: "\u79D1\u6469\u7F57",
      tr: "Komorlar"
    },
    latitude: "-12.16666666",
    longitude: "44.25000000",
    emoji: "\u{1F1F0}\u{1F1F2}",
    emojiU: "U+1F1F0 U+1F1F2"
  },
  {
    id: 50,
    name: "Congo",
    iso3: "COG",
    iso2: "CG",
    numeric_code: "178",
    phone_code: "242",
    capital: "Brazzaville",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FC",
    tld: ".cg",
    native: "R\xE9publique du Congo",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Congolese",
    timezones: [
      {
        zoneName: "Africa/Brazzaville",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uCF69\uACE0",
      "pt-BR": "Congo",
      pt: "Congo",
      nl: "Congo [Republiek]",
      hr: "Kongo",
      fa: "\u06A9\u0646\u06AF\u0648",
      de: "Kongo",
      es: "Congo",
      fr: "Congo",
      ja: "\u30B3\u30F3\u30B4\u5171\u548C\u56FD",
      it: "Congo",
      cn: "\u521A\u679C",
      tr: "Kongo"
    },
    latitude: "-1.00000000",
    longitude: "15.00000000",
    emoji: "\u{1F1E8}\u{1F1EC}",
    emojiU: "U+1F1E8 U+1F1EC"
  },
  {
    id: 52,
    name: "Cook Islands",
    iso3: "COK",
    iso2: "CK",
    numeric_code: "184",
    phone_code: "682",
    capital: "Avarua",
    currency: "NZD",
    currency_name: "Cook Islands dollar",
    currency_symbol: "$",
    tld: ".ck",
    native: "Cook Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Cook Island",
    timezones: [
      {
        zoneName: "Pacific/Rarotonga",
        gmtOffset: -36e3,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "CKT",
        tzName: "Cook Island Time"
      }
    ],
    translations: {
      kr: "\uCFE1 \uC81C\uB3C4",
      "pt-BR": "Ilhas Cook",
      pt: "Ilhas Cook",
      nl: "Cookeilanden",
      hr: "Cookovo Oto\u010Dje",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u06A9\u0648\u06A9",
      de: "Cookinseln",
      es: "Islas Cook",
      fr: "\xCEles Cook",
      ja: "\u30AF\u30C3\u30AF\u8AF8\u5CF6",
      it: "Isole Cook",
      cn: "\u5E93\u514B\u7FA4\u5C9B",
      tr: "Cook Adalari"
    },
    latitude: "-21.23333333",
    longitude: "-159.76666666",
    emoji: "\u{1F1E8}\u{1F1F0}",
    emojiU: "U+1F1E8 U+1F1F0"
  },
  {
    id: 53,
    name: "Costa Rica",
    iso3: "CRI",
    iso2: "CR",
    numeric_code: "188",
    phone_code: "506",
    capital: "San Jose",
    currency: "CRC",
    currency_name: "Costa Rican col\xF3n",
    currency_symbol: "\u20A1",
    tld: ".cr",
    native: "Costa Rica",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Costa Rican",
    timezones: [
      {
        zoneName: "America/Costa_Rica",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uCF54\uC2A4\uD0C0\uB9AC\uCE74",
      "pt-BR": "Costa Rica",
      pt: "Costa Rica",
      nl: "Costa Rica",
      hr: "Kostarika",
      fa: "\u06A9\u0627\u0633\u062A\u0627\u0631\u06CC\u06A9\u0627",
      de: "Costa Rica",
      es: "Costa Rica",
      fr: "Costa Rica",
      ja: "\u30B3\u30B9\u30BF\u30EA\u30AB",
      it: "Costa Rica",
      cn: "\u54E5\u65AF\u8FBE\u9ECE\u52A0",
      tr: "Kosta Rika"
    },
    latitude: "10.00000000",
    longitude: "-84.00000000",
    emoji: "\u{1F1E8}\u{1F1F7}",
    emojiU: "U+1F1E8 U+1F1F7"
  },
  {
    id: 54,
    name: "Cote D'Ivoire (Ivory Coast)",
    iso3: "CIV",
    iso2: "CI",
    numeric_code: "384",
    phone_code: "225",
    capital: "Yamoussoukro",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".ci",
    native: null,
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Ivorian",
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uCF54\uD2B8\uB514\uBD80\uC544\uB974",
      "pt-BR": "Costa do Marfim",
      pt: "Costa do Marfim",
      nl: "Ivoorkust",
      hr: "Obala Bjelokosti",
      fa: "\u0633\u0627\u062D\u0644 \u0639\u0627\u062C",
      de: "Elfenbeink\xFCste",
      es: "Costa de Marfil",
      fr: "C\xF4te d'Ivoire",
      ja: "\u30B3\u30FC\u30C8\u30B8\u30DC\u30EF\u30FC\u30EB",
      it: "Costa D'Avorio",
      cn: "\u79D1\u7279\u8FEA\u74E6",
      tr: "Kotdivuar"
    },
    latitude: "8.00000000",
    longitude: "-5.00000000",
    emoji: "\u{1F1E8}\u{1F1EE}",
    emojiU: "U+1F1E8 U+1F1EE"
  },
  {
    id: 55,
    name: "Croatia",
    iso3: "HRV",
    iso2: "HR",
    numeric_code: "191",
    phone_code: "385",
    capital: "Zagreb",
    currency: "HRK",
    currency_name: "Croatian kuna",
    currency_symbol: "kn",
    tld: ".hr",
    native: "Hrvatska",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Croatian",
    timezones: [
      {
        zoneName: "Europe/Zagreb",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uD06C\uB85C\uC544\uD2F0\uC544",
      "pt-BR": "Cro\xE1cia",
      pt: "Cro\xE1cia",
      nl: "Kroati\xEB",
      hr: "Hrvatska",
      fa: "\u06A9\u0631\u0648\u0627\u0633\u06CC",
      de: "Kroatien",
      es: "Croacia",
      fr: "Croatie",
      ja: "\u30AF\u30ED\u30A2\u30C1\u30A2",
      it: "Croazia",
      cn: "\u514B\u7F57\u5730\u4E9A",
      tr: "Hirvatistan"
    },
    latitude: "45.16666666",
    longitude: "15.50000000",
    emoji: "\u{1F1ED}\u{1F1F7}",
    emojiU: "U+1F1ED U+1F1F7"
  },
  {
    id: 56,
    name: "Cuba",
    iso3: "CUB",
    iso2: "CU",
    numeric_code: "192",
    phone_code: "53",
    capital: "Havana",
    currency: "CUP",
    currency_name: "Cuban peso",
    currency_symbol: "$",
    tld: ".cu",
    native: "Cuba",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Cuban",
    timezones: [
      {
        zoneName: "America/Havana",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "CST",
        tzName: "Cuba Standard Time"
      }
    ],
    translations: {
      kr: "\uCFE0\uBC14",
      "pt-BR": "Cuba",
      pt: "Cuba",
      nl: "Cuba",
      hr: "Kuba",
      fa: "\u06A9\u0648\u0628\u0627",
      de: "Kuba",
      es: "Cuba",
      fr: "Cuba",
      ja: "\u30AD\u30E5\u30FC\u30D0",
      it: "Cuba",
      cn: "\u53E4\u5DF4",
      tr: "K\xFCba"
    },
    latitude: "21.50000000",
    longitude: "-80.00000000",
    emoji: "\u{1F1E8}\u{1F1FA}",
    emojiU: "U+1F1E8 U+1F1FA"
  },
  {
    id: 249,
    name: "Cura\xE7ao",
    iso3: "CUW",
    iso2: "CW",
    numeric_code: "531",
    phone_code: "599",
    capital: "Willemstad",
    currency: "ANG",
    currency_name: "Netherlands Antillean guilder",
    currency_symbol: "\u0192",
    tld: ".cw",
    native: "Cura\xE7ao",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Curacaoan",
    timezones: [
      {
        zoneName: "America/Curacao",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uD034\uB77C\uC18C",
      "pt-BR": "Cura\xE7ao",
      pt: "Cura\xE7ao",
      nl: "Cura\xE7ao",
      fa: "\u06A9\u0648\u0631\u0627\u0633\u0627\u0626\u0648",
      de: "Cura\xE7ao",
      fr: "Cura\xE7ao",
      it: "Cura\xE7ao",
      cn: "\u5E93\u62C9\u7D22",
      tr: "Cura\xE7ao"
    },
    latitude: "12.11666700",
    longitude: "-68.93333300",
    emoji: "\u{1F1E8}\u{1F1FC}",
    emojiU: "U+1F1E8 U+1F1FC"
  },
  {
    id: 57,
    name: "Cyprus",
    iso3: "CYP",
    iso2: "CY",
    numeric_code: "196",
    phone_code: "357",
    capital: "Nicosia",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".cy",
    native: "\u039A\u03CD\u03C0\u03C1\u03BF\u03C2",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Cypriot",
    timezones: [
      {
        zoneName: "Asia/Famagusta",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      },
      {
        zoneName: "Asia/Nicosia",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uD0A4\uD504\uB85C\uC2A4",
      "pt-BR": "Chipre",
      pt: "Chipre",
      nl: "Cyprus",
      hr: "Cipar",
      fa: "\u0642\u0628\u0631\u0633",
      de: "Zypern",
      es: "Chipre",
      fr: "Chypre",
      ja: "\u30AD\u30D7\u30ED\u30B9",
      it: "Cipro",
      cn: "\u585E\u6D66\u8DEF\u65AF",
      tr: "Kuzey K\u0131br\u0131s T\xFCrk Cumhuriyeti"
    },
    latitude: "35.00000000",
    longitude: "33.00000000",
    emoji: "\u{1F1E8}\u{1F1FE}",
    emojiU: "U+1F1E8 U+1F1FE"
  },
  {
    id: 58,
    name: "Czech Republic",
    iso3: "CZE",
    iso2: "CZ",
    numeric_code: "203",
    phone_code: "420",
    capital: "Prague",
    currency: "CZK",
    currency_name: "Czech koruna",
    currency_symbol: "K\u010D",
    tld: ".cz",
    native: "\u010Cesk\xE1 republika",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Czech",
    timezones: null,
    translations: {
      kr: "\uCCB4\uCF54",
      "pt-BR": "Rep\xFAblica Tcheca",
      pt: "Rep\xFAblica Checa",
      nl: "Tsjechi\xEB",
      hr: "\u010Ce\u0161ka",
      fa: "\u062C\u0645\u0647\u0648\u0631\u06CC \u0686\u06A9",
      de: "Tschechische Republik",
      es: "Rep\xFAblica Checa",
      fr: "R\xE9publique tch\xE8que",
      ja: "\u30C1\u30A7\u30B3",
      it: "Repubblica Ceca",
      cn: "\u6377\u514B",
      tr: "\xC7ekya"
    },
    latitude: "49.75000000",
    longitude: "15.50000000",
    emoji: "\u{1F1E8}\u{1F1FF}",
    emojiU: "U+1F1E8 U+1F1FF"
  },
  {
    id: 51,
    name: "Democratic Republic of the Congo",
    iso3: "COD",
    iso2: "CD",
    numeric_code: "180",
    phone_code: "243",
    capital: "Kinshasa",
    currency: "CDF",
    currency_name: "Congolese Franc",
    currency_symbol: "FC",
    tld: ".cd",
    native: "R\xE9publique d\xE9mocratique du Congo",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Congolese",
    timezones: [
      {
        zoneName: "Africa/Kinshasa",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      },
      {
        zoneName: "Africa/Lubumbashi",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time"
      }
    ],
    translations: {
      kr: "\uCF69\uACE0 \uBBFC\uC8FC \uACF5\uD654\uAD6D",
      "pt-BR": "RD Congo",
      pt: "RD Congo",
      nl: "Congo [DRC]",
      hr: "Kongo, Demokratska Republika",
      fa: "\u062C\u0645\u0647\u0648\u0631\u06CC \u06A9\u0646\u06AF\u0648",
      de: "Kongo (Dem. Rep.)",
      es: "Congo (Rep. Dem.)",
      fr: "Congo (R\xE9p. d\xE9m.)",
      ja: "\u30B3\u30F3\u30B4\u6C11\u4E3B\u5171\u548C\u56FD",
      it: "Congo (Rep. Dem.)",
      cn: "\u521A\u679C\uFF08\u91D1\uFF09",
      tr: "Kongo Demokratik Cumhuriyeti"
    },
    latitude: "0.00000000",
    longitude: "25.00000000",
    emoji: "\u{1F1E8}\u{1F1E9}",
    emojiU: "U+1F1E8 U+1F1E9"
  },
  {
    id: 59,
    name: "Denmark",
    iso3: "DNK",
    iso2: "DK",
    numeric_code: "208",
    phone_code: "45",
    capital: "Copenhagen",
    currency: "DKK",
    currency_name: "Danish krone",
    currency_symbol: "Kr.",
    tld: ".dk",
    native: "Danmark",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Danish",
    timezones: [
      {
        zoneName: "Europe/Copenhagen",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uB374\uB9C8\uD06C",
      "pt-BR": "Dinamarca",
      pt: "Dinamarca",
      nl: "Denemarken",
      hr: "Danska",
      fa: "\u062F\u0627\u0646\u0645\u0627\u0631\u06A9",
      de: "D\xE4nemark",
      es: "Dinamarca",
      fr: "Danemark",
      ja: "\u30C7\u30F3\u30DE\u30FC\u30AF",
      it: "Danimarca",
      cn: "\u4E39\u9EA6",
      tr: "Danimarka"
    },
    latitude: "56.00000000",
    longitude: "10.00000000",
    emoji: "\u{1F1E9}\u{1F1F0}",
    emojiU: "U+1F1E9 U+1F1F0"
  },
  {
    id: 60,
    name: "Djibouti",
    iso3: "DJI",
    iso2: "DJ",
    numeric_code: "262",
    phone_code: "253",
    capital: "Djibouti",
    currency: "DJF",
    currency_name: "Djiboutian franc",
    currency_symbol: "Fdj",
    tld: ".dj",
    native: "Djibouti",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Djiboutian",
    timezones: [
      {
        zoneName: "Africa/Djibouti",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uC9C0\uBD80\uD2F0",
      "pt-BR": "Djibuti",
      pt: "Djibuti",
      nl: "Djibouti",
      hr: "D\u017Eibuti",
      fa: "\u062C\u06CC\u0628\u0648\u062A\u06CC",
      de: "Dschibuti",
      es: "Yibuti",
      fr: "Djibouti",
      ja: "\u30B8\u30D6\u30C1",
      it: "Gibuti",
      cn: "\u5409\u5E03\u63D0",
      tr: "Cibuti"
    },
    latitude: "11.50000000",
    longitude: "43.00000000",
    emoji: "\u{1F1E9}\u{1F1EF}",
    emojiU: "U+1F1E9 U+1F1EF"
  },
  {
    id: 61,
    name: "Dominica",
    iso3: "DMA",
    iso2: "DM",
    numeric_code: "212",
    phone_code: "1",
    capital: "Roseau",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".dm",
    native: "Dominica",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Dominican",
    timezones: [
      {
        zoneName: "America/Dominica",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uB3C4\uBBF8\uB2C8\uCE74 \uC5F0\uBC29",
      "pt-BR": "Dominica",
      pt: "Dominica",
      nl: "Dominica",
      hr: "Dominika",
      fa: "\u062F\u0648\u0645\u06CC\u0646\u06CC\u06A9\u0627",
      de: "Dominica",
      es: "Dominica",
      fr: "Dominique",
      ja: "\u30C9\u30DF\u30CB\u30AB\u56FD",
      it: "Dominica",
      cn: "\u591A\u7C73\u5C3C\u52A0",
      tr: "Dominika"
    },
    latitude: "15.41666666",
    longitude: "-61.33333333",
    emoji: "\u{1F1E9}\u{1F1F2}",
    emojiU: "U+1F1E9 U+1F1F2"
  },
  {
    id: 62,
    name: "Dominican Republic",
    iso3: "DOM",
    iso2: "DO",
    numeric_code: "214",
    phone_code: "1",
    capital: "Santo Domingo",
    currency: "DOP",
    currency_name: "Dominican peso",
    currency_symbol: "$",
    tld: ".do",
    native: "Rep\xFAblica Dominicana",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Dominican",
    timezones: [
      {
        zoneName: "America/Santo_Domingo",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uB3C4\uBBF8\uB2C8\uCE74 \uACF5\uD654\uAD6D",
      "pt-BR": "Rep\xFAblica Dominicana",
      pt: "Rep\xFAblica Dominicana",
      nl: "Dominicaanse Republiek",
      hr: "Dominikanska Republika",
      fa: "\u062C\u0645\u0647\u0648\u0631\u06CC \u062F\u0648\u0645\u06CC\u0646\u06CC\u06A9\u0646",
      de: "Dominikanische Republik",
      es: "Rep\xFAblica Dominicana",
      fr: "R\xE9publique dominicaine",
      ja: "\u30C9\u30DF\u30CB\u30AB\u5171\u548C\u56FD",
      it: "Repubblica Dominicana",
      cn: "\u591A\u660E\u5C3C\u52A0\u5171\u548C\u56FD",
      tr: "Dominik Cumhuriyeti"
    },
    latitude: "19.00000000",
    longitude: "-70.66666666",
    emoji: "\u{1F1E9}\u{1F1F4}",
    emojiU: "U+1F1E9 U+1F1F4"
  },
  {
    id: 63,
    name: "East Timor",
    iso3: "TLS",
    iso2: "TL",
    numeric_code: "626",
    phone_code: "670",
    capital: "Dili",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".tl",
    native: "Timor-Leste",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Timorese",
    timezones: [
      {
        zoneName: "Asia/Dili",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "TLT",
        tzName: "Timor Leste Time"
      }
    ],
    translations: {
      kr: "\uB3D9\uD2F0\uBAA8\uB974",
      "pt-BR": "Timor Leste",
      pt: "Timor Leste",
      nl: "Oost-Timor",
      hr: "Isto\u010Dni Timor",
      fa: "\u062A\u06CC\u0645\u0648\u0631 \u0634\u0631\u0642\u06CC",
      de: "Timor-Leste",
      es: "Timor Oriental",
      fr: "Timor oriental",
      ja: "\u6771\u30C6\u30A3\u30E2\u30FC\u30EB",
      it: "Timor Est",
      cn: "\u4E1C\u5E1D\u6C76",
      tr: "Do\u011Fu Timor"
    },
    latitude: "-8.83333333",
    longitude: "125.91666666",
    emoji: "\u{1F1F9}\u{1F1F1}",
    emojiU: "U+1F1F9 U+1F1F1"
  },
  {
    id: 64,
    name: "Ecuador",
    iso3: "ECU",
    iso2: "EC",
    numeric_code: "218",
    phone_code: "593",
    capital: "Quito",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".ec",
    native: "Ecuador",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Ecuadorian",
    timezones: [
      {
        zoneName: "America/Guayaquil",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "ECT",
        tzName: "Ecuador Time"
      },
      {
        zoneName: "Pacific/Galapagos",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "GALT",
        tzName: "Gal\xE1pagos Time"
      }
    ],
    translations: {
      kr: "\uC5D0\uCF70\uB3C4\uB974",
      "pt-BR": "Equador",
      pt: "Equador",
      nl: "Ecuador",
      hr: "Ekvador",
      fa: "\u0627\u06A9\u0648\u0627\u062F\u0648\u0631",
      de: "Ecuador",
      es: "Ecuador",
      fr: "\xC9quateur",
      ja: "\u30A8\u30AF\u30A2\u30C9\u30EB",
      it: "Ecuador",
      cn: "\u5384\u74DC\u591A\u5C14",
      tr: "Ekvator"
    },
    latitude: "-2.00000000",
    longitude: "-77.50000000",
    emoji: "\u{1F1EA}\u{1F1E8}",
    emojiU: "U+1F1EA U+1F1E8"
  },
  {
    id: 65,
    name: "Egypt",
    iso3: "EGY",
    iso2: "EG",
    numeric_code: "818",
    phone_code: "20",
    capital: "Cairo",
    currency: "EGP",
    currency_name: "Egyptian pound",
    currency_symbol: "\u062C.\u0645",
    tld: ".eg",
    native: "\u0645\u0635\u0631\u200E",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Egyptian",
    timezones: [
      {
        zoneName: "Africa/Cairo",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uC774\uC9D1\uD2B8",
      "pt-BR": "Egito",
      pt: "Egipto",
      nl: "Egypte",
      hr: "Egipat",
      fa: "\u0645\u0635\u0631",
      de: "\xC4gypten",
      es: "Egipto",
      fr: "\xC9gypte",
      ja: "\u30A8\u30B8\u30D7\u30C8",
      it: "Egitto",
      cn: "\u57C3\u53CA",
      tr: "M\u0131s\u0131r"
    },
    latitude: "27.00000000",
    longitude: "30.00000000",
    emoji: "\u{1F1EA}\u{1F1EC}",
    emojiU: "U+1F1EA U+1F1EC"
  },
  {
    id: 66,
    name: "El Salvador",
    iso3: "SLV",
    iso2: "SV",
    numeric_code: "222",
    phone_code: "503",
    capital: "San Salvador",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".sv",
    native: "El Salvador",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Salvadoran",
    timezones: [
      {
        zoneName: "America/El_Salvador",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uC5D8\uC0B4\uBC14\uB3C4\uB974",
      "pt-BR": "El Salvador",
      pt: "El Salvador",
      nl: "El Salvador",
      hr: "Salvador",
      fa: "\u0627\u0644\u0633\u0627\u0644\u0648\u0627\u062F\u0648\u0631",
      de: "El Salvador",
      es: "El Salvador",
      fr: "Salvador",
      ja: "\u30A8\u30EB\u30B5\u30EB\u30D0\u30C9\u30EB",
      it: "El Salvador",
      cn: "\u8428\u5C14\u74E6\u591A",
      tr: "El Salvador"
    },
    latitude: "13.83333333",
    longitude: "-88.91666666",
    emoji: "\u{1F1F8}\u{1F1FB}",
    emojiU: "U+1F1F8 U+1F1FB"
  },
  {
    id: 67,
    name: "Equatorial Guinea",
    iso3: "GNQ",
    iso2: "GQ",
    numeric_code: "226",
    phone_code: "240",
    capital: "Malabo",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".gq",
    native: "Guinea Ecuatorial",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Equatorial Guinean, Equatoguinean",
    timezones: [
      {
        zoneName: "Africa/Malabo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uC801\uB3C4 \uAE30\uB2C8",
      "pt-BR": "Guin\xE9 Equatorial",
      pt: "Guin\xE9 Equatorial",
      nl: "Equatoriaal-Guinea",
      hr: "Ekvatorijalna Gvineja",
      fa: "\u06AF\u06CC\u0646\u0647 \u0627\u0633\u062A\u0648\u0627\u06CC\u06CC",
      de: "\xC4quatorial-Guinea",
      es: "Guinea Ecuatorial",
      fr: "Guin\xE9e-\xC9quatoriale",
      ja: "\u8D64\u9053\u30AE\u30CB\u30A2",
      it: "Guinea Equatoriale",
      cn: "\u8D64\u9053\u51E0\u5185\u4E9A",
      tr: "Ekvator Ginesi"
    },
    latitude: "2.00000000",
    longitude: "10.00000000",
    emoji: "\u{1F1EC}\u{1F1F6}",
    emojiU: "U+1F1EC U+1F1F6"
  },
  {
    id: 68,
    name: "Eritrea",
    iso3: "ERI",
    iso2: "ER",
    numeric_code: "232",
    phone_code: "291",
    capital: "Asmara",
    currency: "ERN",
    currency_name: "Eritrean nakfa",
    currency_symbol: "Nfk",
    tld: ".er",
    native: "\u12A4\u122D\u1275\u122B",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Eritrean",
    timezones: [
      {
        zoneName: "Africa/Asmara",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uC5D0\uB9AC\uD2B8\uB808\uC544",
      "pt-BR": "Eritreia",
      pt: "Eritreia",
      nl: "Eritrea",
      hr: "Eritreja",
      fa: "\u0627\u0631\u06CC\u062A\u0631\u0647",
      de: "Eritrea",
      es: "Eritrea",
      fr: "\xC9rythr\xE9e",
      ja: "\u30A8\u30EA\u30C8\u30EA\u30A2",
      it: "Eritrea",
      cn: "\u5384\u7ACB\u7279\u91CC\u4E9A",
      tr: "Eritre"
    },
    latitude: "15.00000000",
    longitude: "39.00000000",
    emoji: "\u{1F1EA}\u{1F1F7}",
    emojiU: "U+1F1EA U+1F1F7"
  },
  {
    id: 69,
    name: "Estonia",
    iso3: "EST",
    iso2: "EE",
    numeric_code: "233",
    phone_code: "372",
    capital: "Tallinn",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".ee",
    native: "Eesti",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Estonian",
    timezones: [
      {
        zoneName: "Europe/Tallinn",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uC5D0\uC2A4\uD1A0\uB2C8\uC544",
      "pt-BR": "Est\xF4nia",
      pt: "Est\xF3nia",
      nl: "Estland",
      hr: "Estonija",
      fa: "\u0627\u0633\u062A\u0648\u0646\u06CC",
      de: "Estland",
      es: "Estonia",
      fr: "Estonie",
      ja: "\u30A8\u30B9\u30C8\u30CB\u30A2",
      it: "Estonia",
      cn: "\u7231\u6C99\u5C3C\u4E9A",
      tr: "Estonya"
    },
    latitude: "59.00000000",
    longitude: "26.00000000",
    emoji: "\u{1F1EA}\u{1F1EA}",
    emojiU: "U+1F1EA U+1F1EA"
  },
  {
    id: 70,
    name: "Ethiopia",
    iso3: "ETH",
    iso2: "ET",
    numeric_code: "231",
    phone_code: "251",
    capital: "Addis Ababa",
    currency: "ETB",
    currency_name: "Ethiopian birr",
    currency_symbol: "Nkf",
    tld: ".et",
    native: "\u12A2\u1275\u12EE\u1335\u12EB",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Ethiopian",
    timezones: [
      {
        zoneName: "Africa/Addis_Ababa",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uC5D0\uD2F0\uC624\uD53C\uC544",
      "pt-BR": "Eti\xF3pia",
      pt: "Eti\xF3pia",
      nl: "Ethiopi\xEB",
      hr: "Etiopija",
      fa: "\u0627\u062A\u06CC\u0648\u067E\u06CC",
      de: "\xC4thiopien",
      es: "Etiop\xEDa",
      fr: "\xC9thiopie",
      ja: "\u30A8\u30C1\u30AA\u30D4\u30A2",
      it: "Etiopia",
      cn: "\u57C3\u585E\u4FC4\u6BD4\u4E9A",
      tr: "Etiyopya"
    },
    latitude: "8.00000000",
    longitude: "38.00000000",
    emoji: "\u{1F1EA}\u{1F1F9}",
    emojiU: "U+1F1EA U+1F1F9"
  },
  {
    id: 71,
    name: "Falkland Islands",
    iso3: "FLK",
    iso2: "FK",
    numeric_code: "238",
    phone_code: "500",
    capital: "Stanley",
    currency: "FKP",
    currency_name: "Falkland Islands pound",
    currency_symbol: "\xA3",
    tld: ".fk",
    native: "Falkland Islands",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Falkland Island",
    timezones: [
      {
        zoneName: "Atlantic/Stanley",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "FKST",
        tzName: "Falkland Islands Summer Time"
      }
    ],
    translations: {
      kr: "\uD3EC\uD074\uB79C\uB4DC \uC81C\uB3C4",
      "pt-BR": "Ilhas Malvinas",
      pt: "Ilhas Falkland",
      nl: "Falklandeilanden [Islas Malvinas]",
      hr: "Falklandski Otoci",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u0641\u0627\u0644\u06A9\u0644\u0646\u062F",
      de: "Falklandinseln",
      es: "Islas Malvinas",
      fr: "\xCEles Malouines",
      ja: "\u30D5\u30A9\u30FC\u30AF\u30E9\u30F3\u30C9\uFF08\u30DE\u30EB\u30D3\u30CA\u30B9\uFF09\u8AF8\u5CF6",
      it: "Isole Falkland o Isole Malvine",
      cn: "\u798F\u514B\u5170\u7FA4\u5C9B",
      tr: "Falkland Adalari"
    },
    latitude: "-51.75000000",
    longitude: "-59.00000000",
    emoji: "\u{1F1EB}\u{1F1F0}",
    emojiU: "U+1F1EB U+1F1F0"
  },
  {
    id: 72,
    name: "Faroe Islands",
    iso3: "FRO",
    iso2: "FO",
    numeric_code: "234",
    phone_code: "298",
    capital: "Torshavn",
    currency: "DKK",
    currency_name: "Danish krone",
    currency_symbol: "Kr.",
    tld: ".fo",
    native: "F\xF8royar",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Faroese",
    timezones: [
      {
        zoneName: "Atlantic/Faroe",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "WET",
        tzName: "Western European Time"
      }
    ],
    translations: {
      kr: "\uD398\uB85C \uC81C\uB3C4",
      "pt-BR": "Ilhas Faro\xE9",
      pt: "Ilhas Faro\xE9",
      nl: "Faer\xF6er",
      hr: "Farski Otoci",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u0641\u0627\u0631\u0648",
      de: "F\xE4r\xF6er-Inseln",
      es: "Islas Faroe",
      fr: "\xCEles F\xE9ro\xE9",
      ja: "\u30D5\u30A7\u30ED\u30FC\u8AF8\u5CF6",
      it: "Isole Far Oer",
      cn: "\u6CD5\u7F57\u7FA4\u5C9B",
      tr: "Faroe Adalari"
    },
    latitude: "62.00000000",
    longitude: "-7.00000000",
    emoji: "\u{1F1EB}\u{1F1F4}",
    emojiU: "U+1F1EB U+1F1F4"
  },
  {
    id: 73,
    name: "Fiji Islands",
    iso3: "FJI",
    iso2: "FJ",
    numeric_code: "242",
    phone_code: "679",
    capital: "Suva",
    currency: "FJD",
    currency_name: "Fijian dollar",
    currency_symbol: "FJ$",
    tld: ".fj",
    native: "Fiji",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "Fijian",
    timezones: [
      {
        zoneName: "Pacific/Fiji",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "FJT",
        tzName: "Fiji Time"
      }
    ],
    translations: {
      kr: "\uD53C\uC9C0",
      "pt-BR": "Fiji",
      pt: "Fiji",
      nl: "Fiji",
      hr: "Fi\u0111i",
      fa: "\u0641\u06CC\u062C\u06CC",
      de: "Fidschi",
      es: "Fiyi",
      fr: "Fidji",
      ja: "\u30D5\u30A3\u30B8\u30FC",
      it: "Figi",
      cn: "\u6590\u6D4E",
      tr: "Fiji"
    },
    latitude: "-18.00000000",
    longitude: "175.00000000",
    emoji: "\u{1F1EB}\u{1F1EF}",
    emojiU: "U+1F1EB U+1F1EF"
  },
  {
    id: 74,
    name: "Finland",
    iso3: "FIN",
    iso2: "FI",
    numeric_code: "246",
    phone_code: "358",
    capital: "Helsinki",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".fi",
    native: "Suomi",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Finnish",
    timezones: [
      {
        zoneName: "Europe/Helsinki",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uD540\uB780\uB4DC",
      "pt-BR": "Finl\xE2ndia",
      pt: "Finl\xE2ndia",
      nl: "Finland",
      hr: "Finska",
      fa: "\u0641\u0646\u0644\u0627\u0646\u062F",
      de: "Finnland",
      es: "Finlandia",
      fr: "Finlande",
      ja: "\u30D5\u30A3\u30F3\u30E9\u30F3\u30C9",
      it: "Finlandia",
      cn: "\u82AC\u5170",
      tr: "Finlandiya"
    },
    latitude: "64.00000000",
    longitude: "26.00000000",
    emoji: "\u{1F1EB}\u{1F1EE}",
    emojiU: "U+1F1EB U+1F1EE"
  },
  {
    id: 75,
    name: "France",
    iso3: "FRA",
    iso2: "FR",
    numeric_code: "250",
    phone_code: "33",
    capital: "Paris",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".fr",
    native: "France",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "French",
    timezones: [
      {
        zoneName: "Europe/Paris",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uD504\uB791\uC2A4",
      "pt-BR": "Fran\xE7a",
      pt: "Fran\xE7a",
      nl: "Frankrijk",
      hr: "Francuska",
      fa: "\u0641\u0631\u0627\u0646\u0633\u0647",
      de: "Frankreich",
      es: "Francia",
      fr: "France",
      ja: "\u30D5\u30E9\u30F3\u30B9",
      it: "Francia",
      cn: "\u6CD5\u56FD",
      tr: "Fransa"
    },
    latitude: "46.00000000",
    longitude: "2.00000000",
    emoji: "\u{1F1EB}\u{1F1F7}",
    emojiU: "U+1F1EB U+1F1F7"
  },
  {
    id: 76,
    name: "French Guiana",
    iso3: "GUF",
    iso2: "GF",
    numeric_code: "254",
    phone_code: "594",
    capital: "Cayenne",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".gf",
    native: "Guyane fran\xE7aise",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "French Guianese",
    timezones: [
      {
        zoneName: "America/Cayenne",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "GFT",
        tzName: "French Guiana Time"
      }
    ],
    translations: {
      kr: "\uD504\uB791\uC2A4\uB839 \uAE30\uC544\uB098",
      "pt-BR": "Guiana Francesa",
      pt: "Guiana Francesa",
      nl: "Frans-Guyana",
      hr: "Francuska Gvajana",
      fa: "\u06AF\u0648\u06CC\u0627\u0646 \u0641\u0631\u0627\u0646\u0633\u0647",
      de: "Franz\xF6sisch Guyana",
      es: "Guayana Francesa",
      fr: "Guayane",
      ja: "\u30D5\u30E9\u30F3\u30B9\u9818\u30AE\u30A2\u30CA",
      it: "Guyana francese",
      cn: "\u6CD5\u5C5E\u572D\u4E9A\u90A3",
      tr: "Fransiz Guyanasi"
    },
    latitude: "4.00000000",
    longitude: "-53.00000000",
    emoji: "\u{1F1EC}\u{1F1EB}",
    emojiU: "U+1F1EC U+1F1EB"
  },
  {
    id: 77,
    name: "French Polynesia",
    iso3: "PYF",
    iso2: "PF",
    numeric_code: "258",
    phone_code: "689",
    capital: "Papeete",
    currency: "XPF",
    currency_name: "CFP franc",
    currency_symbol: "\u20A3",
    tld: ".pf",
    native: "Polyn\xE9sie fran\xE7aise",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "French Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Gambier",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "GAMT",
        tzName: "Gambier Islands Time"
      },
      {
        zoneName: "Pacific/Marquesas",
        gmtOffset: -34200,
        gmtOffsetName: "UTC-09:30",
        abbreviation: "MART",
        tzName: "Marquesas Islands Time"
      },
      {
        zoneName: "Pacific/Tahiti",
        gmtOffset: -36e3,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "TAHT",
        tzName: "Tahiti Time"
      }
    ],
    translations: {
      kr: "\uD504\uB791\uC2A4\uB839 \uD3F4\uB9AC\uB124\uC2DC\uC544",
      "pt-BR": "Polin\xE9sia Francesa",
      pt: "Polin\xE9sia Francesa",
      nl: "Frans-Polynesi\xEB",
      hr: "Francuska Polinezija",
      fa: "\u067E\u0644\u06CC\u200C\u0646\u0632\u06CC \u0641\u0631\u0627\u0646\u0633\u0647",
      de: "Franz\xF6sisch-Polynesien",
      es: "Polinesia Francesa",
      fr: "Polyn\xE9sie fran\xE7aise",
      ja: "\u30D5\u30E9\u30F3\u30B9\u9818\u30DD\u30EA\u30CD\u30B7\u30A2",
      it: "Polinesia Francese",
      cn: "\u6CD5\u5C5E\u6CE2\u5229\u5C3C\u897F\u4E9A",
      tr: "Fransiz Polinezyasi"
    },
    latitude: "-15.00000000",
    longitude: "-140.00000000",
    emoji: "\u{1F1F5}\u{1F1EB}",
    emojiU: "U+1F1F5 U+1F1EB"
  },
  {
    id: 78,
    name: "French Southern Territories",
    iso3: "ATF",
    iso2: "TF",
    numeric_code: "260",
    phone_code: "262",
    capital: "Port-aux-Francais",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".tf",
    native: "Territoire des Terres australes et antarctiques fr",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "French Southern Territories",
    timezones: [
      {
        zoneName: "Indian/Kerguelen",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TFT",
        tzName: "French Southern and Antarctic Time"
      }
    ],
    translations: {
      kr: "\uD504\uB791\uC2A4\uB839 \uB0A8\uBC29 \uBC0F \uB0A8\uADF9",
      "pt-BR": "Terras Austrais e Ant\xE1rticas Francesas",
      pt: "Terras Austrais e Ant\xE1rticas Francesas",
      nl: "Franse Gebieden in de zuidelijke Indische Oceaan",
      hr: "Francuski ju\u017Eni i antarkti\u010Dki teritoriji",
      fa: "\u0633\u0631\u0632\u0645\u06CC\u0646\u200C\u0647\u0627\u06CC \u062C\u0646\u0648\u0628\u06CC \u0648 \u062C\u0646\u0648\u0628\u06AF\u0627\u0646\u06CC \u0641\u0631\u0627\u0646\u0633\u0647",
      de: "Franz\xF6sische S\xFCd- und Antarktisgebiete",
      es: "Tierras Australes y Ant\xE1rticas Francesas",
      fr: "Terres australes et antarctiques fran\xE7aises",
      ja: "\u30D5\u30E9\u30F3\u30B9\u9818\u5357\u65B9\u30FB\u5357\u6975\u5730\u57DF",
      it: "Territori Francesi del Sud",
      cn: "\u6CD5\u5C5E\u5357\u90E8\u9886\u5730",
      tr: "Fransiz G\xFCney Topraklari"
    },
    latitude: "-49.25000000",
    longitude: "69.16700000",
    emoji: "\u{1F1F9}\u{1F1EB}",
    emojiU: "U+1F1F9 U+1F1EB"
  },
  {
    id: 79,
    name: "Gabon",
    iso3: "GAB",
    iso2: "GA",
    numeric_code: "266",
    phone_code: "241",
    capital: "Libreville",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".ga",
    native: "Gabon",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Gabonese",
    timezones: [
      {
        zoneName: "Africa/Libreville",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uAC00\uBD09",
      "pt-BR": "Gab\xE3o",
      pt: "Gab\xE3o",
      nl: "Gabon",
      hr: "Gabon",
      fa: "\u06AF\u0627\u0628\u0646",
      de: "Gabun",
      es: "Gab\xF3n",
      fr: "Gabon",
      ja: "\u30AC\u30DC\u30F3",
      it: "Gabon",
      cn: "\u52A0\u84EC",
      tr: "Gabon"
    },
    latitude: "-1.00000000",
    longitude: "11.75000000",
    emoji: "\u{1F1EC}\u{1F1E6}",
    emojiU: "U+1F1EC U+1F1E6"
  },
  {
    id: 80,
    name: "Gambia The",
    iso3: "GMB",
    iso2: "GM",
    numeric_code: "270",
    phone_code: "220",
    capital: "Banjul",
    currency: "GMD",
    currency_name: "Gambian dalasi",
    currency_symbol: "D",
    tld: ".gm",
    native: "Gambia",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Gambian",
    timezones: [
      {
        zoneName: "Africa/Banjul",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uAC10\uBE44\uC544",
      "pt-BR": "G\xE2mbia",
      pt: "G\xE2mbia",
      nl: "Gambia",
      hr: "Gambija",
      fa: "\u06AF\u0627\u0645\u0628\u06CC\u0627",
      de: "Gambia",
      es: "Gambia",
      fr: "Gambie",
      ja: "\u30AC\u30F3\u30D3\u30A2",
      it: "Gambia",
      cn: "\u5188\u6BD4\u4E9A",
      tr: "Gambiya"
    },
    latitude: "13.46666666",
    longitude: "-16.56666666",
    emoji: "\u{1F1EC}\u{1F1F2}",
    emojiU: "U+1F1EC U+1F1F2"
  },
  {
    id: 81,
    name: "Georgia",
    iso3: "GEO",
    iso2: "GE",
    numeric_code: "268",
    phone_code: "995",
    capital: "Tbilisi",
    currency: "GEL",
    currency_name: "Georgian lari",
    currency_symbol: "\u10DA",
    tld: ".ge",
    native: "\u10E1\u10D0\u10E5\u10D0\u10E0\u10D7\u10D5\u10D4\u10DA\u10DD",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Georgian",
    timezones: [
      {
        zoneName: "Asia/Tbilisi",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "GET",
        tzName: "Georgia Standard Time"
      }
    ],
    translations: {
      kr: "\uC870\uC9C0\uC544",
      "pt-BR": "Ge\xF3rgia",
      pt: "Ge\xF3rgia",
      nl: "Georgi\xEB",
      hr: "Gruzija",
      fa: "\u06AF\u0631\u062C\u0633\u062A\u0627\u0646",
      de: "Georgien",
      es: "Georgia",
      fr: "G\xE9orgie",
      ja: "\u30B0\u30EB\u30B8\u30A2",
      it: "Georgia",
      cn: "\u683C\u9C81\u5409\u4E9A",
      tr: "G\xFCrcistan"
    },
    latitude: "42.00000000",
    longitude: "43.50000000",
    emoji: "\u{1F1EC}\u{1F1EA}",
    emojiU: "U+1F1EC U+1F1EA"
  },
  {
    id: 82,
    name: "Germany",
    iso3: "DEU",
    iso2: "DE",
    numeric_code: "276",
    phone_code: "49",
    capital: "Berlin",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".de",
    native: "Deutschland",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "German",
    timezones: [
      {
        zoneName: "Europe/Berlin",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      },
      {
        zoneName: "Europe/Busingen",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uB3C5\uC77C",
      "pt-BR": "Alemanha",
      pt: "Alemanha",
      nl: "Duitsland",
      hr: "Njema\u010Dka",
      fa: "\u0622\u0644\u0645\u0627\u0646",
      de: "Deutschland",
      es: "Alemania",
      fr: "Allemagne",
      ja: "\u30C9\u30A4\u30C4",
      it: "Germania",
      cn: "\u5FB7\u56FD",
      tr: "Almanya"
    },
    latitude: "51.00000000",
    longitude: "9.00000000",
    emoji: "\u{1F1E9}\u{1F1EA}",
    emojiU: "U+1F1E9 U+1F1EA"
  },
  {
    id: 83,
    name: "Ghana",
    iso3: "GHA",
    iso2: "GH",
    numeric_code: "288",
    phone_code: "233",
    capital: "Accra",
    currency: "GHS",
    currency_name: "Ghanaian cedi",
    currency_symbol: "GH\u20B5",
    tld: ".gh",
    native: "Ghana",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Ghanaian",
    timezones: [
      {
        zoneName: "Africa/Accra",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uAC00\uB098",
      "pt-BR": "Gana",
      pt: "Gana",
      nl: "Ghana",
      hr: "Gana",
      fa: "\u063A\u0646\u0627",
      de: "Ghana",
      es: "Ghana",
      fr: "Ghana",
      ja: "\u30AC\u30FC\u30CA",
      it: "Ghana",
      cn: "\u52A0\u7EB3",
      tr: "Gana"
    },
    latitude: "8.00000000",
    longitude: "-2.00000000",
    emoji: "\u{1F1EC}\u{1F1ED}",
    emojiU: "U+1F1EC U+1F1ED"
  },
  {
    id: 84,
    name: "Gibraltar",
    iso3: "GIB",
    iso2: "GI",
    numeric_code: "292",
    phone_code: "350",
    capital: "Gibraltar",
    currency: "GIP",
    currency_name: "Gibraltar pound",
    currency_symbol: "\xA3",
    tld: ".gi",
    native: "Gibraltar",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Gibraltar",
    timezones: [
      {
        zoneName: "Europe/Gibraltar",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC9C0\uBE0C\uB864\uD130",
      "pt-BR": "Gibraltar",
      pt: "Gibraltar",
      nl: "Gibraltar",
      hr: "Gibraltar",
      fa: "\u062C\u0628\u0644\u200C\u0637\u0627\u0631\u0642",
      de: "Gibraltar",
      es: "Gibraltar",
      fr: "Gibraltar",
      ja: "\u30B8\u30D6\u30E9\u30EB\u30BF\u30EB",
      it: "Gibilterra",
      cn: "\u76F4\u5E03\u7F57\u9640",
      tr: "Cebelitarik"
    },
    latitude: "36.13333333",
    longitude: "-5.35000000",
    emoji: "\u{1F1EC}\u{1F1EE}",
    emojiU: "U+1F1EC U+1F1EE"
  },
  {
    id: 85,
    name: "Greece",
    iso3: "GRC",
    iso2: "GR",
    numeric_code: "300",
    phone_code: "30",
    capital: "Athens",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".gr",
    native: "\u0395\u03BB\u03BB\u03AC\u03B4\u03B1",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Greek, Hellenic",
    timezones: [
      {
        zoneName: "Europe/Athens",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uADF8\uB9AC\uC2A4",
      "pt-BR": "Gr\xE9cia",
      pt: "Gr\xE9cia",
      nl: "Griekenland",
      hr: "Gr\u010Dka",
      fa: "\u06CC\u0648\u0646\u0627\u0646",
      de: "Griechenland",
      es: "Grecia",
      fr: "Gr\xE8ce",
      ja: "\u30AE\u30EA\u30B7\u30E3",
      it: "Grecia",
      cn: "\u5E0C\u814A",
      tr: "Yunanistan"
    },
    latitude: "39.00000000",
    longitude: "22.00000000",
    emoji: "\u{1F1EC}\u{1F1F7}",
    emojiU: "U+1F1EC U+1F1F7"
  },
  {
    id: 86,
    name: "Greenland",
    iso3: "GRL",
    iso2: "GL",
    numeric_code: "304",
    phone_code: "299",
    capital: "Nuuk",
    currency: "DKK",
    currency_name: "Danish krone",
    currency_symbol: "Kr.",
    tld: ".gl",
    native: "Kalaallit Nunaat",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "Greenlandic",
    timezones: [
      {
        zoneName: "America/Danmarkshavn",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      },
      {
        zoneName: "America/Nuuk",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "WGT",
        tzName: "West Greenland Time"
      },
      {
        zoneName: "America/Scoresbysund",
        gmtOffset: -3600,
        gmtOffsetName: "UTC-01:00",
        abbreviation: "EGT",
        tzName: "Eastern Greenland Time"
      },
      {
        zoneName: "America/Thule",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uADF8\uB9B0\uB780\uB4DC",
      "pt-BR": "Groel\xE2ndia",
      pt: "Gronel\xE2ndia",
      nl: "Groenland",
      hr: "Grenland",
      fa: "\u06AF\u0631\u06CC\u0646\u0644\u0646\u062F",
      de: "Gr\xF6nland",
      es: "Groenlandia",
      fr: "Groenland",
      ja: "\u30B0\u30EA\u30FC\u30F3\u30E9\u30F3\u30C9",
      it: "Groenlandia",
      cn: "\u683C\u9675\u5170\u5C9B",
      tr: "Gr\xF6nland"
    },
    latitude: "72.00000000",
    longitude: "-40.00000000",
    emoji: "\u{1F1EC}\u{1F1F1}",
    emojiU: "U+1F1EC U+1F1F1"
  },
  {
    id: 87,
    name: "Grenada",
    iso3: "GRD",
    iso2: "GD",
    numeric_code: "308",
    phone_code: "1",
    capital: "St. George's",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".gd",
    native: "Grenada",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Grenadian",
    timezones: [
      {
        zoneName: "America/Grenada",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uADF8\uB808\uB098\uB2E4",
      "pt-BR": "Granada",
      pt: "Granada",
      nl: "Grenada",
      hr: "Grenada",
      fa: "\u06AF\u0631\u0646\u0627\u062F\u0627",
      de: "Grenada",
      es: "Grenada",
      fr: "Grenade",
      ja: "\u30B0\u30EC\u30CA\u30C0",
      it: "Grenada",
      cn: "\u683C\u6797\u7EB3\u8FBE",
      tr: "Grenada"
    },
    latitude: "12.11666666",
    longitude: "-61.66666666",
    emoji: "\u{1F1EC}\u{1F1E9}",
    emojiU: "U+1F1EC U+1F1E9"
  },
  {
    id: 88,
    name: "Guadeloupe",
    iso3: "GLP",
    iso2: "GP",
    numeric_code: "312",
    phone_code: "590",
    capital: "Basse-Terre",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".gp",
    native: "Guadeloupe",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Guadeloupe",
    timezones: [
      {
        zoneName: "America/Guadeloupe",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uACFC\uB4E4\uB8E8\uD504",
      "pt-BR": "Guadalupe",
      pt: "Guadalupe",
      nl: "Guadeloupe",
      hr: "Gvadalupa",
      fa: "\u062C\u0632\u06CC\u0631\u0647 \u06AF\u0648\u0627\u062F\u0644\u0648\u067E",
      de: "Guadeloupe",
      es: "Guadalupe",
      fr: "Guadeloupe",
      ja: "\u30B0\u30A2\u30C9\u30EB\u30FC\u30D7",
      it: "Guadeloupa",
      cn: "\u74DC\u5FB7\u7F57\u666E\u5C9B",
      tr: "Guadeloupe"
    },
    latitude: "16.25000000",
    longitude: "-61.58333300",
    emoji: "\u{1F1EC}\u{1F1F5}",
    emojiU: "U+1F1EC U+1F1F5"
  },
  {
    id: 89,
    name: "Guam",
    iso3: "GUM",
    iso2: "GU",
    numeric_code: "316",
    phone_code: "1",
    capital: "Hagatna",
    currency: "USD",
    currency_name: "US Dollar",
    currency_symbol: "$",
    tld: ".gu",
    native: "Guam",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Guamanian, Guambat",
    timezones: [
      {
        zoneName: "Pacific/Guam",
        gmtOffset: 36e3,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "CHST",
        tzName: "Chamorro Standard Time"
      }
    ],
    translations: {
      kr: "\uAD0C",
      "pt-BR": "Guam",
      pt: "Guame",
      nl: "Guam",
      hr: "Guam",
      fa: "\u06AF\u0648\u0627\u0645",
      de: "Guam",
      es: "Guam",
      fr: "Guam",
      ja: "\u30B0\u30A2\u30E0",
      it: "Guam",
      cn: "\u5173\u5C9B",
      tr: "Guam"
    },
    latitude: "13.46666666",
    longitude: "144.78333333",
    emoji: "\u{1F1EC}\u{1F1FA}",
    emojiU: "U+1F1EC U+1F1FA"
  },
  {
    id: 90,
    name: "Guatemala",
    iso3: "GTM",
    iso2: "GT",
    numeric_code: "320",
    phone_code: "502",
    capital: "Guatemala City",
    currency: "GTQ",
    currency_name: "Guatemalan quetzal",
    currency_symbol: "Q",
    tld: ".gt",
    native: "Guatemala",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Guatemalan",
    timezones: [
      {
        zoneName: "America/Guatemala",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uACFC\uD14C\uB9D0\uB77C",
      "pt-BR": "Guatemala",
      pt: "Guatemala",
      nl: "Guatemala",
      hr: "Gvatemala",
      fa: "\u06AF\u0648\u0627\u062A\u0645\u0627\u0644\u0627",
      de: "Guatemala",
      es: "Guatemala",
      fr: "Guatemala",
      ja: "\u30B0\u30A2\u30C6\u30DE\u30E9",
      it: "Guatemala",
      cn: "\u5371\u5730\u9A6C\u62C9",
      tr: "Guatemala"
    },
    latitude: "15.50000000",
    longitude: "-90.25000000",
    emoji: "\u{1F1EC}\u{1F1F9}",
    emojiU: "U+1F1EC U+1F1F9"
  },
  {
    id: 91,
    name: "Guernsey and Alderney",
    iso3: "GGY",
    iso2: "GG",
    numeric_code: "831",
    phone_code: "44",
    capital: "St Peter Port",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "\xA3",
    tld: ".gg",
    native: "Guernsey",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Channel Island",
    timezones: [
      {
        zoneName: "Europe/Guernsey",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uAC74\uC9C0, \uC62C\uB354\uB2C8",
      "pt-BR": "Guernsey",
      pt: "Guernsey",
      nl: "Guernsey",
      hr: "Guernsey",
      fa: "\u06AF\u0631\u0646\u0632\u06CC",
      de: "Guernsey",
      es: "Guernsey",
      fr: "Guernesey",
      ja: "\u30AC\u30FC\u30F3\u30B8\u30FC",
      it: "Guernsey",
      cn: "\u6839\u897F\u5C9B",
      tr: "Alderney"
    },
    latitude: "49.46666666",
    longitude: "-2.58333333",
    emoji: "\u{1F1EC}\u{1F1EC}",
    emojiU: "U+1F1EC U+1F1EC"
  },
  {
    id: 92,
    name: "Guinea",
    iso3: "GIN",
    iso2: "GN",
    numeric_code: "324",
    phone_code: "224",
    capital: "Conakry",
    currency: "GNF",
    currency_name: "Guinean franc",
    currency_symbol: "FG",
    tld: ".gn",
    native: "Guin\xE9e",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Guinean",
    timezones: [
      {
        zoneName: "Africa/Conakry",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uAE30\uB2C8",
      "pt-BR": "Guin\xE9",
      pt: "Guin\xE9",
      nl: "Guinee",
      hr: "Gvineja",
      fa: "\u06AF\u06CC\u0646\u0647",
      de: "Guinea",
      es: "Guinea",
      fr: "Guin\xE9e",
      ja: "\u30AE\u30CB\u30A2",
      it: "Guinea",
      cn: "\u51E0\u5185\u4E9A",
      tr: "Gine"
    },
    latitude: "11.00000000",
    longitude: "-10.00000000",
    emoji: "\u{1F1EC}\u{1F1F3}",
    emojiU: "U+1F1EC U+1F1F3"
  },
  {
    id: 93,
    name: "Guinea-Bissau",
    iso3: "GNB",
    iso2: "GW",
    numeric_code: "624",
    phone_code: "245",
    capital: "Bissau",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".gw",
    native: "Guin\xE9-Bissau",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Bissau-Guinean",
    timezones: [
      {
        zoneName: "Africa/Bissau",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uAE30\uB2C8\uBE44\uC0AC\uC6B0",
      "pt-BR": "Guin\xE9-Bissau",
      pt: "Guin\xE9-Bissau",
      nl: "Guinee-Bissau",
      hr: "Gvineja Bisau",
      fa: "\u06AF\u06CC\u0646\u0647 \u0628\u06CC\u0633\u0627\u0626\u0648",
      de: "Guinea-Bissau",
      es: "Guinea-Bis\xE1u",
      fr: "Guin\xE9e-Bissau",
      ja: "\u30AE\u30CB\u30A2\u30D3\u30B5\u30A6",
      it: "Guinea-Bissau",
      cn: "\u51E0\u5185\u4E9A\u6BD4\u7ECD",
      tr: "Gine-bissau"
    },
    latitude: "12.00000000",
    longitude: "-15.00000000",
    emoji: "\u{1F1EC}\u{1F1FC}",
    emojiU: "U+1F1EC U+1F1FC"
  },
  {
    id: 94,
    name: "Guyana",
    iso3: "GUY",
    iso2: "GY",
    numeric_code: "328",
    phone_code: "592",
    capital: "Georgetown",
    currency: "GYD",
    currency_name: "Guyanese dollar",
    currency_symbol: "$",
    tld: ".gy",
    native: "Guyana",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Guyanese",
    timezones: [
      {
        zoneName: "America/Guyana",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "GYT",
        tzName: "Guyana Time"
      }
    ],
    translations: {
      kr: "\uAC00\uC774\uC544\uB098",
      "pt-BR": "Guiana",
      pt: "Guiana",
      nl: "Guyana",
      hr: "Gvajana",
      fa: "\u06AF\u0648\u06CC\u0627\u0646",
      de: "Guyana",
      es: "Guyana",
      fr: "Guyane",
      ja: "\u30AC\u30A4\u30A2\u30CA",
      it: "Guyana",
      cn: "\u572D\u4E9A\u90A3",
      tr: "Guyana"
    },
    latitude: "5.00000000",
    longitude: "-59.00000000",
    emoji: "\u{1F1EC}\u{1F1FE}",
    emojiU: "U+1F1EC U+1F1FE"
  },
  {
    id: 95,
    name: "Haiti",
    iso3: "HTI",
    iso2: "HT",
    numeric_code: "332",
    phone_code: "509",
    capital: "Port-au-Prince",
    currency: "HTG",
    currency_name: "Haitian gourde",
    currency_symbol: "G",
    tld: ".ht",
    native: "Ha\xEFti",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Haitian",
    timezones: [
      {
        zoneName: "America/Port-au-Prince",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uC544\uC774\uD2F0",
      "pt-BR": "Haiti",
      pt: "Haiti",
      nl: "Ha\xEFti",
      hr: "Haiti",
      fa: "\u0647\u0627\u0626\u06CC\u062A\u06CC",
      de: "Haiti",
      es: "Haiti",
      fr: "Ha\xEFti",
      ja: "\u30CF\u30A4\u30C1",
      it: "Haiti",
      cn: "\u6D77\u5730",
      tr: "Haiti"
    },
    latitude: "19.00000000",
    longitude: "-72.41666666",
    emoji: "\u{1F1ED}\u{1F1F9}",
    emojiU: "U+1F1ED U+1F1F9"
  },
  {
    id: 96,
    name: "Heard Island and McDonald Islands",
    iso3: "HMD",
    iso2: "HM",
    numeric_code: "334",
    phone_code: "672",
    capital: "",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".hm",
    native: "Heard Island and McDonald Islands",
    region: "",
    region_id: null,
    subregion: "",
    subregion_id: null,
    nationality: "Heard Island or McDonald Islands",
    timezones: [
      {
        zoneName: "Indian/Kerguelen",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TFT",
        tzName: "French Southern and Antarctic Time"
      }
    ],
    translations: {
      kr: "\uD5C8\uB4DC \uB9E5\uB3C4\uB0A0\uB4DC \uC81C\uB3C4",
      "pt-BR": "Ilha Heard e Ilhas McDonald",
      pt: "Ilha Heard e Ilhas McDonald",
      nl: "Heard- en McDonaldeilanden",
      hr: "Otok Heard i oto\u010Dje McDonald",
      fa: "\u062C\u0632\u06CC\u0631\u0647 \u0647\u0631\u062F \u0648 \u062C\u0632\u0627\u06CC\u0631 \u0645\u06A9\u200C\u062F\u0648\u0646\u0627\u0644\u062F",
      de: "Heard und die McDonaldinseln",
      es: "Islas Heard y McDonald",
      fr: "\xCEles Heard-et-MacDonald",
      ja: "\u30CF\u30FC\u30C9\u5CF6\u3068\u30DE\u30AF\u30C9\u30CA\u30EB\u30C9\u8AF8\u5CF6",
      it: "Isole Heard e McDonald",
      cn: "\u8D6B\u5FB7\xB7\u5510\u7EB3\u5C9B\u53CA\u9EA6\u5510\u7EB3\u5C9B",
      tr: "Heard Adasi Ve Mcdonald Adalari"
    },
    latitude: "-53.10000000",
    longitude: "72.51666666",
    emoji: "\u{1F1ED}\u{1F1F2}",
    emojiU: "U+1F1ED U+1F1F2"
  },
  {
    id: 97,
    name: "Honduras",
    iso3: "HND",
    iso2: "HN",
    numeric_code: "340",
    phone_code: "504",
    capital: "Tegucigalpa",
    currency: "HNL",
    currency_name: "Honduran lempira",
    currency_symbol: "L",
    tld: ".hn",
    native: "Honduras",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Honduran",
    timezones: [
      {
        zoneName: "America/Tegucigalpa",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uC628\uB450\uB77C\uC2A4",
      "pt-BR": "Honduras",
      pt: "Honduras",
      nl: "Honduras",
      hr: "Honduras",
      fa: "\u0647\u0646\u062F\u0648\u0631\u0627\u0633",
      de: "Honduras",
      es: "Honduras",
      fr: "Honduras",
      ja: "\u30DB\u30F3\u30B8\u30E5\u30E9\u30B9",
      it: "Honduras",
      cn: "\u6D2A\u90FD\u62C9\u65AF",
      tr: "Honduras"
    },
    latitude: "15.00000000",
    longitude: "-86.50000000",
    emoji: "\u{1F1ED}\u{1F1F3}",
    emojiU: "U+1F1ED U+1F1F3"
  },
  {
    id: 98,
    name: "Hong Kong S.A.R.",
    iso3: "HKG",
    iso2: "HK",
    numeric_code: "344",
    phone_code: "852",
    capital: "Hong Kong",
    currency: "HKD",
    currency_name: "Hong Kong dollar",
    currency_symbol: "$",
    tld: ".hk",
    native: "\u9999\u6E2F",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Hong Kong, Hong Kongese",
    timezones: [
      {
        zoneName: "Asia/Hong_Kong",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "HKT",
        tzName: "Hong Kong Time"
      }
    ],
    translations: {
      kr: "\uD64D\uCF69",
      "pt-BR": "Hong Kong",
      pt: "Hong Kong",
      nl: "Hongkong",
      hr: "Hong Kong",
      fa: "\u0647\u0646\u06AF\u200C\u06A9\u0646\u06AF",
      de: "Hong Kong",
      es: "Hong Kong",
      fr: "Hong Kong",
      ja: "\u9999\u6E2F",
      it: "Hong Kong",
      cn: "\u4E2D\u56FD\u9999\u6E2F",
      tr: "Hong Kong"
    },
    latitude: "22.25000000",
    longitude: "114.16666666",
    emoji: "\u{1F1ED}\u{1F1F0}",
    emojiU: "U+1F1ED U+1F1F0"
  },
  {
    id: 99,
    name: "Hungary",
    iso3: "HUN",
    iso2: "HU",
    numeric_code: "348",
    phone_code: "36",
    capital: "Budapest",
    currency: "HUF",
    currency_name: "Hungarian forint",
    currency_symbol: "Ft",
    tld: ".hu",
    native: "Magyarorsz\xE1g",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Hungarian, Magyar",
    timezones: [
      {
        zoneName: "Europe/Budapest",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uD5DD\uAC00\uB9AC",
      "pt-BR": "Hungria",
      pt: "Hungria",
      nl: "Hongarije",
      hr: "Ma\u0111arska",
      fa: "\u0645\u062C\u0627\u0631\u0633\u062A\u0627\u0646",
      de: "Ungarn",
      es: "Hungr\xEDa",
      fr: "Hongrie",
      ja: "\u30CF\u30F3\u30AC\u30EA\u30FC",
      it: "Ungheria",
      cn: "\u5308\u7259\u5229",
      tr: "Macaristan"
    },
    latitude: "47.00000000",
    longitude: "20.00000000",
    emoji: "\u{1F1ED}\u{1F1FA}",
    emojiU: "U+1F1ED U+1F1FA"
  },
  {
    id: 100,
    name: "Iceland",
    iso3: "ISL",
    iso2: "IS",
    numeric_code: "352",
    phone_code: "354",
    capital: "Reykjavik",
    currency: "ISK",
    currency_name: "Icelandic kr\xF3na",
    currency_symbol: "kr",
    tld: ".is",
    native: "\xCDsland",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Icelandic",
    timezones: [
      {
        zoneName: "Atlantic/Reykjavik",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uC544\uC774\uC2AC\uB780\uB4DC",
      "pt-BR": "Isl\xE2ndia",
      pt: "Isl\xE2ndia",
      nl: "IJsland",
      hr: "Island",
      fa: "\u0627\u06CC\u0633\u0644\u0646\u062F",
      de: "Island",
      es: "Islandia",
      fr: "Islande",
      ja: "\u30A2\u30A4\u30B9\u30E9\u30F3\u30C9",
      it: "Islanda",
      cn: "\u51B0\u5C9B",
      tr: "\u0130zlanda"
    },
    latitude: "65.00000000",
    longitude: "-18.00000000",
    emoji: "\u{1F1EE}\u{1F1F8}",
    emojiU: "U+1F1EE U+1F1F8"
  },
  {
    id: 101,
    name: "India",
    iso3: "IND",
    iso2: "IN",
    numeric_code: "356",
    phone_code: "91",
    capital: "New Delhi",
    currency: "INR",
    currency_name: "Indian rupee",
    currency_symbol: "\u20B9",
    tld: ".in",
    native: "\u092D\u093E\u0930\u0924",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Indian",
    timezones: [
      {
        zoneName: "Asia/Kolkata",
        gmtOffset: 19800,
        gmtOffsetName: "UTC+05:30",
        abbreviation: "IST",
        tzName: "Indian Standard Time"
      }
    ],
    translations: {
      kr: "\uC778\uB3C4",
      "pt-BR": "\xCDndia",
      pt: "\xCDndia",
      nl: "India",
      hr: "Indija",
      fa: "\u0647\u0646\u062F",
      de: "Indien",
      es: "India",
      fr: "Inde",
      ja: "\u30A4\u30F3\u30C9",
      it: "India",
      cn: "\u5370\u5EA6",
      tr: "Hindistan"
    },
    latitude: "20.00000000",
    longitude: "77.00000000",
    emoji: "\u{1F1EE}\u{1F1F3}",
    emojiU: "U+1F1EE U+1F1F3"
  },
  {
    id: 102,
    name: "Indonesia",
    iso3: "IDN",
    iso2: "ID",
    numeric_code: "360",
    phone_code: "62",
    capital: "Jakarta",
    currency: "IDR",
    currency_name: "Indonesian rupiah",
    currency_symbol: "Rp",
    tld: ".id",
    native: "Indonesia",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Indonesian",
    timezones: [
      {
        zoneName: "Asia/Jakarta",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "WIB",
        tzName: "Western Indonesian Time"
      },
      {
        zoneName: "Asia/Jayapura",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "WIT",
        tzName: "Eastern Indonesian Time"
      },
      {
        zoneName: "Asia/Makassar",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "WITA",
        tzName: "Central Indonesia Time"
      },
      {
        zoneName: "Asia/Pontianak",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "WIB",
        tzName: "Western Indonesian Time"
      }
    ],
    translations: {
      kr: "\uC778\uB3C4\uB124\uC2DC\uC544",
      "pt-BR": "Indon\xE9sia",
      pt: "Indon\xE9sia",
      nl: "Indonesi\xEB",
      hr: "Indonezija",
      fa: "\u0627\u0646\u062F\u0648\u0646\u0632\u06CC",
      de: "Indonesien",
      es: "Indonesia",
      fr: "Indon\xE9sie",
      ja: "\u30A4\u30F3\u30C9\u30CD\u30B7\u30A2",
      it: "Indonesia",
      cn: "\u5370\u5EA6\u5C3C\u897F\u4E9A",
      tr: "Endonezya"
    },
    latitude: "-5.00000000",
    longitude: "120.00000000",
    emoji: "\u{1F1EE}\u{1F1E9}",
    emojiU: "U+1F1EE U+1F1E9"
  },
  {
    id: 103,
    name: "Iran",
    iso3: "IRN",
    iso2: "IR",
    numeric_code: "364",
    phone_code: "98",
    capital: "Tehran",
    currency: "IRR",
    currency_name: "Iranian rial",
    currency_symbol: "\uFDFC",
    tld: ".ir",
    native: "\u0627\u06CC\u0631\u0627\u0646",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Iranian, Persian",
    timezones: [
      {
        zoneName: "Asia/Tehran",
        gmtOffset: 12600,
        gmtOffsetName: "UTC+03:30",
        abbreviation: "IRDT",
        tzName: "Iran Daylight Time"
      }
    ],
    translations: {
      kr: "\uC774\uB780",
      "pt-BR": "Ir\xE3",
      pt: "Ir\xE3o",
      nl: "Iran",
      hr: "Iran",
      fa: "\u0627\u06CC\u0631\u0627\u0646",
      de: "Iran",
      es: "Iran",
      fr: "Iran",
      ja: "\u30A4\u30E9\u30F3\u30FB\u30A4\u30B9\u30E9\u30E0\u5171\u548C\u56FD",
      cn: "\u4F0A\u6717",
      tr: "\u0130ran"
    },
    latitude: "32.00000000",
    longitude: "53.00000000",
    emoji: "\u{1F1EE}\u{1F1F7}",
    emojiU: "U+1F1EE U+1F1F7"
  },
  {
    id: 104,
    name: "Iraq",
    iso3: "IRQ",
    iso2: "IQ",
    numeric_code: "368",
    phone_code: "964",
    capital: "Baghdad",
    currency: "IQD",
    currency_name: "Iraqi dinar",
    currency_symbol: "\u062F.\u0639",
    tld: ".iq",
    native: "\u0627\u0644\u0639\u0631\u0627\u0642",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Iraqi",
    timezones: [
      {
        zoneName: "Asia/Baghdad",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time"
      }
    ],
    translations: {
      kr: "\uC774\uB77C\uD06C",
      "pt-BR": "Iraque",
      pt: "Iraque",
      nl: "Irak",
      hr: "Irak",
      fa: "\u0639\u0631\u0627\u0642",
      de: "Irak",
      es: "Irak",
      fr: "Irak",
      ja: "\u30A4\u30E9\u30AF",
      it: "Iraq",
      cn: "\u4F0A\u62C9\u514B",
      tr: "Irak"
    },
    latitude: "33.00000000",
    longitude: "44.00000000",
    emoji: "\u{1F1EE}\u{1F1F6}",
    emojiU: "U+1F1EE U+1F1F6"
  },
  {
    id: 105,
    name: "Ireland",
    iso3: "IRL",
    iso2: "IE",
    numeric_code: "372",
    phone_code: "353",
    capital: "Dublin",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".ie",
    native: "\xC9ire",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Irish",
    timezones: [
      {
        zoneName: "Europe/Dublin",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uC544\uC77C\uB79C\uB4DC",
      "pt-BR": "Irlanda",
      pt: "Irlanda",
      nl: "Ierland",
      hr: "Irska",
      fa: "\u0627\u06CC\u0631\u0644\u0646\u062F",
      de: "Irland",
      es: "Irlanda",
      fr: "Irlande",
      ja: "\u30A2\u30A4\u30EB\u30E9\u30F3\u30C9",
      it: "Irlanda",
      cn: "\u7231\u5C14\u5170",
      tr: "\u0130rlanda"
    },
    latitude: "53.00000000",
    longitude: "-8.00000000",
    emoji: "\u{1F1EE}\u{1F1EA}",
    emojiU: "U+1F1EE U+1F1EA"
  },
  {
    id: 106,
    name: "Israel",
    iso3: "ISR",
    iso2: "IL",
    numeric_code: "376",
    phone_code: "972",
    capital: "Jerusalem",
    currency: "ILS",
    currency_name: "Israeli new shekel",
    currency_symbol: "\u20AA",
    tld: ".il",
    native: "\u05D9\u05B4\u05E9\u05B0\u05C2\u05E8\u05B8\u05D0\u05B5\u05DC",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Israeli",
    timezones: [
      {
        zoneName: "Asia/Jerusalem",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "IST",
        tzName: "Israel Standard Time"
      }
    ],
    translations: {
      kr: "\uC774\uC2A4\uB77C\uC5D8",
      "pt-BR": "Israel",
      pt: "Israel",
      nl: "Isra\xEBl",
      hr: "Izrael",
      fa: "\u0627\u0633\u0631\u0627\u0626\u06CC\u0644",
      de: "Israel",
      es: "Israel",
      fr: "Isra\xEBl",
      ja: "\u30A4\u30B9\u30E9\u30A8\u30EB",
      it: "Israele",
      cn: "\u4EE5\u8272\u5217",
      tr: "\u0130srail"
    },
    latitude: "31.50000000",
    longitude: "34.75000000",
    emoji: "\u{1F1EE}\u{1F1F1}",
    emojiU: "U+1F1EE U+1F1F1"
  },
  {
    id: 107,
    name: "Italy",
    iso3: "ITA",
    iso2: "IT",
    numeric_code: "380",
    phone_code: "39",
    capital: "Rome",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".it",
    native: "Italia",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Italian",
    timezones: [
      {
        zoneName: "Europe/Rome",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC774\uD0C8\uB9AC\uC544",
      "pt-BR": "It\xE1lia",
      pt: "It\xE1lia",
      nl: "Itali\xEB",
      hr: "Italija",
      fa: "\u0627\u06CC\u062A\u0627\u0644\u06CC\u0627",
      de: "Italien",
      es: "Italia",
      fr: "Italie",
      ja: "\u30A4\u30BF\u30EA\u30A2",
      it: "Italia",
      cn: "\u610F\u5927\u5229",
      tr: "\u0130talya"
    },
    latitude: "42.83333333",
    longitude: "12.83333333",
    emoji: "\u{1F1EE}\u{1F1F9}",
    emojiU: "U+1F1EE U+1F1F9"
  },
  {
    id: 108,
    name: "Jamaica",
    iso3: "JAM",
    iso2: "JM",
    numeric_code: "388",
    phone_code: "1",
    capital: "Kingston",
    currency: "JMD",
    currency_name: "Jamaican dollar",
    currency_symbol: "J$",
    tld: ".jm",
    native: "Jamaica",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Jamaican",
    timezones: [
      {
        zoneName: "America/Jamaica",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uC790\uBA54\uC774\uCE74",
      "pt-BR": "Jamaica",
      pt: "Jamaica",
      nl: "Jamaica",
      hr: "Jamajka",
      fa: "\u062C\u0627\u0645\u0627\u0626\u06CC\u06A9\u0627",
      de: "Jamaika",
      es: "Jamaica",
      fr: "Jama\xEFque",
      ja: "\u30B8\u30E3\u30DE\u30A4\u30AB",
      it: "Giamaica",
      cn: "\u7259\u4E70\u52A0",
      tr: "Jamaika"
    },
    latitude: "18.25000000",
    longitude: "-77.50000000",
    emoji: "\u{1F1EF}\u{1F1F2}",
    emojiU: "U+1F1EF U+1F1F2"
  },
  {
    id: 109,
    name: "Japan",
    iso3: "JPN",
    iso2: "JP",
    numeric_code: "392",
    phone_code: "81",
    capital: "Tokyo",
    currency: "JPY",
    currency_name: "Japanese yen",
    currency_symbol: "\xA5",
    tld: ".jp",
    native: "\u65E5\u672C",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Japanese",
    timezones: [
      {
        zoneName: "Asia/Tokyo",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "JST",
        tzName: "Japan Standard Time"
      }
    ],
    translations: {
      kr: "\uC77C\uBCF8",
      "pt-BR": "Jap\xE3o",
      pt: "Jap\xE3o",
      nl: "Japan",
      hr: "Japan",
      fa: "\u0698\u0627\u067E\u0646",
      de: "Japan",
      es: "Jap\xF3n",
      fr: "Japon",
      ja: "\u65E5\u672C",
      it: "Giappone",
      cn: "\u65E5\u672C",
      tr: "Japonya"
    },
    latitude: "36.00000000",
    longitude: "138.00000000",
    emoji: "\u{1F1EF}\u{1F1F5}",
    emojiU: "U+1F1EF U+1F1F5"
  },
  {
    id: 110,
    name: "Jersey",
    iso3: "JEY",
    iso2: "JE",
    numeric_code: "832",
    phone_code: "44",
    capital: "Saint Helier",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "\xA3",
    tld: ".je",
    native: "Jersey",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Channel Island",
    timezones: [
      {
        zoneName: "Europe/Jersey",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uC800\uC9C0 \uC12C",
      "pt-BR": "Jersey",
      pt: "Jersey",
      nl: "Jersey",
      hr: "Jersey",
      fa: "\u062C\u0631\u0632\u06CC",
      de: "Jersey",
      es: "Jersey",
      fr: "Jersey",
      ja: "\u30B8\u30E3\u30FC\u30B8\u30FC",
      it: "Isola di Jersey",
      cn: "\u6CFD\u897F\u5C9B",
      tr: "Jersey"
    },
    latitude: "49.25000000",
    longitude: "-2.16666666",
    emoji: "\u{1F1EF}\u{1F1EA}",
    emojiU: "U+1F1EF U+1F1EA"
  },
  {
    id: 111,
    name: "Jordan",
    iso3: "JOR",
    iso2: "JO",
    numeric_code: "400",
    phone_code: "962",
    capital: "Amman",
    currency: "JOD",
    currency_name: "Jordanian dinar",
    currency_symbol: "\u0627.\u062F",
    tld: ".jo",
    native: "\u0627\u0644\u0623\u0631\u062F\u0646",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Jordanian",
    timezones: [
      {
        zoneName: "Asia/Amman",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uC694\uB974\uB2E8",
      "pt-BR": "Jord\xE2nia",
      pt: "Jord\xE2nia",
      nl: "Jordani\xEB",
      hr: "Jordan",
      fa: "\u0627\u0631\u062F\u0646",
      de: "Jordanien",
      es: "Jordania",
      fr: "Jordanie",
      ja: "\u30E8\u30EB\u30C0\u30F3",
      it: "Giordania",
      cn: "\u7EA6\u65E6",
      tr: "\xDCrd\xFCn"
    },
    latitude: "31.00000000",
    longitude: "36.00000000",
    emoji: "\u{1F1EF}\u{1F1F4}",
    emojiU: "U+1F1EF U+1F1F4"
  },
  {
    id: 112,
    name: "Kazakhstan",
    iso3: "KAZ",
    iso2: "KZ",
    numeric_code: "398",
    phone_code: "7",
    capital: "Astana",
    currency: "KZT",
    currency_name: "Kazakhstani tenge",
    currency_symbol: "\u043B\u0432",
    tld: ".kz",
    native: "\u049A\u0430\u0437\u0430\u049B\u0441\u0442\u0430\u043D",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Kazakhstani, Kazakh",
    timezones: [
      {
        zoneName: "Asia/Almaty",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "ALMT",
        tzName: "Alma-Ata Time[1"
      },
      {
        zoneName: "Asia/Aqtau",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "AQTT",
        tzName: "Aqtobe Time"
      },
      {
        zoneName: "Asia/Aqtobe",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "AQTT",
        tzName: "Aqtobe Time"
      },
      {
        zoneName: "Asia/Atyrau",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "MSD+1",
        tzName: "Moscow Daylight Time+1"
      },
      {
        zoneName: "Asia/Oral",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "ORAT",
        tzName: "Oral Time"
      },
      {
        zoneName: "Asia/Qostanay",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "QYZST",
        tzName: "Qyzylorda Summer Time"
      },
      {
        zoneName: "Asia/Qyzylorda",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "QYZT",
        tzName: "Qyzylorda Summer Time"
      }
    ],
    translations: {
      kr: "\uCE74\uC790\uD750\uC2A4\uD0C4",
      "pt-BR": "Cazaquist\xE3o",
      pt: "Cazaquist\xE3o",
      nl: "Kazachstan",
      hr: "Kazahstan",
      fa: "\u0642\u0632\u0627\u0642\u0633\u062A\u0627\u0646",
      de: "Kasachstan",
      es: "Kazajist\xE1n",
      fr: "Kazakhstan",
      ja: "\u30AB\u30B6\u30D5\u30B9\u30BF\u30F3",
      it: "Kazakistan",
      cn: "\u54C8\u8428\u514B\u65AF\u5766",
      tr: "Kazakistan"
    },
    latitude: "48.00000000",
    longitude: "68.00000000",
    emoji: "\u{1F1F0}\u{1F1FF}",
    emojiU: "U+1F1F0 U+1F1FF"
  },
  {
    id: 113,
    name: "Kenya",
    iso3: "KEN",
    iso2: "KE",
    numeric_code: "404",
    phone_code: "254",
    capital: "Nairobi",
    currency: "KES",
    currency_name: "Kenyan shilling",
    currency_symbol: "KSh",
    tld: ".ke",
    native: "Kenya",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Kenyan",
    timezones: [
      {
        zoneName: "Africa/Nairobi",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uCF00\uB0D0",
      "pt-BR": "Qu\xEAnia",
      pt: "Qu\xE9nia",
      nl: "Kenia",
      hr: "Kenija",
      fa: "\u06A9\u0646\u06CC\u0627",
      de: "Kenia",
      es: "Kenia",
      fr: "Kenya",
      ja: "\u30B1\u30CB\u30A2",
      it: "Kenya",
      cn: "\u80AF\u5C3C\u4E9A",
      tr: "Kenya"
    },
    latitude: "1.00000000",
    longitude: "38.00000000",
    emoji: "\u{1F1F0}\u{1F1EA}",
    emojiU: "U+1F1F0 U+1F1EA"
  },
  {
    id: 114,
    name: "Kiribati",
    iso3: "KIR",
    iso2: "KI",
    numeric_code: "296",
    phone_code: "686",
    capital: "Tarawa",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".ki",
    native: "Kiribati",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "I-Kiribati",
    timezones: [
      {
        zoneName: "Pacific/Enderbury",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "PHOT",
        tzName: "Phoenix Island Time"
      },
      {
        zoneName: "Pacific/Kiritimati",
        gmtOffset: 50400,
        gmtOffsetName: "UTC+14:00",
        abbreviation: "LINT",
        tzName: "Line Islands Time"
      },
      {
        zoneName: "Pacific/Tarawa",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "GILT",
        tzName: "Gilbert Island Time"
      }
    ],
    translations: {
      kr: "\uD0A4\uB9AC\uBC14\uC2DC",
      "pt-BR": "Kiribati",
      pt: "Quirib\xE1ti",
      nl: "Kiribati",
      hr: "Kiribati",
      fa: "\u06A9\u06CC\u0631\u06CC\u0628\u0627\u062A\u06CC",
      de: "Kiribati",
      es: "Kiribati",
      fr: "Kiribati",
      ja: "\u30AD\u30EA\u30D0\u30B9",
      it: "Kiribati",
      cn: "\u57FA\u91CC\u5DF4\u65AF",
      tr: "Kiribati"
    },
    latitude: "1.41666666",
    longitude: "173.00000000",
    emoji: "\u{1F1F0}\u{1F1EE}",
    emojiU: "U+1F1F0 U+1F1EE"
  },
  {
    id: 248,
    name: "Kosovo",
    iso3: "XKX",
    iso2: "XK",
    numeric_code: "926",
    phone_code: "383",
    capital: "Pristina",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".xk",
    native: "Republika e Kosov\xEBs",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Kosovar, Kosovan",
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uCF54\uC18C\uBCF4",
      cn: "\u79D1\u7D22\u6C83",
      tr: "Kosova"
    },
    latitude: "42.56129090",
    longitude: "20.34030350",
    emoji: "\u{1F1FD}\u{1F1F0}",
    emojiU: "U+1F1FD U+1F1F0"
  },
  {
    id: 117,
    name: "Kuwait",
    iso3: "KWT",
    iso2: "KW",
    numeric_code: "414",
    phone_code: "965",
    capital: "Kuwait City",
    currency: "KWD",
    currency_name: "Kuwaiti dinar",
    currency_symbol: "\u0643.\u062F",
    tld: ".kw",
    native: "\u0627\u0644\u0643\u0648\u064A\u062A",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Kuwaiti",
    timezones: [
      {
        zoneName: "Asia/Kuwait",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time"
      }
    ],
    translations: {
      kr: "\uCFE0\uC6E8\uC774\uD2B8",
      "pt-BR": "Kuwait",
      pt: "Kuwait",
      nl: "Koeweit",
      hr: "Kuvajt",
      fa: "\u06A9\u0648\u06CC\u062A",
      de: "Kuwait",
      es: "Kuwait",
      fr: "Kowe\xEFt",
      ja: "\u30AF\u30A6\u30A7\u30FC\u30C8",
      it: "Kuwait",
      cn: "\u79D1\u5A01\u7279",
      tr: "Kuveyt"
    },
    latitude: "29.50000000",
    longitude: "45.75000000",
    emoji: "\u{1F1F0}\u{1F1FC}",
    emojiU: "U+1F1F0 U+1F1FC"
  },
  {
    id: 118,
    name: "Kyrgyzstan",
    iso3: "KGZ",
    iso2: "KG",
    numeric_code: "417",
    phone_code: "996",
    capital: "Bishkek",
    currency: "KGS",
    currency_name: "Kyrgyzstani som",
    currency_symbol: "\u043B\u0432",
    tld: ".kg",
    native: "\u041A\u044B\u0440\u0433\u044B\u0437\u0441\u0442\u0430\u043D",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
    timezones: [
      {
        zoneName: "Asia/Bishkek",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "KGT",
        tzName: "Kyrgyzstan Time"
      }
    ],
    translations: {
      kr: "\uD0A4\uB974\uAE30\uC2A4\uC2A4\uD0C4",
      "pt-BR": "Quirguist\xE3o",
      pt: "Quirguizist\xE3o",
      nl: "Kirgizi\xEB",
      hr: "Kirgistan",
      fa: "\u0642\u0631\u0642\u06CC\u0632\u0633\u062A\u0627\u0646",
      de: "Kirgisistan",
      es: "Kirguizist\xE1n",
      fr: "Kirghizistan",
      ja: "\u30AD\u30EB\u30AE\u30B9",
      it: "Kirghizistan",
      cn: "\u5409\u5C14\u5409\u65AF\u65AF\u5766",
      tr: "Kirgizistan"
    },
    latitude: "41.00000000",
    longitude: "75.00000000",
    emoji: "\u{1F1F0}\u{1F1EC}",
    emojiU: "U+1F1F0 U+1F1EC"
  },
  {
    id: 119,
    name: "Laos",
    iso3: "LAO",
    iso2: "LA",
    numeric_code: "418",
    phone_code: "856",
    capital: "Vientiane",
    currency: "LAK",
    currency_name: "Lao kip",
    currency_symbol: "\u20AD",
    tld: ".la",
    native: "\u0EAA\u0E9B\u0E9B\u0EA5\u0EB2\u0EA7",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Lao, Laotian",
    timezones: [
      {
        zoneName: "Asia/Vientiane",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time"
      }
    ],
    translations: {
      kr: "\uB77C\uC624\uC2A4",
      "pt-BR": "Laos",
      pt: "Laos",
      nl: "Laos",
      hr: "Laos",
      fa: "\u0644\u0627\u0626\u0648\u0633",
      de: "Laos",
      es: "Laos",
      fr: "Laos",
      ja: "\u30E9\u30AA\u30B9\u4EBA\u6C11\u6C11\u4E3B\u5171\u548C\u56FD",
      it: "Laos",
      cn: "\u5BEE\u4EBA\u6C11\u6C11\u4E3B\u5171\u548C\u56FD",
      tr: "Laos"
    },
    latitude: "18.00000000",
    longitude: "105.00000000",
    emoji: "\u{1F1F1}\u{1F1E6}",
    emojiU: "U+1F1F1 U+1F1E6"
  },
  {
    id: 120,
    name: "Latvia",
    iso3: "LVA",
    iso2: "LV",
    numeric_code: "428",
    phone_code: "371",
    capital: "Riga",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".lv",
    native: "Latvija",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Latvian",
    timezones: [
      {
        zoneName: "Europe/Riga",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uB77C\uD2B8\uBE44\uC544",
      "pt-BR": "Let\xF4nia",
      pt: "Let\xF3nia",
      nl: "Letland",
      hr: "Latvija",
      fa: "\u0644\u062A\u0648\u0646\u06CC",
      de: "Lettland",
      es: "Letonia",
      fr: "Lettonie",
      ja: "\u30E9\u30C8\u30D3\u30A2",
      it: "Lettonia",
      cn: "\u62C9\u8131\u7EF4\u4E9A",
      tr: "Letonya"
    },
    latitude: "57.00000000",
    longitude: "25.00000000",
    emoji: "\u{1F1F1}\u{1F1FB}",
    emojiU: "U+1F1F1 U+1F1FB"
  },
  {
    id: 121,
    name: "Lebanon",
    iso3: "LBN",
    iso2: "LB",
    numeric_code: "422",
    phone_code: "961",
    capital: "Beirut",
    currency: "LBP",
    currency_name: "Lebanese pound",
    currency_symbol: "\xA3",
    tld: ".lb",
    native: "\u0644\u0628\u0646\u0627\u0646",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Lebanese",
    timezones: [
      {
        zoneName: "Asia/Beirut",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uB808\uBC14\uB17C",
      "pt-BR": "L\xEDbano",
      pt: "L\xEDbano",
      nl: "Libanon",
      hr: "Libanon",
      fa: "\u0644\u0628\u0646\u0627\u0646",
      de: "Libanon",
      es: "L\xEDbano",
      fr: "Liban",
      ja: "\u30EC\u30D0\u30CE\u30F3",
      it: "Libano",
      cn: "\u9ECE\u5DF4\u5AE9",
      tr: "L\xFCbnan"
    },
    latitude: "33.83333333",
    longitude: "35.83333333",
    emoji: "\u{1F1F1}\u{1F1E7}",
    emojiU: "U+1F1F1 U+1F1E7"
  },
  {
    id: 122,
    name: "Lesotho",
    iso3: "LSO",
    iso2: "LS",
    numeric_code: "426",
    phone_code: "266",
    capital: "Maseru",
    currency: "LSL",
    currency_name: "Lesotho loti",
    currency_symbol: "L",
    tld: ".ls",
    native: "Lesotho",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "Basotho",
    timezones: [
      {
        zoneName: "Africa/Maseru",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "SAST",
        tzName: "South African Standard Time"
      }
    ],
    translations: {
      kr: "\uB808\uC18C\uD1A0",
      "pt-BR": "Lesoto",
      pt: "Lesoto",
      nl: "Lesotho",
      hr: "Lesoto",
      fa: "\u0644\u0633\u0648\u062A\u0648",
      de: "Lesotho",
      es: "Lesotho",
      fr: "Lesotho",
      ja: "\u30EC\u30BD\u30C8",
      it: "Lesotho",
      cn: "\u83B1\u7D22\u6258",
      tr: "Lesotho"
    },
    latitude: "-29.50000000",
    longitude: "28.50000000",
    emoji: "\u{1F1F1}\u{1F1F8}",
    emojiU: "U+1F1F1 U+1F1F8"
  },
  {
    id: 123,
    name: "Liberia",
    iso3: "LBR",
    iso2: "LR",
    numeric_code: "430",
    phone_code: "231",
    capital: "Monrovia",
    currency: "LRD",
    currency_name: "Liberian dollar",
    currency_symbol: "$",
    tld: ".lr",
    native: "Liberia",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Liberian",
    timezones: [
      {
        zoneName: "Africa/Monrovia",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uB77C\uC774\uBCA0\uB9AC\uC544",
      "pt-BR": "Lib\xE9ria",
      pt: "Lib\xE9ria",
      nl: "Liberia",
      hr: "Liberija",
      fa: "\u0644\u06CC\u0628\u0631\u06CC\u0627",
      de: "Liberia",
      es: "Liberia",
      fr: "Liberia",
      ja: "\u30EA\u30D9\u30EA\u30A2",
      it: "Liberia",
      cn: "\u5229\u6BD4\u91CC\u4E9A",
      tr: "Liberya"
    },
    latitude: "6.50000000",
    longitude: "-9.50000000",
    emoji: "\u{1F1F1}\u{1F1F7}",
    emojiU: "U+1F1F1 U+1F1F7"
  },
  {
    id: 124,
    name: "Libya",
    iso3: "LBY",
    iso2: "LY",
    numeric_code: "434",
    phone_code: "218",
    capital: "Tripolis",
    currency: "LYD",
    currency_name: "Libyan dinar",
    currency_symbol: "\u062F.\u0644",
    tld: ".ly",
    native: "\u200F\u0644\u064A\u0628\u064A\u0627",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Libyan",
    timezones: [
      {
        zoneName: "Africa/Tripoli",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uB9AC\uBE44\uC544",
      "pt-BR": "L\xEDbia",
      pt: "L\xEDbia",
      nl: "Libi\xEB",
      hr: "Libija",
      fa: "\u0644\u06CC\u0628\u06CC",
      de: "Libyen",
      es: "Libia",
      fr: "Libye",
      ja: "\u30EA\u30D3\u30A2",
      it: "Libia",
      cn: "\u5229\u6BD4\u4E9A",
      tr: "Libya"
    },
    latitude: "25.00000000",
    longitude: "17.00000000",
    emoji: "\u{1F1F1}\u{1F1FE}",
    emojiU: "U+1F1F1 U+1F1FE"
  },
  {
    id: 125,
    name: "Liechtenstein",
    iso3: "LIE",
    iso2: "LI",
    numeric_code: "438",
    phone_code: "423",
    capital: "Vaduz",
    currency: "CHF",
    currency_name: "Swiss franc",
    currency_symbol: "CHf",
    tld: ".li",
    native: "Liechtenstein",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Liechtenstein",
    timezones: [
      {
        zoneName: "Europe/Vaduz",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uB9AC\uD788\uD150\uC288\uD0C0\uC778",
      "pt-BR": "Liechtenstein",
      pt: "Listenstaine",
      nl: "Liechtenstein",
      hr: "Lihten\u0161tajn",
      fa: "\u0644\u06CC\u062E\u062A\u0646\u200C\u0627\u0634\u062A\u0627\u06CC\u0646",
      de: "Liechtenstein",
      es: "Liechtenstein",
      fr: "Liechtenstein",
      ja: "\u30EA\u30D2\u30C6\u30F3\u30B7\u30E5\u30BF\u30A4\u30F3",
      it: "Liechtenstein",
      cn: "\u5217\u652F\u6566\u58EB\u767B",
      tr: "Lihten\u015Ftayn"
    },
    latitude: "47.26666666",
    longitude: "9.53333333",
    emoji: "\u{1F1F1}\u{1F1EE}",
    emojiU: "U+1F1F1 U+1F1EE"
  },
  {
    id: 126,
    name: "Lithuania",
    iso3: "LTU",
    iso2: "LT",
    numeric_code: "440",
    phone_code: "370",
    capital: "Vilnius",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".lt",
    native: "Lietuva",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Lithuanian",
    timezones: [
      {
        zoneName: "Europe/Vilnius",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uB9AC\uD22C\uC544\uB2C8\uC544",
      "pt-BR": "Litu\xE2nia",
      pt: "Litu\xE2nia",
      nl: "Litouwen",
      hr: "Litva",
      fa: "\u0644\u06CC\u062A\u0648\u0627\u0646\u06CC",
      de: "Litauen",
      es: "Lituania",
      fr: "Lituanie",
      ja: "\u30EA\u30C8\u30A2\u30CB\u30A2",
      it: "Lituania",
      cn: "\u7ACB\u9676\u5B9B",
      tr: "Litvanya"
    },
    latitude: "56.00000000",
    longitude: "24.00000000",
    emoji: "\u{1F1F1}\u{1F1F9}",
    emojiU: "U+1F1F1 U+1F1F9"
  },
  {
    id: 127,
    name: "Luxembourg",
    iso3: "LUX",
    iso2: "LU",
    numeric_code: "442",
    phone_code: "352",
    capital: "Luxembourg",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".lu",
    native: "Luxembourg",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Luxembourg, Luxembourgish",
    timezones: [
      {
        zoneName: "Europe/Luxembourg",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uB8E9\uC148\uBD80\uB974\uD06C",
      "pt-BR": "Luxemburgo",
      pt: "Luxemburgo",
      nl: "Luxemburg",
      hr: "Luksemburg",
      fa: "\u0644\u0648\u06A9\u0632\u0627\u0645\u0628\u0648\u0631\u06AF",
      de: "Luxemburg",
      es: "Luxemburgo",
      fr: "Luxembourg",
      ja: "\u30EB\u30AF\u30BB\u30F3\u30D6\u30EB\u30AF",
      it: "Lussemburgo",
      cn: "\u5362\u68EE\u5821",
      tr: "L\xFCksemburg"
    },
    latitude: "49.75000000",
    longitude: "6.16666666",
    emoji: "\u{1F1F1}\u{1F1FA}",
    emojiU: "U+1F1F1 U+1F1FA"
  },
  {
    id: 128,
    name: "Macau S.A.R.",
    iso3: "MAC",
    iso2: "MO",
    numeric_code: "446",
    phone_code: "853",
    capital: "Macao",
    currency: "MOP",
    currency_name: "Macanese pataca",
    currency_symbol: "$",
    tld: ".mo",
    native: "\u6FB3\u9580",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Macanese, Chinese",
    timezones: [
      {
        zoneName: "Asia/Macau",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CST",
        tzName: "China Standard Time"
      }
    ],
    translations: {
      kr: "\uB9C8\uCE74\uC624",
      "pt-BR": "Macau",
      pt: "Macau",
      nl: "Macao",
      hr: "Makao",
      fa: "\u0645\u06A9\u0627\u0626\u0648",
      de: "Macao",
      es: "Macao",
      fr: "Macao",
      ja: "\u30DE\u30AB\u30AA",
      it: "Macao",
      cn: "\u4E2D\u56FD\u6FB3\u95E8",
      tr: "Makao"
    },
    latitude: "22.16666666",
    longitude: "113.55000000",
    emoji: "\u{1F1F2}\u{1F1F4}",
    emojiU: "U+1F1F2 U+1F1F4"
  },
  {
    id: 130,
    name: "Madagascar",
    iso3: "MDG",
    iso2: "MG",
    numeric_code: "450",
    phone_code: "261",
    capital: "Antananarivo",
    currency: "MGA",
    currency_name: "Malagasy ariary",
    currency_symbol: "Ar",
    tld: ".mg",
    native: "Madagasikara",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Malagasy",
    timezones: [
      {
        zoneName: "Indian/Antananarivo",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uB9C8\uB2E4\uAC00\uC2A4\uCE74\uB974",
      "pt-BR": "Madagascar",
      pt: "Madag\xE1scar",
      nl: "Madagaskar",
      hr: "Madagaskar",
      fa: "\u0645\u0627\u062F\u0627\u06AF\u0627\u0633\u06A9\u0627\u0631",
      de: "Madagaskar",
      es: "Madagascar",
      fr: "Madagascar",
      ja: "\u30DE\u30C0\u30AC\u30B9\u30AB\u30EB",
      it: "Madagascar",
      cn: "\u9A6C\u8FBE\u52A0\u65AF\u52A0",
      tr: "Madagaskar"
    },
    latitude: "-20.00000000",
    longitude: "47.00000000",
    emoji: "\u{1F1F2}\u{1F1EC}",
    emojiU: "U+1F1F2 U+1F1EC"
  },
  {
    id: 131,
    name: "Malawi",
    iso3: "MWI",
    iso2: "MW",
    numeric_code: "454",
    phone_code: "265",
    capital: "Lilongwe",
    currency: "MWK",
    currency_name: "Malawian kwacha",
    currency_symbol: "MK",
    tld: ".mw",
    native: "Malawi",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Malawian",
    timezones: [
      {
        zoneName: "Africa/Blantyre",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time"
      }
    ],
    translations: {
      kr: "\uB9D0\uB77C\uC704",
      "pt-BR": "Malawi",
      pt: "Mal\xE1vi",
      nl: "Malawi",
      hr: "Malavi",
      fa: "\u0645\u0627\u0644\u0627\u0648\u06CC",
      de: "Malawi",
      es: "Malawi",
      fr: "Malawi",
      ja: "\u30DE\u30E9\u30A6\u30A4",
      it: "Malawi",
      cn: "\u9A6C\u62C9\u7EF4",
      tr: "Malavi"
    },
    latitude: "-13.50000000",
    longitude: "34.00000000",
    emoji: "\u{1F1F2}\u{1F1FC}",
    emojiU: "U+1F1F2 U+1F1FC"
  },
  {
    id: 132,
    name: "Malaysia",
    iso3: "MYS",
    iso2: "MY",
    numeric_code: "458",
    phone_code: "60",
    capital: "Kuala Lumpur",
    currency: "MYR",
    currency_name: "Malaysian ringgit",
    currency_symbol: "RM",
    tld: ".my",
    native: "Malaysia",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Malaysian",
    timezones: [
      {
        zoneName: "Asia/Kuala_Lumpur",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "MYT",
        tzName: "Malaysia Time"
      },
      {
        zoneName: "Asia/Kuching",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "MYT",
        tzName: "Malaysia Time"
      }
    ],
    translations: {
      kr: "\uB9D0\uB808\uC774\uC2DC\uC544",
      "pt-BR": "Mal\xE1sia",
      pt: "Mal\xE1sia",
      nl: "Maleisi\xEB",
      hr: "Malezija",
      fa: "\u0645\u0627\u0644\u0632\u06CC",
      de: "Malaysia",
      es: "Malasia",
      fr: "Malaisie",
      ja: "\u30DE\u30EC\u30FC\u30B7\u30A2",
      it: "Malesia",
      cn: "\u9A6C\u6765\u897F\u4E9A",
      tr: "Malezya"
    },
    latitude: "2.50000000",
    longitude: "112.50000000",
    emoji: "\u{1F1F2}\u{1F1FE}",
    emojiU: "U+1F1F2 U+1F1FE"
  },
  {
    id: 133,
    name: "Maldives",
    iso3: "MDV",
    iso2: "MV",
    numeric_code: "462",
    phone_code: "960",
    capital: "Male",
    currency: "MVR",
    currency_name: "Maldivian rufiyaa",
    currency_symbol: "Rf",
    tld: ".mv",
    native: "Maldives",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Maldivian",
    timezones: [
      {
        zoneName: "Indian/Maldives",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "MVT",
        tzName: "Maldives Time"
      }
    ],
    translations: {
      kr: "\uBAB0\uB514\uBE0C",
      "pt-BR": "Maldivas",
      pt: "Maldivas",
      nl: "Maldiven",
      hr: "Maldivi",
      fa: "\u0645\u0627\u0644\u062F\u06CC\u0648",
      de: "Malediven",
      es: "Maldivas",
      fr: "Maldives",
      ja: "\u30E2\u30EB\u30C7\u30A3\u30D6",
      it: "Maldive",
      cn: "\u9A6C\u5C14\u4EE3\u592B",
      tr: "Maldivler"
    },
    latitude: "3.25000000",
    longitude: "73.00000000",
    emoji: "\u{1F1F2}\u{1F1FB}",
    emojiU: "U+1F1F2 U+1F1FB"
  },
  {
    id: 134,
    name: "Mali",
    iso3: "MLI",
    iso2: "ML",
    numeric_code: "466",
    phone_code: "223",
    capital: "Bamako",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".ml",
    native: "Mali",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Malian, Malinese",
    timezones: [
      {
        zoneName: "Africa/Bamako",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uB9D0\uB9AC",
      "pt-BR": "Mali",
      pt: "Mali",
      nl: "Mali",
      hr: "Mali",
      fa: "\u0645\u0627\u0644\u06CC",
      de: "Mali",
      es: "Mali",
      fr: "Mali",
      ja: "\u30DE\u30EA",
      it: "Mali",
      cn: "\u9A6C\u91CC",
      tr: "Mali"
    },
    latitude: "17.00000000",
    longitude: "-4.00000000",
    emoji: "\u{1F1F2}\u{1F1F1}",
    emojiU: "U+1F1F2 U+1F1F1"
  },
  {
    id: 135,
    name: "Malta",
    iso3: "MLT",
    iso2: "MT",
    numeric_code: "470",
    phone_code: "356",
    capital: "Valletta",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".mt",
    native: "Malta",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Maltese",
    timezones: [
      {
        zoneName: "Europe/Malta",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uBAB0\uD0C0",
      "pt-BR": "Malta",
      pt: "Malta",
      nl: "Malta",
      hr: "Malta",
      fa: "\u0645\u0627\u0644\u062A",
      de: "Malta",
      es: "Malta",
      fr: "Malte",
      ja: "\u30DE\u30EB\u30BF",
      it: "Malta",
      cn: "\u9A6C\u8033\u4ED6",
      tr: "Malta"
    },
    latitude: "35.83333333",
    longitude: "14.58333333",
    emoji: "\u{1F1F2}\u{1F1F9}",
    emojiU: "U+1F1F2 U+1F1F9"
  },
  {
    id: 136,
    name: "Man (Isle of)",
    iso3: "IMN",
    iso2: "IM",
    numeric_code: "833",
    phone_code: "44",
    capital: "Douglas, Isle of Man",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "\xA3",
    tld: ".im",
    native: "Isle of Man",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Manx",
    timezones: [
      {
        zoneName: "Europe/Isle_of_Man",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uB9E8 \uC12C",
      "pt-BR": "Ilha de Man",
      pt: "Ilha de Man",
      nl: "Isle of Man",
      hr: "Otok Man",
      fa: "\u062C\u0632\u06CC\u0631\u0647 \u0645\u0646",
      de: "Insel Man",
      es: "Isla de Man",
      fr: "\xCEle de Man",
      ja: "\u30DE\u30F3\u5CF6",
      it: "Isola di Man",
      cn: "\u9A6C\u6069\u5C9B",
      tr: "Man Adasi"
    },
    latitude: "54.25000000",
    longitude: "-4.50000000",
    emoji: "\u{1F1EE}\u{1F1F2}",
    emojiU: "U+1F1EE U+1F1F2"
  },
  {
    id: 137,
    name: "Marshall Islands",
    iso3: "MHL",
    iso2: "MH",
    numeric_code: "584",
    phone_code: "692",
    capital: "Majuro",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".mh",
    native: "M\u0327aje\u013C",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Marshallese",
    timezones: [
      {
        zoneName: "Pacific/Kwajalein",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "MHT",
        tzName: "Marshall Islands Time"
      },
      {
        zoneName: "Pacific/Majuro",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "MHT",
        tzName: "Marshall Islands Time"
      }
    ],
    translations: {
      kr: "\uB9C8\uC15C \uC81C\uB3C4",
      "pt-BR": "Ilhas Marshall",
      pt: "Ilhas Marshall",
      nl: "Marshalleilanden",
      hr: "Mar\u0161alovi Otoci",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u0645\u0627\u0631\u0634\u0627\u0644",
      de: "Marshallinseln",
      es: "Islas Marshall",
      fr: "\xCEles Marshall",
      ja: "\u30DE\u30FC\u30B7\u30E3\u30EB\u8AF8\u5CF6",
      it: "Isole Marshall",
      cn: "\u9A6C\u7ECD\u5C14\u7FA4\u5C9B",
      tr: "Mar\u015Fal Adalari"
    },
    latitude: "9.00000000",
    longitude: "168.00000000",
    emoji: "\u{1F1F2}\u{1F1ED}",
    emojiU: "U+1F1F2 U+1F1ED"
  },
  {
    id: 138,
    name: "Martinique",
    iso3: "MTQ",
    iso2: "MQ",
    numeric_code: "474",
    phone_code: "596",
    capital: "Fort-de-France",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".mq",
    native: "Martinique",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Martiniquais, Martinican",
    timezones: [
      {
        zoneName: "America/Martinique",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uB9C8\uB974\uD2F0\uB2C8\uD06C",
      "pt-BR": "Martinica",
      pt: "Martinica",
      nl: "Martinique",
      hr: "Martinique",
      fa: "\u0645\u0648\u0646\u062A\u0633\u0631\u0627\u062A",
      de: "Martinique",
      es: "Martinica",
      fr: "Martinique",
      ja: "\u30DE\u30EB\u30C6\u30A3\u30CB\u30FC\u30AF",
      it: "Martinica",
      cn: "\u9A6C\u63D0\u5C3C\u514B\u5C9B",
      tr: "Martinik"
    },
    latitude: "14.66666700",
    longitude: "-61.00000000",
    emoji: "\u{1F1F2}\u{1F1F6}",
    emojiU: "U+1F1F2 U+1F1F6"
  },
  {
    id: 139,
    name: "Mauritania",
    iso3: "MRT",
    iso2: "MR",
    numeric_code: "478",
    phone_code: "222",
    capital: "Nouakchott",
    currency: "MRO",
    currency_name: "Mauritanian ouguiya",
    currency_symbol: "MRU",
    tld: ".mr",
    native: "\u0645\u0648\u0631\u064A\u062A\u0627\u0646\u064A\u0627",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Mauritanian",
    timezones: [
      {
        zoneName: "Africa/Nouakchott",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uBAA8\uB9AC\uD0C0\uB2C8",
      "pt-BR": "Maurit\xE2nia",
      pt: "Maurit\xE2nia",
      nl: "Mauritani\xEB",
      hr: "Mauritanija",
      fa: "\u0645\u0648\u0631\u06CC\u062A\u0627\u0646\u06CC",
      de: "Mauretanien",
      es: "Mauritania",
      fr: "Mauritanie",
      ja: "\u30E2\u30FC\u30EA\u30BF\u30CB\u30A2",
      it: "Mauritania",
      cn: "\u6BDB\u91CC\u5854\u5C3C\u4E9A",
      tr: "Moritanya"
    },
    latitude: "20.00000000",
    longitude: "-12.00000000",
    emoji: "\u{1F1F2}\u{1F1F7}",
    emojiU: "U+1F1F2 U+1F1F7"
  },
  {
    id: 140,
    name: "Mauritius",
    iso3: "MUS",
    iso2: "MU",
    numeric_code: "480",
    phone_code: "230",
    capital: "Port Louis",
    currency: "MUR",
    currency_name: "Mauritian rupee",
    currency_symbol: "\u20A8",
    tld: ".mu",
    native: "Maurice",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Mauritian",
    timezones: [
      {
        zoneName: "Indian/Mauritius",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "MUT",
        tzName: "Mauritius Time"
      }
    ],
    translations: {
      kr: "\uBAA8\uB9AC\uC154\uC2A4",
      "pt-BR": "Maur\xEDcio",
      pt: "Maur\xEDcia",
      nl: "Mauritius",
      hr: "Mauricijus",
      fa: "\u0645\u0648\u0631\u06CC\u0633",
      de: "Mauritius",
      es: "Mauricio",
      fr: "\xCEle Maurice",
      ja: "\u30E2\u30FC\u30EA\u30B7\u30E3\u30B9",
      it: "Mauritius",
      cn: "\u6BDB\u91CC\u6C42\u65AF",
      tr: "Morityus"
    },
    latitude: "-20.28333333",
    longitude: "57.55000000",
    emoji: "\u{1F1F2}\u{1F1FA}",
    emojiU: "U+1F1F2 U+1F1FA"
  },
  {
    id: 141,
    name: "Mayotte",
    iso3: "MYT",
    iso2: "YT",
    numeric_code: "175",
    phone_code: "262",
    capital: "Mamoudzou",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".yt",
    native: "Mayotte",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Mahoran",
    timezones: [
      {
        zoneName: "Indian/Mayotte",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uB9C8\uC694\uD2B8",
      "pt-BR": "Mayotte",
      pt: "Mayotte",
      nl: "Mayotte",
      hr: "Mayotte",
      fa: "\u0645\u0627\u06CC\u0648\u062A",
      de: "Mayotte",
      es: "Mayotte",
      fr: "Mayotte",
      ja: "\u30DE\u30E8\u30C3\u30C8",
      it: "Mayotte",
      cn: "\u9A6C\u7EA6\u7279",
      tr: "Mayotte"
    },
    latitude: "-12.83333333",
    longitude: "45.16666666",
    emoji: "\u{1F1FE}\u{1F1F9}",
    emojiU: "U+1F1FE U+1F1F9"
  },
  {
    id: 142,
    name: "Mexico",
    iso3: "MEX",
    iso2: "MX",
    numeric_code: "484",
    phone_code: "52",
    capital: "Ciudad de M\xE9xico",
    currency: "MXN",
    currency_name: "Mexican peso",
    currency_symbol: "$",
    tld: ".mx",
    native: "M\xE9xico",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Mexican",
    timezones: [
      {
        zoneName: "America/Bahia_Banderas",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Cancun",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Chihuahua",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      },
      {
        zoneName: "America/Hermosillo",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      },
      {
        zoneName: "America/Matamoros",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Mazatlan",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      },
      {
        zoneName: "America/Merida",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Mexico_City",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Monterrey",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Ojinaga",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      },
      {
        zoneName: "America/Tijuana",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uBA55\uC2DC\uCF54",
      "pt-BR": "M\xE9xico",
      pt: "M\xE9xico",
      nl: "Mexico",
      hr: "Meksiko",
      fa: "\u0645\u06A9\u0632\u06CC\u06A9",
      de: "Mexiko",
      es: "M\xE9xico",
      fr: "Mexique",
      ja: "\u30E1\u30AD\u30B7\u30B3",
      it: "Messico",
      cn: "\u58A8\u897F\u54E5",
      tr: "Meksika"
    },
    latitude: "23.00000000",
    longitude: "-102.00000000",
    emoji: "\u{1F1F2}\u{1F1FD}",
    emojiU: "U+1F1F2 U+1F1FD"
  },
  {
    id: 143,
    name: "Micronesia",
    iso3: "FSM",
    iso2: "FM",
    numeric_code: "583",
    phone_code: "691",
    capital: "Palikir",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".fm",
    native: "Micronesia",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Micronesian",
    timezones: [
      {
        zoneName: "Pacific/Chuuk",
        gmtOffset: 36e3,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "CHUT",
        tzName: "Chuuk Time"
      },
      {
        zoneName: "Pacific/Kosrae",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "KOST",
        tzName: "Kosrae Time"
      },
      {
        zoneName: "Pacific/Pohnpei",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "PONT",
        tzName: "Pohnpei Standard Time"
      }
    ],
    translations: {
      kr: "\uBBF8\uD06C\uB85C\uB124\uC2DC\uC544 \uC5F0\uBC29",
      "pt-BR": "Micron\xE9sia",
      pt: "Micron\xE9sia",
      nl: "Micronesi\xEB",
      hr: "Mikronezija",
      fa: "\u0627\u06CC\u0627\u0644\u0627\u062A \u0641\u062F\u0631\u0627\u0644 \u0645\u06CC\u06A9\u0631\u0648\u0646\u0632\u06CC",
      de: "Mikronesien",
      es: "Micronesia",
      fr: "Micron\xE9sie",
      ja: "\u30DF\u30AF\u30ED\u30CD\u30B7\u30A2\u9023\u90A6",
      it: "Micronesia",
      cn: "\u5BC6\u514B\u7F57\u5C3C\u897F\u4E9A",
      tr: "Mikronezya"
    },
    latitude: "6.91666666",
    longitude: "158.25000000",
    emoji: "\u{1F1EB}\u{1F1F2}",
    emojiU: "U+1F1EB U+1F1F2"
  },
  {
    id: 144,
    name: "Moldova",
    iso3: "MDA",
    iso2: "MD",
    numeric_code: "498",
    phone_code: "373",
    capital: "Chisinau",
    currency: "MDL",
    currency_name: "Moldovan leu",
    currency_symbol: "L",
    tld: ".md",
    native: "Moldova",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Moldovan",
    timezones: [
      {
        zoneName: "Europe/Chisinau",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uBAB0\uB3C4\uBC14",
      "pt-BR": "Mold\xE1via",
      pt: "Mold\xE1via",
      nl: "Moldavi\xEB",
      hr: "Moldova",
      fa: "\u0645\u0648\u0644\u062F\u0627\u0648\u06CC",
      de: "Moldawie",
      es: "Moldavia",
      fr: "Moldavie",
      ja: "\u30E2\u30EB\u30C9\u30D0\u5171\u548C\u56FD",
      it: "Moldavia",
      cn: "\u6469\u5C14\u591A\u74E6",
      tr: "Moldova"
    },
    latitude: "47.00000000",
    longitude: "29.00000000",
    emoji: "\u{1F1F2}\u{1F1E9}",
    emojiU: "U+1F1F2 U+1F1E9"
  },
  {
    id: 145,
    name: "Monaco",
    iso3: "MCO",
    iso2: "MC",
    numeric_code: "492",
    phone_code: "377",
    capital: "Monaco",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".mc",
    native: "Monaco",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Monegasque, Monacan",
    timezones: [
      {
        zoneName: "Europe/Monaco",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uBAA8\uB098\uCF54",
      "pt-BR": "M\xF4naco",
      pt: "M\xF3naco",
      nl: "Monaco",
      hr: "Monako",
      fa: "\u0645\u0648\u0646\u0627\u06A9\u0648",
      de: "Monaco",
      es: "M\xF3naco",
      fr: "Monaco",
      ja: "\u30E2\u30CA\u30B3",
      it: "Principato di Monaco",
      cn: "\u6469\u7EB3\u54E5",
      tr: "Monako"
    },
    latitude: "43.73333333",
    longitude: "7.40000000",
    emoji: "\u{1F1F2}\u{1F1E8}",
    emojiU: "U+1F1F2 U+1F1E8"
  },
  {
    id: 146,
    name: "Mongolia",
    iso3: "MNG",
    iso2: "MN",
    numeric_code: "496",
    phone_code: "976",
    capital: "Ulan Bator",
    currency: "MNT",
    currency_name: "Mongolian t\xF6gr\xF6g",
    currency_symbol: "\u20AE",
    tld: ".mn",
    native: "\u041C\u043E\u043D\u0433\u043E\u043B \u0443\u043B\u0441",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Mongolian",
    timezones: [
      {
        zoneName: "Asia/Choibalsan",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CHOT",
        tzName: "Choibalsan Standard Time"
      },
      {
        zoneName: "Asia/Hovd",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "HOVT",
        tzName: "Hovd Time"
      },
      {
        zoneName: "Asia/Ulaanbaatar",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "ULAT",
        tzName: "Ulaanbaatar Standard Time"
      }
    ],
    translations: {
      kr: "\uBABD\uACE8",
      "pt-BR": "Mong\xF3lia",
      pt: "Mong\xF3lia",
      nl: "Mongoli\xEB",
      hr: "Mongolija",
      fa: "\u0645\u063A\u0648\u0644\u0633\u062A\u0627\u0646",
      de: "Mongolei",
      es: "Mongolia",
      fr: "Mongolie",
      ja: "\u30E2\u30F3\u30B4\u30EB",
      it: "Mongolia",
      cn: "\u8499\u53E4",
      tr: "Mo\u011Folistan"
    },
    latitude: "46.00000000",
    longitude: "105.00000000",
    emoji: "\u{1F1F2}\u{1F1F3}",
    emojiU: "U+1F1F2 U+1F1F3"
  },
  {
    id: 147,
    name: "Montenegro",
    iso3: "MNE",
    iso2: "ME",
    numeric_code: "499",
    phone_code: "382",
    capital: "Podgorica",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".me",
    native: "\u0426\u0440\u043D\u0430 \u0413\u043E\u0440\u0430",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Montenegrin",
    timezones: [
      {
        zoneName: "Europe/Podgorica",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uBAAC\uD14C\uB124\uADF8\uB85C",
      "pt-BR": "Montenegro",
      pt: "Montenegro",
      nl: "Montenegro",
      hr: "Crna Gora",
      fa: "\u0645\u0648\u0646\u062A\u0647\u200C\u0646\u06AF\u0631\u0648",
      de: "Montenegro",
      es: "Montenegro",
      fr: "Mont\xE9n\xE9gro",
      ja: "\u30E2\u30F3\u30C6\u30CD\u30B0\u30ED",
      it: "Montenegro",
      cn: "\u9ED1\u5C71",
      tr: "Karada\u011F"
    },
    latitude: "42.50000000",
    longitude: "19.30000000",
    emoji: "\u{1F1F2}\u{1F1EA}",
    emojiU: "U+1F1F2 U+1F1EA"
  },
  {
    id: 148,
    name: "Montserrat",
    iso3: "MSR",
    iso2: "MS",
    numeric_code: "500",
    phone_code: "1",
    capital: "Plymouth",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".ms",
    native: "Montserrat",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Montserratian",
    timezones: [
      {
        zoneName: "America/Montserrat",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uBAAC\uD2B8\uC138\uB7AB",
      "pt-BR": "Montserrat",
      pt: "Monserrate",
      nl: "Montserrat",
      hr: "Montserrat",
      fa: "\u0645\u0627\u06CC\u0648\u062A",
      de: "Montserrat",
      es: "Montserrat",
      fr: "Montserrat",
      ja: "\u30E2\u30F3\u30C8\u30BB\u30E9\u30C8",
      it: "Montserrat",
      cn: "\u8499\u7279\u585E\u62C9\u7279",
      tr: "Montserrat"
    },
    latitude: "16.75000000",
    longitude: "-62.20000000",
    emoji: "\u{1F1F2}\u{1F1F8}",
    emojiU: "U+1F1F2 U+1F1F8"
  },
  {
    id: 149,
    name: "Morocco",
    iso3: "MAR",
    iso2: "MA",
    numeric_code: "504",
    phone_code: "212",
    capital: "Rabat",
    currency: "MAD",
    currency_name: "Moroccan dirham",
    currency_symbol: "DH",
    tld: ".ma",
    native: "\u0627\u0644\u0645\u063A\u0631\u0628",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Moroccan",
    timezones: [
      {
        zoneName: "Africa/Casablanca",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WEST",
        tzName: "Western European Summer Time"
      }
    ],
    translations: {
      kr: "\uBAA8\uB85C\uCF54",
      "pt-BR": "Marrocos",
      pt: "Marrocos",
      nl: "Marokko",
      hr: "Maroko",
      fa: "\u0645\u0631\u0627\u06A9\u0634",
      de: "Marokko",
      es: "Marruecos",
      fr: "Maroc",
      ja: "\u30E2\u30ED\u30C3\u30B3",
      it: "Marocco",
      cn: "\u6469\u6D1B\u54E5",
      tr: "Fas"
    },
    latitude: "32.00000000",
    longitude: "-5.00000000",
    emoji: "\u{1F1F2}\u{1F1E6}",
    emojiU: "U+1F1F2 U+1F1E6"
  },
  {
    id: 150,
    name: "Mozambique",
    iso3: "MOZ",
    iso2: "MZ",
    numeric_code: "508",
    phone_code: "258",
    capital: "Maputo",
    currency: "MZN",
    currency_name: "Mozambican metical",
    currency_symbol: "MT",
    tld: ".mz",
    native: "Mo\xE7ambique",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Mozambican",
    timezones: [
      {
        zoneName: "Africa/Maputo",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time"
      }
    ],
    translations: {
      kr: "\uBAA8\uC7A0\uBE44\uD06C",
      "pt-BR": "Mo\xE7ambique",
      pt: "Mo\xE7ambique",
      nl: "Mozambique",
      hr: "Mozambik",
      fa: "\u0645\u0648\u0632\u0627\u0645\u0628\u06CC\u06A9",
      de: "Mosambik",
      es: "Mozambique",
      fr: "Mozambique",
      ja: "\u30E2\u30B6\u30F3\u30D3\u30FC\u30AF",
      it: "Mozambico",
      cn: "\u83AB\u6851\u6BD4\u514B",
      tr: "Mozambik"
    },
    latitude: "-18.25000000",
    longitude: "35.00000000",
    emoji: "\u{1F1F2}\u{1F1FF}",
    emojiU: "U+1F1F2 U+1F1FF"
  },
  {
    id: 151,
    name: "Myanmar",
    iso3: "MMR",
    iso2: "MM",
    numeric_code: "104",
    phone_code: "95",
    capital: "Nay Pyi Taw",
    currency: "MMK",
    currency_name: "Burmese kyat",
    currency_symbol: "K",
    tld: ".mm",
    native: "\u1019\u103C\u1014\u103A\u1019\u102C",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Burmese",
    timezones: [
      {
        zoneName: "Asia/Yangon",
        gmtOffset: 23400,
        gmtOffsetName: "UTC+06:30",
        abbreviation: "MMT",
        tzName: "Myanmar Standard Time"
      }
    ],
    translations: {
      kr: "\uBBF8\uC580\uB9C8",
      "pt-BR": "Myanmar",
      pt: "Myanmar",
      nl: "Myanmar",
      hr: "Mijanmar",
      fa: "\u0645\u06CC\u0627\u0646\u0645\u0627\u0631",
      de: "Myanmar",
      es: "Myanmar",
      fr: "Myanmar",
      ja: "\u30DF\u30E3\u30F3\u30DE\u30FC",
      it: "Birmania",
      cn: "\u7F05\u7538",
      tr: "Myanmar"
    },
    latitude: "22.00000000",
    longitude: "98.00000000",
    emoji: "\u{1F1F2}\u{1F1F2}",
    emojiU: "U+1F1F2 U+1F1F2"
  },
  {
    id: 152,
    name: "Namibia",
    iso3: "NAM",
    iso2: "NA",
    numeric_code: "516",
    phone_code: "264",
    capital: "Windhoek",
    currency: "NAD",
    currency_name: "Namibian dollar",
    currency_symbol: "$",
    tld: ".na",
    native: "Namibia",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "Namibian",
    timezones: [
      {
        zoneName: "Africa/Windhoek",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "WAST",
        tzName: "West Africa Summer Time"
      }
    ],
    translations: {
      kr: "\uB098\uBBF8\uBE44\uC544",
      "pt-BR": "Nam\xEDbia",
      pt: "Nam\xEDbia",
      nl: "Namibi\xEB",
      hr: "Namibija",
      fa: "\u0646\u0627\u0645\u06CC\u0628\u06CC\u0627",
      de: "Namibia",
      es: "Namibia",
      fr: "Namibie",
      ja: "\u30CA\u30DF\u30D3\u30A2",
      it: "Namibia",
      cn: "\u7EB3\u7C73\u6BD4\u4E9A",
      tr: "Namibya"
    },
    latitude: "-22.00000000",
    longitude: "17.00000000",
    emoji: "\u{1F1F3}\u{1F1E6}",
    emojiU: "U+1F1F3 U+1F1E6"
  },
  {
    id: 153,
    name: "Nauru",
    iso3: "NRU",
    iso2: "NR",
    numeric_code: "520",
    phone_code: "674",
    capital: "Yaren",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".nr",
    native: "Nauru",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Nauruan",
    timezones: [
      {
        zoneName: "Pacific/Nauru",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "NRT",
        tzName: "Nauru Time"
      }
    ],
    translations: {
      kr: "\uB098\uC6B0\uB8E8",
      "pt-BR": "Nauru",
      pt: "Nauru",
      nl: "Nauru",
      hr: "Nauru",
      fa: "\u0646\u0627\u0626\u0648\u0631\u0648",
      de: "Nauru",
      es: "Nauru",
      fr: "Nauru",
      ja: "\u30CA\u30A6\u30EB",
      it: "Nauru",
      cn: "\u7459\u9C81",
      tr: "Nauru"
    },
    latitude: "-0.53333333",
    longitude: "166.91666666",
    emoji: "\u{1F1F3}\u{1F1F7}",
    emojiU: "U+1F1F3 U+1F1F7"
  },
  {
    id: 154,
    name: "Nepal",
    iso3: "NPL",
    iso2: "NP",
    numeric_code: "524",
    phone_code: "977",
    capital: "Kathmandu",
    currency: "NPR",
    currency_name: "Nepalese rupee",
    currency_symbol: "\u20A8",
    tld: ".np",
    native: "\u0928\u092A\u0932",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Nepali, Nepalese",
    timezones: [
      {
        zoneName: "Asia/Kathmandu",
        gmtOffset: 20700,
        gmtOffsetName: "UTC+05:45",
        abbreviation: "NPT",
        tzName: "Nepal Time"
      }
    ],
    translations: {
      kr: "\uB124\uD314",
      "pt-BR": "Nepal",
      pt: "Nepal",
      nl: "Nepal",
      hr: "Nepal",
      fa: "\u0646\u067E\u0627\u0644",
      de: "N\xE9pal",
      es: "Nepal",
      fr: "N\xE9pal",
      ja: "\u30CD\u30D1\u30FC\u30EB",
      it: "Nepal",
      cn: "\u5C3C\u6CCA\u5C14",
      tr: "Nepal"
    },
    latitude: "28.00000000",
    longitude: "84.00000000",
    emoji: "\u{1F1F3}\u{1F1F5}",
    emojiU: "U+1F1F3 U+1F1F5"
  },
  {
    id: 156,
    name: "Netherlands",
    iso3: "NLD",
    iso2: "NL",
    numeric_code: "528",
    phone_code: "31",
    capital: "Amsterdam",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".nl",
    native: "Nederland",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Dutch, Netherlandic",
    timezones: [
      {
        zoneName: "Europe/Amsterdam",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uB124\uB35C\uB780\uB4DC",
      "pt-BR": "Holanda",
      pt: "Pa\xEDses Baixos",
      nl: "Nederland",
      hr: "Nizozemska",
      fa: "\u067E\u0627\u062F\u0634\u0627\u0647\u06CC \u0647\u0644\u0646\u062F",
      de: "Niederlande",
      es: "Pa\xEDses Bajos",
      fr: "Pays-Bas",
      ja: "\u30AA\u30E9\u30F3\u30C0",
      it: "Paesi Bassi",
      cn: "\u8377\u5170",
      tr: "Hollanda"
    },
    latitude: "52.50000000",
    longitude: "5.75000000",
    emoji: "\u{1F1F3}\u{1F1F1}",
    emojiU: "U+1F1F3 U+1F1F1"
  },
  {
    id: 157,
    name: "New Caledonia",
    iso3: "NCL",
    iso2: "NC",
    numeric_code: "540",
    phone_code: "687",
    capital: "Noumea",
    currency: "XPF",
    currency_name: "CFP franc",
    currency_symbol: "\u20A3",
    tld: ".nc",
    native: "Nouvelle-Cal\xE9donie",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "New Caledonian",
    timezones: [
      {
        zoneName: "Pacific/Noumea",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "NCT",
        tzName: "New Caledonia Time"
      }
    ],
    translations: {
      kr: "\uB204\uBCA8\uCE7C\uB808\uB3C4\uB2C8",
      "pt-BR": "Nova Caled\xF4nia",
      pt: "Nova Caled\xF3nia",
      nl: "Nieuw-Caledoni\xEB",
      hr: "Nova Kaledonija",
      fa: "\u06A9\u0627\u0644\u062F\u0648\u0646\u06CC\u0627\u06CC \u062C\u062F\u06CC\u062F",
      de: "Neukaledonien",
      es: "Nueva Caledonia",
      fr: "Nouvelle-Cal\xE9donie",
      ja: "\u30CB\u30E5\u30FC\u30AB\u30EC\u30C9\u30CB\u30A2",
      it: "Nuova Caledonia",
      cn: "\u65B0\u5580\u91CC\u591A\u5C3C\u4E9A",
      tr: "Yeni Kaledonya"
    },
    latitude: "-21.50000000",
    longitude: "165.50000000",
    emoji: "\u{1F1F3}\u{1F1E8}",
    emojiU: "U+1F1F3 U+1F1E8"
  },
  {
    id: 158,
    name: "New Zealand",
    iso3: "NZL",
    iso2: "NZ",
    numeric_code: "554",
    phone_code: "64",
    capital: "Wellington",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".nz",
    native: "New Zealand",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "New Zealand, NZ",
    timezones: [
      {
        zoneName: "Pacific/Auckland",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "NZDT",
        tzName: "New Zealand Daylight Time"
      },
      {
        zoneName: "Pacific/Chatham",
        gmtOffset: 49500,
        gmtOffsetName: "UTC+13:45",
        abbreviation: "CHAST",
        tzName: "Chatham Standard Time"
      }
    ],
    translations: {
      kr: "\uB274\uC9C8\uB79C\uB4DC",
      "pt-BR": "Nova Zel\xE2ndia",
      pt: "Nova Zel\xE2ndia",
      nl: "Nieuw-Zeeland",
      hr: "Novi Zeland",
      fa: "\u0646\u06CC\u0648\u0632\u06CC\u0644\u0646\u062F",
      de: "Neuseeland",
      es: "Nueva Zelanda",
      fr: "Nouvelle-Z\xE9lande",
      ja: "\u30CB\u30E5\u30FC\u30B8\u30FC\u30E9\u30F3\u30C9",
      it: "Nuova Zelanda",
      cn: "\u65B0\u897F\u5170",
      tr: "Yeni Zelanda"
    },
    latitude: "-41.00000000",
    longitude: "174.00000000",
    emoji: "\u{1F1F3}\u{1F1FF}",
    emojiU: "U+1F1F3 U+1F1FF"
  },
  {
    id: 159,
    name: "Nicaragua",
    iso3: "NIC",
    iso2: "NI",
    numeric_code: "558",
    phone_code: "505",
    capital: "Managua",
    currency: "NIO",
    currency_name: "Nicaraguan c\xF3rdoba",
    currency_symbol: "C$",
    tld: ".ni",
    native: "Nicaragua",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Nicaraguan",
    timezones: [
      {
        zoneName: "America/Managua",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uB2C8\uCE74\uB77C\uACFC",
      "pt-BR": "Nicar\xE1gua",
      pt: "Nicar\xE1gua",
      nl: "Nicaragua",
      hr: "Nikaragva",
      fa: "\u0646\u06CC\u06A9\u0627\u0631\u0627\u06AF\u0648\u0626\u0647",
      de: "Nicaragua",
      es: "Nicaragua",
      fr: "Nicaragua",
      ja: "\u30CB\u30AB\u30E9\u30B0\u30A2",
      it: "Nicaragua",
      cn: "\u5C3C\u52A0\u62C9\u74DC",
      tr: "Nikaragua"
    },
    latitude: "13.00000000",
    longitude: "-85.00000000",
    emoji: "\u{1F1F3}\u{1F1EE}",
    emojiU: "U+1F1F3 U+1F1EE"
  },
  {
    id: 160,
    name: "Niger",
    iso3: "NER",
    iso2: "NE",
    numeric_code: "562",
    phone_code: "227",
    capital: "Niamey",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".ne",
    native: "Niger",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Nigerien",
    timezones: [
      {
        zoneName: "Africa/Niamey",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uB2C8\uC81C\uB974",
      "pt-BR": "N\xEDger",
      pt: "N\xEDger",
      nl: "Niger",
      hr: "Niger",
      fa: "\u0646\u06CC\u062C\u0631",
      de: "Niger",
      es: "N\xEDger",
      fr: "Niger",
      ja: "\u30CB\u30B8\u30A7\u30FC\u30EB",
      it: "Niger",
      cn: "\u5C3C\u65E5\u5C14",
      tr: "Nijer"
    },
    latitude: "16.00000000",
    longitude: "8.00000000",
    emoji: "\u{1F1F3}\u{1F1EA}",
    emojiU: "U+1F1F3 U+1F1EA"
  },
  {
    id: 161,
    name: "Nigeria",
    iso3: "NGA",
    iso2: "NG",
    numeric_code: "566",
    phone_code: "234",
    capital: "Abuja",
    currency: "NGN",
    currency_name: "Nigerian naira",
    currency_symbol: "\u20A6",
    tld: ".ng",
    native: "Nigeria",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Nigerian",
    timezones: [
      {
        zoneName: "Africa/Lagos",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time"
      }
    ],
    translations: {
      kr: "\uB098\uC774\uC9C0\uB9AC\uC544",
      "pt-BR": "Nig\xE9ria",
      pt: "Nig\xE9ria",
      nl: "Nigeria",
      hr: "Nigerija",
      fa: "\u0646\u06CC\u062C\u0631\u06CC\u0647",
      de: "Nigeria",
      es: "Nigeria",
      fr: "Nig\xE9ria",
      ja: "\u30CA\u30A4\u30B8\u30A7\u30EA\u30A2",
      it: "Nigeria",
      cn: "\u5C3C\u65E5\u5229\u4E9A",
      tr: "Nijerya"
    },
    latitude: "10.00000000",
    longitude: "8.00000000",
    emoji: "\u{1F1F3}\u{1F1EC}",
    emojiU: "U+1F1F3 U+1F1EC"
  },
  {
    id: 162,
    name: "Niue",
    iso3: "NIU",
    iso2: "NU",
    numeric_code: "570",
    phone_code: "683",
    capital: "Alofi",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".nu",
    native: "Niu\u0113",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Niuean",
    timezones: [
      {
        zoneName: "Pacific/Niue",
        gmtOffset: -39600,
        gmtOffsetName: "UTC-11:00",
        abbreviation: "NUT",
        tzName: "Niue Time"
      }
    ],
    translations: {
      kr: "\uB2C8\uC6B0\uC5D0",
      "pt-BR": "Niue",
      pt: "Niue",
      nl: "Niue",
      hr: "Niue",
      fa: "\u0646\u06CC\u0648\u0648\u06CC",
      de: "Niue",
      es: "Niue",
      fr: "Niue",
      ja: "\u30CB\u30A6\u30A8",
      it: "Niue",
      cn: "\u7EBD\u57C3",
      tr: "Niue"
    },
    latitude: "-19.03333333",
    longitude: "-169.86666666",
    emoji: "\u{1F1F3}\u{1F1FA}",
    emojiU: "U+1F1F3 U+1F1FA"
  },
  {
    id: 163,
    name: "Norfolk Island",
    iso3: "NFK",
    iso2: "NF",
    numeric_code: "574",
    phone_code: "672",
    capital: "Kingston",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".nf",
    native: "Norfolk Island",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "Norfolk Island",
    timezones: [
      {
        zoneName: "Pacific/Norfolk",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "NFT",
        tzName: "Norfolk Time"
      }
    ],
    translations: {
      kr: "\uB178\uD37D \uC12C",
      "pt-BR": "Ilha Norfolk",
      pt: "Ilha Norfolk",
      nl: "Norfolkeiland",
      hr: "Otok Norfolk",
      fa: "\u062C\u0632\u06CC\u0631\u0647 \u0646\u0648\u0631\u0641\u06A9",
      de: "Norfolkinsel",
      es: "Isla de Norfolk",
      fr: "\xCEle de Norfolk",
      ja: "\u30CE\u30FC\u30D5\u30A9\u30FC\u30AF\u5CF6",
      it: "Isola Norfolk",
      cn: "\u8BFA\u798F\u514B\u5C9B",
      tr: "Norfolk Adasi"
    },
    latitude: "-29.03333333",
    longitude: "167.95000000",
    emoji: "\u{1F1F3}\u{1F1EB}",
    emojiU: "U+1F1F3 U+1F1EB"
  },
  {
    id: 115,
    name: "North Korea",
    iso3: "PRK",
    iso2: "KP",
    numeric_code: "408",
    phone_code: "850",
    capital: "Pyongyang",
    currency: "KPW",
    currency_name: "North Korean Won",
    currency_symbol: "\u20A9",
    tld: ".kp",
    native: "\uBD81\uD55C",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "North Korean",
    timezones: [
      {
        zoneName: "Asia/Pyongyang",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "KST",
        tzName: "Korea Standard Time"
      }
    ],
    translations: {
      kr: "\uC870\uC120\uBBFC\uC8FC\uC8FC\uC758\uC778\uBBFC\uACF5\uD654\uAD6D",
      "pt-BR": "Coreia do Norte",
      pt: "Coreia do Norte",
      nl: "Noord-Korea",
      hr: "Sjeverna Koreja",
      fa: "\u06A9\u0631\u0647 \u062C\u0646\u0648\u0628\u06CC",
      de: "Nordkorea",
      es: "Corea del Norte",
      fr: "Cor\xE9e du Nord",
      ja: "\u671D\u9BAE\u6C11\u4E3B\u4E3B\u7FA9\u4EBA\u6C11\u5171\u548C\u56FD",
      it: "Corea del Nord",
      cn: "\u671D\u9C9C",
      tr: "Kuzey Kore"
    },
    latitude: "40.00000000",
    longitude: "127.00000000",
    emoji: "\u{1F1F0}\u{1F1F5}",
    emojiU: "U+1F1F0 U+1F1F5"
  },
  {
    id: 129,
    name: "North Macedonia",
    iso3: "MKD",
    iso2: "MK",
    numeric_code: "807",
    phone_code: "389",
    capital: "Skopje",
    currency: "MKD",
    currency_name: "Denar",
    currency_symbol: "\u0434\u0435\u043D",
    tld: ".mk",
    native: "\u0421\u0435\u0432\u0435\u0440\u043D\u0430 \u041C\u0430\u043A\u0435\u0434\u043E\u043D\u0438\u0458\u0430",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Macedonian",
    timezones: [
      {
        zoneName: "Europe/Skopje",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uBD81\uB9C8\uCF00\uB3C4\uB2C8\uC544",
      "pt-BR": "Maced\xF4nia do Norte",
      pt: "Maced\xF3nia do Norte",
      nl: "Noord-Macedoni\xEB",
      hr: "Sjeverna Makedonija",
      fa: "\uFEE2\uFED7\uFEA9\uFEEE\uFEE8\uFBFF\uFEEB \uFEB6\uFEE3\uFE8E\uFEE0\uFBFE",
      de: "Nordmazedonien",
      es: "Macedonia del Norte",
      fr: "Mac\xE9doine du Nord",
      ja: "\u5317\u30DE\u30B1\u30C9\u30CB\u30A2",
      it: "Macedonia del Nord",
      cn: "\u5317\u99AC\u5176\u9813",
      tr: "Kuzey Makedonya"
    },
    latitude: "41.83333333",
    longitude: "22.00000000",
    emoji: "\u{1F1F2}\u{1F1F0}",
    emojiU: "U+1F1F2 U+1F1F0"
  },
  {
    id: 164,
    name: "Northern Mariana Islands",
    iso3: "MNP",
    iso2: "MP",
    numeric_code: "580",
    phone_code: "1",
    capital: "Saipan",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".mp",
    native: "Northern Mariana Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Northern Marianan",
    timezones: [
      {
        zoneName: "Pacific/Saipan",
        gmtOffset: 36e3,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "ChST",
        tzName: "Chamorro Standard Time"
      }
    ],
    translations: {
      kr: "\uBD81\uB9C8\uB9AC\uC544\uB098 \uC81C\uB3C4",
      "pt-BR": "Ilhas Marianas",
      pt: "Ilhas Marianas",
      nl: "Noordelijke Marianeneilanden",
      hr: "Sjevernomarijanski otoci",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u0645\u0627\u0631\u06CC\u0627\u0646\u0627\u06CC \u0634\u0645\u0627\u0644\u06CC",
      de: "N\xF6rdliche Marianen",
      es: "Islas Marianas del Norte",
      fr: "\xCEles Mariannes du Nord",
      ja: "\u5317\u30DE\u30EA\u30A2\u30CA\u8AF8\u5CF6",
      it: "Isole Marianne Settentrionali",
      cn: "\u5317\u9A6C\u91CC\u4E9A\u7EB3\u7FA4\u5C9B",
      tr: "Kuzey Mariana Adalari"
    },
    latitude: "15.20000000",
    longitude: "145.75000000",
    emoji: "\u{1F1F2}\u{1F1F5}",
    emojiU: "U+1F1F2 U+1F1F5"
  },
  {
    id: 165,
    name: "Norway",
    iso3: "NOR",
    iso2: "NO",
    numeric_code: "578",
    phone_code: "47",
    capital: "Oslo",
    currency: "NOK",
    currency_name: "Norwegian krone",
    currency_symbol: "kr",
    tld: ".no",
    native: "Norge",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Norwegian",
    timezones: [
      {
        zoneName: "Europe/Oslo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uB178\uB974\uC6E8\uC774",
      "pt-BR": "Noruega",
      pt: "Noruega",
      nl: "Noorwegen",
      hr: "Norve\u0161ka",
      fa: "\u0646\u0631\u0648\u0698",
      de: "Norwegen",
      es: "Noruega",
      fr: "Norv\xE8ge",
      ja: "\u30CE\u30EB\u30A6\u30A7\u30FC",
      it: "Norvegia",
      cn: "\u632A\u5A01",
      tr: "Norve\xE7"
    },
    latitude: "62.00000000",
    longitude: "10.00000000",
    emoji: "\u{1F1F3}\u{1F1F4}",
    emojiU: "U+1F1F3 U+1F1F4"
  },
  {
    id: 166,
    name: "Oman",
    iso3: "OMN",
    iso2: "OM",
    numeric_code: "512",
    phone_code: "968",
    capital: "Muscat",
    currency: "OMR",
    currency_name: "Omani rial",
    currency_symbol: ".\u0639.\u0631",
    tld: ".om",
    native: "\u0639\u0645\u0627\u0646",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Omani",
    timezones: [
      {
        zoneName: "Asia/Muscat",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "GST",
        tzName: "Gulf Standard Time"
      }
    ],
    translations: {
      kr: "\uC624\uB9CC",
      "pt-BR": "Om\xE3",
      pt: "Om\xE3",
      nl: "Oman",
      hr: "Oman",
      fa: "\u0639\u0645\u0627\u0646",
      de: "Oman",
      es: "Om\xE1n",
      fr: "Oman",
      ja: "\u30AA\u30DE\u30FC\u30F3",
      it: "oman",
      cn: "\u963F\u66FC",
      tr: "Umman"
    },
    latitude: "21.00000000",
    longitude: "57.00000000",
    emoji: "\u{1F1F4}\u{1F1F2}",
    emojiU: "U+1F1F4 U+1F1F2"
  },
  {
    id: 167,
    name: "Pakistan",
    iso3: "PAK",
    iso2: "PK",
    numeric_code: "586",
    phone_code: "92",
    capital: "Islamabad",
    currency: "PKR",
    currency_name: "Pakistani rupee",
    currency_symbol: "\u20A8",
    tld: ".pk",
    native: "Pakistan",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Pakistani",
    timezones: [
      {
        zoneName: "Asia/Karachi",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "PKT",
        tzName: "Pakistan Standard Time"
      }
    ],
    translations: {
      kr: "\uD30C\uD0A4\uC2A4\uD0C4",
      "pt-BR": "Paquist\xE3o",
      pt: "Paquist\xE3o",
      nl: "Pakistan",
      hr: "Pakistan",
      fa: "\u067E\u0627\u06A9\u0633\u062A\u0627\u0646",
      de: "Pakistan",
      es: "Pakist\xE1n",
      fr: "Pakistan",
      ja: "\u30D1\u30AD\u30B9\u30BF\u30F3",
      it: "Pakistan",
      cn: "\u5DF4\u57FA\u65AF\u5766",
      tr: "Pakistan"
    },
    latitude: "30.00000000",
    longitude: "70.00000000",
    emoji: "\u{1F1F5}\u{1F1F0}",
    emojiU: "U+1F1F5 U+1F1F0"
  },
  {
    id: 168,
    name: "Palau",
    iso3: "PLW",
    iso2: "PW",
    numeric_code: "585",
    phone_code: "680",
    capital: "Melekeok",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".pw",
    native: "Palau",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Palauan",
    timezones: [
      {
        zoneName: "Pacific/Palau",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "PWT",
        tzName: "Palau Time"
      }
    ],
    translations: {
      kr: "\uD314\uB77C\uC6B0",
      "pt-BR": "Palau",
      pt: "Palau",
      nl: "Palau",
      hr: "Palau",
      fa: "\u067E\u0627\u0644\u0627\u0626\u0648",
      de: "Palau",
      es: "Palau",
      fr: "Palaos",
      ja: "\u30D1\u30E9\u30AA",
      it: "Palau",
      cn: "\u5E15\u52B3",
      tr: "Palau"
    },
    latitude: "7.50000000",
    longitude: "134.50000000",
    emoji: "\u{1F1F5}\u{1F1FC}",
    emojiU: "U+1F1F5 U+1F1FC"
  },
  {
    id: 169,
    name: "Palestinian Territory Occupied",
    iso3: "PSE",
    iso2: "PS",
    numeric_code: "275",
    phone_code: "970",
    capital: "East Jerusalem",
    currency: "ILS",
    currency_name: "Israeli new shekel",
    currency_symbol: "\u20AA",
    tld: ".ps",
    native: "\u0641\u0644\u0633\u0637\u064A\u0646",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Palestinian",
    timezones: [
      {
        zoneName: "Asia/Gaza",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      },
      {
        zoneName: "Asia/Hebron",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uD314\uB808\uC2A4\uD0C0\uC778 \uC601\uD1A0",
      "pt-BR": "Palestina",
      pt: "Palestina",
      nl: "Palestijnse gebieden",
      hr: "Palestina",
      fa: "\u0641\u0644\u0633\u0637\u06CC\u0646",
      de: "Pal\xE4stina",
      es: "Palestina",
      fr: "Palestine",
      ja: "\u30D1\u30EC\u30B9\u30C1\u30CA",
      it: "Palestina",
      cn: "\u5DF4\u52D2\u65AF\u5766",
      tr: "Filistin"
    },
    latitude: "31.90000000",
    longitude: "35.20000000",
    emoji: "\u{1F1F5}\u{1F1F8}",
    emojiU: "U+1F1F5 U+1F1F8"
  },
  {
    id: 170,
    name: "Panama",
    iso3: "PAN",
    iso2: "PA",
    numeric_code: "591",
    phone_code: "507",
    capital: "Panama City",
    currency: "PAB",
    currency_name: "Panamanian balboa",
    currency_symbol: "B/.",
    tld: ".pa",
    native: "Panam\xE1",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Panamanian",
    timezones: [
      {
        zoneName: "America/Panama",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uD30C\uB098\uB9C8",
      "pt-BR": "Panam\xE1",
      pt: "Panam\xE1",
      nl: "Panama",
      hr: "Panama",
      fa: "\u067E\u0627\u0646\u0627\u0645\u0627",
      de: "Panama",
      es: "Panam\xE1",
      fr: "Panama",
      ja: "\u30D1\u30CA\u30DE",
      it: "Panama",
      cn: "\u5DF4\u62FF\u9A6C",
      tr: "Panama"
    },
    latitude: "9.00000000",
    longitude: "-80.00000000",
    emoji: "\u{1F1F5}\u{1F1E6}",
    emojiU: "U+1F1F5 U+1F1E6"
  },
  {
    id: 171,
    name: "Papua new Guinea",
    iso3: "PNG",
    iso2: "PG",
    numeric_code: "598",
    phone_code: "675",
    capital: "Port Moresby",
    currency: "PGK",
    currency_name: "Papua New Guinean kina",
    currency_symbol: "K",
    tld: ".pg",
    native: "Papua Niugini",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "Papua New Guinean, Papuan",
    timezones: [
      {
        zoneName: "Pacific/Bougainville",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "BST",
        tzName: "Bougainville Standard Time[6"
      },
      {
        zoneName: "Pacific/Port_Moresby",
        gmtOffset: 36e3,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "PGT",
        tzName: "Papua New Guinea Time"
      }
    ],
    translations: {
      kr: "\uD30C\uD478\uC544\uB274\uAE30\uB2C8",
      "pt-BR": "Papua Nova Guin\xE9",
      pt: "Papua Nova Guin\xE9",
      nl: "Papoea-Nieuw-Guinea",
      hr: "Papua Nova Gvineja",
      fa: "\u067E\u0627\u067E\u0648\u0622 \u06AF\u06CC\u0646\u0647 \u0646\u0648",
      de: "Papua-Neuguinea",
      es: "Pap\xFAa Nueva Guinea",
      fr: "Papouasie-Nouvelle-Guin\xE9e",
      ja: "\u30D1\u30D7\u30A2\u30CB\u30E5\u30FC\u30AE\u30CB\u30A2",
      it: "Papua Nuova Guinea",
      cn: "\u5DF4\u5E03\u4E9A\u65B0\u51E0\u5185\u4E9A",
      tr: "Papua Yeni Gine"
    },
    latitude: "-6.00000000",
    longitude: "147.00000000",
    emoji: "\u{1F1F5}\u{1F1EC}",
    emojiU: "U+1F1F5 U+1F1EC"
  },
  {
    id: 172,
    name: "Paraguay",
    iso3: "PRY",
    iso2: "PY",
    numeric_code: "600",
    phone_code: "595",
    capital: "Asuncion",
    currency: "PYG",
    currency_name: "Paraguayan guarani",
    currency_symbol: "\u20B2",
    tld: ".py",
    native: "Paraguay",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Paraguayan",
    timezones: [
      {
        zoneName: "America/Asuncion",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "PYST",
        tzName: "Paraguay Summer Time"
      }
    ],
    translations: {
      kr: "\uD30C\uB77C\uACFC\uC774",
      "pt-BR": "Paraguai",
      pt: "Paraguai",
      nl: "Paraguay",
      hr: "Paragvaj",
      fa: "\u067E\u0627\u0631\u0627\u06AF\u0648\u0626\u0647",
      de: "Paraguay",
      es: "Paraguay",
      fr: "Paraguay",
      ja: "\u30D1\u30E9\u30B0\u30A2\u30A4",
      it: "Paraguay",
      cn: "\u5DF4\u62C9\u572D",
      tr: "Paraguay"
    },
    latitude: "-23.00000000",
    longitude: "-58.00000000",
    emoji: "\u{1F1F5}\u{1F1FE}",
    emojiU: "U+1F1F5 U+1F1FE"
  },
  {
    id: 173,
    name: "Peru",
    iso3: "PER",
    iso2: "PE",
    numeric_code: "604",
    phone_code: "51",
    capital: "Lima",
    currency: "PEN",
    currency_name: "Peruvian sol",
    currency_symbol: "S/.",
    tld: ".pe",
    native: "Per\xFA",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Peruvian",
    timezones: [
      {
        zoneName: "America/Lima",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "PET",
        tzName: "Peru Time"
      }
    ],
    translations: {
      kr: "\uD398\uB8E8",
      "pt-BR": "Peru",
      pt: "Peru",
      nl: "Peru",
      hr: "Peru",
      fa: "\u067E\u0631\u0648",
      de: "Peru",
      es: "Per\xFA",
      fr: "P\xE9rou",
      ja: "\u30DA\u30EB\u30FC",
      it: "Per\xF9",
      cn: "\u79D8\u9C81",
      tr: "Peru"
    },
    latitude: "-10.00000000",
    longitude: "-76.00000000",
    emoji: "\u{1F1F5}\u{1F1EA}",
    emojiU: "U+1F1F5 U+1F1EA"
  },
  {
    id: 174,
    name: "Philippines",
    iso3: "PHL",
    iso2: "PH",
    numeric_code: "608",
    phone_code: "63",
    capital: "Manila",
    currency: "PHP",
    currency_name: "Philippine peso",
    currency_symbol: "\u20B1",
    tld: ".ph",
    native: "Pilipinas",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Philippine, Filipino",
    timezones: [
      {
        zoneName: "Asia/Manila",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "PHT",
        tzName: "Philippine Time"
      }
    ],
    translations: {
      kr: "\uD544\uB9AC\uD540",
      "pt-BR": "Filipinas",
      pt: "Filipinas",
      nl: "Filipijnen",
      hr: "Filipini",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u0627\u0644\u0646\u062F\u0641\u06CC\u0644\u06CC\u067E\u06CC\u0646",
      de: "Philippinen",
      es: "Filipinas",
      fr: "Philippines",
      ja: "\u30D5\u30A3\u30EA\u30D4\u30F3",
      it: "Filippine",
      cn: "\u83F2\u5F8B\u5BBE",
      tr: "Filipinler"
    },
    latitude: "13.00000000",
    longitude: "122.00000000",
    emoji: "\u{1F1F5}\u{1F1ED}",
    emojiU: "U+1F1F5 U+1F1ED"
  },
  {
    id: 175,
    name: "Pitcairn Island",
    iso3: "PCN",
    iso2: "PN",
    numeric_code: "612",
    phone_code: "870",
    capital: "Adamstown",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".pn",
    native: "Pitcairn Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Pitcairn Island",
    timezones: [
      {
        zoneName: "Pacific/Pitcairn",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uD54F\uCF00\uC5B8 \uC81C\uB3C4",
      "pt-BR": "Ilhas Pitcairn",
      pt: "Ilhas Pic\xE1rnia",
      nl: "Pitcairneilanden",
      hr: "Pitcairnovo oto\u010Dje",
      fa: "\u067E\u06CC\u062A\u06A9\u0631\u0646",
      de: "Pitcairn",
      es: "Islas Pitcairn",
      fr: "\xCEles Pitcairn",
      ja: "\u30D4\u30C8\u30B1\u30A2\u30F3",
      it: "Isole Pitcairn",
      cn: "\u76AE\u7279\u51EF\u6069\u7FA4\u5C9B",
      tr: "Pitcairn Adalari"
    },
    latitude: "-25.06666666",
    longitude: "-130.10000000",
    emoji: "\u{1F1F5}\u{1F1F3}",
    emojiU: "U+1F1F5 U+1F1F3"
  },
  {
    id: 176,
    name: "Poland",
    iso3: "POL",
    iso2: "PL",
    numeric_code: "616",
    phone_code: "48",
    capital: "Warsaw",
    currency: "PLN",
    currency_name: "Polish z\u0142oty",
    currency_symbol: "z\u0142",
    tld: ".pl",
    native: "Polska",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Polish",
    timezones: [
      {
        zoneName: "Europe/Warsaw",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uD3F4\uB780\uB4DC",
      "pt-BR": "Pol\xF4nia",
      pt: "Pol\xF3nia",
      nl: "Polen",
      hr: "Poljska",
      fa: "\u0644\u0647\u0633\u062A\u0627\u0646",
      de: "Polen",
      es: "Polonia",
      fr: "Pologne",
      ja: "\u30DD\u30FC\u30E9\u30F3\u30C9",
      it: "Polonia",
      cn: "\u6CE2\u5170",
      tr: "Polonya"
    },
    latitude: "52.00000000",
    longitude: "20.00000000",
    emoji: "\u{1F1F5}\u{1F1F1}",
    emojiU: "U+1F1F5 U+1F1F1"
  },
  {
    id: 177,
    name: "Portugal",
    iso3: "PRT",
    iso2: "PT",
    numeric_code: "620",
    phone_code: "351",
    capital: "Lisbon",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".pt",
    native: "Portugal",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Portuguese",
    timezones: [
      {
        zoneName: "Atlantic/Azores",
        gmtOffset: -3600,
        gmtOffsetName: "UTC-01:00",
        abbreviation: "AZOT",
        tzName: "Azores Standard Time"
      },
      {
        zoneName: "Atlantic/Madeira",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "WET",
        tzName: "Western European Time"
      },
      {
        zoneName: "Europe/Lisbon",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "WET",
        tzName: "Western European Time"
      }
    ],
    translations: {
      kr: "\uD3EC\uB974\uD22C\uAC08",
      "pt-BR": "Portugal",
      pt: "Portugal",
      nl: "Portugal",
      hr: "Portugal",
      fa: "\u067E\u0631\u062A\u063A\u0627\u0644",
      de: "Portugal",
      es: "Portugal",
      fr: "Portugal",
      ja: "\u30DD\u30EB\u30C8\u30AC\u30EB",
      it: "Portogallo",
      cn: "\u8461\u8404\u7259",
      tr: "Portekiz"
    },
    latitude: "39.50000000",
    longitude: "-8.00000000",
    emoji: "\u{1F1F5}\u{1F1F9}",
    emojiU: "U+1F1F5 U+1F1F9"
  },
  {
    id: 178,
    name: "Puerto Rico",
    iso3: "PRI",
    iso2: "PR",
    numeric_code: "630",
    phone_code: "1",
    capital: "San Juan",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".pr",
    native: "Puerto Rico",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Puerto Rican",
    timezones: [
      {
        zoneName: "America/Puerto_Rico",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uD478\uC5D0\uB974\uD1A0\uB9AC\uCF54",
      "pt-BR": "Porto Rico",
      pt: "Porto Rico",
      nl: "Puerto Rico",
      hr: "Portoriko",
      fa: "\u067E\u0648\u0631\u062A\u0648 \u0631\u06CC\u06A9\u0648",
      de: "Puerto Rico",
      es: "Puerto Rico",
      fr: "Porto Rico",
      ja: "\u30D7\u30A8\u30EB\u30C8\u30EA\u30B3",
      it: "Porto Rico",
      cn: "\u6CE2\u591A\u9ECE\u5404",
      tr: "Porto Riko"
    },
    latitude: "18.25000000",
    longitude: "-66.50000000",
    emoji: "\u{1F1F5}\u{1F1F7}",
    emojiU: "U+1F1F5 U+1F1F7"
  },
  {
    id: 179,
    name: "Qatar",
    iso3: "QAT",
    iso2: "QA",
    numeric_code: "634",
    phone_code: "974",
    capital: "Doha",
    currency: "QAR",
    currency_name: "Qatari riyal",
    currency_symbol: "\u0642.\u0631",
    tld: ".qa",
    native: "\u0642\u0637\u0631",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Qatari",
    timezones: [
      {
        zoneName: "Asia/Qatar",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time"
      }
    ],
    translations: {
      kr: "\uCE74\uD0C0\uB974",
      "pt-BR": "Catar",
      pt: "Catar",
      nl: "Qatar",
      hr: "Katar",
      fa: "\u0642\u0637\u0631",
      de: "Katar",
      es: "Catar",
      fr: "Qatar",
      ja: "\u30AB\u30BF\u30FC\u30EB",
      it: "Qatar",
      cn: "\u5361\u5854\u5C14",
      tr: "Katar"
    },
    latitude: "25.50000000",
    longitude: "51.25000000",
    emoji: "\u{1F1F6}\u{1F1E6}",
    emojiU: "U+1F1F6 U+1F1E6"
  },
  {
    id: 180,
    name: "Reunion",
    iso3: "REU",
    iso2: "RE",
    numeric_code: "638",
    phone_code: "262",
    capital: "Saint-Denis",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".re",
    native: "La R\xE9union",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Reunionese, Reunionnais",
    timezones: [
      {
        zoneName: "Indian/Reunion",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "RET",
        tzName: "R\xE9union Time"
      }
    ],
    translations: {
      kr: "\uB808\uC704\uB2C8\uC639",
      "pt-BR": "Reuni\xE3o",
      pt: "Reuni\xE3o",
      nl: "R\xE9union",
      hr: "R\xE9union",
      fa: "\u0631\u0626\u0648\u0646\u06CC\u0648\u0646",
      de: "R\xE9union",
      es: "Reuni\xF3n",
      fr: "R\xE9union",
      ja: "\u30EC\u30E6\u30CB\u30AA\u30F3",
      it: "Riunione",
      cn: "\u7559\u5C3C\u6C6A\u5C9B",
      tr: "R\xE9union"
    },
    latitude: "-21.15000000",
    longitude: "55.50000000",
    emoji: "\u{1F1F7}\u{1F1EA}",
    emojiU: "U+1F1F7 U+1F1EA"
  },
  {
    id: 181,
    name: "Romania",
    iso3: "ROU",
    iso2: "RO",
    numeric_code: "642",
    phone_code: "40",
    capital: "Bucharest",
    currency: "RON",
    currency_name: "Romanian leu",
    currency_symbol: "lei",
    tld: ".ro",
    native: "Rom\xE2nia",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Romanian",
    timezones: [
      {
        zoneName: "Europe/Bucharest",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uB8E8\uB9C8\uB2C8\uC544",
      "pt-BR": "Rom\xEAnia",
      pt: "Rom\xE9nia",
      nl: "Roemeni\xEB",
      hr: "Rumunjska",
      fa: "\u0631\u0648\u0645\u0627\u0646\u06CC",
      de: "Rum\xE4nien",
      es: "Rumania",
      fr: "Roumanie",
      ja: "\u30EB\u30FC\u30DE\u30CB\u30A2",
      it: "Romania",
      cn: "\u7F57\u9A6C\u5C3C\u4E9A",
      tr: "Romanya"
    },
    latitude: "46.00000000",
    longitude: "25.00000000",
    emoji: "\u{1F1F7}\u{1F1F4}",
    emojiU: "U+1F1F7 U+1F1F4"
  },
  {
    id: 182,
    name: "Russia",
    iso3: "RUS",
    iso2: "RU",
    numeric_code: "643",
    phone_code: "7",
    capital: "Moscow",
    currency: "RUB",
    currency_name: "Russian ruble",
    currency_symbol: "\u20BD",
    tld: ".ru",
    native: "\u0420\u043E\u0441\u0441\u0438\u044F",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Russian",
    timezones: [
      {
        zoneName: "Asia/Anadyr",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "ANAT",
        tzName: "Anadyr Time[4"
      },
      {
        zoneName: "Asia/Barnaul",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "KRAT",
        tzName: "Krasnoyarsk Time"
      },
      {
        zoneName: "Asia/Chita",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "YAKT",
        tzName: "Yakutsk Time"
      },
      {
        zoneName: "Asia/Irkutsk",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "IRKT",
        tzName: "Irkutsk Time"
      },
      {
        zoneName: "Asia/Kamchatka",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "PETT",
        tzName: "Kamchatka Time"
      },
      {
        zoneName: "Asia/Khandyga",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "YAKT",
        tzName: "Yakutsk Time"
      },
      {
        zoneName: "Asia/Krasnoyarsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "KRAT",
        tzName: "Krasnoyarsk Time"
      },
      {
        zoneName: "Asia/Magadan",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "MAGT",
        tzName: "Magadan Time"
      },
      {
        zoneName: "Asia/Novokuznetsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "KRAT",
        tzName: "Krasnoyarsk Time"
      },
      {
        zoneName: "Asia/Novosibirsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "NOVT",
        tzName: "Novosibirsk Time"
      },
      {
        zoneName: "Asia/Omsk",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "OMST",
        tzName: "Omsk Time"
      },
      {
        zoneName: "Asia/Sakhalin",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "SAKT",
        tzName: "Sakhalin Island Time"
      },
      {
        zoneName: "Asia/Srednekolymsk",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "SRET",
        tzName: "Srednekolymsk Time"
      },
      {
        zoneName: "Asia/Tomsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "MSD+3",
        tzName: "Moscow Daylight Time+3"
      },
      {
        zoneName: "Asia/Ust-Nera",
        gmtOffset: 36e3,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "VLAT",
        tzName: "Vladivostok Time"
      },
      {
        zoneName: "Asia/Vladivostok",
        gmtOffset: 36e3,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "VLAT",
        tzName: "Vladivostok Time"
      },
      {
        zoneName: "Asia/Yakutsk",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "YAKT",
        tzName: "Yakutsk Time"
      },
      {
        zoneName: "Asia/Yekaterinburg",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "YEKT",
        tzName: "Yekaterinburg Time"
      },
      {
        zoneName: "Europe/Astrakhan",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SAMT",
        tzName: "Samara Time"
      },
      {
        zoneName: "Europe/Kaliningrad",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      },
      {
        zoneName: "Europe/Kirov",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time"
      },
      {
        zoneName: "Europe/Moscow",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time"
      },
      {
        zoneName: "Europe/Samara",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SAMT",
        tzName: "Samara Time"
      },
      {
        zoneName: "Europe/Saratov",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "MSD",
        tzName: "Moscow Daylight Time+4"
      },
      {
        zoneName: "Europe/Ulyanovsk",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SAMT",
        tzName: "Samara Time"
      },
      {
        zoneName: "Europe/Volgograd",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "MSK",
        tzName: "Moscow Standard Time"
      }
    ],
    translations: {
      kr: "\uB7EC\uC2DC\uC544",
      "pt-BR": "R\xFAssia",
      pt: "R\xFAssia",
      nl: "Rusland",
      hr: "Rusija",
      fa: "\u0631\u0648\u0633\u06CC\u0647",
      de: "Russland",
      es: "Rusia",
      fr: "Russie",
      ja: "\u30ED\u30B7\u30A2\u9023\u90A6",
      it: "Russia",
      cn: "\u4FC4\u7F57\u65AF\u8054\u90A6",
      tr: "Rusya"
    },
    latitude: "60.00000000",
    longitude: "100.00000000",
    emoji: "\u{1F1F7}\u{1F1FA}",
    emojiU: "U+1F1F7 U+1F1FA"
  },
  {
    id: 183,
    name: "Rwanda",
    iso3: "RWA",
    iso2: "RW",
    numeric_code: "646",
    phone_code: "250",
    capital: "Kigali",
    currency: "RWF",
    currency_name: "Rwandan franc",
    currency_symbol: "FRw",
    tld: ".rw",
    native: "Rwanda",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Rwandan",
    timezones: [
      {
        zoneName: "Africa/Kigali",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time"
      }
    ],
    translations: {
      kr: "\uB974\uC644\uB2E4",
      "pt-BR": "Ruanda",
      pt: "Ruanda",
      nl: "Rwanda",
      hr: "Ruanda",
      fa: "\u0631\u0648\u0627\u0646\u062F\u0627",
      de: "Ruanda",
      es: "Ruanda",
      fr: "Rwanda",
      ja: "\u30EB\u30EF\u30F3\u30C0",
      it: "Ruanda",
      cn: "\u5362\u65FA\u8FBE",
      tr: "Ruanda"
    },
    latitude: "-2.00000000",
    longitude: "30.00000000",
    emoji: "\u{1F1F7}\u{1F1FC}",
    emojiU: "U+1F1F7 U+1F1FC"
  },
  {
    id: 184,
    name: "Saint Helena",
    iso3: "SHN",
    iso2: "SH",
    numeric_code: "654",
    phone_code: "290",
    capital: "Jamestown",
    currency: "SHP",
    currency_name: "Saint Helena pound",
    currency_symbol: "\xA3",
    tld: ".sh",
    native: "Saint Helena",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Saint Helenian",
    timezones: [
      {
        zoneName: "Atlantic/St_Helena",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uC138\uC778\uD2B8\uD5EC\uB808\uB098",
      "pt-BR": "Santa Helena",
      pt: "Santa Helena",
      nl: "Sint-Helena",
      hr: "Sveta Helena",
      fa: "\u0633\u0646\u062A \u0647\u0644\u0646\u0627\u060C \u0627\u0633\u0646\u0634\u0646 \u0648 \u062A\u0631\u06CC\u0633\u062A\u0627\u0646 \u062F\u0627 \u06A9\u0648\u0646\u0627",
      de: "Sankt Helena",
      es: "Santa Helena",
      fr: "Sainte-H\xE9l\xE8ne",
      ja: "\u30BB\u30F3\u30C8\u30D8\u30EC\u30CA\u30FB\u30A2\u30BB\u30F3\u30B7\u30E7\u30F3\u304A\u3088\u3073\u30C8\u30EA\u30B9\u30BF\u30F3\u30C0\u30AF\u30FC\u30CB\u30E3",
      it: "Sant'Elena",
      cn: "\u5723\u8D6B\u52D2\u62FF",
      tr: "Saint Helena"
    },
    latitude: "-15.95000000",
    longitude: "-5.70000000",
    emoji: "\u{1F1F8}\u{1F1ED}",
    emojiU: "U+1F1F8 U+1F1ED"
  },
  {
    id: 185,
    name: "Saint Kitts And Nevis",
    iso3: "KNA",
    iso2: "KN",
    numeric_code: "659",
    phone_code: "1",
    capital: "Basseterre",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".kn",
    native: "Saint Kitts and Nevis",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Kittitian or Nevisian",
    timezones: [
      {
        zoneName: "America/St_Kitts",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC138\uC778\uD2B8\uD0A4\uCE20 \uB124\uBE44\uC2A4",
      "pt-BR": "S\xE3o Crist\xF3v\xE3o e Neves",
      pt: "S\xE3o Crist\xF3v\xE3o e Neves",
      nl: "Saint Kitts en Nevis",
      hr: "Sveti Kristof i Nevis",
      fa: "\u0633\u0646\u062A \u06A9\u06CC\u062A\u0633 \u0648 \u0646\u0648\u06CC\u0633",
      de: "St. Kitts und Nevis",
      es: "San Crist\xF3bal y Nieves",
      fr: "Saint-Christophe-et-Ni\xE9v\xE8s",
      ja: "\u30BB\u30F3\u30C8\u30AF\u30EA\u30B9\u30C8\u30D5\u30A1\u30FC\u30FB\u30CD\u30A4\u30D3\u30B9",
      it: "Saint Kitts e Nevis",
      cn: "\u5723\u57FA\u8328\u548C\u5C3C\u7EF4\u65AF",
      tr: "Saint Kitts Ve Nevis"
    },
    latitude: "17.33333333",
    longitude: "-62.75000000",
    emoji: "\u{1F1F0}\u{1F1F3}",
    emojiU: "U+1F1F0 U+1F1F3"
  },
  {
    id: 186,
    name: "Saint Lucia",
    iso3: "LCA",
    iso2: "LC",
    numeric_code: "662",
    phone_code: "1",
    capital: "Castries",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".lc",
    native: "Saint Lucia",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Saint Lucian",
    timezones: [
      {
        zoneName: "America/St_Lucia",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC138\uC778\uD2B8\uB8E8\uC2DC\uC544",
      "pt-BR": "Santa L\xFAcia",
      pt: "Santa L\xFAcia",
      nl: "Saint Lucia",
      hr: "Sveta Lucija",
      fa: "\u0633\u0646\u062A \u0644\u0648\u0633\u06CC\u0627",
      de: "Saint Lucia",
      es: "Santa Luc\xEDa",
      fr: "Saint-Lucie",
      ja: "\u30BB\u30F3\u30C8\u30EB\u30B7\u30A2",
      it: "Santa Lucia",
      cn: "\u5723\u5362\u897F\u4E9A",
      tr: "Saint Lucia"
    },
    latitude: "13.88333333",
    longitude: "-60.96666666",
    emoji: "\u{1F1F1}\u{1F1E8}",
    emojiU: "U+1F1F1 U+1F1E8"
  },
  {
    id: 187,
    name: "Saint Pierre and Miquelon",
    iso3: "SPM",
    iso2: "PM",
    numeric_code: "666",
    phone_code: "508",
    capital: "Saint-Pierre",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".pm",
    native: "Saint-Pierre-et-Miquelon",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "Saint-Pierrais or Miquelonnais",
    timezones: [
      {
        zoneName: "America/Miquelon",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "PMDT",
        tzName: "Pierre & Miquelon Daylight Time"
      }
    ],
    translations: {
      kr: "\uC0DD\uD53C\uC5D0\uB974 \uBBF8\uD074\uB871",
      "pt-BR": "Saint-Pierre e Miquelon",
      pt: "S\xE3o Pedro e Miquelon",
      nl: "Saint Pierre en Miquelon",
      hr: "Sveti Petar i Mikelon",
      fa: "\u0633\u0646 \u067E\u06CC\u0631 \u0648 \u0645\u06CC\u06A9\u0644\u0646",
      de: "Saint-Pierre und Miquelon",
      es: "San Pedro y Miquel\xF3n",
      fr: "Saint-Pierre-et-Miquelon",
      ja: "\u30B5\u30F3\u30D4\u30A8\u30FC\u30EB\u5CF6\u30FB\u30DF\u30AF\u30ED\u30F3\u5CF6",
      it: "Saint-Pierre e Miquelon",
      cn: "\u5723\u76AE\u57C3\u5C14\u548C\u5BC6\u514B\u9686",
      tr: "Saint Pierre Ve Miquelon"
    },
    latitude: "46.83333333",
    longitude: "-56.33333333",
    emoji: "\u{1F1F5}\u{1F1F2}",
    emojiU: "U+1F1F5 U+1F1F2"
  },
  {
    id: 188,
    name: "Saint Vincent And The Grenadines",
    iso3: "VCT",
    iso2: "VC",
    numeric_code: "670",
    phone_code: "1",
    capital: "Kingstown",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".vc",
    native: "Saint Vincent and the Grenadines",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Saint Vincentian, Vincentian",
    timezones: [
      {
        zoneName: "America/St_Vincent",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC138\uC778\uD2B8\uBE48\uC13C\uD2B8 \uADF8\uB808\uB098\uB518",
      "pt-BR": "S\xE3o Vicente e Granadinas",
      pt: "S\xE3o Vicente e Granadinas",
      nl: "Saint Vincent en de Grenadines",
      hr: "Sveti Vincent i Grenadini",
      fa: "\u0633\u0646\u062A \u0648\u06CC\u0646\u0633\u0646\u062A \u0648 \u06AF\u0631\u0646\u0627\u062F\u06CC\u0646\u200C\u0647\u0627",
      de: "Saint Vincent und die Grenadinen",
      es: "San Vicente y Granadinas",
      fr: "Saint-Vincent-et-les-Grenadines",
      ja: "\u30BB\u30F3\u30C8\u30D3\u30F3\u30BB\u30F3\u30C8\u304A\u3088\u3073\u30B0\u30EC\u30CA\u30C7\u30A3\u30FC\u30F3\u8AF8\u5CF6",
      it: "Saint Vincent e Grenadine",
      cn: "\u5723\u6587\u68EE\u7279\u548C\u683C\u6797\u7EB3\u4E01\u65AF",
      tr: "Saint Vincent Ve Grenadinler"
    },
    latitude: "13.25000000",
    longitude: "-61.20000000",
    emoji: "\u{1F1FB}\u{1F1E8}",
    emojiU: "U+1F1FB U+1F1E8"
  },
  {
    id: 189,
    name: "Saint-Barthelemy",
    iso3: "BLM",
    iso2: "BL",
    numeric_code: "652",
    phone_code: "590",
    capital: "Gustavia",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".bl",
    native: "Saint-Barth\xE9lemy",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Barthelemois",
    timezones: [
      {
        zoneName: "America/St_Barthelemy",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC0DD\uBC14\uB974\uD154\uB808\uBBF8",
      "pt-BR": "S\xE3o Bartolomeu",
      pt: "S\xE3o Bartolomeu",
      nl: "Saint Barth\xE9lemy",
      hr: "Saint Barth\xE9lemy",
      fa: "\u0633\u0646-\u0628\u0627\u0631\u062A\u0644\u0645\u06CC",
      de: "Saint-Barth\xE9lemy",
      es: "San Bartolom\xE9",
      fr: "Saint-Barth\xE9lemy",
      ja: "\u30B5\u30F3\u30FB\u30D0\u30EB\u30C6\u30EB\u30DF\u30FC",
      it: "Antille Francesi",
      cn: "\u5723\u5DF4\u6CF0\u52D2\u7C73",
      tr: "Saint Barth\xE9lemy"
    },
    latitude: "18.50000000",
    longitude: "-63.41666666",
    emoji: "\u{1F1E7}\u{1F1F1}",
    emojiU: "U+1F1E7 U+1F1F1"
  },
  {
    id: 190,
    name: "Saint-Martin (French part)",
    iso3: "MAF",
    iso2: "MF",
    numeric_code: "663",
    phone_code: "590",
    capital: "Marigot",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".mf",
    native: "Saint-Martin",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Saint-Martinoise",
    timezones: [
      {
        zoneName: "America/Marigot",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC138\uC778\uD2B8\uB9C8\uD2F4 \uC12C",
      "pt-BR": "Saint Martin",
      pt: "Ilha S\xE3o Martinho",
      nl: "Saint-Martin",
      hr: "Sveti Martin",
      fa: "\u0633\u06CC\u0646\u062A \u0645\u0627\u0631\u062A\u0646",
      de: "Saint Martin",
      es: "Saint Martin",
      fr: "Saint-Martin",
      ja: "\u30B5\u30F3\u30FB\u30DE\u30EB\u30BF\u30F3\uFF08\u30D5\u30E9\u30F3\u30B9\u9818\uFF09",
      it: "Saint Martin",
      cn: "\u5BC6\u514B\u7F57\u5C3C\u897F\u4E9A",
      tr: "Saint Martin"
    },
    latitude: "18.08333333",
    longitude: "-63.95000000",
    emoji: "\u{1F1F2}\u{1F1EB}",
    emojiU: "U+1F1F2 U+1F1EB"
  },
  {
    id: 191,
    name: "Samoa",
    iso3: "WSM",
    iso2: "WS",
    numeric_code: "882",
    phone_code: "685",
    capital: "Apia",
    currency: "WST",
    currency_name: "Samoan t\u0101l\u0101",
    currency_symbol: "SAT",
    tld: ".ws",
    native: "Samoa",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Samoan",
    timezones: [
      {
        zoneName: "Pacific/Apia",
        gmtOffset: 50400,
        gmtOffsetName: "UTC+14:00",
        abbreviation: "WST",
        tzName: "West Samoa Time"
      }
    ],
    translations: {
      kr: "\uC0AC\uBAA8\uC544",
      "pt-BR": "Samoa",
      pt: "Samoa",
      nl: "Samoa",
      hr: "Samoa",
      fa: "\u0633\u0627\u0645\u0648\u0622",
      de: "Samoa",
      es: "Samoa",
      fr: "Samoa",
      ja: "\u30B5\u30E2\u30A2",
      it: "Samoa",
      cn: "\u8428\u6469\u4E9A",
      tr: "Samoa"
    },
    latitude: "-13.58333333",
    longitude: "-172.33333333",
    emoji: "\u{1F1FC}\u{1F1F8}",
    emojiU: "U+1F1FC U+1F1F8"
  },
  {
    id: 192,
    name: "San Marino",
    iso3: "SMR",
    iso2: "SM",
    numeric_code: "674",
    phone_code: "378",
    capital: "San Marino",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".sm",
    native: "San Marino",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Sammarinese",
    timezones: [
      {
        zoneName: "Europe/San_Marino",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC0B0\uB9C8\uB9AC\uB178",
      "pt-BR": "San Marino",
      pt: "S\xE3o Marinho",
      nl: "San Marino",
      hr: "San Marino",
      fa: "\u0633\u0627\u0646 \u0645\u0627\u0631\u06CC\u0646\u0648",
      de: "San Marino",
      es: "San Marino",
      fr: "Saint-Marin",
      ja: "\u30B5\u30F3\u30DE\u30EA\u30CE",
      it: "San Marino",
      cn: "\u5723\u9A6C\u529B\u8BFA",
      tr: "San Marino"
    },
    latitude: "43.76666666",
    longitude: "12.41666666",
    emoji: "\u{1F1F8}\u{1F1F2}",
    emojiU: "U+1F1F8 U+1F1F2"
  },
  {
    id: 193,
    name: "Sao Tome and Principe",
    iso3: "STP",
    iso2: "ST",
    numeric_code: "678",
    phone_code: "239",
    capital: "Sao Tome",
    currency: "STD",
    currency_name: "Dobra",
    currency_symbol: "Db",
    tld: ".st",
    native: "S\xE3o Tom\xE9 e Pr\xEDncipe",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Sao Tomean",
    timezones: [
      {
        zoneName: "Africa/Sao_Tome",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uC0C1\uD22C\uBA54 \uD504\uB9B0\uC2DC\uD398",
      "pt-BR": "S\xE3o Tom\xE9 e Pr\xEDncipe",
      pt: "S\xE3o Tom\xE9 e Pr\xEDncipe",
      nl: "Sao Tom\xE9 en Principe",
      hr: "Sveti Toma i Princip",
      fa: "\u06A9\u0648\u0627\u062A\u0631\u0648 \u062F\u0648 \u0641\u0631\u0648\u06CC\u0631\u0648",
      de: "S\xE3o Tom\xE9 und Pr\xEDncipe",
      es: "Santo Tom\xE9 y Pr\xEDncipe",
      fr: "Sao Tom\xE9-et-Principe",
      ja: "\u30B5\u30F3\u30C8\u30E1\u30FB\u30D7\u30EA\u30F3\u30B7\u30DA",
      it: "S\xE3o Tom\xE9 e Pr\xEDncipe",
      cn: "\u5723\u591A\u7F8E\u548C\u666E\u6797\u897F\u6BD4",
      tr: "Sao Tome Ve Prinsipe"
    },
    latitude: "1.00000000",
    longitude: "7.00000000",
    emoji: "\u{1F1F8}\u{1F1F9}",
    emojiU: "U+1F1F8 U+1F1F9"
  },
  {
    id: 194,
    name: "Saudi Arabia",
    iso3: "SAU",
    iso2: "SA",
    numeric_code: "682",
    phone_code: "966",
    capital: "Riyadh",
    currency: "SAR",
    currency_name: "Saudi riyal",
    currency_symbol: "\uFDFC",
    tld: ".sa",
    native: "\u0627\u0644\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0639\u0631\u0628\u064A\u0629 \u0627\u0644\u0633\u0639\u0648\u062F\u064A\u0629",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Saudi, Saudi Arabian",
    timezones: [
      {
        zoneName: "Asia/Riyadh",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time"
      }
    ],
    translations: {
      kr: "\uC0AC\uC6B0\uB514\uC544\uB77C\uBE44\uC544",
      "pt-BR": "Ar\xE1bia Saudita",
      pt: "Ar\xE1bia Saudita",
      nl: "Saoedi-Arabi\xEB",
      hr: "Saudijska Arabija",
      fa: "\u0639\u0631\u0628\u0633\u062A\u0627\u0646 \u0633\u0639\u0648\u062F\u06CC",
      de: "Saudi-Arabien",
      es: "Arabia Saud\xED",
      fr: "Arabie Saoudite",
      ja: "\u30B5\u30A6\u30B8\u30A2\u30E9\u30D3\u30A2",
      it: "Arabia Saudita",
      cn: "\u6C99\u7279\u963F\u62C9\u4F2F",
      tr: "Suudi Arabistan"
    },
    latitude: "25.00000000",
    longitude: "45.00000000",
    emoji: "\u{1F1F8}\u{1F1E6}",
    emojiU: "U+1F1F8 U+1F1E6"
  },
  {
    id: 195,
    name: "Senegal",
    iso3: "SEN",
    iso2: "SN",
    numeric_code: "686",
    phone_code: "221",
    capital: "Dakar",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".sn",
    native: "S\xE9n\xE9gal",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Senegalese",
    timezones: [
      {
        zoneName: "Africa/Dakar",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uC138\uB124\uAC08",
      "pt-BR": "Senegal",
      pt: "Senegal",
      nl: "Senegal",
      hr: "Senegal",
      fa: "\u0633\u0646\u06AF\u0627\u0644",
      de: "Senegal",
      es: "Senegal",
      fr: "S\xE9n\xE9gal",
      ja: "\u30BB\u30CD\u30AC\u30EB",
      it: "Senegal",
      cn: "\u585E\u5185\u52A0\u5C14",
      tr: "Senegal"
    },
    latitude: "14.00000000",
    longitude: "-14.00000000",
    emoji: "\u{1F1F8}\u{1F1F3}",
    emojiU: "U+1F1F8 U+1F1F3"
  },
  {
    id: 196,
    name: "Serbia",
    iso3: "SRB",
    iso2: "RS",
    numeric_code: "688",
    phone_code: "381",
    capital: "Belgrade",
    currency: "RSD",
    currency_name: "Serbian dinar",
    currency_symbol: "din",
    tld: ".rs",
    native: "\u0421\u0440\u0431\u0438\u0458\u0430",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Serbian",
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC138\uB974\uBE44\uC544",
      "pt-BR": "S\xE9rvia",
      pt: "S\xE9rvia",
      nl: "Servi\xEB",
      hr: "Srbija",
      fa: "\u0635\u0631\u0628\u0633\u062A\u0627\u0646",
      de: "Serbien",
      es: "Serbia",
      fr: "Serbie",
      ja: "\u30BB\u30EB\u30D3\u30A2",
      it: "Serbia",
      cn: "\u585E\u5C14\u7EF4\u4E9A",
      tr: "Sirbistan"
    },
    latitude: "44.00000000",
    longitude: "21.00000000",
    emoji: "\u{1F1F7}\u{1F1F8}",
    emojiU: "U+1F1F7 U+1F1F8"
  },
  {
    id: 197,
    name: "Seychelles",
    iso3: "SYC",
    iso2: "SC",
    numeric_code: "690",
    phone_code: "248",
    capital: "Victoria",
    currency: "SCR",
    currency_name: "Seychellois rupee",
    currency_symbol: "SRe",
    tld: ".sc",
    native: "Seychelles",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Seychellois",
    timezones: [
      {
        zoneName: "Indian/Mahe",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SCT",
        tzName: "Seychelles Time"
      }
    ],
    translations: {
      kr: "\uC138\uC774\uC178",
      "pt-BR": "Seicheles",
      pt: "Seicheles",
      nl: "Seychellen",
      hr: "Sej\u0161eli",
      fa: "\u0633\u06CC\u0634\u0644",
      de: "Seychellen",
      es: "Seychelles",
      fr: "Seychelles",
      ja: "\u30BB\u30FC\u30B7\u30A7\u30EB",
      it: "Seychelles",
      cn: "\u585E\u820C\u5C14",
      tr: "Sey\u015Feller"
    },
    latitude: "-4.58333333",
    longitude: "55.66666666",
    emoji: "\u{1F1F8}\u{1F1E8}",
    emojiU: "U+1F1F8 U+1F1E8"
  },
  {
    id: 198,
    name: "Sierra Leone",
    iso3: "SLE",
    iso2: "SL",
    numeric_code: "694",
    phone_code: "232",
    capital: "Freetown",
    currency: "SLL",
    currency_name: "Sierra Leonean leone",
    currency_symbol: "Le",
    tld: ".sl",
    native: "Sierra Leone",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Sierra Leonean",
    timezones: [
      {
        zoneName: "Africa/Freetown",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uC2DC\uC5D0\uB77C\uB9AC\uC628",
      "pt-BR": "Serra Leoa",
      pt: "Serra Leoa",
      nl: "Sierra Leone",
      hr: "Sijera Leone",
      fa: "\u0633\u06CC\u0631\u0627\u0644\u0626\u0648\u0646",
      de: "Sierra Leone",
      es: "Sierra Leone",
      fr: "Sierra Leone",
      ja: "\u30B7\u30A8\u30E9\u30EC\u30AA\u30CD",
      it: "Sierra Leone",
      cn: "\u585E\u62C9\u5229\u6602",
      tr: "Sierra Leone"
    },
    latitude: "8.50000000",
    longitude: "-11.50000000",
    emoji: "\u{1F1F8}\u{1F1F1}",
    emojiU: "U+1F1F8 U+1F1F1"
  },
  {
    id: 199,
    name: "Singapore",
    iso3: "SGP",
    iso2: "SG",
    numeric_code: "702",
    phone_code: "65",
    capital: "Singapur",
    currency: "SGD",
    currency_name: "Singapore dollar",
    currency_symbol: "$",
    tld: ".sg",
    native: "Singapore",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Singaporean",
    timezones: [
      {
        zoneName: "Asia/Singapore",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "SGT",
        tzName: "Singapore Time"
      }
    ],
    translations: {
      kr: "\uC2F1\uAC00\uD3EC\uB974",
      "pt-BR": "Singapura",
      pt: "Singapura",
      nl: "Singapore",
      hr: "Singapur",
      fa: "\u0633\u0646\u06AF\u0627\u067E\u0648\u0631",
      de: "Singapur",
      es: "Singapur",
      fr: "Singapour",
      ja: "\u30B7\u30F3\u30AC\u30DD\u30FC\u30EB",
      it: "Singapore",
      cn: "\u65B0\u52A0\u5761",
      tr: "Singapur"
    },
    latitude: "1.36666666",
    longitude: "103.80000000",
    emoji: "\u{1F1F8}\u{1F1EC}",
    emojiU: "U+1F1F8 U+1F1EC"
  },
  {
    id: 250,
    name: "Sint Maarten (Dutch part)",
    iso3: "SXM",
    iso2: "SX",
    numeric_code: "534",
    phone_code: "1721",
    capital: "Philipsburg",
    currency: "ANG",
    currency_name: "Netherlands Antillean guilder",
    currency_symbol: "\u0192",
    tld: ".sx",
    native: "Sint Maarten",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Sint Maarten",
    timezones: [
      {
        zoneName: "America/Anguilla",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC2E0\uD2B8\uB9C8\uB974\uD134",
      "pt-BR": "Sint Maarten",
      pt: "S\xE3o Martinho",
      nl: "Sint Maarten",
      fa: "\u0633\u06CC\u0646\u062A \u0645\u0627\u0631\u062A\u0646",
      de: "Sint Maarten (niederl. Teil)",
      fr: "Saint Martin (partie n\xE9erlandaise)",
      it: "Saint Martin (parte olandese)",
      cn: "\u5723\u9A6C\u4E01\u5C9B\uFF08\u8377\u5170\u90E8\u5206\uFF09",
      tr: "Sint Maarten"
    },
    latitude: "18.03333300",
    longitude: "-63.05000000",
    emoji: "\u{1F1F8}\u{1F1FD}",
    emojiU: "U+1F1F8 U+1F1FD"
  },
  {
    id: 200,
    name: "Slovakia",
    iso3: "SVK",
    iso2: "SK",
    numeric_code: "703",
    phone_code: "421",
    capital: "Bratislava",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".sk",
    native: "Slovensko",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Slovak",
    timezones: [
      {
        zoneName: "Europe/Bratislava",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC2AC\uB85C\uBC14\uD0A4\uC544",
      "pt-BR": "Eslov\xE1quia",
      pt: "Eslov\xE1quia",
      nl: "Slowakije",
      hr: "Slova\u010Dka",
      fa: "\u0627\u0633\u0644\u0648\u0627\u06A9\u06CC",
      de: "Slowakei",
      es: "Rep\xFAblica Eslovaca",
      fr: "Slovaquie",
      ja: "\u30B9\u30ED\u30D0\u30AD\u30A2",
      it: "Slovacchia",
      cn: "\u65AF\u6D1B\u4F10\u514B",
      tr: "Slovakya"
    },
    latitude: "48.66666666",
    longitude: "19.50000000",
    emoji: "\u{1F1F8}\u{1F1F0}",
    emojiU: "U+1F1F8 U+1F1F0"
  },
  {
    id: 201,
    name: "Slovenia",
    iso3: "SVN",
    iso2: "SI",
    numeric_code: "705",
    phone_code: "386",
    capital: "Ljubljana",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".si",
    native: "Slovenija",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Slovenian, Slovene",
    timezones: [
      {
        zoneName: "Europe/Ljubljana",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC2AC\uB85C\uBCA0\uB2C8\uC544",
      "pt-BR": "Eslov\xEAnia",
      pt: "Eslov\xE9nia",
      nl: "Sloveni\xEB",
      hr: "Slovenija",
      fa: "\u0627\u0633\u0644\u0648\u0648\u0646\u06CC",
      de: "Slowenien",
      es: "Eslovenia",
      fr: "Slov\xE9nie",
      ja: "\u30B9\u30ED\u30D9\u30CB\u30A2",
      it: "Slovenia",
      cn: "\u65AF\u6D1B\u6587\u5C3C\u4E9A",
      tr: "Slovenya"
    },
    latitude: "46.11666666",
    longitude: "14.81666666",
    emoji: "\u{1F1F8}\u{1F1EE}",
    emojiU: "U+1F1F8 U+1F1EE"
  },
  {
    id: 202,
    name: "Solomon Islands",
    iso3: "SLB",
    iso2: "SB",
    numeric_code: "090",
    phone_code: "677",
    capital: "Honiara",
    currency: "SBD",
    currency_name: "Solomon Islands dollar",
    currency_symbol: "Si$",
    tld: ".sb",
    native: "Solomon Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "Solomon Island",
    timezones: [
      {
        zoneName: "Pacific/Guadalcanal",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "SBT",
        tzName: "Solomon Islands Time"
      }
    ],
    translations: {
      kr: "\uC194\uB85C\uBAAC \uC81C\uB3C4",
      "pt-BR": "Ilhas Salom\xE3o",
      pt: "Ilhas Salom\xE3o",
      nl: "Salomonseilanden",
      hr: "Solomonski Otoci",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u0633\u0644\u06CC\u0645\u0627\u0646",
      de: "Salomonen",
      es: "Islas Salom\xF3n",
      fr: "\xCEles Salomon",
      ja: "\u30BD\u30ED\u30E2\u30F3\u8AF8\u5CF6",
      it: "Isole Salomone",
      cn: "\u6240\u7F57\u95E8\u7FA4\u5C9B",
      tr: "Solomon Adalari"
    },
    latitude: "-8.00000000",
    longitude: "159.00000000",
    emoji: "\u{1F1F8}\u{1F1E7}",
    emojiU: "U+1F1F8 U+1F1E7"
  },
  {
    id: 203,
    name: "Somalia",
    iso3: "SOM",
    iso2: "SO",
    numeric_code: "706",
    phone_code: "252",
    capital: "Mogadishu",
    currency: "SOS",
    currency_name: "Somali shilling",
    currency_symbol: "Sh.so.",
    tld: ".so",
    native: "Soomaaliya",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Somali, Somalian",
    timezones: [
      {
        zoneName: "Africa/Mogadishu",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uC18C\uB9D0\uB9AC\uC544",
      "pt-BR": "Som\xE1lia",
      pt: "Som\xE1lia",
      nl: "Somali\xEB",
      hr: "Somalija",
      fa: "\u0633\u0648\u0645\u0627\u0644\u06CC",
      de: "Somalia",
      es: "Somalia",
      fr: "Somalie",
      ja: "\u30BD\u30DE\u30EA\u30A2",
      it: "Somalia",
      cn: "\u7D22\u9A6C\u91CC",
      tr: "Somali"
    },
    latitude: "10.00000000",
    longitude: "49.00000000",
    emoji: "\u{1F1F8}\u{1F1F4}",
    emojiU: "U+1F1F8 U+1F1F4"
  },
  {
    id: 204,
    name: "South Africa",
    iso3: "ZAF",
    iso2: "ZA",
    numeric_code: "710",
    phone_code: "27",
    capital: "Pretoria",
    currency: "ZAR",
    currency_name: "South African rand",
    currency_symbol: "R",
    tld: ".za",
    native: "South Africa",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "South African",
    timezones: [
      {
        zoneName: "Africa/Johannesburg",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "SAST",
        tzName: "South African Standard Time"
      }
    ],
    translations: {
      kr: "\uB0A8\uC544\uD504\uB9AC\uCE74 \uACF5\uD654\uAD6D",
      "pt-BR": "Rep\xFAblica Sul-Africana",
      pt: "Rep\xFAblica Sul-Africana",
      nl: "Zuid-Afrika",
      hr: "Ju\u017Enoafri\u010Dka Republika",
      fa: "\u0622\u0641\u0631\u06CC\u0642\u0627\u06CC \u062C\u0646\u0648\u0628\u06CC",
      de: "Republik S\xFCdafrika",
      es: "Rep\xFAblica de Sud\xE1frica",
      fr: "Afrique du Sud",
      ja: "\u5357\u30A2\u30D5\u30EA\u30AB",
      it: "Sud Africa",
      cn: "\u5357\u975E",
      tr: "G\xFCney Afrika Cumhuriyeti"
    },
    latitude: "-29.00000000",
    longitude: "24.00000000",
    emoji: "\u{1F1FF}\u{1F1E6}",
    emojiU: "U+1F1FF U+1F1E6"
  },
  {
    id: 205,
    name: "South Georgia",
    iso3: "SGS",
    iso2: "GS",
    numeric_code: "239",
    phone_code: "500",
    capital: "Grytviken",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "\xA3",
    tld: ".gs",
    native: "South Georgia",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "South Georgia or South Sandwich Islands",
    timezones: [
      {
        zoneName: "Atlantic/South_Georgia",
        gmtOffset: -7200,
        gmtOffsetName: "UTC-02:00",
        abbreviation: "GST",
        tzName: "South Georgia and the South Sandwich Islands Time"
      }
    ],
    translations: {
      kr: "\uC0AC\uC6B0\uC2A4\uC870\uC9C0\uC544",
      "pt-BR": "Ilhas Ge\xF3rgias do Sul e Sandwich do Sul",
      pt: "Ilhas Ge\xF3rgia do Sul e Sandu\xEDche do Sul",
      nl: "Zuid-Georgia en Zuidelijke Sandwicheilanden",
      hr: "Ju\u017Ena Georgija i oto\u010Dje Ju\u017Eni Sandwich",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u062C\u0648\u0631\u062C\u06CC\u0627\u06CC \u062C\u0646\u0648\u0628\u06CC \u0648 \u0633\u0627\u0646\u062F\u0648\u06CC\u0686 \u062C\u0646\u0648\u0628\u06CC",
      de: "S\xFCdgeorgien und die S\xFCdlichen Sandwichinseln",
      es: "Islas Georgias del Sur y Sandwich del Sur",
      fr: "G\xE9orgie du Sud-et-les \xCEles Sandwich du Sud",
      ja: "\u30B5\u30A6\u30B9\u30B8\u30E7\u30FC\u30B8\u30A2\u30FB\u30B5\u30A6\u30B9\u30B5\u30F3\u30C9\u30A6\u30A3\u30C3\u30C1\u8AF8\u5CF6",
      it: "Georgia del Sud e Isole Sandwich Meridionali",
      cn: "\u5357\u4E54\u6CBB\u4E9A",
      tr: "G\xFCney Georgia"
    },
    latitude: "-54.50000000",
    longitude: "-37.00000000",
    emoji: "\u{1F1EC}\u{1F1F8}",
    emojiU: "U+1F1EC U+1F1F8"
  },
  {
    id: 116,
    name: "South Korea",
    iso3: "KOR",
    iso2: "KR",
    numeric_code: "410",
    phone_code: "82",
    capital: "Seoul",
    currency: "KRW",
    currency_name: "Won",
    currency_symbol: "\u20A9",
    tld: ".kr",
    native: "\uB300\uD55C\uBBFC\uAD6D",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "South Korean",
    timezones: [
      {
        zoneName: "Asia/Seoul",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "KST",
        tzName: "Korea Standard Time"
      }
    ],
    translations: {
      kr: "\uB300\uD55C\uBBFC\uAD6D",
      "pt-BR": "Coreia do Sul",
      pt: "Coreia do Sul",
      nl: "Zuid-Korea",
      hr: "Ju\u017Ena Koreja",
      fa: "\u06A9\u0631\u0647 \u0634\u0645\u0627\u0644\u06CC",
      de: "S\xFCdkorea",
      es: "Corea del Sur",
      fr: "Cor\xE9e du Sud",
      ja: "\u5927\u97D3\u6C11\u56FD",
      it: "Corea del Sud",
      cn: "\u97E9\u56FD",
      tr: "G\xFCney Kore"
    },
    latitude: "37.00000000",
    longitude: "127.50000000",
    emoji: "\u{1F1F0}\u{1F1F7}",
    emojiU: "U+1F1F0 U+1F1F7"
  },
  {
    id: 206,
    name: "South Sudan",
    iso3: "SSD",
    iso2: "SS",
    numeric_code: "728",
    phone_code: "211",
    capital: "Juba",
    currency: "SSP",
    currency_name: "South Sudanese pound",
    currency_symbol: "\xA3",
    tld: ".ss",
    native: "South Sudan",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "South Sudanese",
    timezones: [
      {
        zoneName: "Africa/Juba",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uB0A8\uC218\uB2E8",
      "pt-BR": "Sud\xE3o do Sul",
      pt: "Sud\xE3o do Sul",
      nl: "Zuid-Soedan",
      hr: "Ju\u017Eni Sudan",
      fa: "\u0633\u0648\u062F\u0627\u0646 \u062C\u0646\u0648\u0628\u06CC",
      de: "S\xFCdsudan",
      es: "Sud\xE1n del Sur",
      fr: "Soudan du Sud",
      ja: "\u5357\u30B9\u30FC\u30C0\u30F3",
      it: "Sudan del sud",
      cn: "\u5357\u82CF\u4E39",
      tr: "G\xFCney Sudan"
    },
    latitude: "7.00000000",
    longitude: "30.00000000",
    emoji: "\u{1F1F8}\u{1F1F8}",
    emojiU: "U+1F1F8 U+1F1F8"
  },
  {
    id: 207,
    name: "Spain",
    iso3: "ESP",
    iso2: "ES",
    numeric_code: "724",
    phone_code: "34",
    capital: "Madrid",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".es",
    native: "Espa\xF1a",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Spanish",
    timezones: [
      {
        zoneName: "Africa/Ceuta",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      },
      {
        zoneName: "Atlantic/Canary",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "WET",
        tzName: "Western European Time"
      },
      {
        zoneName: "Europe/Madrid",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC2A4\uD398\uC778",
      "pt-BR": "Espanha",
      pt: "Espanha",
      nl: "Spanje",
      hr: "\u0160panjolska",
      fa: "\u0627\u0633\u067E\u0627\u0646\u06CC\u0627",
      de: "Spanien",
      es: "Espa\xF1a",
      fr: "Espagne",
      ja: "\u30B9\u30DA\u30A4\u30F3",
      it: "Spagna",
      cn: "\u897F\u73ED\u7259",
      tr: "\u0130spanya"
    },
    latitude: "40.00000000",
    longitude: "-4.00000000",
    emoji: "\u{1F1EA}\u{1F1F8}",
    emojiU: "U+1F1EA U+1F1F8"
  },
  {
    id: 208,
    name: "Sri Lanka",
    iso3: "LKA",
    iso2: "LK",
    numeric_code: "144",
    phone_code: "94",
    capital: "Colombo",
    currency: "LKR",
    currency_name: "Sri Lankan rupee",
    currency_symbol: "Rs",
    tld: ".lk",
    native: "\u015Br\u012B la\u1E43k\u0101va",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Sri Lankan",
    timezones: [
      {
        zoneName: "Asia/Colombo",
        gmtOffset: 19800,
        gmtOffsetName: "UTC+05:30",
        abbreviation: "IST",
        tzName: "Indian Standard Time"
      }
    ],
    translations: {
      kr: "\uC2A4\uB9AC\uB791\uCE74",
      "pt-BR": "Sri Lanka",
      pt: "Sri Lanka",
      nl: "Sri Lanka",
      hr: "\u0160ri Lanka",
      fa: "\u0633\u0631\u06CC\u200C\u0644\u0627\u0646\u06A9\u0627",
      de: "Sri Lanka",
      es: "Sri Lanka",
      fr: "Sri Lanka",
      ja: "\u30B9\u30EA\u30E9\u30F3\u30AB",
      it: "Sri Lanka",
      cn: "\u65AF\u91CC\u5170\u5361",
      tr: "Sri Lanka"
    },
    latitude: "7.00000000",
    longitude: "81.00000000",
    emoji: "\u{1F1F1}\u{1F1F0}",
    emojiU: "U+1F1F1 U+1F1F0"
  },
  {
    id: 209,
    name: "Sudan",
    iso3: "SDN",
    iso2: "SD",
    numeric_code: "729",
    phone_code: "249",
    capital: "Khartoum",
    currency: "SDG",
    currency_name: "Sudanese pound",
    currency_symbol: ".\u0633.\u062C",
    tld: ".sd",
    native: "\u0627\u0644\u0633\u0648\u062F\u0627\u0646",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Sudanese",
    timezones: [
      {
        zoneName: "Africa/Khartoum",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EAT",
        tzName: "Eastern African Time"
      }
    ],
    translations: {
      kr: "\uC218\uB2E8",
      "pt-BR": "Sud\xE3o",
      pt: "Sud\xE3o",
      nl: "Soedan",
      hr: "Sudan",
      fa: "\u0633\u0648\u062F\u0627\u0646",
      de: "Sudan",
      es: "Sud\xE1n",
      fr: "Soudan",
      ja: "\u30B9\u30FC\u30C0\u30F3",
      it: "Sudan",
      cn: "\u82CF\u4E39",
      tr: "Sudan"
    },
    latitude: "15.00000000",
    longitude: "30.00000000",
    emoji: "\u{1F1F8}\u{1F1E9}",
    emojiU: "U+1F1F8 U+1F1E9"
  },
  {
    id: 210,
    name: "Suriname",
    iso3: "SUR",
    iso2: "SR",
    numeric_code: "740",
    phone_code: "597",
    capital: "Paramaribo",
    currency: "SRD",
    currency_name: "Surinamese dollar",
    currency_symbol: "$",
    tld: ".sr",
    native: "Suriname",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Surinamese",
    timezones: [
      {
        zoneName: "America/Paramaribo",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "SRT",
        tzName: "Suriname Time"
      }
    ],
    translations: {
      kr: "\uC218\uB9AC\uB0A8",
      "pt-BR": "Suriname",
      pt: "Suriname",
      nl: "Suriname",
      hr: "Surinam",
      fa: "\u0633\u0648\u0631\u06CC\u0646\u0627\u0645",
      de: "Suriname",
      es: "Surinam",
      fr: "Surinam",
      ja: "\u30B9\u30EA\u30CA\u30E0",
      it: "Suriname",
      cn: "\u82CF\u91CC\u5357",
      tr: "Surinam"
    },
    latitude: "4.00000000",
    longitude: "-56.00000000",
    emoji: "\u{1F1F8}\u{1F1F7}",
    emojiU: "U+1F1F8 U+1F1F7"
  },
  {
    id: 211,
    name: "Svalbard And Jan Mayen Islands",
    iso3: "SJM",
    iso2: "SJ",
    numeric_code: "744",
    phone_code: "47",
    capital: "Longyearbyen",
    currency: "NOK",
    currency_name: "Norwegian Krone",
    currency_symbol: "kr",
    tld: ".sj",
    native: "Svalbard og Jan Mayen",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Svalbard",
    timezones: [
      {
        zoneName: "Arctic/Longyearbyen",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC2A4\uBC1C\uBC14\uB974 \uC580\uB9C8\uC60C \uC81C\uB3C4",
      "pt-BR": "Svalbard",
      pt: "Svalbard",
      nl: "Svalbard en Jan Mayen",
      hr: "Svalbard i Jan Mayen",
      fa: "\u0633\u0648\u0627\u0644\u0628\u0627\u0631\u062F \u0648 \u06CC\u0627\u0646 \u0645\u0627\u06CC\u0646",
      de: "Svalbard und Jan Mayen",
      es: "Islas Svalbard y Jan Mayen",
      fr: "Svalbard et Jan Mayen",
      ja: "\u30B9\u30F4\u30A1\u30FC\u30EB\u30D0\u30EB\u8AF8\u5CF6\u304A\u3088\u3073\u30E4\u30F3\u30DE\u30A4\u30A8\u30F3\u5CF6",
      it: "Svalbard e Jan Mayen",
      cn: "\u65AF\u74E6\u5C14\u5DF4\u548C\u626C\u9A6C\u5EF6\u7FA4\u5C9B",
      tr: "Svalbard Ve Jan Mayen"
    },
    latitude: "78.00000000",
    longitude: "20.00000000",
    emoji: "\u{1F1F8}\u{1F1EF}",
    emojiU: "U+1F1F8 U+1F1EF"
  },
  {
    id: 212,
    name: "Swaziland",
    iso3: "SWZ",
    iso2: "SZ",
    numeric_code: "748",
    phone_code: "268",
    capital: "Mbabane",
    currency: "SZL",
    currency_name: "Lilangeni",
    currency_symbol: "E",
    tld: ".sz",
    native: "Swaziland",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "Swazi",
    timezones: [
      {
        zoneName: "Africa/Mbabane",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "SAST",
        tzName: "South African Standard Time"
      }
    ],
    translations: {
      kr: "\uC5D0\uC2A4\uC640\uD2F0\uB2C8",
      "pt-BR": "Suazil\xE2ndia",
      pt: "Suazil\xE2ndia",
      nl: "Swaziland",
      hr: "Svazi",
      fa: "\u0633\u0648\u0627\u0632\u06CC\u0644\u0646\u062F",
      de: "Swasiland",
      es: "Suazilandia",
      fr: "Swaziland",
      ja: "\u30B9\u30EF\u30B8\u30E9\u30F3\u30C9",
      it: "Swaziland",
      cn: "\u65AF\u5A01\u58EB\u5170",
      tr: "Esvatini"
    },
    latitude: "-26.50000000",
    longitude: "31.50000000",
    emoji: "\u{1F1F8}\u{1F1FF}",
    emojiU: "U+1F1F8 U+1F1FF"
  },
  {
    id: 213,
    name: "Sweden",
    iso3: "SWE",
    iso2: "SE",
    numeric_code: "752",
    phone_code: "46",
    capital: "Stockholm",
    currency: "SEK",
    currency_name: "Swedish krona",
    currency_symbol: "kr",
    tld: ".se",
    native: "Sverige",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Swedish",
    timezones: [
      {
        zoneName: "Europe/Stockholm",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC2A4\uC6E8\uB374",
      "pt-BR": "Su\xE9cia",
      pt: "Su\xE9cia",
      nl: "Zweden",
      hr: "\u0160vedska",
      fa: "\u0633\u0648\u0626\u062F",
      de: "Schweden",
      es: "Suecia",
      fr: "Su\xE8de",
      ja: "\u30B9\u30A6\u30A7\u30FC\u30C7\u30F3",
      it: "Svezia",
      cn: "\u745E\u5178",
      tr: "\u0130sve\xE7"
    },
    latitude: "62.00000000",
    longitude: "15.00000000",
    emoji: "\u{1F1F8}\u{1F1EA}",
    emojiU: "U+1F1F8 U+1F1EA"
  },
  {
    id: 214,
    name: "Switzerland",
    iso3: "CHE",
    iso2: "CH",
    numeric_code: "756",
    phone_code: "41",
    capital: "Bern",
    currency: "CHF",
    currency_name: "Swiss franc",
    currency_symbol: "CHf",
    tld: ".ch",
    native: "Schweiz",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Swiss",
    timezones: [
      {
        zoneName: "Europe/Zurich",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uC2A4\uC704\uC2A4",
      "pt-BR": "Su\xED\xE7a",
      pt: "Su\xED\xE7a",
      nl: "Zwitserland",
      hr: "\u0160vicarska",
      fa: "\u0633\u0648\u0626\u06CC\u0633",
      de: "Schweiz",
      es: "Suiza",
      fr: "Suisse",
      ja: "\u30B9\u30A4\u30B9",
      it: "Svizzera",
      cn: "\u745E\u58EB",
      tr: "\u0130svi\xE7re"
    },
    latitude: "47.00000000",
    longitude: "8.00000000",
    emoji: "\u{1F1E8}\u{1F1ED}",
    emojiU: "U+1F1E8 U+1F1ED"
  },
  {
    id: 215,
    name: "Syria",
    iso3: "SYR",
    iso2: "SY",
    numeric_code: "760",
    phone_code: "963",
    capital: "Damascus",
    currency: "SYP",
    currency_name: "Syrian pound",
    currency_symbol: "LS",
    tld: ".sy",
    native: "\u0633\u0648\u0631\u064A\u0627",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Syrian",
    timezones: [
      {
        zoneName: "Asia/Damascus",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uC2DC\uB9AC\uC544",
      "pt-BR": "S\xEDria",
      pt: "S\xEDria",
      nl: "Syri\xEB",
      hr: "Sirija",
      fa: "\u0633\u0648\u0631\u06CC\u0647",
      de: "Syrien",
      es: "Siria",
      fr: "Syrie",
      ja: "\u30B7\u30EA\u30A2\u30FB\u30A2\u30E9\u30D6\u5171\u548C\u56FD",
      it: "Siria",
      cn: "\u53D9\u5229\u4E9A",
      tr: "Suriye"
    },
    latitude: "35.00000000",
    longitude: "38.00000000",
    emoji: "\u{1F1F8}\u{1F1FE}",
    emojiU: "U+1F1F8 U+1F1FE"
  },
  {
    id: 216,
    name: "Taiwan",
    iso3: "TWN",
    iso2: "TW",
    numeric_code: "158",
    phone_code: "886",
    capital: "Taipei",
    currency: "TWD",
    currency_name: "New Taiwan dollar",
    currency_symbol: "$",
    tld: ".tw",
    native: "\u81FA\u7063",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Chinese, Taiwanese",
    timezones: [
      {
        zoneName: "Asia/Taipei",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CST",
        tzName: "China Standard Time"
      }
    ],
    translations: {
      kr: "\uB300\uB9CC",
      "pt-BR": "Taiwan",
      pt: "Taiwan",
      nl: "Taiwan",
      hr: "Tajvan",
      fa: "\u062A\u0627\u06CC\u0648\u0627\u0646",
      de: "Taiwan",
      es: "Taiw\xE1n",
      fr: "Ta\xEFwan",
      ja: "\u53F0\u6E7E\uFF08\u4E2D\u83EF\u6C11\u56FD\uFF09",
      it: "Taiwan",
      cn: "\u4E2D\u56FD\u53F0\u6E7E",
      tr: "Tayvan"
    },
    latitude: "23.50000000",
    longitude: "121.00000000",
    emoji: "\u{1F1F9}\u{1F1FC}",
    emojiU: "U+1F1F9 U+1F1FC"
  },
  {
    id: 217,
    name: "Tajikistan",
    iso3: "TJK",
    iso2: "TJ",
    numeric_code: "762",
    phone_code: "992",
    capital: "Dushanbe",
    currency: "TJS",
    currency_name: "Tajikistani somoni",
    currency_symbol: "SM",
    tld: ".tj",
    native: "\u0422\u043E\u04B7\u0438\u043A\u0438\u0441\u0442\u043E\u043D",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Tajikistani",
    timezones: [
      {
        zoneName: "Asia/Dushanbe",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TJT",
        tzName: "Tajikistan Time"
      }
    ],
    translations: {
      kr: "\uD0C0\uC9C0\uD0A4\uC2A4\uD0C4",
      "pt-BR": "Tajiquist\xE3o",
      pt: "Tajiquist\xE3o",
      nl: "Tadzjikistan",
      hr: "Ta\u0111ikistan",
      fa: "\u062A\u0627\u062C\u06CC\u06A9\u0633\u062A\u0627\u0646",
      de: "Tadschikistan",
      es: "Tayikist\xE1n",
      fr: "Tadjikistan",
      ja: "\u30BF\u30B8\u30AD\u30B9\u30BF\u30F3",
      it: "Tagikistan",
      cn: "\u5854\u5409\u514B\u65AF\u5766",
      tr: "Tacikistan"
    },
    latitude: "39.00000000",
    longitude: "71.00000000",
    emoji: "\u{1F1F9}\u{1F1EF}",
    emojiU: "U+1F1F9 U+1F1EF"
  },
  {
    id: 218,
    name: "Tanzania",
    iso3: "TZA",
    iso2: "TZ",
    numeric_code: "834",
    phone_code: "255",
    capital: "Dodoma",
    currency: "TZS",
    currency_name: "Tanzanian shilling",
    currency_symbol: "TSh",
    tld: ".tz",
    native: "Tanzania",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Tanzanian",
    timezones: [
      {
        zoneName: "Africa/Dar_es_Salaam",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uD0C4\uC790\uB2C8\uC544",
      "pt-BR": "Tanz\xE2nia",
      pt: "Tanz\xE2nia",
      nl: "Tanzania",
      hr: "Tanzanija",
      fa: "\u062A\u0627\u0646\u0632\u0627\u0646\u06CC\u0627",
      de: "Tansania",
      es: "Tanzania",
      fr: "Tanzanie",
      ja: "\u30BF\u30F3\u30B6\u30CB\u30A2",
      it: "Tanzania",
      cn: "\u5766\u6851\u5C3C\u4E9A",
      tr: "Tanzanya"
    },
    latitude: "-6.00000000",
    longitude: "35.00000000",
    emoji: "\u{1F1F9}\u{1F1FF}",
    emojiU: "U+1F1F9 U+1F1FF"
  },
  {
    id: 219,
    name: "Thailand",
    iso3: "THA",
    iso2: "TH",
    numeric_code: "764",
    phone_code: "66",
    capital: "Bangkok",
    currency: "THB",
    currency_name: "Thai baht",
    currency_symbol: "\u0E3F",
    tld: ".th",
    native: "\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28\u0E44\u0E17\u0E22",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Thai",
    timezones: [
      {
        zoneName: "Asia/Bangkok",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time"
      }
    ],
    translations: {
      kr: "\uD0DC\uAD6D",
      "pt-BR": "Tail\xE2ndia",
      pt: "Tail\xE2ndia",
      nl: "Thailand",
      hr: "Tajland",
      fa: "\u062A\u0627\u06CC\u0644\u0646\u062F",
      de: "Thailand",
      es: "Tailandia",
      fr: "Tha\xEFlande",
      ja: "\u30BF\u30A4",
      it: "Tailandia",
      cn: "\u6CF0\u56FD",
      tr: "Tayland"
    },
    latitude: "15.00000000",
    longitude: "100.00000000",
    emoji: "\u{1F1F9}\u{1F1ED}",
    emojiU: "U+1F1F9 U+1F1ED"
  },
  {
    id: 17,
    name: "The Bahamas",
    iso3: "BHS",
    iso2: "BS",
    numeric_code: "044",
    phone_code: "1",
    capital: "Nassau",
    currency: "BSD",
    currency_name: "Bahamian dollar",
    currency_symbol: "B$",
    tld: ".bs",
    native: "Bahamas",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Bahamian",
    timezones: [
      {
        zoneName: "America/Nassau",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America)"
      }
    ],
    translations: {
      kr: "\uBC14\uD558\uB9C8",
      "pt-BR": "Bahamas",
      pt: "Baamas",
      nl: "Bahama\u2019s",
      hr: "Bahami",
      fa: "\u0628\u0627\u0647\u0627\u0645\u0627",
      de: "Bahamas",
      es: "Bahamas",
      fr: "Bahamas",
      ja: "\u30D0\u30CF\u30DE",
      it: "Bahamas",
      cn: "\u5DF4\u54C8\u9A6C",
      tr: "Bahamalar"
    },
    latitude: "24.25000000",
    longitude: "-76.00000000",
    emoji: "\u{1F1E7}\u{1F1F8}",
    emojiU: "U+1F1E7 U+1F1F8"
  },
  {
    id: 220,
    name: "Togo",
    iso3: "TGO",
    iso2: "TG",
    numeric_code: "768",
    phone_code: "228",
    capital: "Lome",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".tg",
    native: "Togo",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Togolese",
    timezones: [
      {
        zoneName: "Africa/Lome",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uD1A0\uACE0",
      "pt-BR": "Togo",
      pt: "Togo",
      nl: "Togo",
      hr: "Togo",
      fa: "\u062A\u0648\u06AF\u0648",
      de: "Togo",
      es: "Togo",
      fr: "Togo",
      ja: "\u30C8\u30FC\u30B4",
      it: "Togo",
      cn: "\u591A\u54E5",
      tr: "Togo"
    },
    latitude: "8.00000000",
    longitude: "1.16666666",
    emoji: "\u{1F1F9}\u{1F1EC}",
    emojiU: "U+1F1F9 U+1F1EC"
  },
  {
    id: 221,
    name: "Tokelau",
    iso3: "TKL",
    iso2: "TK",
    numeric_code: "772",
    phone_code: "690",
    capital: "",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".tk",
    native: "Tokelau",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Tokelauan",
    timezones: [
      {
        zoneName: "Pacific/Fakaofo",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "TKT",
        tzName: "Tokelau Time"
      }
    ],
    translations: {
      kr: "\uD1A0\uCF08\uB77C\uC6B0",
      "pt-BR": "Tokelau",
      pt: "Toquelau",
      nl: "Tokelau",
      hr: "Tokelau",
      fa: "\u062A\u0648\u06A9\u0644\u0627\u0626\u0648",
      de: "Tokelau",
      es: "Islas Tokelau",
      fr: "Tokelau",
      ja: "\u30C8\u30B1\u30E9\u30A6",
      it: "Isole Tokelau",
      cn: "\u6258\u514B\u52B3",
      tr: "Tokelau"
    },
    latitude: "-9.00000000",
    longitude: "-172.00000000",
    emoji: "\u{1F1F9}\u{1F1F0}",
    emojiU: "U+1F1F9 U+1F1F0"
  },
  {
    id: 222,
    name: "Tonga",
    iso3: "TON",
    iso2: "TO",
    numeric_code: "776",
    phone_code: "676",
    capital: "Nuku'alofa",
    currency: "TOP",
    currency_name: "Tongan pa\u02BBanga",
    currency_symbol: "$",
    tld: ".to",
    native: "Tonga",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Tongan",
    timezones: [
      {
        zoneName: "Pacific/Tongatapu",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "TOT",
        tzName: "Tonga Time"
      }
    ],
    translations: {
      kr: "\uD1B5\uAC00",
      "pt-BR": "Tonga",
      pt: "Tonga",
      nl: "Tonga",
      hr: "Tonga",
      fa: "\u062A\u0648\u0646\u06AF\u0627",
      de: "Tonga",
      es: "Tonga",
      fr: "Tonga",
      ja: "\u30C8\u30F3\u30AC",
      it: "Tonga",
      cn: "\u6C64\u52A0",
      tr: "Tonga"
    },
    latitude: "-20.00000000",
    longitude: "-175.00000000",
    emoji: "\u{1F1F9}\u{1F1F4}",
    emojiU: "U+1F1F9 U+1F1F4"
  },
  {
    id: 223,
    name: "Trinidad And Tobago",
    iso3: "TTO",
    iso2: "TT",
    numeric_code: "780",
    phone_code: "1",
    capital: "Port of Spain",
    currency: "TTD",
    currency_name: "Trinidad and Tobago dollar",
    currency_symbol: "$",
    tld: ".tt",
    native: "Trinidad and Tobago",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Trinidadian or Tobagonian",
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uD2B8\uB9AC\uB2C8\uB2E4\uB4DC \uD1A0\uBC14\uACE0",
      "pt-BR": "Trinidad e Tobago",
      pt: "Trindade e Tobago",
      nl: "Trinidad en Tobago",
      hr: "Trinidad i Tobago",
      fa: "\u062A\u0631\u06CC\u0646\u06CC\u062F\u0627\u062F \u0648 \u062A\u0648\u0628\u0627\u06AF\u0648",
      de: "Trinidad und Tobago",
      es: "Trinidad y Tobago",
      fr: "Trinit\xE9 et Tobago",
      ja: "\u30C8\u30EA\u30CB\u30C0\u30FC\u30C9\u30FB\u30C8\u30D0\u30B4",
      it: "Trinidad e Tobago",
      cn: "\u7279\u7ACB\u5C3C\u8FBE\u548C\u591A\u5DF4\u54E5",
      tr: "Trinidad Ve Tobago"
    },
    latitude: "11.00000000",
    longitude: "-61.00000000",
    emoji: "\u{1F1F9}\u{1F1F9}",
    emojiU: "U+1F1F9 U+1F1F9"
  },
  {
    id: 224,
    name: "Tunisia",
    iso3: "TUN",
    iso2: "TN",
    numeric_code: "788",
    phone_code: "216",
    capital: "Tunis",
    currency: "TND",
    currency_name: "Tunisian dinar",
    currency_symbol: "\u062A.\u062F",
    tld: ".tn",
    native: "\u062A\u0648\u0646\u0633",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Tunisian",
    timezones: [
      {
        zoneName: "Africa/Tunis",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uD280\uB2C8\uC9C0",
      "pt-BR": "Tun\xEDsia",
      pt: "Tun\xEDsia",
      nl: "Tunesi\xEB",
      hr: "Tunis",
      fa: "\u062A\u0648\u0646\u0633",
      de: "Tunesien",
      es: "T\xFAnez",
      fr: "Tunisie",
      ja: "\u30C1\u30E5\u30CB\u30B8\u30A2",
      it: "Tunisia",
      cn: "\u7A81\u5C3C\u65AF",
      tr: "Tunus"
    },
    latitude: "34.00000000",
    longitude: "9.00000000",
    emoji: "\u{1F1F9}\u{1F1F3}",
    emojiU: "U+1F1F9 U+1F1F3"
  },
  {
    id: 225,
    name: "Turkey",
    iso3: "TUR",
    iso2: "TR",
    numeric_code: "792",
    phone_code: "90",
    capital: "Ankara",
    currency: "TRY",
    currency_name: "Turkish lira",
    currency_symbol: "\u20BA",
    tld: ".tr",
    native: "T\xFCrkiye",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Turkish",
    timezones: [
      {
        zoneName: "Europe/Istanbul",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uD130\uD0A4",
      "pt-BR": "Turquia",
      pt: "Turquia",
      nl: "Turkije",
      hr: "Turska",
      fa: "\u062A\u0631\u06A9\u06CC\u0647",
      de: "T\xFCrkei",
      es: "Turqu\xEDa",
      fr: "Turquie",
      ja: "\u30C8\u30EB\u30B3",
      it: "Turchia",
      cn: "\u571F\u8033\u5176",
      tr: "T\xFCrkiye"
    },
    latitude: "39.00000000",
    longitude: "35.00000000",
    emoji: "\u{1F1F9}\u{1F1F7}",
    emojiU: "U+1F1F9 U+1F1F7"
  },
  {
    id: 226,
    name: "Turkmenistan",
    iso3: "TKM",
    iso2: "TM",
    numeric_code: "795",
    phone_code: "993",
    capital: "Ashgabat",
    currency: "TMT",
    currency_name: "Turkmenistan manat",
    currency_symbol: "T",
    tld: ".tm",
    native: "T\xFCrkmenistan",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Turkmen",
    timezones: [
      {
        zoneName: "Asia/Ashgabat",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TMT",
        tzName: "Turkmenistan Time"
      }
    ],
    translations: {
      kr: "\uD22C\uB974\uD06C\uBA54\uB2C8\uC2A4\uD0C4",
      "pt-BR": "Turcomenist\xE3o",
      pt: "Turquemenist\xE3o",
      nl: "Turkmenistan",
      hr: "Turkmenistan",
      fa: "\u062A\u0631\u06A9\u0645\u0646\u0633\u062A\u0627\u0646",
      de: "Turkmenistan",
      es: "Turkmenist\xE1n",
      fr: "Turkm\xE9nistan",
      ja: "\u30C8\u30EB\u30AF\u30E1\u30CB\u30B9\u30BF\u30F3",
      it: "Turkmenistan",
      cn: "\u571F\u5E93\u66FC\u65AF\u5766",
      tr: "T\xFCrkmenistan"
    },
    latitude: "40.00000000",
    longitude: "60.00000000",
    emoji: "\u{1F1F9}\u{1F1F2}",
    emojiU: "U+1F1F9 U+1F1F2"
  },
  {
    id: 227,
    name: "Turks And Caicos Islands",
    iso3: "TCA",
    iso2: "TC",
    numeric_code: "796",
    phone_code: "1",
    capital: "Cockburn Town",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".tc",
    native: "Turks and Caicos Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Turks and Caicos Island",
    timezones: [
      {
        zoneName: "America/Grand_Turk",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      }
    ],
    translations: {
      kr: "\uD130\uD06C\uC2A4 \uCF00\uC774\uCEE4\uC2A4 \uC81C\uB3C4",
      "pt-BR": "Ilhas Turcas e Caicos",
      pt: "Ilhas Turcas e Caicos",
      nl: "Turks- en Caicoseilanden",
      hr: "Otoci Turks i Caicos",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u062A\u0648\u0631\u06A9\u0633 \u0648 \u06A9\u0627\u06CC\u06A9\u0648\u0633",
      de: "Turks- und Caicosinseln",
      es: "Islas Turks y Caicos",
      fr: "\xCEles Turques-et-Ca\xEFques",
      ja: "\u30BF\u30FC\u30AF\u30B9\u30FB\u30AB\u30A4\u30B3\u30B9\u8AF8\u5CF6",
      it: "Isole Turks e Caicos",
      cn: "\u7279\u514B\u65AF\u548C\u51EF\u79D1\u65AF\u7FA4\u5C9B",
      tr: "Turks Ve Caicos Adalari"
    },
    latitude: "21.75000000",
    longitude: "-71.58333333",
    emoji: "\u{1F1F9}\u{1F1E8}",
    emojiU: "U+1F1F9 U+1F1E8"
  },
  {
    id: 228,
    name: "Tuvalu",
    iso3: "TUV",
    iso2: "TV",
    numeric_code: "798",
    phone_code: "688",
    capital: "Funafuti",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".tv",
    native: "Tuvalu",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Tuvaluan",
    timezones: [
      {
        zoneName: "Pacific/Funafuti",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "TVT",
        tzName: "Tuvalu Time"
      }
    ],
    translations: {
      kr: "\uD22C\uBC1C\uB8E8",
      "pt-BR": "Tuvalu",
      pt: "Tuvalu",
      nl: "Tuvalu",
      hr: "Tuvalu",
      fa: "\u062A\u0648\u0648\u0627\u0644\u0648",
      de: "Tuvalu",
      es: "Tuvalu",
      fr: "Tuvalu",
      ja: "\u30C4\u30D0\u30EB",
      it: "Tuvalu",
      cn: "\u56FE\u74E6\u5362",
      tr: "Tuvalu"
    },
    latitude: "-8.00000000",
    longitude: "178.00000000",
    emoji: "\u{1F1F9}\u{1F1FB}",
    emojiU: "U+1F1F9 U+1F1FB"
  },
  {
    id: 229,
    name: "Uganda",
    iso3: "UGA",
    iso2: "UG",
    numeric_code: "800",
    phone_code: "256",
    capital: "Kampala",
    currency: "UGX",
    currency_name: "Ugandan shilling",
    currency_symbol: "USh",
    tld: ".ug",
    native: "Uganda",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Ugandan",
    timezones: [
      {
        zoneName: "Africa/Kampala",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time"
      }
    ],
    translations: {
      kr: "\uC6B0\uAC04\uB2E4",
      "pt-BR": "Uganda",
      pt: "Uganda",
      nl: "Oeganda",
      hr: "Uganda",
      fa: "\u0627\u0648\u06AF\u0627\u0646\u062F\u0627",
      de: "Uganda",
      es: "Uganda",
      fr: "Uganda",
      ja: "\u30A6\u30AC\u30F3\u30C0",
      it: "Uganda",
      cn: "\u4E4C\u5E72\u8FBE",
      tr: "Uganda"
    },
    latitude: "1.00000000",
    longitude: "32.00000000",
    emoji: "\u{1F1FA}\u{1F1EC}",
    emojiU: "U+1F1FA U+1F1EC"
  },
  {
    id: 230,
    name: "Ukraine",
    iso3: "UKR",
    iso2: "UA",
    numeric_code: "804",
    phone_code: "380",
    capital: "Kyiv",
    currency: "UAH",
    currency_name: "Ukrainian hryvnia",
    currency_symbol: "\u20B4",
    tld: ".ua",
    native: "\u0423\u043A\u0440\u0430\u0457\u043D\u0430",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Ukrainian",
    timezones: [
      {
        zoneName: "Europe/Kiev",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      },
      {
        zoneName: "Europe/Simferopol",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time"
      },
      {
        zoneName: "Europe/Uzhgorod",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      },
      {
        zoneName: "Europe/Zaporozhye",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time"
      }
    ],
    translations: {
      kr: "\uC6B0\uD06C\uB77C\uC774\uB098",
      "pt-BR": "Ucr\xE2nia",
      pt: "Ucr\xE2nia",
      nl: "Oekra\xEFne",
      hr: "Ukrajina",
      fa: "\u0648\u06A9\u0631\u0627\u06CC\u0646",
      de: "Ukraine",
      es: "Ucrania",
      fr: "Ukraine",
      ja: "\u30A6\u30AF\u30E9\u30A4\u30CA",
      it: "Ucraina",
      cn: "\u4E4C\u514B\u5170",
      tr: "Ukrayna"
    },
    latitude: "49.00000000",
    longitude: "32.00000000",
    emoji: "\u{1F1FA}\u{1F1E6}",
    emojiU: "U+1F1FA U+1F1E6"
  },
  {
    id: 231,
    name: "United Arab Emirates",
    iso3: "ARE",
    iso2: "AE",
    numeric_code: "784",
    phone_code: "971",
    capital: "Abu Dhabi",
    currency: "AED",
    currency_name: "United Arab Emirates dirham",
    currency_symbol: "\u0625.\u062F",
    tld: ".ae",
    native: "\u062F\u0648\u0644\u0629 \u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062A \u0627\u0644\u0639\u0631\u0628\u064A\u0629 \u0627\u0644\u0645\u062A\u062D\u062F\u0629",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Emirati, Emirian, Emiri",
    timezones: [
      {
        zoneName: "Asia/Dubai",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "GST",
        tzName: "Gulf Standard Time"
      }
    ],
    translations: {
      kr: "\uC544\uB78D\uC5D0\uBBF8\uB9AC\uD2B8",
      "pt-BR": "Emirados \xE1rabes Unidos",
      pt: "Emirados \xE1rabes Unidos",
      nl: "Verenigde Arabische Emiraten",
      hr: "Ujedinjeni Arapski Emirati",
      fa: "\u0627\u0645\u0627\u0631\u0627\u062A \u0645\u062A\u062D\u062F\u0647 \u0639\u0631\u0628\u06CC",
      de: "Vereinigte Arabische Emirate",
      es: "Emiratos \xC1rabes Unidos",
      fr: "\xC9mirats arabes unis",
      ja: "\u30A2\u30E9\u30D6\u9996\u9577\u56FD\u9023\u90A6",
      it: "Emirati Arabi Uniti",
      cn: "\u963F\u62C9\u4F2F\u8054\u5408\u914B\u957F\u56FD",
      tr: "Birle\u015Fik Arap Emirlikleri"
    },
    latitude: "24.00000000",
    longitude: "54.00000000",
    emoji: "\u{1F1E6}\u{1F1EA}",
    emojiU: "U+1F1E6 U+1F1EA"
  },
  {
    id: 232,
    name: "United Kingdom",
    iso3: "GBR",
    iso2: "GB",
    numeric_code: "826",
    phone_code: "44",
    capital: "London",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "\xA3",
    tld: ".uk",
    native: "United Kingdom",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "British, UK",
    timezones: [
      {
        zoneName: "Europe/London",
        gmtOffset: 0,
        gmtOffsetName: "UTC\xB100",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time"
      }
    ],
    translations: {
      kr: "\uC601\uAD6D",
      "pt-BR": "Reino Unido",
      pt: "Reino Unido",
      nl: "Verenigd Koninkrijk",
      hr: "Ujedinjeno Kraljevstvo",
      fa: "\u0628\u0631\u06CC\u062A\u0627\u0646\u06CC\u0627\u06CC \u06A9\u0628\u06CC\u0631 \u0648 \u0627\u06CC\u0631\u0644\u0646\u062F \u0634\u0645\u0627\u0644\u06CC",
      de: "Vereinigtes K\xF6nigreich",
      es: "Reino Unido",
      fr: "Royaume-Uni",
      ja: "\u30A4\u30AE\u30EA\u30B9",
      it: "Regno Unito",
      cn: "\u82F1\u56FD",
      tr: "Birle\u015Fik Krallik"
    },
    latitude: "54.00000000",
    longitude: "-2.00000000",
    emoji: "\u{1F1EC}\u{1F1E7}",
    emojiU: "U+1F1EC U+1F1E7"
  },
  {
    id: 233,
    name: "United States",
    iso3: "USA",
    iso2: "US",
    numeric_code: "840",
    phone_code: "1",
    capital: "Washington",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".us",
    native: "United States",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "American",
    timezones: [
      {
        zoneName: "America/Adak",
        gmtOffset: -36e3,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "HST",
        tzName: "Hawaii\u2013Aleutian Standard Time"
      },
      {
        zoneName: "America/Anchorage",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time"
      },
      {
        zoneName: "America/Boise",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      },
      {
        zoneName: "America/Chicago",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Denver",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      },
      {
        zoneName: "America/Detroit",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Indiana/Indianapolis",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Indiana/Knox",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Indiana/Marengo",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Indiana/Petersburg",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Indiana/Tell_City",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Indiana/Vevay",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Indiana/Vincennes",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Indiana/Winamac",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Juneau",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time"
      },
      {
        zoneName: "America/Kentucky/Louisville",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Kentucky/Monticello",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Los_Angeles",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America"
      },
      {
        zoneName: "America/Menominee",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Metlakatla",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time"
      },
      {
        zoneName: "America/New_York",
        gmtOffset: -18e3,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America"
      },
      {
        zoneName: "America/Nome",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time"
      },
      {
        zoneName: "America/North_Dakota/Beulah",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/North_Dakota/Center",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/North_Dakota/New_Salem",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America"
      },
      {
        zoneName: "America/Phoenix",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America"
      },
      {
        zoneName: "America/Sitka",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time"
      },
      {
        zoneName: "America/Yakutat",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time"
      },
      {
        zoneName: "Pacific/Honolulu",
        gmtOffset: -36e3,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "HST",
        tzName: "Hawaii\u2013Aleutian Standard Time"
      }
    ],
    translations: {
      kr: "\uBBF8\uAD6D",
      "pt-BR": "Estados Unidos",
      pt: "Estados Unidos",
      nl: "Verenigde Staten",
      hr: "Sjedinjene Ameri\u010Dke Dr\u017Eave",
      fa: "\u0627\u06CC\u0627\u0644\u0627\u062A \u0645\u062A\u062D\u062F\u0647 \u0622\u0645\u0631\u06CC\u06A9\u0627",
      de: "Vereinigte Staaten von Amerika",
      es: "Estados Unidos",
      fr: "\xC9tats-Unis",
      ja: "\u30A2\u30E1\u30EA\u30AB\u5408\u8846\u56FD",
      it: "Stati Uniti D'America",
      cn: "\u7F8E\u56FD",
      tr: "Amerika"
    },
    latitude: "38.00000000",
    longitude: "-97.00000000",
    emoji: "\u{1F1FA}\u{1F1F8}",
    emojiU: "U+1F1FA U+1F1F8"
  },
  {
    id: 234,
    name: "United States Minor Outlying Islands",
    iso3: "UMI",
    iso2: "UM",
    numeric_code: "581",
    phone_code: "1",
    capital: "",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".us",
    native: "United States Minor Outlying Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "American",
    timezones: [
      {
        zoneName: "Pacific/Midway",
        gmtOffset: -39600,
        gmtOffsetName: "UTC-11:00",
        abbreviation: "SST",
        tzName: "Samoa Standard Time"
      },
      {
        zoneName: "Pacific/Wake",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "WAKT",
        tzName: "Wake Island Time"
      }
    ],
    translations: {
      kr: "\uBBF8\uAD6D\uB839 \uAD70\uC18C \uC81C\uB3C4",
      "pt-BR": "Ilhas Menores Distantes dos Estados Unidos",
      pt: "Ilhas Menores Distantes dos Estados Unidos",
      nl: "Kleine afgelegen eilanden van de Verenigde Staten",
      hr: "Mali udaljeni otoci SAD-a",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u06A9\u0648\u0686\u06A9 \u062D\u0627\u0634\u06CC\u0647\u200C\u0627\u06CC \u0627\u06CC\u0627\u0644\u0627\u062A \u0645\u062A\u062D\u062F\u0647 \u0622\u0645\u0631\u06CC\u06A9\u0627",
      de: "Kleinere Inselbesitzungen der Vereinigten Staaten",
      es: "Islas Ultramarinas Menores de Estados Unidos",
      fr: "\xCEles mineures \xE9loign\xE9es des \xC9tats-Unis",
      ja: "\u5408\u8846\u56FD\u9818\u6709\u5C0F\u96E2\u5CF6",
      it: "Isole minori esterne degli Stati Uniti d'America",
      cn: "\u7F8E\u56FD\u672C\u571F\u5916\u5C0F\u5C9B\u5C7F",
      tr: "Abd K\xFC\xE7\xFCk Harici Adalari"
    },
    latitude: "0.00000000",
    longitude: "0.00000000",
    emoji: "\u{1F1FA}\u{1F1F2}",
    emojiU: "U+1F1FA U+1F1F2"
  },
  {
    id: 235,
    name: "Uruguay",
    iso3: "URY",
    iso2: "UY",
    numeric_code: "858",
    phone_code: "598",
    capital: "Montevideo",
    currency: "UYU",
    currency_name: "Uruguayan peso",
    currency_symbol: "$",
    tld: ".uy",
    native: "Uruguay",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Uruguayan",
    timezones: [
      {
        zoneName: "America/Montevideo",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "UYT",
        tzName: "Uruguay Standard Time"
      }
    ],
    translations: {
      kr: "\uC6B0\uB8E8\uACFC\uC774",
      "pt-BR": "Uruguai",
      pt: "Uruguai",
      nl: "Uruguay",
      hr: "Urugvaj",
      fa: "\u0627\u0631\u0648\u06AF\u0648\u0626\u0647",
      de: "Uruguay",
      es: "Uruguay",
      fr: "Uruguay",
      ja: "\u30A6\u30EB\u30B0\u30A2\u30A4",
      it: "Uruguay",
      cn: "\u4E4C\u62C9\u572D",
      tr: "Uruguay"
    },
    latitude: "-33.00000000",
    longitude: "-56.00000000",
    emoji: "\u{1F1FA}\u{1F1FE}",
    emojiU: "U+1F1FA U+1F1FE"
  },
  {
    id: 236,
    name: "Uzbekistan",
    iso3: "UZB",
    iso2: "UZ",
    numeric_code: "860",
    phone_code: "998",
    capital: "Tashkent",
    currency: "UZS",
    currency_name: "Uzbekistani so\u02BBm",
    currency_symbol: "\u043B\u0432",
    tld: ".uz",
    native: "O\u2018zbekiston",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Uzbekistani, Uzbek",
    timezones: [
      {
        zoneName: "Asia/Samarkand",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "UZT",
        tzName: "Uzbekistan Time"
      },
      {
        zoneName: "Asia/Tashkent",
        gmtOffset: 18e3,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "UZT",
        tzName: "Uzbekistan Time"
      }
    ],
    translations: {
      kr: "\uC6B0\uC988\uBCA0\uD0A4\uC2A4\uD0C4",
      "pt-BR": "Uzbequist\xE3o",
      pt: "Usbequist\xE3o",
      nl: "Oezbekistan",
      hr: "Uzbekistan",
      fa: "\u0627\u0632\u0628\u06A9\u0633\u062A\u0627\u0646",
      de: "Usbekistan",
      es: "Uzbekist\xE1n",
      fr: "Ouzb\xE9kistan",
      ja: "\u30A6\u30BA\u30D9\u30AD\u30B9\u30BF\u30F3",
      it: "Uzbekistan",
      cn: "\u4E4C\u5179\u522B\u514B\u65AF\u5766",
      tr: "\xD6zbekistan"
    },
    latitude: "41.00000000",
    longitude: "64.00000000",
    emoji: "\u{1F1FA}\u{1F1FF}",
    emojiU: "U+1F1FA U+1F1FF"
  },
  {
    id: 237,
    name: "Vanuatu",
    iso3: "VUT",
    iso2: "VU",
    numeric_code: "548",
    phone_code: "678",
    capital: "Port Vila",
    currency: "VUV",
    currency_name: "Vanuatu vatu",
    currency_symbol: "VT",
    tld: ".vu",
    native: "Vanuatu",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "Ni-Vanuatu, Vanuatuan",
    timezones: [
      {
        zoneName: "Pacific/Efate",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "VUT",
        tzName: "Vanuatu Time"
      }
    ],
    translations: {
      kr: "\uBC14\uB204\uC544\uD22C",
      "pt-BR": "Vanuatu",
      pt: "Vanuatu",
      nl: "Vanuatu",
      hr: "Vanuatu",
      fa: "\u0648\u0627\u0646\u0648\u0627\u062A\u0648",
      de: "Vanuatu",
      es: "Vanuatu",
      fr: "Vanuatu",
      ja: "\u30D0\u30CC\u30A2\u30C4",
      it: "Vanuatu",
      cn: "\u74E6\u52AA\u963F\u56FE",
      tr: "Vanuatu"
    },
    latitude: "-16.00000000",
    longitude: "167.00000000",
    emoji: "\u{1F1FB}\u{1F1FA}",
    emojiU: "U+1F1FB U+1F1FA"
  },
  {
    id: 238,
    name: "Vatican City State (Holy See)",
    iso3: "VAT",
    iso2: "VA",
    numeric_code: "336",
    phone_code: "379",
    capital: "Vatican City",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "\u20AC",
    tld: ".va",
    native: "Vaticano",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Vatican",
    timezones: [
      {
        zoneName: "Europe/Vatican",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time"
      }
    ],
    translations: {
      kr: "\uBC14\uD2F0\uCE78 \uC2DC\uAD6D",
      "pt-BR": "Vaticano",
      pt: "Vaticano",
      nl: "Heilige Stoel",
      hr: "Sveta Stolica",
      fa: "\u0633\u0631\u06CC\u0631 \u0645\u0642\u062F\u0633",
      de: "Heiliger Stuhl",
      es: "Santa Sede",
      fr: "voir Saint",
      ja: "\u8056\u5EA7",
      it: "Santa Sede",
      cn: "\u68B5\u8482\u5188",
      tr: "Vatikan"
    },
    latitude: "41.90000000",
    longitude: "12.45000000",
    emoji: "\u{1F1FB}\u{1F1E6}",
    emojiU: "U+1F1FB U+1F1E6"
  },
  {
    id: 239,
    name: "Venezuela",
    iso3: "VEN",
    iso2: "VE",
    numeric_code: "862",
    phone_code: "58",
    capital: "Caracas",
    currency: "VES",
    currency_name: "Bol\xEDvar",
    currency_symbol: "Bs",
    tld: ".ve",
    native: "Venezuela",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Venezuelan",
    timezones: [
      {
        zoneName: "America/Caracas",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "VET",
        tzName: "Venezuelan Standard Time"
      }
    ],
    translations: {
      kr: "\uBCA0\uB124\uC218\uC5D8\uB77C",
      "pt-BR": "Venezuela",
      pt: "Venezuela",
      nl: "Venezuela",
      hr: "Venezuela",
      fa: "\u0648\u0646\u0632\u0648\u0626\u0644\u0627",
      de: "Venezuela",
      es: "Venezuela",
      fr: "Venezuela",
      ja: "\u30D9\u30CD\u30BA\u30A8\u30E9\u30FB\u30DC\u30EA\u30D0\u30EB\u5171\u548C\u56FD",
      it: "Venezuela",
      cn: "\u59D4\u5185\u745E\u62C9",
      tr: "Venezuela"
    },
    latitude: "8.00000000",
    longitude: "-66.00000000",
    emoji: "\u{1F1FB}\u{1F1EA}",
    emojiU: "U+1F1FB U+1F1EA"
  },
  {
    id: 240,
    name: "Vietnam",
    iso3: "VNM",
    iso2: "VN",
    numeric_code: "704",
    phone_code: "84",
    capital: "Hanoi",
    currency: "VND",
    currency_name: "Vietnamese \u0111\u1ED3ng",
    currency_symbol: "\u20AB",
    tld: ".vn",
    native: "Vi\u1EC7t Nam",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Vietnamese",
    timezones: [
      {
        zoneName: "Asia/Ho_Chi_Minh",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time"
      }
    ],
    translations: {
      kr: "\uBCA0\uD2B8\uB0A8",
      "pt-BR": "Vietn\xE3",
      pt: "Vietname",
      nl: "Vietnam",
      hr: "Vijetnam",
      fa: "\u0648\u06CC\u062A\u0646\u0627\u0645",
      de: "Vietnam",
      es: "Vietnam",
      fr: "Vi\xEAt Nam",
      ja: "\u30D9\u30C8\u30CA\u30E0",
      it: "Vietnam",
      cn: "\u8D8A\u5357",
      tr: "Vietnam"
    },
    latitude: "16.16666666",
    longitude: "107.83333333",
    emoji: "\u{1F1FB}\u{1F1F3}",
    emojiU: "U+1F1FB U+1F1F3"
  },
  {
    id: 241,
    name: "Virgin Islands (British)",
    iso3: "VGB",
    iso2: "VG",
    numeric_code: "092",
    phone_code: "1",
    capital: "Road Town",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".vg",
    native: "British Virgin Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "British Virgin Island",
    timezones: [
      {
        zoneName: "America/Tortola",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uC601\uAD6D\uB839 \uBC84\uC9C4\uC544\uC77C\uB79C\uB4DC",
      "pt-BR": "Ilhas Virgens Brit\xE2nicas",
      pt: "Ilhas Virgens Brit\xE2nicas",
      nl: "Britse Maagdeneilanden",
      hr: "Britanski Djevi\u010Danski Otoci",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u0648\u06CC\u0631\u062C\u06CC\u0646 \u0628\u0631\u06CC\u062A\u0627\u0646\u06CC\u0627",
      de: "Britische Jungferninseln",
      es: "Islas V\xEDrgenes del Reino Unido",
      fr: "\xCEles Vierges britanniques",
      ja: "\u30A4\u30AE\u30EA\u30B9\u9818\u30F4\u30A1\u30FC\u30B8\u30F3\u8AF8\u5CF6",
      it: "Isole Vergini Britanniche",
      cn: "\u5723\u6587\u68EE\u7279\u548C\u683C\u6797\u7EB3\u4E01\u65AF",
      tr: "Britanya Virjin Adalari"
    },
    latitude: "18.43138300",
    longitude: "-64.62305000",
    emoji: "\u{1F1FB}\u{1F1EC}",
    emojiU: "U+1F1FB U+1F1EC"
  },
  {
    id: 242,
    name: "Virgin Islands (US)",
    iso3: "VIR",
    iso2: "VI",
    numeric_code: "850",
    phone_code: "1",
    capital: "Charlotte Amalie",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".vi",
    native: "United States Virgin Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "U.S. Virgin Island",
    timezones: [
      {
        zoneName: "America/St_Thomas",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time"
      }
    ],
    translations: {
      kr: "\uBBF8\uAD6D\uB839 \uBC84\uC9C4\uC544\uC77C\uB79C\uB4DC",
      "pt-BR": "Ilhas Virgens Americanas",
      pt: "Ilhas Virgens Americanas",
      nl: "Verenigde Staten Maagdeneilanden",
      fa: "\u062C\u0632\u0627\u06CC\u0631 \u0648\u06CC\u0631\u062C\u06CC\u0646 \u0622\u0645\u0631\u06CC\u06A9\u0627",
      de: "Amerikanische Jungferninseln",
      es: "Islas V\xEDrgenes de los Estados Unidos",
      fr: "\xCEles Vierges des \xC9tats-Unis",
      ja: "\u30A2\u30E1\u30EA\u30AB\u9818\u30F4\u30A1\u30FC\u30B8\u30F3\u8AF8\u5CF6",
      it: "Isole Vergini americane",
      cn: "\u7EF4\u5C14\u4EAC\u7FA4\u5C9B\uFF08\u7F8E\u56FD\uFF09",
      tr: "Abd Virjin Adalari"
    },
    latitude: "18.34000000",
    longitude: "-64.93000000",
    emoji: "\u{1F1FB}\u{1F1EE}",
    emojiU: "U+1F1FB U+1F1EE"
  },
  {
    id: 243,
    name: "Wallis And Futuna Islands",
    iso3: "WLF",
    iso2: "WF",
    numeric_code: "876",
    phone_code: "681",
    capital: "Mata Utu",
    currency: "XPF",
    currency_name: "CFP franc",
    currency_symbol: "\u20A3",
    tld: ".wf",
    native: "Wallis et Futuna",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Wallis and Futuna, Wallisian or Futunan",
    timezones: [
      {
        zoneName: "Pacific/Wallis",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "WFT",
        tzName: "Wallis & Futuna Time"
      }
    ],
    translations: {
      kr: "\uC648\uB9AC\uC2A4 \uD478\uD22C\uB098",
      "pt-BR": "Wallis e Futuna",
      pt: "Wallis e Futuna",
      nl: "Wallis en Futuna",
      hr: "Wallis i Fortuna",
      fa: "\u0648\u0627\u0644\u06CC\u0633 \u0648 \u0641\u0648\u062A\u0648\u0646\u0627",
      de: "Wallis und Futuna",
      es: "Wallis y Futuna",
      fr: "Wallis-et-Futuna",
      ja: "\u30A6\u30A9\u30EA\u30B9\u30FB\u30D5\u30C4\u30CA",
      it: "Wallis e Futuna",
      cn: "\u74E6\u5229\u65AF\u7FA4\u5C9B\u548C\u5BCC\u56FE\u7EB3\u7FA4\u5C9B",
      tr: "Wallis Ve Futuna"
    },
    latitude: "-13.30000000",
    longitude: "-176.20000000",
    emoji: "\u{1F1FC}\u{1F1EB}",
    emojiU: "U+1F1FC U+1F1EB"
  },
  {
    id: 244,
    name: "Western Sahara",
    iso3: "ESH",
    iso2: "EH",
    numeric_code: "732",
    phone_code: "212",
    capital: "El-Aaiun",
    currency: "MAD",
    currency_name: "Moroccan Dirham",
    currency_symbol: "MAD",
    tld: ".eh",
    native: "\u0627\u0644\u0635\u062D\u0631\u0627\u0621 \u0627\u0644\u063A\u0631\u0628\u064A\u0629",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Sahrawi, Sahrawian, Sahraouian",
    timezones: [
      {
        zoneName: "Africa/El_Aaiun",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WEST",
        tzName: "Western European Summer Time"
      }
    ],
    translations: {
      kr: "\uC11C\uC0AC\uD558\uB77C",
      "pt-BR": "Saara Ocidental",
      pt: "Saara Ocidental",
      nl: "Westelijke Sahara",
      hr: "Zapadna Sahara",
      fa: "\u062C\u0645\u0647\u0648\u0631\u06CC \u062F\u0645\u0648\u06A9\u0631\u0627\u062A\u06CC\u06A9 \u0639\u0631\u0628\u06CC \u0635\u062D\u0631\u0627",
      de: "Westsahara",
      es: "Sahara Occidental",
      fr: "Sahara Occidental",
      ja: "\u897F\u30B5\u30CF\u30E9",
      it: "Sahara Occidentale",
      cn: "\u897F\u6492\u54C8\u62C9",
      tr: "Bati Sahra"
    },
    latitude: "24.50000000",
    longitude: "-13.00000000",
    emoji: "\u{1F1EA}\u{1F1ED}",
    emojiU: "U+1F1EA U+1F1ED"
  },
  {
    id: 245,
    name: "Yemen",
    iso3: "YEM",
    iso2: "YE",
    numeric_code: "887",
    phone_code: "967",
    capital: "Sanaa",
    currency: "YER",
    currency_name: "Yemeni rial",
    currency_symbol: "\uFDFC",
    tld: ".ye",
    native: "\u0627\u0644\u064A\u064E\u0645\u064E\u0646",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Yemeni",
    timezones: [
      {
        zoneName: "Asia/Aden",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time"
      }
    ],
    translations: {
      kr: "\uC608\uBA58",
      "pt-BR": "I\xEAmen",
      pt: "I\xE9men",
      nl: "Jemen",
      hr: "Jemen",
      fa: "\u06CC\u0645\u0646",
      de: "Jemen",
      es: "Yemen",
      fr: "Y\xE9men",
      ja: "\u30A4\u30A8\u30E1\u30F3",
      it: "Yemen",
      cn: "\u4E5F\u95E8",
      tr: "Yemen"
    },
    latitude: "15.00000000",
    longitude: "48.00000000",
    emoji: "\u{1F1FE}\u{1F1EA}",
    emojiU: "U+1F1FE U+1F1EA"
  },
  {
    id: 246,
    name: "Zambia",
    iso3: "ZMB",
    iso2: "ZM",
    numeric_code: "894",
    phone_code: "260",
    capital: "Lusaka",
    currency: "ZMW",
    currency_name: "Zambian kwacha",
    currency_symbol: "ZK",
    tld: ".zm",
    native: "Zambia",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Zambian",
    timezones: [
      {
        zoneName: "Africa/Lusaka",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time"
      }
    ],
    translations: {
      kr: "\uC7A0\uBE44\uC544",
      "pt-BR": "Z\xE2mbia",
      pt: "Z\xE2mbia",
      nl: "Zambia",
      hr: "Zambija",
      fa: "\u0632\u0627\u0645\u0628\u06CC\u0627",
      de: "Sambia",
      es: "Zambia",
      fr: "Zambie",
      ja: "\u30B6\u30F3\u30D3\u30A2",
      it: "Zambia",
      cn: "\u8D5E\u6BD4\u4E9A",
      tr: "Zambiya"
    },
    latitude: "-15.00000000",
    longitude: "30.00000000",
    emoji: "\u{1F1FF}\u{1F1F2}",
    emojiU: "U+1F1FF U+1F1F2"
  },
  {
    id: 247,
    name: "Zimbabwe",
    iso3: "ZWE",
    iso2: "ZW",
    numeric_code: "716",
    phone_code: "263",
    capital: "Harare",
    currency: "ZWL",
    currency_name: "Zimbabwe Dollar",
    currency_symbol: "$",
    tld: ".zw",
    native: "Zimbabwe",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Zimbabwean",
    timezones: [
      {
        zoneName: "Africa/Harare",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time"
      }
    ],
    translations: {
      kr: "\uC9D0\uBC14\uBE0C\uC6E8",
      "pt-BR": "Zimbabwe",
      pt: "Zimbabu\xE9",
      nl: "Zimbabwe",
      hr: "Zimbabve",
      fa: "\u0632\u06CC\u0645\u0628\u0627\u0648\u0647",
      de: "Simbabwe",
      es: "Zimbabue",
      fr: "Zimbabwe",
      ja: "\u30B8\u30F3\u30D0\u30D6\u30A8",
      it: "Zimbabwe",
      cn: "\u6D25\u5DF4\u5E03\u97E6",
      tr: "Zimbabve"
    },
    latitude: "-20.00000000",
    longitude: "30.00000000",
    emoji: "\u{1F1FF}\u{1F1FC}",
    emojiU: "U+1F1FF U+1F1FC"
  }
];

// src/memberships.ts
import { MembershipInterval } from "@clubsoul/const";

// ../../node_modules/.pnpm/@date-fns+utc@1.2.0/node_modules/@date-fns/utc/date/mini.mjs
var UTCDateMini = class extends Date {
  constructor() {
    super();
    this.setTime(
      arguments.length === 0 ? (
        // Enables Sinon's fake timers that override the constructor
        Date.now()
      ) : arguments.length === 1 ? typeof arguments[0] === "string" ? +new Date(arguments[0]) : arguments[0] : Date.UTC(...arguments)
    );
  }
  getTimezoneOffset() {
    return 0;
  }
};
var re = /^(get|set)(?!UTC)/;
Object.getOwnPropertyNames(Date.prototype).forEach((method) => {
  if (re.test(method)) {
    const utcMethod = Date.prototype[method.replace(re, "$1UTC")];
    if (utcMethod)
      UTCDateMini.prototype[method] = utcMethod;
  }
});

// ../../node_modules/.pnpm/@date-fns+utc@1.2.0/node_modules/@date-fns/utc/date/index.mjs
var UTCDate = class extends UTCDateMini {
  toString() {
    const date = this.toDateString();
    const time = this.toTimeString();
    return `${date} ${time}`;
  }
  toDateString() {
    const weekday = weekdayFormat.format(this);
    const date = dateFormat.format(this);
    const year = this.getFullYear();
    return `${weekday} ${date} ${year}`;
  }
  toTimeString() {
    const time = timeFormat.format(this);
    return `${time} GMT+0000 (Coordinated Universal Time)`;
  }
  toLocaleString(locales, options) {
    return Date.prototype.toLocaleString.call(this, locales, {
      timeZone: "UTC",
      ...options
    });
  }
  toLocaleDateString(locales, options) {
    return Date.prototype.toLocaleDateString.call(this, locales, {
      timeZone: "UTC",
      ...options
    });
  }
  toLocaleTimeString(locales, options) {
    return Date.prototype.toLocaleTimeString.call(this, locales, {
      timeZone: "UTC",
      ...options
    });
  }
};
var weekdayFormat = new Intl.DateTimeFormat("en-US", {
  weekday: "short",
  timeZone: "UTC"
});
var dateFormat = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  timeZone: "UTC"
});
var timeFormat = new Intl.DateTimeFormat("en-GB", {
  hour12: false,
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZone: "UTC"
});

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/toDate.mjs
function toDate(argument) {
  const argStr = Object.prototype.toString.call(argument);
  if (argument instanceof Date || typeof argument === "object" && argStr === "[object Date]") {
    return new argument.constructor(+argument);
  } else if (typeof argument === "number" || argStr === "[object Number]" || typeof argument === "string" || argStr === "[object String]") {
    return new Date(argument);
  } else {
    return /* @__PURE__ */ new Date(NaN);
  }
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/constructFrom.mjs
function constructFrom(date, value) {
  if (date instanceof Date) {
    return new date.constructor(value);
  } else {
    return new Date(value);
  }
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/addMonths.mjs
function addMonths(date, amount) {
  const _date = toDate(date);
  if (isNaN(amount))
    return constructFrom(date, NaN);
  if (!amount) {
    return _date;
  }
  const dayOfMonth = _date.getDate();
  const endOfDesiredMonth = constructFrom(date, _date.getTime());
  endOfDesiredMonth.setMonth(_date.getMonth() + amount + 1, 0);
  const daysInMonth = endOfDesiredMonth.getDate();
  if (dayOfMonth >= daysInMonth) {
    return endOfDesiredMonth;
  } else {
    _date.setFullYear(
      endOfDesiredMonth.getFullYear(),
      endOfDesiredMonth.getMonth(),
      dayOfMonth
    );
    return _date;
  }
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/constants.mjs
var daysInYear = 365.2425;
var maxTime = Math.pow(10, 8) * 24 * 60 * 60 * 1e3;
var minTime = -maxTime;
var millisecondsInWeek = 6048e5;
var millisecondsInDay = 864e5;
var secondsInHour = 3600;
var secondsInDay = secondsInHour * 24;
var secondsInWeek = secondsInDay * 7;
var secondsInYear = secondsInDay * daysInYear;
var secondsInMonth = secondsInYear / 12;
var secondsInQuarter = secondsInMonth * 3;

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/_lib/defaultOptions.mjs
var defaultOptions = {};
function getDefaultOptions() {
  return defaultOptions;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/startOfWeek.mjs
function startOfWeek(date, options) {
  const defaultOptions2 = getDefaultOptions();
  const weekStartsOn = options?.weekStartsOn ?? options?.locale?.options?.weekStartsOn ?? defaultOptions2.weekStartsOn ?? defaultOptions2.locale?.options?.weekStartsOn ?? 0;
  const _date = toDate(date);
  const day = _date.getDay();
  const diff2 = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
  _date.setDate(_date.getDate() - diff2);
  _date.setHours(0, 0, 0, 0);
  return _date;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/startOfISOWeek.mjs
function startOfISOWeek(date) {
  return startOfWeek(date, { weekStartsOn: 1 });
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/getISOWeekYear.mjs
function getISOWeekYear(date) {
  const _date = toDate(date);
  const year = _date.getFullYear();
  const fourthOfJanuaryOfNextYear = constructFrom(date, 0);
  fourthOfJanuaryOfNextYear.setFullYear(year + 1, 0, 4);
  fourthOfJanuaryOfNextYear.setHours(0, 0, 0, 0);
  const startOfNextYear = startOfISOWeek(fourthOfJanuaryOfNextYear);
  const fourthOfJanuaryOfThisYear = constructFrom(date, 0);
  fourthOfJanuaryOfThisYear.setFullYear(year, 0, 4);
  fourthOfJanuaryOfThisYear.setHours(0, 0, 0, 0);
  const startOfThisYear = startOfISOWeek(fourthOfJanuaryOfThisYear);
  if (_date.getTime() >= startOfNextYear.getTime()) {
    return year + 1;
  } else if (_date.getTime() >= startOfThisYear.getTime()) {
    return year;
  } else {
    return year - 1;
  }
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/startOfDay.mjs
function startOfDay(date) {
  const _date = toDate(date);
  _date.setHours(0, 0, 0, 0);
  return _date;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/_lib/getTimezoneOffsetInMilliseconds.mjs
function getTimezoneOffsetInMilliseconds(date) {
  const _date = toDate(date);
  const utcDate = new Date(
    Date.UTC(
      _date.getFullYear(),
      _date.getMonth(),
      _date.getDate(),
      _date.getHours(),
      _date.getMinutes(),
      _date.getSeconds(),
      _date.getMilliseconds()
    )
  );
  utcDate.setUTCFullYear(_date.getFullYear());
  return +date - +utcDate;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/differenceInCalendarDays.mjs
function differenceInCalendarDays(dateLeft, dateRight) {
  const startOfDayLeft = startOfDay(dateLeft);
  const startOfDayRight = startOfDay(dateRight);
  const timestampLeft = +startOfDayLeft - getTimezoneOffsetInMilliseconds(startOfDayLeft);
  const timestampRight = +startOfDayRight - getTimezoneOffsetInMilliseconds(startOfDayRight);
  return Math.round((timestampLeft - timestampRight) / millisecondsInDay);
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/startOfISOWeekYear.mjs
function startOfISOWeekYear(date) {
  const year = getISOWeekYear(date);
  const fourthOfJanuary = constructFrom(date, 0);
  fourthOfJanuary.setFullYear(year, 0, 4);
  fourthOfJanuary.setHours(0, 0, 0, 0);
  return startOfISOWeek(fourthOfJanuary);
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/addYears.mjs
function addYears(date, amount) {
  return addMonths(date, amount * 12);
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/isDate.mjs
function isDate(value) {
  return value instanceof Date || typeof value === "object" && Object.prototype.toString.call(value) === "[object Date]";
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/isValid.mjs
function isValid(date) {
  if (!isDate(date) && typeof date !== "number") {
    return false;
  }
  const _date = toDate(date);
  return !isNaN(Number(_date));
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/startOfMonth.mjs
function startOfMonth(date) {
  const _date = toDate(date);
  _date.setDate(1);
  _date.setHours(0, 0, 0, 0);
  return _date;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/startOfYear.mjs
function startOfYear(date) {
  const cleanDate = toDate(date);
  const _date = constructFrom(date, 0);
  _date.setFullYear(cleanDate.getFullYear(), 0, 1);
  _date.setHours(0, 0, 0, 0);
  return _date;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/en-US/_lib/formatDistance.mjs
var formatDistanceLocale = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
};
var formatDistance = (token, count, options) => {
  let result;
  const tokenValue = formatDistanceLocale[token];
  if (typeof tokenValue === "string") {
    result = tokenValue;
  } else if (count === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other.replace("{{count}}", count.toString());
  }
  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }
  return result;
};

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/_lib/buildFormatLongFn.mjs
function buildFormatLongFn(args) {
  return (options = {}) => {
    const width = options.width ? String(options.width) : args.defaultWidth;
    const format2 = args.formats[width] || args.formats[args.defaultWidth];
    return format2;
  };
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/en-US/_lib/formatLong.mjs
var dateFormats = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
var formatLong = {
  date: buildFormatLongFn({
    formats: dateFormats,
    defaultWidth: "full"
  }),
  time: buildFormatLongFn({
    formats: timeFormats,
    defaultWidth: "full"
  }),
  dateTime: buildFormatLongFn({
    formats: dateTimeFormats,
    defaultWidth: "full"
  })
};

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/en-US/_lib/formatRelative.mjs
var formatRelativeLocale = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
};
var formatRelative = (token, _date, _baseDate, _options) => formatRelativeLocale[token];

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/_lib/buildLocalizeFn.mjs
function buildLocalizeFn(args) {
  return (value, options) => {
    const context = options?.context ? String(options.context) : "standalone";
    let valuesArray;
    if (context === "formatting" && args.formattingValues) {
      const defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
      const width = options?.width ? String(options.width) : defaultWidth;
      valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
    } else {
      const defaultWidth = args.defaultWidth;
      const width = options?.width ? String(options.width) : args.defaultWidth;
      valuesArray = args.values[width] || args.values[defaultWidth];
    }
    const index = args.argumentCallback ? args.argumentCallback(value) : value;
    return valuesArray[index];
  };
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/en-US/_lib/localize.mjs
var eraValues = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
};
var quarterValues = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
};
var monthValues = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  wide: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
};
var dayValues = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
};
var dayPeriodValues = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
};
var formattingDayPeriodValues = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
};
var ordinalNumber = (dirtyNumber, _options) => {
  const number = Number(dirtyNumber);
  const rem100 = number % 100;
  if (rem100 > 20 || rem100 < 10) {
    switch (rem100 % 10) {
      case 1:
        return number + "st";
      case 2:
        return number + "nd";
      case 3:
        return number + "rd";
    }
  }
  return number + "th";
};
var localize = {
  ordinalNumber,
  era: buildLocalizeFn({
    values: eraValues,
    defaultWidth: "wide"
  }),
  quarter: buildLocalizeFn({
    values: quarterValues,
    defaultWidth: "wide",
    argumentCallback: (quarter) => quarter - 1
  }),
  month: buildLocalizeFn({
    values: monthValues,
    defaultWidth: "wide"
  }),
  day: buildLocalizeFn({
    values: dayValues,
    defaultWidth: "wide"
  }),
  dayPeriod: buildLocalizeFn({
    values: dayPeriodValues,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues,
    defaultFormattingWidth: "wide"
  })
};

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/_lib/buildMatchFn.mjs
function buildMatchFn(args) {
  return (string, options = {}) => {
    const width = options.width;
    const matchPattern = width && args.matchPatterns[width] || args.matchPatterns[args.defaultMatchWidth];
    const matchResult = string.match(matchPattern);
    if (!matchResult) {
      return null;
    }
    const matchedString = matchResult[0];
    const parsePatterns = width && args.parsePatterns[width] || args.parsePatterns[args.defaultParseWidth];
    const key = Array.isArray(parsePatterns) ? findIndex(parsePatterns, (pattern) => pattern.test(matchedString)) : (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      findKey(parsePatterns, (pattern) => pattern.test(matchedString))
    );
    let value;
    value = args.valueCallback ? args.valueCallback(key) : key;
    value = options.valueCallback ? (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      options.valueCallback(value)
    ) : value;
    const rest = string.slice(matchedString.length);
    return { value, rest };
  };
}
function findKey(object, predicate) {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key) && predicate(object[key])) {
      return key;
    }
  }
  return void 0;
}
function findIndex(array, predicate) {
  for (let key = 0; key < array.length; key++) {
    if (predicate(array[key])) {
      return key;
    }
  }
  return void 0;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/_lib/buildMatchPatternFn.mjs
function buildMatchPatternFn(args) {
  return (string, options = {}) => {
    const matchResult = string.match(args.matchPattern);
    if (!matchResult)
      return null;
    const matchedString = matchResult[0];
    const parseResult = string.match(args.parsePattern);
    if (!parseResult)
      return null;
    let value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
    value = options.valueCallback ? options.valueCallback(value) : value;
    const rest = string.slice(matchedString.length);
    return { value, rest };
  };
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/en-US/_lib/match.mjs
var matchOrdinalNumberPattern = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern = /\d+/i;
var matchEraPatterns = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
};
var parseEraPatterns = {
  any: [/^b/i, /^(a|c)/i]
};
var matchQuarterPatterns = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
};
var parseQuarterPatterns = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
};
var parseMonthPatterns = {
  narrow: [
    /^j/i,
    /^f/i,
    /^m/i,
    /^a/i,
    /^m/i,
    /^j/i,
    /^j/i,
    /^a/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ],
  any: [
    /^ja/i,
    /^f/i,
    /^mar/i,
    /^ap/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^au/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ]
};
var matchDayPatterns = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
};
var parseDayPatterns = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
};
var matchDayPeriodPatterns = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
};
var parseDayPeriodPatterns = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
};
var match = {
  ordinalNumber: buildMatchPatternFn({
    matchPattern: matchOrdinalNumberPattern,
    parsePattern: parseOrdinalNumberPattern,
    valueCallback: (value) => parseInt(value, 10)
  }),
  era: buildMatchFn({
    matchPatterns: matchEraPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns,
    defaultParseWidth: "any"
  }),
  quarter: buildMatchFn({
    matchPatterns: matchQuarterPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns,
    defaultParseWidth: "any",
    valueCallback: (index) => index + 1
  }),
  month: buildMatchFn({
    matchPatterns: matchMonthPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns,
    defaultParseWidth: "any"
  }),
  day: buildMatchFn({
    matchPatterns: matchDayPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns,
    defaultParseWidth: "any"
  }),
  dayPeriod: buildMatchFn({
    matchPatterns: matchDayPeriodPatterns,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns,
    defaultParseWidth: "any"
  })
};

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/locale/en-US.mjs
var enUS = {
  code: "en-US",
  formatDistance,
  formatLong,
  formatRelative,
  localize,
  match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/getDayOfYear.mjs
function getDayOfYear(date) {
  const _date = toDate(date);
  const diff2 = differenceInCalendarDays(_date, startOfYear(_date));
  const dayOfYear = diff2 + 1;
  return dayOfYear;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/getISOWeek.mjs
function getISOWeek(date) {
  const _date = toDate(date);
  const diff2 = +startOfISOWeek(_date) - +startOfISOWeekYear(_date);
  return Math.round(diff2 / millisecondsInWeek) + 1;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/getWeekYear.mjs
function getWeekYear(date, options) {
  const _date = toDate(date);
  const year = _date.getFullYear();
  const defaultOptions2 = getDefaultOptions();
  const firstWeekContainsDate = options?.firstWeekContainsDate ?? options?.locale?.options?.firstWeekContainsDate ?? defaultOptions2.firstWeekContainsDate ?? defaultOptions2.locale?.options?.firstWeekContainsDate ?? 1;
  const firstWeekOfNextYear = constructFrom(date, 0);
  firstWeekOfNextYear.setFullYear(year + 1, 0, firstWeekContainsDate);
  firstWeekOfNextYear.setHours(0, 0, 0, 0);
  const startOfNextYear = startOfWeek(firstWeekOfNextYear, options);
  const firstWeekOfThisYear = constructFrom(date, 0);
  firstWeekOfThisYear.setFullYear(year, 0, firstWeekContainsDate);
  firstWeekOfThisYear.setHours(0, 0, 0, 0);
  const startOfThisYear = startOfWeek(firstWeekOfThisYear, options);
  if (_date.getTime() >= startOfNextYear.getTime()) {
    return year + 1;
  } else if (_date.getTime() >= startOfThisYear.getTime()) {
    return year;
  } else {
    return year - 1;
  }
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/startOfWeekYear.mjs
function startOfWeekYear(date, options) {
  const defaultOptions2 = getDefaultOptions();
  const firstWeekContainsDate = options?.firstWeekContainsDate ?? options?.locale?.options?.firstWeekContainsDate ?? defaultOptions2.firstWeekContainsDate ?? defaultOptions2.locale?.options?.firstWeekContainsDate ?? 1;
  const year = getWeekYear(date, options);
  const firstWeek = constructFrom(date, 0);
  firstWeek.setFullYear(year, 0, firstWeekContainsDate);
  firstWeek.setHours(0, 0, 0, 0);
  const _date = startOfWeek(firstWeek, options);
  return _date;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/getWeek.mjs
function getWeek(date, options) {
  const _date = toDate(date);
  const diff2 = +startOfWeek(_date, options) - +startOfWeekYear(_date, options);
  return Math.round(diff2 / millisecondsInWeek) + 1;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/_lib/addLeadingZeros.mjs
function addLeadingZeros(number, targetLength) {
  const sign = number < 0 ? "-" : "";
  const output = Math.abs(number).toString().padStart(targetLength, "0");
  return sign + output;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/_lib/format/lightFormatters.mjs
var lightFormatters = {
  // Year
  y(date, token) {
    const signedYear = date.getFullYear();
    const year = signedYear > 0 ? signedYear : 1 - signedYear;
    return addLeadingZeros(token === "yy" ? year % 100 : year, token.length);
  },
  // Month
  M(date, token) {
    const month = date.getMonth();
    return token === "M" ? String(month + 1) : addLeadingZeros(month + 1, 2);
  },
  // Day of the month
  d(date, token) {
    return addLeadingZeros(date.getDate(), token.length);
  },
  // AM or PM
  a(date, token) {
    const dayPeriodEnumValue = date.getHours() / 12 >= 1 ? "pm" : "am";
    switch (token) {
      case "a":
      case "aa":
        return dayPeriodEnumValue.toUpperCase();
      case "aaa":
        return dayPeriodEnumValue;
      case "aaaaa":
        return dayPeriodEnumValue[0];
      case "aaaa":
      default:
        return dayPeriodEnumValue === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h(date, token) {
    return addLeadingZeros(date.getHours() % 12 || 12, token.length);
  },
  // Hour [0-23]
  H(date, token) {
    return addLeadingZeros(date.getHours(), token.length);
  },
  // Minute
  m(date, token) {
    return addLeadingZeros(date.getMinutes(), token.length);
  },
  // Second
  s(date, token) {
    return addLeadingZeros(date.getSeconds(), token.length);
  },
  // Fraction of second
  S(date, token) {
    const numberOfDigits = token.length;
    const milliseconds = date.getMilliseconds();
    const fractionalSeconds = Math.trunc(
      milliseconds * Math.pow(10, numberOfDigits - 3)
    );
    return addLeadingZeros(fractionalSeconds, token.length);
  }
};

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/_lib/format/formatters.mjs
var dayPeriodEnum = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
};
var formatters = {
  // Era
  G: function(date, token, localize2) {
    const era = date.getFullYear() > 0 ? 1 : 0;
    switch (token) {
      case "G":
      case "GG":
      case "GGG":
        return localize2.era(era, { width: "abbreviated" });
      case "GGGGG":
        return localize2.era(era, { width: "narrow" });
      case "GGGG":
      default:
        return localize2.era(era, { width: "wide" });
    }
  },
  // Year
  y: function(date, token, localize2) {
    if (token === "yo") {
      const signedYear = date.getFullYear();
      const year = signedYear > 0 ? signedYear : 1 - signedYear;
      return localize2.ordinalNumber(year, { unit: "year" });
    }
    return lightFormatters.y(date, token);
  },
  // Local week-numbering year
  Y: function(date, token, localize2, options) {
    const signedWeekYear = getWeekYear(date, options);
    const weekYear = signedWeekYear > 0 ? signedWeekYear : 1 - signedWeekYear;
    if (token === "YY") {
      const twoDigitYear = weekYear % 100;
      return addLeadingZeros(twoDigitYear, 2);
    }
    if (token === "Yo") {
      return localize2.ordinalNumber(weekYear, { unit: "year" });
    }
    return addLeadingZeros(weekYear, token.length);
  },
  // ISO week-numbering year
  R: function(date, token) {
    const isoWeekYear = getISOWeekYear(date);
    return addLeadingZeros(isoWeekYear, token.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(date, token) {
    const year = date.getFullYear();
    return addLeadingZeros(year, token.length);
  },
  // Quarter
  Q: function(date, token, localize2) {
    const quarter = Math.ceil((date.getMonth() + 1) / 3);
    switch (token) {
      case "Q":
        return String(quarter);
      case "QQ":
        return addLeadingZeros(quarter, 2);
      case "Qo":
        return localize2.ordinalNumber(quarter, { unit: "quarter" });
      case "QQQ":
        return localize2.quarter(quarter, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return localize2.quarter(quarter, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return localize2.quarter(quarter, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(date, token, localize2) {
    const quarter = Math.ceil((date.getMonth() + 1) / 3);
    switch (token) {
      case "q":
        return String(quarter);
      case "qq":
        return addLeadingZeros(quarter, 2);
      case "qo":
        return localize2.ordinalNumber(quarter, { unit: "quarter" });
      case "qqq":
        return localize2.quarter(quarter, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return localize2.quarter(quarter, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return localize2.quarter(quarter, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(date, token, localize2) {
    const month = date.getMonth();
    switch (token) {
      case "M":
      case "MM":
        return lightFormatters.M(date, token);
      case "Mo":
        return localize2.ordinalNumber(month + 1, { unit: "month" });
      case "MMM":
        return localize2.month(month, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return localize2.month(month, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return localize2.month(month, { width: "wide", context: "formatting" });
    }
  },
  // Stand-alone month
  L: function(date, token, localize2) {
    const month = date.getMonth();
    switch (token) {
      case "L":
        return String(month + 1);
      case "LL":
        return addLeadingZeros(month + 1, 2);
      case "Lo":
        return localize2.ordinalNumber(month + 1, { unit: "month" });
      case "LLL":
        return localize2.month(month, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return localize2.month(month, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return localize2.month(month, { width: "wide", context: "standalone" });
    }
  },
  // Local week of year
  w: function(date, token, localize2, options) {
    const week = getWeek(date, options);
    if (token === "wo") {
      return localize2.ordinalNumber(week, { unit: "week" });
    }
    return addLeadingZeros(week, token.length);
  },
  // ISO week of year
  I: function(date, token, localize2) {
    const isoWeek = getISOWeek(date);
    if (token === "Io") {
      return localize2.ordinalNumber(isoWeek, { unit: "week" });
    }
    return addLeadingZeros(isoWeek, token.length);
  },
  // Day of the month
  d: function(date, token, localize2) {
    if (token === "do") {
      return localize2.ordinalNumber(date.getDate(), { unit: "date" });
    }
    return lightFormatters.d(date, token);
  },
  // Day of year
  D: function(date, token, localize2) {
    const dayOfYear = getDayOfYear(date);
    if (token === "Do") {
      return localize2.ordinalNumber(dayOfYear, { unit: "dayOfYear" });
    }
    return addLeadingZeros(dayOfYear, token.length);
  },
  // Day of week
  E: function(date, token, localize2) {
    const dayOfWeek = date.getDay();
    switch (token) {
      case "E":
      case "EE":
      case "EEE":
        return localize2.day(dayOfWeek, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return localize2.day(dayOfWeek, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return localize2.day(dayOfWeek, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return localize2.day(dayOfWeek, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(date, token, localize2, options) {
    const dayOfWeek = date.getDay();
    const localDayOfWeek = (dayOfWeek - options.weekStartsOn + 8) % 7 || 7;
    switch (token) {
      case "e":
        return String(localDayOfWeek);
      case "ee":
        return addLeadingZeros(localDayOfWeek, 2);
      case "eo":
        return localize2.ordinalNumber(localDayOfWeek, { unit: "day" });
      case "eee":
        return localize2.day(dayOfWeek, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return localize2.day(dayOfWeek, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return localize2.day(dayOfWeek, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return localize2.day(dayOfWeek, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(date, token, localize2, options) {
    const dayOfWeek = date.getDay();
    const localDayOfWeek = (dayOfWeek - options.weekStartsOn + 8) % 7 || 7;
    switch (token) {
      case "c":
        return String(localDayOfWeek);
      case "cc":
        return addLeadingZeros(localDayOfWeek, token.length);
      case "co":
        return localize2.ordinalNumber(localDayOfWeek, { unit: "day" });
      case "ccc":
        return localize2.day(dayOfWeek, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return localize2.day(dayOfWeek, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return localize2.day(dayOfWeek, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return localize2.day(dayOfWeek, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(date, token, localize2) {
    const dayOfWeek = date.getDay();
    const isoDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
    switch (token) {
      case "i":
        return String(isoDayOfWeek);
      case "ii":
        return addLeadingZeros(isoDayOfWeek, token.length);
      case "io":
        return localize2.ordinalNumber(isoDayOfWeek, { unit: "day" });
      case "iii":
        return localize2.day(dayOfWeek, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return localize2.day(dayOfWeek, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return localize2.day(dayOfWeek, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return localize2.day(dayOfWeek, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(date, token, localize2) {
    const hours = date.getHours();
    const dayPeriodEnumValue = hours / 12 >= 1 ? "pm" : "am";
    switch (token) {
      case "a":
      case "aa":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(date, token, localize2) {
    const hours = date.getHours();
    let dayPeriodEnumValue;
    if (hours === 12) {
      dayPeriodEnumValue = dayPeriodEnum.noon;
    } else if (hours === 0) {
      dayPeriodEnumValue = dayPeriodEnum.midnight;
    } else {
      dayPeriodEnumValue = hours / 12 >= 1 ? "pm" : "am";
    }
    switch (token) {
      case "b":
      case "bb":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(date, token, localize2) {
    const hours = date.getHours();
    let dayPeriodEnumValue;
    if (hours >= 17) {
      dayPeriodEnumValue = dayPeriodEnum.evening;
    } else if (hours >= 12) {
      dayPeriodEnumValue = dayPeriodEnum.afternoon;
    } else if (hours >= 4) {
      dayPeriodEnumValue = dayPeriodEnum.morning;
    } else {
      dayPeriodEnumValue = dayPeriodEnum.night;
    }
    switch (token) {
      case "B":
      case "BB":
      case "BBB":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(date, token, localize2) {
    if (token === "ho") {
      let hours = date.getHours() % 12;
      if (hours === 0)
        hours = 12;
      return localize2.ordinalNumber(hours, { unit: "hour" });
    }
    return lightFormatters.h(date, token);
  },
  // Hour [0-23]
  H: function(date, token, localize2) {
    if (token === "Ho") {
      return localize2.ordinalNumber(date.getHours(), { unit: "hour" });
    }
    return lightFormatters.H(date, token);
  },
  // Hour [0-11]
  K: function(date, token, localize2) {
    const hours = date.getHours() % 12;
    if (token === "Ko") {
      return localize2.ordinalNumber(hours, { unit: "hour" });
    }
    return addLeadingZeros(hours, token.length);
  },
  // Hour [1-24]
  k: function(date, token, localize2) {
    let hours = date.getHours();
    if (hours === 0)
      hours = 24;
    if (token === "ko") {
      return localize2.ordinalNumber(hours, { unit: "hour" });
    }
    return addLeadingZeros(hours, token.length);
  },
  // Minute
  m: function(date, token, localize2) {
    if (token === "mo") {
      return localize2.ordinalNumber(date.getMinutes(), { unit: "minute" });
    }
    return lightFormatters.m(date, token);
  },
  // Second
  s: function(date, token, localize2) {
    if (token === "so") {
      return localize2.ordinalNumber(date.getSeconds(), { unit: "second" });
    }
    return lightFormatters.s(date, token);
  },
  // Fraction of second
  S: function(date, token) {
    return lightFormatters.S(date, token);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(date, token, _localize) {
    const timezoneOffset = date.getTimezoneOffset();
    if (timezoneOffset === 0) {
      return "Z";
    }
    switch (token) {
      case "X":
        return formatTimezoneWithOptionalMinutes(timezoneOffset);
      case "XXXX":
      case "XX":
        return formatTimezone(timezoneOffset);
      case "XXXXX":
      case "XXX":
      default:
        return formatTimezone(timezoneOffset, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(date, token, _localize) {
    const timezoneOffset = date.getTimezoneOffset();
    switch (token) {
      case "x":
        return formatTimezoneWithOptionalMinutes(timezoneOffset);
      case "xxxx":
      case "xx":
        return formatTimezone(timezoneOffset);
      case "xxxxx":
      case "xxx":
      default:
        return formatTimezone(timezoneOffset, ":");
    }
  },
  // Timezone (GMT)
  O: function(date, token, _localize) {
    const timezoneOffset = date.getTimezoneOffset();
    switch (token) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + formatTimezoneShort(timezoneOffset, ":");
      case "OOOO":
      default:
        return "GMT" + formatTimezone(timezoneOffset, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(date, token, _localize) {
    const timezoneOffset = date.getTimezoneOffset();
    switch (token) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + formatTimezoneShort(timezoneOffset, ":");
      case "zzzz":
      default:
        return "GMT" + formatTimezone(timezoneOffset, ":");
    }
  },
  // Seconds timestamp
  t: function(date, token, _localize) {
    const timestamp = Math.trunc(date.getTime() / 1e3);
    return addLeadingZeros(timestamp, token.length);
  },
  // Milliseconds timestamp
  T: function(date, token, _localize) {
    const timestamp = date.getTime();
    return addLeadingZeros(timestamp, token.length);
  }
};
function formatTimezoneShort(offset, delimiter = "") {
  const sign = offset > 0 ? "-" : "+";
  const absOffset = Math.abs(offset);
  const hours = Math.trunc(absOffset / 60);
  const minutes = absOffset % 60;
  if (minutes === 0) {
    return sign + String(hours);
  }
  return sign + String(hours) + delimiter + addLeadingZeros(minutes, 2);
}
function formatTimezoneWithOptionalMinutes(offset, delimiter) {
  if (offset % 60 === 0) {
    const sign = offset > 0 ? "-" : "+";
    return sign + addLeadingZeros(Math.abs(offset) / 60, 2);
  }
  return formatTimezone(offset, delimiter);
}
function formatTimezone(offset, delimiter = "") {
  const sign = offset > 0 ? "-" : "+";
  const absOffset = Math.abs(offset);
  const hours = addLeadingZeros(Math.trunc(absOffset / 60), 2);
  const minutes = addLeadingZeros(absOffset % 60, 2);
  return sign + hours + delimiter + minutes;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/_lib/format/longFormatters.mjs
var dateLongFormatter = (pattern, formatLong2) => {
  switch (pattern) {
    case "P":
      return formatLong2.date({ width: "short" });
    case "PP":
      return formatLong2.date({ width: "medium" });
    case "PPP":
      return formatLong2.date({ width: "long" });
    case "PPPP":
    default:
      return formatLong2.date({ width: "full" });
  }
};
var timeLongFormatter = (pattern, formatLong2) => {
  switch (pattern) {
    case "p":
      return formatLong2.time({ width: "short" });
    case "pp":
      return formatLong2.time({ width: "medium" });
    case "ppp":
      return formatLong2.time({ width: "long" });
    case "pppp":
    default:
      return formatLong2.time({ width: "full" });
  }
};
var dateTimeLongFormatter = (pattern, formatLong2) => {
  const matchResult = pattern.match(/(P+)(p+)?/) || [];
  const datePattern = matchResult[1];
  const timePattern = matchResult[2];
  if (!timePattern) {
    return dateLongFormatter(pattern, formatLong2);
  }
  let dateTimeFormat;
  switch (datePattern) {
    case "P":
      dateTimeFormat = formatLong2.dateTime({ width: "short" });
      break;
    case "PP":
      dateTimeFormat = formatLong2.dateTime({ width: "medium" });
      break;
    case "PPP":
      dateTimeFormat = formatLong2.dateTime({ width: "long" });
      break;
    case "PPPP":
    default:
      dateTimeFormat = formatLong2.dateTime({ width: "full" });
      break;
  }
  return dateTimeFormat.replace("{{date}}", dateLongFormatter(datePattern, formatLong2)).replace("{{time}}", timeLongFormatter(timePattern, formatLong2));
};
var longFormatters = {
  p: timeLongFormatter,
  P: dateTimeLongFormatter
};

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/_lib/protectedTokens.mjs
var dayOfYearTokenRE = /^D+$/;
var weekYearTokenRE = /^Y+$/;
var throwTokens = ["D", "DD", "YY", "YYYY"];
function isProtectedDayOfYearToken(token) {
  return dayOfYearTokenRE.test(token);
}
function isProtectedWeekYearToken(token) {
  return weekYearTokenRE.test(token);
}
function warnOrThrowProtectedError(token, format2, input) {
  const _message = message(token, format2, input);
  console.warn(_message);
  if (throwTokens.includes(token))
    throw new RangeError(_message);
}
function message(token, format2, input) {
  const subject = token[0] === "Y" ? "years" : "days of the month";
  return `Use \`${token.toLowerCase()}\` instead of \`${token}\` (in \`${format2}\`) for formatting ${subject} to the input \`${input}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}

// ../../node_modules/.pnpm/date-fns@3.6.0/node_modules/date-fns/format.mjs
var formattingTokensRegExp = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g;
var longFormattingTokensRegExp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
var escapedStringRegExp = /^'([^]*?)'?$/;
var doubleQuoteRegExp = /''/g;
var unescapedLatinCharacterRegExp = /[a-zA-Z]/;
function format(date, formatStr, options) {
  const defaultOptions2 = getDefaultOptions();
  const locale = options?.locale ?? defaultOptions2.locale ?? enUS;
  const firstWeekContainsDate = options?.firstWeekContainsDate ?? options?.locale?.options?.firstWeekContainsDate ?? defaultOptions2.firstWeekContainsDate ?? defaultOptions2.locale?.options?.firstWeekContainsDate ?? 1;
  const weekStartsOn = options?.weekStartsOn ?? options?.locale?.options?.weekStartsOn ?? defaultOptions2.weekStartsOn ?? defaultOptions2.locale?.options?.weekStartsOn ?? 0;
  const originalDate = toDate(date);
  if (!isValid(originalDate)) {
    throw new RangeError("Invalid time value");
  }
  let parts = formatStr.match(longFormattingTokensRegExp).map((substring) => {
    const firstCharacter = substring[0];
    if (firstCharacter === "p" || firstCharacter === "P") {
      const longFormatter = longFormatters[firstCharacter];
      return longFormatter(substring, locale.formatLong);
    }
    return substring;
  }).join("").match(formattingTokensRegExp).map((substring) => {
    if (substring === "''") {
      return { isToken: false, value: "'" };
    }
    const firstCharacter = substring[0];
    if (firstCharacter === "'") {
      return { isToken: false, value: cleanEscapedString(substring) };
    }
    if (formatters[firstCharacter]) {
      return { isToken: true, value: substring };
    }
    if (firstCharacter.match(unescapedLatinCharacterRegExp)) {
      throw new RangeError(
        "Format string contains an unescaped latin alphabet character `" + firstCharacter + "`"
      );
    }
    return { isToken: false, value: substring };
  });
  if (locale.localize.preprocessor) {
    parts = locale.localize.preprocessor(originalDate, parts);
  }
  const formatterOptions = {
    firstWeekContainsDate,
    weekStartsOn,
    locale
  };
  return parts.map((part) => {
    if (!part.isToken)
      return part.value;
    const token = part.value;
    if (!options?.useAdditionalWeekYearTokens && isProtectedWeekYearToken(token) || !options?.useAdditionalDayOfYearTokens && isProtectedDayOfYearToken(token)) {
      warnOrThrowProtectedError(token, formatStr, String(date));
    }
    const formatter = formatters[token[0]];
    return formatter(originalDate, token, locale.localize, formatterOptions);
  }).join("");
}
function cleanEscapedString(input) {
  const matched = input.match(escapedStringRegExp);
  if (!matched) {
    return input;
  }
  return matched[1].replace(doubleQuoteRegExp, "'");
}

// src/memberships.ts
function calculateExpirationDate(interval, startDate = new UTCDate()) {
  let expirationDate = /* @__PURE__ */ new Date(
    `${format(startDate, "yyyy-MM-dd")}T00:00:00.000Z`
  );
  switch (interval) {
    case MembershipInterval.QUARTERLY:
      expirationDate = addMonths(expirationDate, 3);
      break;
    case MembershipInterval.SEMIYEARLY:
      expirationDate = addMonths(expirationDate, 6);
      break;
    case MembershipInterval.YEARLY:
      expirationDate = addYears(expirationDate, 1);
      break;
    default:
      expirationDate = addMonths(expirationDate, 1);
  }
  return startOfMonth(expirationDate);
}

// src/object.ts
function objectPropsToString(obj) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    if (value === void 0) {
      return result;
    }
    if (value && typeof value === "number" && !isNaN(value)) {
      return {
        ...result,
        [key]: value.toString()
      };
    }
    return {
      ...result,
      [key]: value
    };
  }, {});
}
var removeObjectProperties = (obj, keysToRemove) => {
  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!keysToRemove.includes(key)) {
        if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
          result[key] = removeObjectProperties(obj[key], keysToRemove);
        } else {
          result[key] = obj[key];
        }
      }
    }
  }
  return result;
};
function diff(obj1, obj2) {
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length || obj1.some((el, index) => !!diff(el, obj2[index]))) {
      return obj2;
    } else {
      return void 0;
    }
  }
  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
    return obj1 === obj2 ? void 0 : obj2;
  }
  const result = {};
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (!obj2.hasOwnProperty(key)) {
        result[key] = void 0;
      } else {
        const value = diff(obj1[key], obj2[key]);
        if (value !== void 0) {
          result[key] = value;
        }
      }
    }
  }
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      result[key] = obj2[key];
    }
  }
  return Object.keys(result).length === 0 ? void 0 : result;
}
function deepCloneObject(obj) {
  return Object.assign(Object.create(Object.getPrototypeOf(obj)), obj);
}

// src/phone.ts
import {
  AsYouType
} from "libphonenumber-js";
function getPhoneData(phone) {
  const asYouType = new AsYouType();
  asYouType.input(phone);
  const number = asYouType.getNumber();
  return {
    phoneNumber: number?.number,
    countryCode: number?.country,
    countryCallingCode: number?.countryCallingCode,
    carrierCode: number?.carrierCode,
    nationalNumber: number?.nationalNumber,
    internationalNumber: number?.formatInternational(),
    possibleCountries: number?.getPossibleCountries().join(", "),
    isValid: number?.isValid(),
    isPossible: number?.isPossible(),
    uri: number?.getURI(),
    type: number?.getType(),
    isOnlyCountryCode: phone === `+${asYouType.getCallingCode()}`
  };
}

// src/sort.ts
function sortAscBy(list, key) {
  return list.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
}
export {
  calculateExpirationDate,
  countries,
  deepCloneObject,
  diff,
  getPhoneData,
  objectPropsToString,
  removeObjectProperties,
  sortAscBy
};
