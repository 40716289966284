import {
  ComponentPropsWithRef,
  ComponentType,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
} from 'react';

import { AlertDialogContentProps } from '@radix-ui/react-alert-dialog';

import {
  AlertDialog,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';
import { DropdownMenuItem } from '../../components/ui/dropdown-menu';

type AlertNestedDialogProps = {
  trigger: ReactNode;
  component: ComponentType<ComponentPropsWithRef<typeof DropdownMenuItem>>;
  onSelect?(): void;
  onOpenChange?(open: boolean): void;
  children: ReactElement<AlertDialogContentProps>;
};

const AlertNestedDialog = (
  {
    trigger,
    component: Component,
    children,
    onSelect,
    onOpenChange,
    ...itemProps
  }: AlertNestedDialogProps,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    <AlertDialog onOpenChange={onOpenChange}>
      <AlertDialogTrigger asChild>
        <Component
          ref={ref}
          onSelect={(event) => {
            event.preventDefault();
            onSelect && onSelect();
          }}
          {...itemProps}
        >
          {trigger}
        </Component>
      </AlertDialogTrigger>
      {children}
    </AlertDialog>
  );
};

AlertNestedDialog.displayName = 'AlertNestedDialog';

export default forwardRef<
  React.ElementRef<typeof DropdownMenuItem>,
  AlertNestedDialogProps
>(AlertNestedDialog);
