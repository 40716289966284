import { FeatureFlags } from '@clubsoul/api-contracts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { featureKeys } from '@/api/features/feature.keys';
import { authClient } from '@/lib/axios';

export const getFeaturesQueryOptions = queryOptions({
  queryKey: featureKeys.base,
  queryFn: () => authClient.get<FeatureFlags>('/v1/features'),
});

export const useGetFeatures = () => useQuery(getFeaturesQueryOptions);

export const getFeaturesByIdQueryOptions = (id: string) =>
  queryOptions({
    queryKey: featureKeys.get(id),
    queryFn: () =>
      authClient.get<FeatureFlags>(`/console/users/features/${id}`),
  });

export const useGetFeaturesById = (id: string) =>
  useQuery(getFeaturesByIdQueryOptions(id));
