import { ConsoleClubDetails } from '@clubsoul/api-contracts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { clubKeys } from '@/api/clubs/club.keys';
import { authClient } from '@/lib/axios';

export const getClubQueryOptions = (id: string) =>
  queryOptions({
    queryKey: clubKeys.get(id),
    queryFn: () => authClient.get<ConsoleClubDetails>(`/console/clubs/${id}`),
  });

export const useGetClub = (id: string) => useQuery(getClubQueryOptions(id));
