import { useMutation, useQueryClient } from '@tanstack/react-query';

import { clubKeys } from '@/api/clubs/club.keys';
import { authClient } from '@/lib/axios';

export const useDeleteBetaCode = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      authClient.delete(`console/clubs/beta-codes/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clubKeys.getBetaCodes() });
    },
  });
};
