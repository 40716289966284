import { useMutation, useQueryClient } from '@tanstack/react-query';

import { clubKeys } from '@/api/clubs/club.keys';
import { authClient } from '@/lib/axios';

export const useDeleteClub = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => authClient.delete(`console/clubs/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clubKeys.base });
    },
  });
};
