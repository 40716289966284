import { ConsoleBetaCode } from '@clubsoul/api-contracts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { clubKeys } from '@/api/clubs/club.keys';
import { authClient } from '@/lib/axios';

export const getBetaCodesQueryOptions = queryOptions({
  queryKey: clubKeys.getBetaCodes(),
  queryFn: () => authClient.get<ConsoleBetaCode[]>('/console/clubs/beta-codes'),
});

export const useGetBetaCodes = () => useQuery(getBetaCodesQueryOptions);
