import { BaseClient } from '@/api/baseClient';
import { auth } from '@/lib/firebase';
import axios from 'axios';

export const axiosAuthClient = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
});

axiosAuthClient.interceptors.request.use(async (config) => {
  const token = await auth.currentUser?.getIdToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const authClient = new BaseClient(axiosAuthClient);
