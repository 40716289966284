import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type ConsoleUser = {
  uid: string;
  firstName: string;
  lastName: string;
  profilePic?: string;
};

export interface AuthContextType {
  isAuthenticated: boolean;
  user: ConsoleUser | null;
  setUser: Dispatch<SetStateAction<ConsoleUser | null>>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
