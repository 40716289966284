import { useEffect, useMemo, useState } from 'react';

import {
  ConsoleUser,
  PageableSchema,
  UserFiltersSchema,
} from '@clubsoul/api-contracts';
import { defaultPagination } from '@clubsoul/const';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
  ColumnDef,
  PaginationState,
  createColumnHelper,
} from '@tanstack/react-table';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  DataTable,
  Loader,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@ui/components';
import { useCopyToClipboard } from '@ui/hooks';

import { getUsersQueryOptions } from '@/api/users/useGetUsers';
import PageError from '@/components/errors/PageError';
import { CopyIcon, User } from 'lucide-react';
import { toast } from 'sonner';

export const Route = createFileRoute('/_auth/users/')({
  loaderDeps: ({ search: { page, search, size } }) => ({ page, search, size }),
  loader: ({ context: { queryClient }, deps: params }) => {
    return queryClient.ensureQueryData(
      getUsersQueryOptions(
        { search: params.search },
        { page: params.page, size: params.size },
      ),
    );
  },
  validateSearch: PageableSchema.and(UserFiltersSchema),
  component: UsersPage,
  errorComponent: PageError,
  pendingComponent: () => (
    <div className="container">
      <Loader />
    </div>
  ),
  onError: (err) => console.log(err),
});

function UsersPage() {
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: (searchParams.page ?? defaultPagination.page) - 1,
    pageSize: searchParams.size ?? defaultPagination.size,
  });

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex],
  );

  useEffect(() => {
    navigate({
      to: '/users',
      search: () => ({
        page: pageIndex + 1,
        size: pageSize,
      }),
    });
  }, [navigate, pageIndex, pageSize]);

  return (
    <section className="container">
      <UsersList pagination={pagination} onPaginationChange={setPagination} />
    </section>
  );
}

type UsersListProps = {
  pagination: Required<PaginationState>;
  onPaginationChange: React.Dispatch<React.SetStateAction<PaginationState>>;
};

const columnHelper = createColumnHelper<ConsoleUser>();

function UsersList({ pagination, onPaginationChange }: UsersListProps) {
  const navigate = Route.useNavigate();
  const usersQuery = useSuspenseQuery(
    getUsersQueryOptions(
      {},
      { page: pagination.pageIndex + 1, size: pagination.pageSize },
    ),
  );

  const users = usersQuery.data.content;
  const meta = usersQuery.data.meta;

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor((row) => row, {
          id: 'id',
          header: () => <User width={18} height={18} className="ml-3" />,
          cell: ({ row }) => (
            <Avatar className="text-foreground">
              <AvatarImage src={row.original.profilePic} />
              <AvatarFallback>
                {row.original.firstName.charAt(0).toUpperCase()}
                {row.original.lastName.charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>
          ),
        }),
        columnHelper.accessor((row) => row, {
          id: 'firstName',
          header: 'Name / E-Mail',
          cell: ({ row }) => (
            <div className="flex flex-col">
              <span className="font-semibold leading-5">
                {row.original.firstName} {row.original.lastName}
              </span>
              <span className="text-xs leading-5">{row.original.email}</span>
            </div>
          ),
        }),
        columnHelper.accessor('username', {
          header: 'Benutzername',
          cell: (info) => <span className="leading-5">{info.getValue()}</span>,
        }),
        columnHelper.accessor('club', {
          header: 'Club',
          cell: (info) => (
            <span className="font-semibold">
              {info.getValue()?.name ?? '--'}
            </span>
          ),
        }),
        columnHelper.display({
          id: 'copyId',
          cell: ({ row }) => <CopyButton text={row.original.id} />,
        }),
      ] as ColumnDef<ConsoleUser>[],
    [],
  );

  const handleRowClick = ({ id }: ConsoleUser) => {
    navigate({ to: `/users/${id}`, replace: false });
  };

  return (
    <>
      <h1 className="mb-4 font-heading text-xl font-semibold">
        {meta.total} Benutzer
      </h1>
      <DataTable
        columns={columns}
        data={users}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        meta={meta}
        onRowClick={handleRowClick}
      />
    </>
  );
}

function CopyButton({ text }: { text: string }) {
  const [, copy] = useCopyToClipboard();

  return (
    <Tooltip delayDuration={0}>
      <TooltipTrigger>
        <Button
          variant="outline"
          size="icon-sm"
          onClick={async (e) => {
            e.stopPropagation();
            const copied = await copy(text);
            if (copied) {
              toast.info('Copied user id to clipboard');
            } else {
              toast.error('Failed to copy');
            }
          }}
        >
          <CopyIcon size={14} />
        </Button>
      </TooltipTrigger>
      <TooltipContent className="bg-primary text-white">
        Benutzer ID kopieren
      </TooltipContent>
    </Tooltip>
  );
}
