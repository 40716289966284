import { ReactNode } from 'react';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@ui/components';

import { useDeleteBetaCode } from '@/api/clubs/useDeleteBetaCode';

type DeleteBetaCodeDialogProps = {
  trigger: ReactNode;
  id: number;
};

export default function DeleteBetaCodeDialog({
  id,
  trigger,
}: DeleteBetaCodeDialogProps) {
  const { mutateAsync: deleteBetaCode } = useDeleteBetaCode();

  const handleClick = async () => {
    await deleteBetaCode(id);
  };
  return (
    <AlertDialog key={id}>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Beta code löschen</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          Bist du dir sicher? Ungenutzte beta codes werden für immer gelöscht.
        </AlertDialogDescription>
        <AlertDialogFooter className="mt-4">
          <AlertDialogCancel>Abbrechen</AlertDialogCancel>
          <AlertDialogAction variant={'destructive'} onClick={handleClick}>
            Löschen
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
