import { useState } from 'react';

import { LoginRequest, LoginRequestSchema } from '@clubsoul/api-contracts';
import { createFileRoute, redirect, useRouter } from '@tanstack/react-router';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormTextInput,
} from '@ui/components';

import { ConsoleUser, useAuth } from '@/auth/useAuth';
import { auth } from '@/lib/firebase';
import axios from 'axios';
import {
  UserCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { Loader } from 'lucide-react';
import { z } from 'zod';

const fallback = '/' as const;

export const Route = createFileRoute('/login')({
  validateSearch: z.object({
    redirect: z.string().optional().catch(''),
  }),
  beforeLoad: ({ search }) => {
    if (auth.currentUser) {
      throw redirect({ to: search.redirect || fallback });
    }
  },
  component: LoginComponent,
});

function LoginComponent() {
  const { setUser } = useAuth();
  const router = useRouter();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const search = Route.useSearch();

  const handleSubmit = async (fields: LoginRequest) => {
    setIsLoading(true);
    setError('');

    // validate clubsoul.de email
    if (!fields.email.endsWith('@clubsoul.de')) {
      setError('Was machst du eigentlich? Geh weg 😒');
      setIsLoading(false);
      return;
    }

    let credentials: UserCredential;

    try {
      // sign in to firebase
      credentials = await signInWithEmailAndPassword(
        auth,
        fields.email,
        fields.password,
      );

      if (!credentials.user.emailVerified) {
        setError('Deine E-Mail wurde noch nicht verifiziert');
        setIsLoading(false);
        return;
      }
    } catch (error) {
      setError('E-Mail oder Passwort falsch');
      setIsLoading(false);
    }

    // get token
    const idToken = await credentials!.user.getIdToken();

    let customToken: string;

    try {
      // call post-login URL to API to get custom token
      const { data } = await axios.post<{ token: string }>(
        `${import.meta.env.VITE_API_URL}/v1/auth/console/login`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      customToken = data.token;
    } catch (error) {
      setError('Du kannst dich nicht bei der Konsole einloggen');
      setIsLoading(false);
    }

    // login with custom token again
    const finalUser = await signInWithCustomToken(auth, customToken!);
    const result = await finalUser.user.getIdTokenResult();

    setUser(result.claims.clubsoul as ConsoleUser);

    setIsLoading(false);

    router.navigate({ to: search.redirect || fallback });
  };

  return (
    <div className="grid place-items-center gap-2 p-2">
      <Card className="w-full max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>
            Einloggen in die magic world von ClubSoul
          </CardDescription>
        </CardHeader>
        <Form
          onSubmit={handleSubmit}
          schema={LoginRequestSchema}
          className="w-full"
        >
          <CardContent className="grid gap-4">
            <FormTextInput label="E-Mail" name="email" />
            <FormTextInput label="Passwort" name="password" type="password" />
          </CardContent>
          <CardFooter className="flex-col">
            <Button disabled={isLoading} className="w-full">
              {isLoading ? <Loader className="animate-spin" /> : 'Einloggen'}
            </Button>
            {error && <div className="mt-4 text-sm text-red-600">{error}</div>}
          </CardFooter>
        </Form>
      </Card>
    </div>
  );
}
