import { FormEvent, useState } from 'react';

import { FeatureFlag } from '@clubsoul/api-contracts';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
  Button,
  Input,
  Label,
  Loader,
  Switch,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@ui/components';
import { cn } from '@ui/lib/utils';
import { getApiError } from '@ui/utils/apiError';

import {
  getFeaturesByIdQueryOptions,
  getFeaturesQueryOptions,
} from '@/api/features/useGetFeatures';
import {
  useSetUserFeature,
  useSetUserFeatureById,
} from '@/api/features/useSetUserFeature';
import { getUserByIdQueryOptions } from '@/api/users/useGetUsers';
import ErrorComponent from '@/components/errors/ErrorComponent';
import PageError from '@/components/errors/PageError';
import { toast } from 'sonner';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/features/')({
  validateSearch: z.object({ userId: z.string().optional() }),
  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(getFeaturesQueryOptions);
  },
  component: FeaturesPage,
  errorComponent: PageError,
  pendingComponent: () => (
    <div className="container">
      <Loader />
    </div>
  ),
  onError: (e) => console.log(e),
});

function FeaturesPage() {
  const { userId } = Route.useSearch();

  return (
    <section className="container">
      <header className="mb-6">
        <h1 className="font-heading text-xl font-semibold">Feature flags</h1>
        <p className="text-sm text-muted-foreground">
          Enable a feature flag to test any feature in the UI
        </p>
      </header>

      <Tabs defaultValue={userId ? 'user' : 'mine'}>
        <TabsList className="mb-6 grid w-full grid-cols-2">
          <TabsTrigger value="mine">Meine Features</TabsTrigger>
          <TabsTrigger value="user">Spezifische Benutzer Features</TabsTrigger>
        </TabsList>
        <TabsContent value="mine">
          <MyFeatures />
        </TabsContent>
        <TabsContent value="user">
          <UserFeatures userId={userId} />
        </TabsContent>
      </Tabs>
    </section>
  );
}

function MyFeatures() {
  const featuresQuery = useSuspenseQuery(getFeaturesQueryOptions);
  const features = featuresQuery.data;

  const { mutateAsync: setFeature, isPending } = useSetUserFeature();

  const handleChange = async (feature: FeatureFlag, checked: boolean) => {
    try {
      await setFeature({
        features: [{ name: feature.name, enabled: checked }],
      });
      toast.success('Saved feature flag correctly');
    } catch (error) {
      console.log(error);
      toast.error('There was an error saving the feature flag');
    }
  };

  return (
    <ul className="flex flex-col gap-4">
      {Object.entries(features).map(([key, feature]) => (
        <Feature
          key={key}
          {...feature}
          onChange={handleChange}
          isLoading={isPending}
        />
      ))}
    </ul>
  );
}

type UserFeaturesProps = { userId?: string };

function UserFeatures({ userId: initialUserId }: UserFeaturesProps) {
  const navigate = Route.useNavigate();
  const [userId, setUserId] = useState<string | undefined>(initialUserId);
  const [formUserId, setFormUserId] = useState<string | undefined>(
    initialUserId ?? '',
  );

  const {
    data: features,
    isLoading,
    isError,
    error,
  } = useQuery({
    ...getFeaturesByIdQueryOptions(userId!),
    retry: false,
    enabled: !!userId,
  });

  const { data: user } = useQuery({
    ...getUserByIdQueryOptions(userId!),
    retry: false,
    enabled: !!userId,
  });

  const { mutateAsync: setFeature, isPending } = useSetUserFeatureById();

  const handleChange = async (feature: FeatureFlag, checked: boolean) => {
    if (!userId) {
      return;
    }

    try {
      await setFeature({
        id: userId,
        payload: {
          features: [{ name: feature.name, enabled: checked }],
        },
      });

      toast.success('Saved feature flag correctly');
    } catch (error) {
      console.log(error);
      toast.error('There was an error saving the feature flag');
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!formUserId) {
      return;
    }
    setUserId(formUserId);
    navigate({ search: { userId: formUserId } });
  };

  const reset = () => {
    setUserId(undefined);
    setFormUserId('');
    navigate({ search: { userId: undefined } });
  };

  return (
    <>
      <form className="mb-4 flex max-w-[550px] gap-2" onSubmit={handleSubmit}>
        <div>
          <Input
            value={formUserId}
            onChange={(e) => setFormUserId(e.target.value)}
            placeholder="Benutzer ID"
          />
          <p className="mt-1 text-xs text-muted-foreground">
            Du kannst die Benutzer ID aus der Benutzertabelle kopieren
          </p>
        </div>
        <Button>Suchen</Button>
        <Button variant="outline" type="button" onClick={reset}>
          Reset
        </Button>
      </form>
      {isLoading && <Loader />}
      {isError && (
        <ErrorComponent title={getApiError(error).message} description="" />
      )}
      {!!features && !isLoading && !isError && (
        <>
          <div className="mb-4 inline-block h-[28px] rounded bg-yellow-100 px-1 text-lg font-semibold">
            Features von {user?.firstName} {user?.lastName}
          </div>
          <ul className="flex flex-col gap-4">
            {Object.entries(features ?? {}).map(([key, feature]) => (
              <Feature
                key={key}
                {...feature}
                onChange={handleChange}
                isLoading={isPending}
              />
            ))}
          </ul>
        </>
      )}
    </>
  );
}

type FeatureProps = FeatureFlag & {
  isLoading?: boolean;
  onChange: (feature: FeatureFlag, checked: boolean) => void;
};

function Feature({ onChange, isLoading = false, ...feature }: FeatureProps) {
  return (
    <li
      className={cn('flex justify-between gap-4 rounded-md p-4', {
        'bg-red-200': !feature.enabled,
        'bg-green-200': feature.enabled,
      })}
    >
      <div className="flex flex-col">
        <Label htmlFor={feature.name} className="font-normal">
          <span className="text-base font-semibold">{feature.name}</span>
          <p className="mt-1">{feature.description}</p>
        </Label>
      </div>
      <Switch
        id={feature.name}
        checked={feature.enabled}
        onCheckedChange={(checked) => onChange(feature, checked)}
        disabled={isLoading}
      />
    </li>
  );
}
