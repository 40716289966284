import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Avatar, AvatarFallback, AvatarImage, Button } from '@ui/components';

import { getClubQueryOptions } from '@/api/clubs/useGetClub';
import ClubEditDialog from '@/components/clubs/ClubEditDialog';
import ClubRemoveDialog from '@/components/clubs/ClubRemoveDialog';
import ClubStatusBadge from '@/components/clubs/ClubStatusBadge';
import ClubVerifiedBadge from '@/components/clubs/ClubVerifiedBadge';
import ClubVisibilityBadge from '@/components/clubs/ClubVisibilityBadge';
import ConfigProperty from '@/components/common/ConfigProperty';
import Property from '@/components/common/Property';
import { LONG_DATE_FORMAT, TIME_FORMAT } from '@/const';
import { format } from 'date-fns';
import { Trash2 } from 'lucide-react';

export const Route = createFileRoute('/_auth/clubs/$clubId')({
  loader: ({ context: { queryClient }, params: { clubId } }) => {
    return queryClient.ensureQueryData(getClubQueryOptions(clubId));
  },
  component: ClubPage,
});

function ClubPage() {
  const clubId = Route.useParams().clubId;
  const { data: club } = useSuspenseQuery(getClubQueryOptions(clubId));

  return (
    <div className="container">
      <header className="mb-6">
        <div className="flex justify-between">
          <div className="mb-2 flex items-center gap-4">
            <Avatar className="h-14 w-14">
              <AvatarImage src={club.logoUrl} />
              <AvatarFallback>{club.name.charAt(0)}</AvatarFallback>
            </Avatar>
            <div>
              <h1 className="font-heading text-3xl font-semibold">
                {club.name}
              </h1>
              <span className="text-xs text-muted-foreground">
                ID: {club.id}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <ClubEditDialog
              trigger={
                <Button variant="outline" size="sm">
                  Bearbeiten
                </Button>
              }
              id={club.id}
              status={club.status}
              visibility={club.visibility}
              verifiedAt={club.verifiedAt}
            />
            {club.name !== 'Clubsoul' && (
              <ClubRemoveDialog
                id={club.id}
                trigger={
                  <Button variant="destructive" size="sm">
                    <Trash2 size={20} />
                  </Button>
                }
              />
            )}
          </div>
        </div>
        <div className="mb-4 flex items-center gap-2">
          <ClubVisibilityBadge visibility={club.visibility} />
          <ClubStatusBadge status={club.status} />
          <ClubVerifiedBadge verifiedAt={club.verifiedAt} />
        </div>
        <p className="text-sm text-muted-foreground">
          Erstellt am {format(club.createdAt, LONG_DATE_FORMAT)}{' '}
          {format(club.createdAt, TIME_FORMAT)}
        </p>
        <p className="text-sm text-muted-foreground">
          Aktualisiert am {format(club.updatedAt, LONG_DATE_FORMAT)}{' '}
          {format(club.updatedAt, TIME_FORMAT)}
        </p>
      </header>
      <section>
        <h2 className="mb-2 text-lg font-semibold">Daten</h2>
        <div className="mb-2 mt-4 grid grid-cols-2 gap-2">
          <Property label="Mitglieder" value={`${club.membersCount} / 500`} />
          <Property label="Kennung" value={club.cid} />
          <Property label="Vereinsnummer" value={club.registrationNumber} />
          <Property label="Mindestalter" value={club.minimumAge} />
          <Property label="E-Mail" value={club.email} />
          <Property label="Telefonnummer" value={club.phoneNumber} />
        </div>
      </section>

      <Property
        label="Beschreibung"
        value={club.description}
        className="max-w-[800px]"
      />

      <section className="mt-6">
        <h2 className="mb-2 text-lg font-semibold">Addresse</h2>
        <div className="mb-2 mt-4 grid grid-cols-2 gap-2">
          <Property label="Straße und Hausnummer" value={club.address.street} />
          <Property label="PLZ" value={club.address.postalCode} />
          <Property label="Stadt" value={club.address.city} />
          <Property label="CO" value={club.address.co} />
          <Property label="Land" value={club.address.country} />
        </div>
      </section>

      <section className="mt-6">
        <h2 className="mb-2 text-lg font-semibold">Social & Links</h2>
        <div className="mb-2 mt-4 grid grid-cols-2 gap-2">
          <Property label="Website" value={club.socialInfo.website} />
          <Property label="Instagram" value={club.socialInfo.instagram} />
          <Property label="Facebook" value={club.socialInfo.facebook} />
          <Property label="X" value={club.socialInfo.twitter} />
          <Property label="WhatsApp" value={club.socialInfo.whatsapp} />
          <Property label="YouTube" value={club.socialInfo.youtube} />
        </div>
      </section>

      <section className="mt-6">
        <h2 className="mb-2 text-lg font-semibold">Config</h2>
        <div>
          <ConfigProperty label="Warteliste" value={club.allowWaitlist} />
        </div>
      </section>
    </div>
  );
}
