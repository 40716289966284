import { ClubVisibility } from '@clubsoul/const';
import { Badge, BadgeProps } from '@ui/components';

import { Eye, EyeOff } from 'lucide-react';

type ClubVisibilityBadgeProps = {
  visibility: ClubVisibility;
};

export default function ClubVisibilityBadge({
  visibility,
}: ClubVisibilityBadgeProps) {
  const props: BadgeProps = {
    size: 'sm',
    className: 'flex items-center gap-2',
  };

  if (visibility === ClubVisibility.PRIVATE) {
    return (
      <Badge variant="inactive" {...props}>
        <EyeOff size={14} />
        <span>Privat</span>
      </Badge>
    );
  }

  if (visibility === ClubVisibility.PUBLIC) {
    return (
      <Badge variant="active" {...props}>
        <Eye size={14} />
        Öffentlich
      </Badge>
    );
  }

  return null;
}
