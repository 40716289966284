import { ConsoleEditClubRequest } from '@clubsoul/api-contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { clubKeys } from '@/api/clubs/club.keys';
import { authClient } from '@/lib/axios';

export const useUpdateClub = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fields: ConsoleEditClubRequest) =>
      authClient.patch(`console/clubs/${id}`, fields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clubKeys.get(id) });
    },
  });
};
