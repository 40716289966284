import { TextInputTransform } from '../../types/input.types';

export const defaultTextTransform: TextInputTransform = {
  input: (v) => (v === undefined ? '' : v),
  output: (v) => v,
};

export const numberTextTransform: TextInputTransform<
  number | undefined,
  string
> = {
  input: (v?) => (v === undefined ? '' : v.toString()),
  output: (v) => (v === '' ? undefined : parseInt(v)),
};

export const floatTextTransform: TextInputTransform<
  number | undefined,
  string
> = {
  input: (v?) => (v === undefined ? '' : v.toString()),
  output: (v) => (v === '' ? undefined : parseFloat(v)),
};
