import { ErrorComponentProps } from '@tanstack/react-router';

import ErrorComponent from '@/components/errors/ErrorComponent';
import { isAxiosError } from 'axios';

type PageErrorProps = ErrorComponentProps;

export default function PageError({ error }: PageErrorProps) {
  if (isAxiosError(error)) {
    return <ErrorComponent description={error.response?.data.message} />;
  }

  return <ErrorComponent />;
}
