import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Avatar, AvatarFallback, AvatarImage } from '@ui/components';

import { getUserByIdQueryOptions } from '@/api/users/useGetUsers';
import { LONG_DATE_FORMAT, TIME_FORMAT } from '@/const';
import { format } from 'date-fns';
import { Mail } from 'lucide-react';

export const Route = createFileRoute('/_auth/users/$userId')({
  loader: ({ context: { queryClient }, params: { userId } }) => {
    return queryClient.ensureQueryData(getUserByIdQueryOptions(userId));
  },
  component: UserPage,
});

function UserPage() {
  const userId = Route.useParams().userId;
  const { data: user } = useSuspenseQuery(getUserByIdQueryOptions(userId));

  return (
    <div className="container">
      <header className="mb-6">
        <div className="flex justify-between">
          <div className="mb-2 flex items-center gap-4">
            <Avatar className="h-14 w-14">
              <AvatarImage src={user.profilePic} />
              <AvatarFallback>
                {user.firstName.charAt(0)}
                {user.lastName.charAt(0)}
              </AvatarFallback>
            </Avatar>
            <div>
              <h1 className="font-heading text-3xl font-semibold">
                {user.firstName} {user.lastName} ({user.club?.name})
              </h1>
              <span className="text-xs text-muted-foreground">
                ID: {user.id}
              </span>
            </div>
          </div>
        </div>
      </header>
      <p className="text-sm text-muted-foreground">
        Erstellt am {format(user.createdAt, LONG_DATE_FORMAT)}{' '}
        {format(user.createdAt, TIME_FORMAT)}
      </p>
      <p className="text-sm text-muted-foreground">
        Aktualisiert am {format(user.updatedAt, LONG_DATE_FORMAT)}{' '}
        {format(user.updatedAt, TIME_FORMAT)}
      </p>

      <section className="mt-6">
        <a
          href={`mailto:${user.email}`}
          className="flex items-center gap-2 text-sm text-blue-600 underline-offset-4 hover:underline"
        >
          <Mail size={16} />
          {user.email}
        </a>
      </section>
    </div>
  );
}
