import { FieldValues, FormState } from 'react-hook-form';

import { formatISO } from 'date-fns';
import { z } from 'zod';

import { Nullable } from '../types/helper.types';

export const FLOAT_REGEX = /^(0|[1-9]\d*)(\.\d+)?$/;

export const emptyStringToUndefined = <T>(v: T): T | undefined =>
  v === '' ? undefined : (v as T);

export const emptyStringToNull = (v: unknown): Nullable<string> =>
  v === '' ? null : (v as string);

export const transformDateToIso = (v?: Date) =>
  v ? formatISO(v, { representation: 'date' }) : null;

export const transformDateToSafeIso = (v: Date) =>
  formatISO(v, { representation: 'date' });

export const undefinedToNull = <Input>(v: Input) =>
  v === undefined ? null : v;

export const stringOrUndefinedToNull = (v: string | undefined) =>
  undefinedToNull(v);
export const stringToDate = (v: unknown) => {
  if (v instanceof Date) return v;
  return v ? new Date(v as string) : undefined;
};

export const stringToFloat = (v: string, ctx: z.RefinementCtx) => {
  if (v === '') {
    return undefined;
  }

  const match = FLOAT_REGEX.exec(v);

  if (!match) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Ungültige Zahl',
    });
  }

  return parseFloat(v);
};

export const preprocessEmptyStringToUndefined = <
  T extends z.ZodType<string | undefined>,
>(
  schema: T,
) => {
  return z.effect(schema, {
    type: 'preprocess',
    transform: emptyStringToUndefined,
  });
};

export const preprocessStringToFloat = <T extends z.ZodType<string>>(
  schema: T,
) => {
  return z.effect(schema, {
    type: 'preprocess',
    transform: stringToFloat,
  });
};

export const preprocessStringToDate = <
  T extends z.ZodType<string | Date | undefined>,
>(
  schema: T,
) => {
  return z.effect(schema, {
    type: 'preprocess',
    transform: stringToDate,
  });
};

export const mapToDirtyFields = <Input extends FieldValues>(
  fields: Input,
  dirtyFields: FormState<Input>['dirtyFields'],
): Partial<Input> => {
  return Object.keys(dirtyFields).reduce((final, key) => {
    return {
      ...final,
      [key]: fields[key],
    };
  }, {});
};
