import { Alert, AlertDescription, AlertTitle } from '@ui/components';

import { AlertCircle } from 'lucide-react';

type ErrorComponentProps = {
  title?: string;
  description?: string;
};

export default function ErrorComponent({
  title = 'Fehler',
  description = 'Scheisse Wolfgang, es ist einen Fehler aufgetretten.',
}: ErrorComponentProps) {
  return (
    <Alert variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
}
