import { FieldValues, Path, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form-base';
import { Input, InputProps } from '../../components/ui/input';

import { cn } from '../../lib/utils';

import { FieldTransform } from '../../types/input.types';

import { ReactNode } from 'react';
import { defaultTextTransform } from './helpers';

export type FormTextInputProps<T extends FieldValues> = {
  name: Path<T>;
  label?: string;
  placeholder?: InputProps['placeholder'];
  type?: InputProps['type'];
  transform?: Required<FieldTransform>;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  description?: ReactNode;
};

export function FormTextInput<T extends FieldValues>({
  name,
  label,
  type = 'text',
  placeholder,
  transform = defaultTextTransform,
  disabled,
  className,
  required = false,
  description,
}: FormTextInputProps<T>) {
  const { control } = useFormContext();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={cn(className)}>
          {label && (
            <FormLabel>
              {label} {required && '*'}
            </FormLabel>
          )}
          <FormControl>
            <Input
              ref={field.ref}
              className={cn({
                'border-red-600': fieldState.error,
              })}
              type={type}
              placeholder={placeholder}
              autoComplete="new-password"
              value={transform.input(field.value)}
              onChange={(e) => field.onChange(transform.output(e.target.value))}
              onBlur={field.onBlur}
              disabled={disabled}
              required={required}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
