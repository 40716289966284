import { ReactNode, Ref, useRef, useState } from 'react';

import { MB, MimeType } from '@clubsoul/const';

import { Camera } from 'lucide-react';
import { useRerender } from '../hooks';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  ImageLoadingStatus,
} from './ui/avatar';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import {
  UploadAvatarDialog,
  UploadAvatarDialogProps,
} from './upload-avatar-dialog';

type UploadAvatarProps = Pick<UploadAvatarDialogProps, 'onUpload'> & {
  imgUrl?: string;
  fallback: ReactNode;
  onDelete?: () => void;
};

const ALLOWED_MIMES = {
  [MimeType.PNG]: [],
  [MimeType.JPEG]: [],
  [MimeType.WEBP]: [],
};
const MAX_SIZE = 5 * MB; // 5 MB

export function UploadAvatar({
  imgUrl,
  fallback,
  onUpload,
  onDelete,
}: UploadAvatarProps) {
  const [open, setOpen] = useState(false);
  const [retryKey, rerender] = useRerender();
  const retries = useRef(5);
  const retryInterval = useRef<ReturnType<typeof setInterval>>();

  const handleOnLoadChange = (status: ImageLoadingStatus) => {
    if (status === 'error' && imgUrl) {
      retryInterval.current = setInterval(() => {
        retry();
      }, 500);
    } else {
      clearInterval(retryInterval.current);
    }
  };

  const retry = () => {
    if (retries.current <= 0) {
      clearInterval(retryInterval.current);
      return;
    }

    retries.current -= 1;
    rerender();
  };

  return (
    <div className="relative">
      <Avatar key={retryKey} className="aspect-square h-full w-full">
        <AvatarImage src={imgUrl} onLoadingStatusChange={handleOnLoadChange} />
        <AvatarFallback>{fallback}</AvatarFallback>
      </Avatar>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button className="absolute bottom-0 right-0 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-primary p-2">
            <Camera size={'1.5rem'} strokeWidth={1.75} color="white" />
            <span className="sr-only">Bild bearbeiten</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-20" align="start">
          <DropdownMenuGroup>
            <UploadAvatarDialog
              onUpload={onUpload}
              maxSize={MAX_SIZE}
              accept={ALLOWED_MIMES}
              renderTrigger={(
                ref: Ref<HTMLElement>,
                onSelect: (event: Event) => void,
              ) => (
                <DropdownMenuItem
                  ref={ref as Ref<HTMLDivElement>}
                  onSelect={onSelect}
                >
                  <span className="text-xs">Bearbeiten</span>
                </DropdownMenuItem>
              )}
            />

            {imgUrl && (
              <DropdownMenuItem onClick={onDelete}>
                <span className="text-xs">Löschen</span>
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
