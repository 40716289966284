'use client';

import { createContext, useContext } from 'react';

export type StepperState = {
  activeStep: number;
  onNext: () => void;
  onPrev: () => void;
  totalSteps: number;
};

export const StepperContext = createContext<StepperState | undefined>(
  undefined,
);

export function useStepper(): StepperState {
  const context = useContext(StepperContext);

  if (!context) {
    throw new Error('useStepper must be used with StepperProvider');
  }

  return context;
}
