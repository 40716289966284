import { Pageable, UserFilters } from '@clubsoul/api-contracts';

export const userKeys = {
  base: ['users'],
  get: (filters: UserFilters, pagination: Pageable) => [
    ...userKeys.base,
    { ...filters, ...pagination },
  ],
  getOne: (id: string) => [...userKeys.base, id],
};
