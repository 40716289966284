import { ReactNode } from '@tanstack/react-router';

type PropertyProps = {
  label: string;
  value: ReactNode;
  className?: string;
};

export default function Property({ label, value, className }: PropertyProps) {
  return (
    <div className={className}>
      <span className="block font-semibold">{label}</span>
      <span className="block text-sm">{value ?? '--'}</span>
    </div>
  );
}
