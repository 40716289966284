import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FormTextInput,
  FormWithMethods,
  SubmitButton,
} from '@ui/components';
import { getApiError } from '@ui/utils/apiError';

import { useDeleteClub } from '@/api/clubs/useDeleteClub';
import { toast } from 'sonner';
import { z } from 'zod';

type ClubRemoveDialogProps = {
  trigger: ReactNode;
  id: string;
};

const errorMessage = `Gib "delete permanently" ein, um den Club und dessen
Daten für immer zu löschen.`;

const DeleteClubConfirmationSchema = z.object({
  confirmation: z
    .string({
      message: errorMessage,
    })
    .refine((val) => val === 'delete permanently', errorMessage),
});

export default function ClubRemoveDialog({
  trigger,
  id,
}: ClubRemoveDialogProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { mutateAsync: remove, isPending } = useDeleteClub(id);

  const form = useForm({
    defaultValues: {
      confirmation: '',
    },
    resolver: zodResolver(DeleteClubConfirmationSchema),
    shouldUnregister: true,
  });

  const handleSubmit = async () => {
    try {
      await remove();
      toast.success('Club erfolgreich gelöscht');
      form.reset();
      navigate({ to: '/clubs' });
      setOpen(false);
    } catch (e) {
      toast.error(getApiError(e).message);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Club löschen</DialogTitle>
        </DialogHeader>

        <p>Diese Aktion kann nicht rückgängig gemacht werden!</p>

        <FormWithMethods {...form} onSubmit={handleSubmit}>
          <FormTextInput
            name="confirmation"
            label="Club Löschung bestätigen"
            description={
              <span className="text-xs">
                Gib <i>"delete permanently"</i> ein, um den Club und dessen
                Daten für immer zu löschen.
              </span>
            }
          />
          <div className="flex gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => setOpen(false)}
            >
              Abbrechen
            </Button>
            <SubmitButton
              variant="destructive"
              isLoading={isPending}
              disabled={!form.formState.isValid}
            >
              Löschen
            </SubmitButton>
          </div>
        </FormWithMethods>
      </DialogContent>
    </Dialog>
  );
}
