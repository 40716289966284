import { ReactNode, useState } from 'react';

import {
  CreateBetaCodeRequest,
  CreateBetaCodeSchema,
} from '@clubsoul/api-contracts';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormTextInput,
  SubmitButton,
} from '@ui/components';
import { getApiError } from '@ui/utils/apiError';

import { useCreateBetaCode } from '@/api/clubs/useCreateBetaCode';

type CreateBetaCodeDialogProps = {
  trigger: ReactNode;
};

export default function CreateBetaCodeDialog({
  trigger,
}: CreateBetaCodeDialogProps) {
  const [open, setOpen] = useState(false);
  const { mutateAsync: create, isPending, error } = useCreateBetaCode();

  const handleSubmit = async (data: CreateBetaCodeRequest) => {
    try {
      await create(data);
      setOpen(false);
    } catch (error) {}
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Beta code erstellen</DialogTitle>
        </DialogHeader>

        <Form onSubmit={handleSubmit} schema={CreateBetaCodeSchema}>
          <FormTextInput type="email" required name="email" label="E-Mail" />
          <FormTextInput required name="firstName" label="Vorname" />
          <FormTextInput required name="lastName" label="Nachname" />
          <div>
            <SubmitButton isLoading={isPending}>Erstellen</SubmitButton>
            {error && (
              <span className="mt-2 block text-sm text-red-600">
                {getApiError(error).message ?? 'Fehler aufgetreten'}
              </span>
            )}
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
