'use client';

import { ReactElement, ReactNode, Ref, forwardRef } from 'react';

import { DialogContentProps, DialogProps } from '@radix-ui/react-dialog';

import { DropdownMenuItem } from '../../components/ui/dropdown-menu';

import { Dialog, DialogTrigger } from './dialog';

export type NestedDialogProps = DialogProps & {
  onSelect?(): void;
  onOpenChange?(open: boolean): void;
  children: ReactElement<DialogContentProps>;
  renderTrigger: (
    ref: Ref<HTMLElement>,
    onSelect: (event: any) => void,
  ) => ReactNode;
};

const NestedDialog = (
  { children, onSelect, onOpenChange, renderTrigger, open }: NestedDialogProps,
  ref: Ref<HTMLElement>,
) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        {renderTrigger(ref, (event: any) => {
          event.preventDefault();
          onSelect && onSelect();
        })}
      </DialogTrigger>
      {children}
    </Dialog>
  );
};

NestedDialog.displayName = 'NestedDialog';

export default forwardRef<
  React.ElementRef<typeof DropdownMenuItem>,
  NestedDialogProps
>(NestedDialog);
