import { AxiosError } from 'axios';
import {
  ApiError,
  ApiErrorResponse,
  ValidationErrorResponse,
} from '../types/api.types';

export function getApiError<ErrorCode>(error: unknown): {
  code: ErrorCode | ApiError;
  message: ApiErrorResponse<ErrorCode>['message'];
} {
  if (error instanceof AxiosError) {
    if (error.response) {
      const apiError = error.response.data as ApiErrorResponse<ErrorCode>;

      if (apiError.error === ApiError.VALIDATION_ERROR) {
        return getValidationErrors(
          apiError as unknown as ValidationErrorResponse,
        );
      }

      return {
        code: apiError.error as ErrorCode,
        message: apiError.message,
      };
    }
  }

  return {
    code: ApiError.UNKNOWN_ERROR,
    message: 'Unbekannter fehler aufgetretten',
  };
}

export function getValidationErrors(error: ValidationErrorResponse) {
  const errors = [];

  for (const e of error.issues) {
    errors.push(e.message);
  }

  return {
    code: ApiError.VALIDATION_ERROR,
    message: errors.join('\n'),
  };
}
