import { Loader2 } from 'lucide-react';

type LoaderProps = {
  message?: string;
};

export function Loader({ message = 'Lade Daten...' }: LoaderProps) {
  return (
    <div className="flex w-full flex-col items-center justify-center">
      <Loader2 className="mb-2 animate-spin" />
      <span className="text-sm">{message}</span>
    </div>
  );
}
